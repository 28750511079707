import React, { useEffect, useRef, useState } from "react";
import Headernew from '../Common/Headernew';
import '/node_modules/bootstrap/dist/css/bootstrap.min.css';
import '/node_modules/bootstrap/dist/js/bootstrap.min.js';
//import { Link } from 'react-router-dom';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import PersonIcon from '@mui/icons-material/Person';
import '../Common/custom-style.css';
//import { findAllByDisplayValue } from "@testing-library/react";


function NimasApplication() {
	useEffect(() => {
	}, []);

    let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
    const bookingInfo = JSON.parse(localStorage.getItem("Booking-Info"));
    const userStudentinfo = JSON.parse(localStorage.getItem("user-Studentinfo"));
    const BookingType = localStorage.getItem("Booking-Type");
    const [error, setError] = useState('');
    if (userStudentinfo === null || userStudentinfo === undefined) {
        window.location.href = '/Student-admin/Login';
    }
    if (bookingInfo === null || bookingInfo === undefined) {
        window.location.href = '/';
    }
    const loginpra = { "NumberOfFieldsView": 50, "filters": { "Email": userStudentinfo?.records[0][8].value, "Password": userStudentinfo?.records[0][9].value }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 4 }
    //console.log(userStudentinfo)
    const [fullName, setfullName] = useState("");
    const [gender, setgender] = useState("");
    const [dob, setDOB] = useState("");
    const [mobile, setmobile] = useState("");
    const [address, setaddress] = useState("");
    const [City, setCity] = useState("");
    const [state, setState] = useState("");
    const [pincode, setPincode] = useState("");
    const [altmobile, setAltmobile] = useState("");
    const [country, setcountry] = useState("");
    const [aadharNumber, setaadharNumber] = useState("");
    const [nir, setnir] = useState("");
    const [nationality, setnationality] = useState("");
    const [fathersName, setfathersName] = useState("");
    function fun_Login() {
        fetch(
            "https://api.dialerp.com/api/api/getTemplateDataList",
            {
                method: "POST",
                headers: {
                    "Authorization-Token": authToken,
                    Accept: "application/json, text/plain",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify(loginpra),
            }
        ).then((result) => {
            result.json().then((resp) => {
                if (resp.response?.records.length > 0) {
                    //localStorage.setItem("user-Studentinfo", JSON.stringify(resp.response)); 

                    setfullName(resp.response?.records[0][5].value);
                    setgender(resp.response?.records[0][10].value);
                    //setDOB(userStudentinfo?.records[0][11].value);
                    setmobile(resp.response?.records[0][6].value);
                    setaddress(resp.response?.records[0][12].value);
                    setCity(resp.response?.records[0][13].value);
                    setState(resp.response?.records[0][14].value);
                    setPincode(resp.response?.records[0][15].value);

                    setAltmobile(resp.response?.records[0][7].value);
                    setcountry(resp.response?.records[0][16].value);
                    setaadharNumber(resp.response?.records[0][17].value);
                    setnir(resp.response?.records[0][18].value);
                    setnationality(resp.response?.records[0][19].value);
                    setfathersName(resp.response?.records[0][20].value);

                    const timeStamp = Date.parse(resp.response.records[0][11].value);
                    const date = new Date(timeStamp);
                    const year = date.getFullYear();
                    const month = date.getMonth() + 1;
                    const dateVal = date.getDate();
                    const formattedDate_ = `${dateVal < 10 ? "0" + dateVal : dateVal}-${month < 10 ? "0" + month : month}-${year}`;
                    const formattedDate = formattedDate_.split("-").reverse().join("-");
                    setDOB(formattedDate);
                    //getcategoryList();
                }
                else {
                    window.location.href = '/Student-admin/Login';
                    //alert('Invalid username or password');
                }
            });
        });
    }
    // const [categoryList, setcategoryList] = useState([]);
    // const categoryandcoursepara = { "NumberOfFieldsView": 50, "filters": {}, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 3 }
    // function getcategoryList() {
    //     fetch(
    //         "https://api.dialerp.com/api/api/getTemplateDataList",
    //         {
    //             method: "POST",
    //             headers: {
    //                 "Authorization-Token":
    //                     "oq+R3B3lt5I1bybDbHvscBRQDkBF8ek9cciW21c2Lza9XNAKeGE13bKO1giqxZ/uL2fM+m2QLjbSkMpBo3qM9DqDqDv7SojZA+5WpHVMh+tsnC9HUXan4CKJ8/bI4/2Lqa7DMzLXd1LqL9KA1iuY8g==",
    //                 Accept: "application/json, text/plain",
    //                 "Content-Type": "application/json;charset=UTF-8",
    //             },
    //             body: JSON.stringify(categoryandcoursepara),
    //         }
    //     ).then((result) => {
    //         result.json().then((resp) => {
    //             setcategoryList(resp);
    //         });
    //     });
    // }
    // const [CategoryName, setCategoryName] = useState("");
    // const [CourseName, setCourseName] = useState("");
    // const [CourseList, setCourseList] = useState([]);
    // function getcourseList(categoryName) {
    //     debugger
    //     setCourseName("");
    //     const coursepara = { "NumberOfFieldsView": 50, "filters": {"Category Name":categoryName}, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 3 }
    //     setCategoryName(categoryName);
    //     fetch(
    //         "https://api.dialerp.com/api/api/getTemplateDataList",
    //         {
    //             method: "POST",
    //             headers: {
    //                 "Authorization-Token":
    //                     "oq+R3B3lt5I1bybDbHvscBRQDkBF8ek9cciW21c2Lza9XNAKeGE13bKO1giqxZ/uL2fM+m2QLjbSkMpBo3qM9DqDqDv7SojZA+5WpHVMh+tsnC9HUXan4CKJ8/bI4/2Lqa7DMzLXd1LqL9KA1iuY8g==",
    //                 Accept: "application/json, text/plain",
    //                 "Content-Type": "application/json;charset=UTF-8",
    //             },
    //             body: JSON.stringify(coursepara),
    //         }
    //     ).then((result) => {
    //         result.json().then((resp) => {
    //             setCourseList(resp);
    //         });
    //     });
    // }
    // const newcategoryList = [];
    // categoryList?.response?.records?.forEach(obj => {
    //     if (!newcategoryList.some(o => o[4].value === obj[4].value)) {
    //         newcategoryList.push({ ...obj });
    //     }
    // });
    // const NewcourseList = [];
    // CourseList?.response?.records?.forEach(obj => {
    //     if (!NewcourseList.some(o => o[5].value === obj[5].value)) {
    //         NewcourseList.push({ ...obj });
    //     }
    // });

    const maxBookingPra = { "NumberOfFieldsView": 50, "filters": {}, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 5 }
    function fun_MaxbookingId() {
        fetch(
            "https://api.dialerp.com/api/api/getTemplateDataList",
            {
                method: "POST",
                headers: {
                    "Authorization-Token": authToken,
                    Accept: "application/json, text/plain",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify(maxBookingPra),
            }
        ).then((result) => {
            result.json().then((resp) => {
                let value = 2023000001
                if (resp.response?.records.length > 0) {
                    setBookingID(resp.response?.records[0][3].value + 1);
                    //console.log(BookingID);
                }
                else {
                    setBookingID(value);
                }
            });
        });
    }
    useEffect(() => {
        fun_Login();
        fun_MaxbookingId();
    }, [0]);

    const updatestudentpara = {
        "templateID": "4"
        , "_id": userStudentinfo?.records[0][0].value
        , "_1": userStudentinfo?.records[0][3].value
        , "_2": ""
        , "_3": fullName
        , "_4": mobile
        , "_5": altmobile
        , "_6": userStudentinfo?.records[0][8].value
        , "_7": userStudentinfo?.records[0][9].value
        , "_8": gender
        , "_9": dob
        , "_10": address
        , "_11": City
        , "_12": state
        , "_13": pincode
        , "_14": country
        , "_15": aadharNumber
        , "_16": nir
        , "_17": nationality
        , "_18": fathersName
        , "_19": userStudentinfo?.records[0][21].value
    }
    function fun_UpdateStudentInfo() {
        fetch("https://api.dialerp.com/api/api/addUpdateTemplateDataWeb",
            {
                method: "POST",
                headers: {
                    "Authorization-Token": authToken,
                    Accept: "application/json, text/plain",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify(updatestudentpara),
            }
        ).then((result) => {
            result.json().then((resp) => {
                if (resp.hasErrors === false) {
                    // alert("Profile update successfully");
                    fun_addBooking();
                }
                else {
                    alert(resp.errors);
                }
            });
        });
    }
    const [BookingID, setBookingID] = useState("");
    const [NextofKin, setNextofKin] = useState("");
    const [Noofseats, setNoofseats] = useState(1);
    const [HeightinCM, setHeightinCM] = useState("");
    const [WeightinKG, setWeightinKG] = useState("");
    const [BMI, setBMI] = useState("");


    const [ShoeSize, setShoeSize] = useState("");
    const [AcademicQualification, setAcademicQualification] = useState("");
    const [Anyspecialqualificationhobbies, setAnyspecialqualificationhobbies] = useState("");
    const [NextofKinWithAddressTelephone, setNextofKinWithAddressTelephone] = useState("");
    const [InsurancePolicyNo, setInsurancePolicyNo] = useState("");
    const [DietaryDetails, setDietaryDetails] = useState("");
    const [RefundPolicyStatus] = useState("Yes");
    const [BookingStatus] = useState("In-Review");
    const [age, setage] = useState("");



    const [isrefund, setrefund] = useState(false);
    const refundCheckboxChange = (event) => { setrefund(event.target.checked); };
    const [ispolicy, setpolicy] = useState(false);
    const policyCheckboxChange = (event) => { setpolicy(event.target.checked); };
    const [isentries, setentries] = useState(false);
    const entriesCheckboxChange = (event) => { setentries(event.target.checked); };
    const [isinjury, setinjury] = useState(false);
    const injuryCheckboxChange = (event) => { setinjury(event.target.checked); };

    const ref = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);

    function fun_bookingValidation() {
        fun_MaxbookingId();
        if (fullName === "") {
            // alert("Enter Full Name");
            // return;
            setError("Enter Full Name");
            document.getElementById('fullName').focus();
            return;
        }
        else if (mobile === "") {
            // alert("Enter Mobile No");
            setError("Enter Mobile No");
            document.getElementById('dyourmobile').focus();
            return;
        }
        else if (altmobile === "") {
            // alert("Enter Alternate Mobile Number");
            setError("Enter Alternate Mobile Number");
            document.getElementById('altmobile').focus();
            return;
        }
        else if (gender === "") {
            //alert("Select Gender Type");
            setError("Select Gender Type");
            document.getElementById('male').focus();
            return;
        }
        else if (dob === "") {
            // alert("Select Date Of Birth");
            setError("Select Date Of Birth");
            document.getElementById('dob').focus();
            return;
        }
        else if (address === "") {
            setError("Enter Address");
            //alert("Enter Address");
            return;
        }
        else if (City === "") {
            // alert("Enter City");
            setError("Enter City");
            return;
        }
        else if (state === "") {
            // alert("Enter State");
            setError("Enter State");
            return;

        }
        else if (pincode === "") {
            // alert("Enter PinCode");
            setError("Enter PinCode");
            document.getElementById('pincode').focus();
            return;
        }
        // if(country==="")
        // {
        //     alert("Enter Address");
        //     return;
        // }
        else if (aadharNumber === "") {
            // alert("Enter aadhar number");
            setError("Enter aadhar number");
            document.getElementById('aadharnumber').focus();
            return;
        }
        else if (aadharNumber.length !== 12 && aadharNumber.length !== undefined) {
            // alert("Enter valid aadhar number");
            setError("Enter valid aadhar number");
            document.getElementById('aadharnumber').focus();
            return;
        }
        else if (nir === "") {
            setError("select NIR Type");
            // alert("select NIR Type");
            document.getElementById('yes').focus();
            return;
        }
        else if (nationality === "") {
            setError("Enter Nationality");
            document.getElementById('nationality').focus();
            // alert("Enter Nationality");
            return;
        }
        else if (fathersName === "") {
            setError("Enter Father's Name");
            // alert("Enter Father's Name"); 
            document.getElementById('fName').focus();
            return;
        }
        else if (age === "") {
            setError("Enter Age on the date of applicaton");
            // alert("Enter Age on the date of applicaton"); 
            document.getElementById('age').focus();
            return;
        }
        else if (NextofKin === "") {
            setError("Select any nextof kin");
            // alert("Enter Age on the date of applicaton"); 
            document.getElementById('cars').focus();
            return;
        }
        else if (Noofseats === '') {
            // alert("Enter no of seats");
            setError("Enter no of seats");
            //return;
            document.getElementById('textnoofseats').focus();;
        }
        else if (HeightinCM === '') {
            setError("Enter height in CM");
            // alert("Enter height in CM");
            document.getElementById('textHeight').focus();;
        }
        else if (WeightinKG === '') {
            // alert("Enter weight in KG");
            setError("Enter weight in KG");
            document.getElementById('textWeight').focus();;
        }
        else if (ShoeSize === '') {
            setError("Enter shoe size");
            //alert("Enter shoe size");
            document.getElementById('textShoeSize').focus();;
        }
        else if (AcademicQualification === '') {
            setError("Enter academic qualification");
            // alert("Enter academic qualification");
            document.getElementById('textAcademicQualification').focus();;
        }
        else if (Anyspecialqualificationhobbies === '') {
            setError("Enter any special qualificati or hobbies");
            // alert("Enter academic qualification");
            document.getElementById('Anyspecialqualificationhobbies').focus();;
        }
        else if (NextofKinWithAddressTelephone === '') {
            setError("Enter next of kin with address telephone");
            // alert("Enter academic qualification");
            document.getElementById('NextofKinWithAddressTelephone').focus();;
        }
        else if (InsurancePolicyNo === '') {
            setError("Enter insurance policyNo");
            // alert("Enter academic qualification");
            document.getElementById('InsurancePolicyNo').focus();;
        }
        else if (DietaryDetails === '') {
            setError("Select dietary details");
            // alert("Select dietary details");
            document.getElementById('veg').focus();;
        }
        else if (isinjury === false) {
            const injury = document.getElementById('injury');
            injury.focus();
            setError("Kindly select Refund Policy !!");
            //  alert("Kindly select Refund Policy !!");
        }
        else if (isentries === false) {
            const entries = document.getElementById('entries');
            entries.focus();
            setError("Kindly select Refund Policy !!");
            //alert("Kindly select Refund Policy !!");
        }
        else if (ispolicy === false) {
            const policy = document.getElementById('policy');
            policy.focus();
            // alert("Kindly select Refund Policy !!");
            setError("Kindly select Refund Policy !!");
        }
        else if (isrefund === false) {
            const refund = document.getElementById('refund');
            refund.focus();
            // alert("Kindly select Refund Policy !!");
            setError("Kindly select Refund Policy !!");
        }
        else {
            if(InsurancePolicyNo===""||InsurancePolicyNo===null){
                setInsurancePolicyNo("0");
            }
            //setIsLoading(true)
            fun_UpdateStudentInfo();
            //setIsLoading(false)
        }

        //BookingID
        // NextofKin

        // HeightinCM
        // WeightinKG
        // BMI
        // ShoeSize
        // AcademicQualification
        // Anyspecialqualificationhobbies
        // NextofKinWithAddressTelephone
        // InsurancePolicyNo
        // DietaryDetails
        // RefundPolicyStatus
        // BookingStatus
    }
    const addBookingpra = {
        "templateID": "5"
        , "_1": BookingID
        , "_2": bookingInfo[4].value
        , "_3": bookingInfo[5].value
        , "_4": bookingInfo[9].value
        , "_5": NextofKin
        , "_6": Noofseats
        , "_7": HeightinCM
        , "_8": WeightinKG
        , "_9": BMI
        , "_10": ShoeSize
        , "_11": AcademicQualification
        , "_12": Anyspecialqualificationhobbies
        , "_13": NextofKinWithAddressTelephone
        , "_14": InsurancePolicyNo
        , "_15": DietaryDetails
        , "_16": RefundPolicyStatus
        , "_17": BookingStatus
        , "_18": bookingInfo[8].value
        , "_19": bookingInfo[10].value
        , "_20": bookingInfo[11].value
        , "_21": age
        , "_22": fullName
        , "_23": userStudentinfo?.records[0][8].value
        , "_24": ""
        , "_25": ""
    }
    function fun_addBooking() {
        fetch("https://api.dialerp.com/api/api/addUpdateTemplateDataWeb",
            {
                method: "POST",
                headers: {
                    "Authorization-Token": authToken,
                    Accept: "application/json, text/plain",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify(addBookingpra),
            }
        ).then((result) => {
            result.json().then((resp) => {
                if (resp.hasErrors === false) {
                    alert("Add booking successfully");
                    //setIsLoading(false)
                    //window.location.href = "/student-admin/payment?bid=" + { BookingID } + "";
                    // const url = `/student-admin/payment?bid=${BookingID}`;
                    // const newTab = window.open(url,'');
                    // newTab.focus();
                    window.location.href = `/student-admin/payment?bid=${BookingID}`;
                }
                else {
                    alert(resp.errors);
                    // setIsLoading(false)
                }
            });
        });
    }

    useEffect(() => {
        if (WeightinKG && HeightinCM) {
            // Convert height from cm to meters
            const heightInMeters = HeightinCM / 100;

            // Calculate BMI
            const calculatedBMI = WeightinKG / (heightInMeters * heightInMeters);

            // Set the BMI state
            setBMI(calculatedBMI.toFixed(2));
        } else {
            // Reset the BMI state if weight or height is empty
            setBMI(null);
        }
    }, [WeightinKG, HeightinCM]);


    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const handleClick2 = () => {
        ref2.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const handleClick3 = () => {
        ref3.current?.scrollIntoView({ behavior: 'smooth' });
    };

    //const [isLoading, setIsLoading] = useState(false);
    return (
        <>
            <div><Headernew /></div>
            {/* {isLoading && (
                <div className="page-loader">
                    <div class="loader-container">
                        <h1 class="loader-image">Loading...</h1>
                    </div>
                </div>
            )} */}
            <main id="main" className="main mainss">
                <section className="section dashboard">
                    <div className="container-fluid pb-200">
                        <div className="row">
                            <div className="col-lg-12 col-sm-12">
                                <div className="pagetitle">
                                    <nav>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                                            {/* student-admin/dashboard */}
                                            <li className="breadcrumb-item active">Book a Course ({bookingInfo[4].value})</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="card cards">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8 col-sm-12">

                                        <div className="row">
                                            <div className="col-lg-8 col-sm-12">
                                                <div className="single-camp-hi">
                                                    <img src="../admin/img/adventure/570x250/mountaineering.png" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6">
                                                <div className="single-camp-hi">
                                                    <img src="../admin/img/adventure/275x250/mountaineering_1.png" alt="" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-lg-4 col-md-4 col col-sm-12">

                                        <div className="lgnPersuation red">  <DirectionsRunIcon className="redn" /> <p className="lgnPersuation__text">Hurry! Last few seats remaining for your dates on NIMAS</p></div>

                                        <div className="prmRoomDtlCard">

                                            <div className="prmRoomDtlCard__inner">
                                                <span className="inlineBlock font16 latoBlack blackText truncate appendBottom7" data-testid="baseSrRoomName heading" >{bookingInfo[5].value}</span>
                                                <div className="makeFlex">
                                                    <div className="prmRoomDtlCard__left">
                                                        <p className="makeFlex hrtlCenter appendBottom12 pr"> <PersonIcon className="icoo" />   <span className="font12">For {Noofseats !== '' ? Noofseats : '0'} Person</span></p>
                                                        <ul className="prmRoomFacilities">
                                                            <li className="prmRoomFacilities__item onFcHover">

                                                                <p className="prmRoomFacilities__item--text"><mat-icon className="uico">done</mat-icon> Free Cancellation till Joining</p>

                                                            </li>
                                                            <li className="prmRoomFacilities__item">

                                                                <p className="prmRoomFacilities__item--text"><mat-icon className="uico">done</mat-icon> Positive environment</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="prmRoomDtlCard__right">
                                                        <div className="prmRoomDtlCard__right maxWidth170">

                                                            <p className="grayText font16 lineThrough">₹{bookingInfo[12].value}</p>
                                                            <p className="font20 blackText latoBlack price">₹{bookingInfo[12].value}</p>

                                                            <p className="font12 latoBold appendTop5 grayText">+ ₹ 0 taxes &amp; fees</p>
                                                            <p className="font12 redText appendTop5">Saving ₹ 0</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="prmRoomDtlCard__footer">
                                                {/* <span className="prmRoomDtlCard__link">VIEW OTHER COURSES</span> */}
                                                <span className="bookNowBtn "><button className="primaryBtn" onClick={fun_bookingValidation}>BOOK THIS NOW</button></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 titless">
                                        <h1 className="">{bookingInfo[5].value}</h1>
                                        <p className="grayText">{BookingType} | Age:{bookingInfo[14].value} Years</p>
                                    </div>
                                </div>

                                <div className="row cdetails">
                                    <div className="col-md-12">


                                        <div id="guide-template">

                                            <div id="nav-bar">
                                                <div className="topmenu" id="fixed">

                                                    <a className="link" id="one" onClick={handleClick}>Personal Details</a>

                                                    <a className="link" id="two" onClick={handleClick2}>Eligibility </a>

                                                    <a className="link" id="three" onClick={handleClick3}>Refund Policy </a>
                                                </div>
                                            </div>
                                            <div id="mainn" className="login-form">

                                                <div className="wrapper">
                                                    <div id="sectionA" className="section" ref={ref} >
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <p><b>Personal Details</b></p>
                                                            </div>
                                                        </div>
                                                        <div className="form-left">
                                                            <div className="row">
                                                                <div className="col-md-3 col-xl-3">

                                                                    <label for="fullName" class="form-label">Name<span class="required">*</span></label>
                                                                    {/* <label>Name</label> */}
                                                                    <div className="single-field">
                                                                        <input type="text" placeholder="Name" id="fullName" value={fullName} onChange={(e) => { setfullName(e.target.value); setError(''); }}
                                                                            className={`form-control ${error && fullName === '' ? 'is-invalid' : ''}`}
                                                                        />
                                                                        {error && fullName === '' && <div className="invalid-feedback">Please enter fullName</div>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 col-xl-3">
                                                                    <label>Email</label>
                                                                    <div className="single-field">
                                                                        <input type="text" className="form-control" placeholder="Email" id="userStudentinfo" value={userStudentinfo?.records[0][8].value} disabled />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 col-xl-3">

                                                                    <label for="yourmobile" class="form-label">Mobile<span class="required">*</span></label>
                                                                    <div className="single-field">
                                                                        <input type="text"
                                                                            value={mobile} onChange={(e) => { setmobile(e.target.value); setError(''); }}
                                                                            placeholder="Mobile" id="dyourmobile"
                                                                            className={`form-control ${error && mobile === '' ? 'is-invalid' : ''}`}
                                                                        />
                                                                        {error && mobile === '' && <div className="invalid-feedback">Please enter mobile no</div>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 col-xl-3">
                                                                    {/* <label>Alternate Mobile Number</label> */}
                                                                    <label for="altmobile" class="form-label">Alternate Mobile Number<span class="required">*</span></label>
                                                                    <div className="single-field">
                                                                        <input type="text" placeholder="Alternate Mobile Number" id="altmobile"
                                                                            value={altmobile} onChange={(e) => { setAltmobile(e.target.value); setError(''); }}
                                                                            className={`form-control ${error && altmobile === '' ? 'is-invalid' : ''}`}
                                                                        />
                                                                        {error && altmobile === '' && <div className="invalid-feedback">Please enter alternate mobile no</div>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 col-xl-3">
                                                                    <label>Gender</label>
                                                                    <div className="single-field checkboxesfield">
                                                                        <div> <input type="radio" id="male" value="Male" checked={gender === "Male"} onChange={(e) => { setgender(e.target.value); setError(''); }} name="gender" className={`${error && gender === '' ? 'is-invalid' : ''}`} />
                                                                            <label htmlFor="male">Male</label>
                                                                            {error && gender === '' && <div className="invalid-feedback">Please select gender type</div>}
                                                                        </div>
                                                                        <div><input type="radio" id="female" value="Female" checked={gender === "Female"} onChange={(e) => { setgender(e.target.value); setError(''); }} name="gender" className={`${error && gender === '' ? 'is-invalid' : ''}`} />
                                                                            <label htmlFor="female"> Female</label>
                                                                            {error && gender === '' && <div className="invalid-feedback">Please select gender type</div>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 col-xl-3">
                                                                    <label for="dob" class="form-label">Date of Birth<span class="required">*</span></label>
                                                                    {/* <label>Date of Birth</label> */}
                                                                    <div className="single-field">
                                                                        <input type="date" id="dob"
                                                                            placeholder="Date of Birth" value={dob} onChange={(e) => { setDOB(e.target.value); setError(''); }} className={`form-control ${error && dob === '' ? 'is-invalid' : ''}`} />
                                                                        {error && dob === '' && <div className="invalid-feedback">Please select date of birth</div>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 col-xl-3">
                                                                    {/* <label>Age on the date of applicaton</label> */}
                                                                    <label for="age" class="form-label">Age on the date of applicaton<span class="required">*</span></label>
                                                                    <div className="single-field">
                                                                        <input type="text" className={`form-control ${error && age === '' ? 'is-invalid' : ''}`} id="age" placeholder="Age on the date of applicaton" value={age} onChange={(e) => { setage(e.target.value); setError(''); }} />
                                                                        {error && age === '' && <div className="invalid-feedback">Age on the date of applicaton</div>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 col-xl-3">
                                                                    {/* <label>Father's Name</label> */}
                                                                    <label for="fName" class="form-label">Father's Name<span class="required">*</span></label>
                                                                    <div className="single-field">
                                                                        <input type="text" className={`form-control ${error && fathersName === '' ? 'is-invalid' : ''}`} id="fName" placeholder="Father's Name" value={fathersName} onChange={(e) => { setfathersName(e.target.value); setError(''); }} />
                                                                        {error && fathersName === '' && <div className="invalid-feedback">Please enter father's name</div>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 col-xl-3">
                                                                    {/* <label>Aadhar Number</label> */}
                                                                    <label for="aadharnumber" class="form-label">Aadhar Number<span class="required">*</span></label>
                                                                    <div className="single-field">
                                                                        <input type="text" className={`form-control ${error && aadharNumber === '' ? 'is-invalid' : ''}`} minLength={12} maxLength={12} placeholder="Aadhar Number" id="aadharnumber" value={aadharNumber} onChange={(e) => { setaadharNumber(e.target.value); setError(''); }} />
                                                                        {error && aadharNumber === '' && <div className="invalid-feedback">Please enter aadhar number</div>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 col-xl-3">
                                                                    <label for="nationality" class="form-label">Nationality<span class="required">*</span></label>
                                                                    {/* <label>Nationality</label> */}
                                                                    <div className="single-field">
                                                                        <input type="text" placeholder="Nationality" id="nationality"
                                                                            value={nationality} onChange={(e) => { setnationality(e.target.value); setError(''); }} className={`form-control ${error && nationality === '' ? 'is-invalid' : ''}`} />
                                                                        {error && nationality === '' && <div className="invalid-feedback">Please enter nationality</div>}
                                                                    </div>
                                                                </div>


                                                                <div className="col-md-3 col-xl-3">
                                                                    {/* <label>NRI</label> */}
                                                                    <label for="yes" class="form-label">NRI<span class="required">*</span></label>
                                                                    <div className="single-field checkboxesfield">
                                                                        <div>
                                                                            <input type="radio" id="yes" value="Yes" checked={nir === "Yes"} onChange={(e) => { setnir(e.target.value); setError(''); }} name="nri" className={` ${error && nir === '' ? 'is-invalid' : ''}`} />
                                                                            {/* {error && nir === '' && <div className="invalid-feedback">Please select NIR type</div>} */}
                                                                            <label htmlFor="yes">Yes</label>
                                                                        </div>
                                                                        <div>
                                                                            <input type="radio" id="no" value="No" checked={nir === "No"} onChange={(e) => { setnir(e.target.value); setError(''); }} name="nri" className={` ${error && nir === '' ? 'is-invalid' : ''}`} />
                                                                            <label htmlFor="no"> No</label>
                                                                            {error && nir === '' && <div className="invalid-feedback">Please select NIR type</div>}
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 col-xl-3">
                                                                    {/* <label>Next of Kin</label> */}
                                                                    <label for="NextofKin" class="form-label">Next of Kin<span class="required">*</span></label>
                                                                    <div className="single-field">
                                                                        <select name="cars" id="cars" value={NextofKin} onChange={(e) => { setNextofKin(e.target.value); setError(''); }} className={`form-control ${error && NextofKin === '' ? 'is-invalid' : ''}`}>
                                                                            <option value="">Next of Kin</option>
                                                                            <option value="Mother">Mother</option>
                                                                            <option value="Father">Father</option>
                                                                            <option value="Sister">Sister</option>
                                                                            <option value="Brother">Brother</option>
                                                                        </select>
                                                                        {error && NextofKin === '' && <div className="invalid-feedback">Please select next of kin</div>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 col-xl-6">
                                                                    {/* <label>Permanent Address</label> */}
                                                                    <label for="Address" class="form-label">Permanent Address<span class="required">*</span></label>
                                                                    <div className="single-field">
                                                                        <input type="text" className={`form-control ${error && address === '' ? 'is-invalid' : ''}`} id="Address" placeholder="Permanent Address"
                                                                            value={address} onChange={(e) => { setaddress(e.target.value); setError(''); }} />
                                                                        {error && address === '' && <div className="invalid-feedback">Please enter address</div>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 col-xl-3">
                                                                    {/* <label>Pincode</label> */}
                                                                    <label for="pincode" class="form-label">Pincode<span class="required">*</span></label>
                                                                    <div className="single-field">
                                                                        <input type="text" className={`form-control ${error && pincode === '' ? 'is-invalid' : ''}`} id="pincode" placeholder="Pincode"
                                                                            value={pincode} onChange={(e) => { setPincode(e.target.value); setError(''); }} />
                                                                        {error && pincode === '' && <div className="invalid-feedback">Please enter pincode</div>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 col-xl-3">
                                                                    <label>Course Name</label>
                                                                    <div className="single-field">
                                                                        <input type="text" className="form-control" id="courseName" placeholder="Course Name" value={bookingInfo[5].value} disabled />
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-md-3 col-xl-3">
                                                                    <label for="CategoryName" class="form-label">Category Name<span class="required">*</span></label>
                                                                    <div className="single-field">
                                                                        <select name="cars" id="cars" value={CategoryName} onChange={(e) => { getcourseList(e.target.value); setError(''); }} className={`form-control ${error && CategoryName === '' ? 'is-invalid' : ''}`}>
                                                                        <option value="" selected>--Select Category--</option>
                                                                            {newcategoryList?.map((dd, i) => {
                                                                                return (
                                                                                    <option value={dd[4].value}>{dd[4].value}</option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                        {error && CategoryName === '' && <div className="invalid-feedback">Please select category Name</div>}
                                                                    </div>
                                                                </div> 
                                                                 <div className="col-md-3 col-xl-3">
                                                                    <label for="CourseName" class="form-label">CourseName<span class="required">*</span></label>
                                                                    <div className="single-field">
                                                                        <select name="cars" id="cars" value={CourseName} onChange={(e) => { setCourseName(e.target.value); setError(''); }} className={`form-control ${error && CourseName === '' ? 'is-invalid' : ''}`}>
                                                                        <option value="" selected>--Select Course--</option>
                                                                            {NewcourseList?.map((dd, i) => {
                                                                                return (
                                                                                    <option value={dd[5].value}>{dd[5].value}</option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                        {error && CourseName === '' && <div className="invalid-feedback">Please select category Name</div>}
                                                                    </div>
                                                                </div> 
                                                                 */}
                                                                <div className="col-md-3 col-xl-3">
                                                                    {/* <label>No of seats</label> */}
                                                                    <label for="textnoofseats" class="form-label">No of seats<span class="required">*</span></label>
                                                                    <div className="single-field">
                                                                        <input type="number" className={`form-control ${error && Noofseats === '' ? 'is-invalid' : ''}`} id="textnoofseats"
                                                                            value={Noofseats} onChange={(e) => { setNoofseats(e.target.value); setError(''); }} placeholder="No of seats" disabled />
                                                                        {error && Noofseats === '' && <div className="invalid-feedback">Please enter no of seats</div>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-xl-12">
                                                                    <div className="single-field">
                                                                        <p><b>Note : </b></p>
                                                                        <ul className="listing">
                                                                            <li>1. Please enclose Address Proof, If NRI, please mention country and job/profession with documents. </li>
                                                                            <li>2. All applicants to enclose copy of following documents:-
                                                                                <ul>
                                                                                    <li>(a) Aaadhar Card <b>(Mandatory)</b></li>
                                                                                    <li>(b) Passport or Driving License</li>
                                                                                    <li>(c) Pan Card </li>
                                                                                    <li>(d) Basic Course certificates for students applying for <b>Advance courses.</b></li>
                                                                                </ul>

                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 col-xl-6">
                                                                    <div className="row">
                                                                        <div className="col-md-6 col-xl-6">
                                                                            <div className="single-field">
                                                                                <label htmlFor="BMI"> Height in CM<span class="required">*</span></label>
                                                                                <input type="text" className={`form-control ${error && HeightinCM === '' ? 'is-invalid' : ''}`}
                                                                                    id="textHeight" placeholder="Height in CM" value={HeightinCM} onChange={(e) => { setHeightinCM(e.target.value); setError(''); }} />
                                                                                {error && HeightinCM === '' && <div className="invalid-feedback">Please enter height in CM</div>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 col-xl-6">
                                                                            <div className="single-field">
                                                                                <label htmlFor="BMI"> Weight in KG<span class="required">*</span></label>
                                                                                <input type="text" className={`form-control ${error && WeightinKG === '' ? 'is-invalid' : ''}`}
                                                                                    id="textWeight" placeholder="Weight in KG" value={WeightinKG} onChange={(e) => { setWeightinKG(e.target.value); setError(''); }} style={{ margin: '5px 0px;' }} />
                                                                                {error && WeightinKG === '' && <div className="invalid-feedback">Please enter weight in KG</div>}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div className="col-md-3 col-xl-3">
                                                                    <div className="single-field">
                                                                        <label htmlFor="BMI"> BMI</label>
                                                                        <input type="text" className="form-control" id="bmi" placeholder="BMI" value={BMI} onChange={(e) => { setBMI(e.target.value); setError(''); }} disabled />

                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 col-xl-3">
                                                                    <div className="single-field">
                                                                        <label htmlFor="ShoeSize"> Shoe Size<span class="required">*</span></label>
                                                                        <input type="" className={`form-control ${error && ShoeSize === '' ? 'is-invalid' : ''}`}
                                                                            placeholder="Shoe Size" id="textShoeSize" value={ShoeSize} onChange={(e) => { setShoeSize(e.target.value); setError(''); }} />
                                                                        {error && ShoeSize === '' && <div className="invalid-feedback">Please enter shoe size</div>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-xl-12">
                                                                    <div className="single-field">
                                                                        <p><b>Note : </b></p>
                                                                        <ul className="listing">
                                                                            <li>Maximum weight permissible – 90 Kg (for all courses). </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div id="sectionB" className="section" ref={ref2}>

                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <p><b>Eligibility for Advance Courses : </b></p>
                                                            </div>
                                                        </div>
                                                        <div className="form-left">
                                                            <div className="row">
                                                                <div className="col-md-12 col-xl-12">
                                                                    <div className="single-field">

                                                                        <ul className="listing">
                                                                            <li>(a) AMC - BMC with ‘A’ grading. </li>
                                                                            <li>(b) MOI & S&R - AMC with ‘A’ grading. </li>
                                                                            <li>(c) Intermediate Paragliding(P3 level)- Basic Paragliding with QR grading.</li>
                                                                            <li>(d) Intermediate Para Motoring - Basic Para Motoring with ‘A’ grading.
                                                                            </li>
                                                                            <li> (e) Advance Scuba Diving - Basic Scuba Diving with ‘A’ grading.</li>
                                                                            <li> (f) High Altitude Scuba Diving - NIMAS Basic Scuba Diving course with QR grading or PADI qualified in Basic open water course.
                                                                            </li>
                                                                            <li></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-3 col-xl-3">
                                                                    <div className="single-field">
                                                                        <label htmlFor="AcademicQualification"> Academic Qualification<span class="required">*</span></label>
                                                                        <input type="text" className={`form-control ${error && AcademicQualification === '' ? 'is-invalid' : ''}`}
                                                                            placeholder="Academic Qualification"
                                                                            value={AcademicQualification} onChange={(e) => { setAcademicQualification(e.target.value); setError(''); }} id="textAcademicQualification" />
                                                                        {error && AcademicQualification === '' && <div className="invalid-feedback">Please enter academic qualification</div>}
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-3 col-xl-3">
                                                                    <div className="single-field">
                                                                        <label htmlFor="hobbies"> Any special qualification or hobbies<span class="required">*</span></label>
                                                                        <input type="text" className={`form-control ${error && Anyspecialqualificationhobbies === '' ? 'is-invalid' : ''}`} placeholder="Any special qualification or hobbies"
                                                                            id="Anyspecialqualificationhobbies" value={Anyspecialqualificationhobbies} onChange={(e) => { setAnyspecialqualificationhobbies(e.target.value); setError(''); }} />
                                                                        {error && Anyspecialqualificationhobbies === '' && <div className="invalid-feedback">Please enter special qualification hobbies</div>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 col-xl-3">
                                                                    <div className="single-field">
                                                                        <label htmlFor="Kin"> Next of Kin (in the event of any eventually)<span class="required">*</span></label>
                                                                        <input type="text" className={`form-control ${error && NextofKinWithAddressTelephone === '' ? 'is-invalid' : ''}`} placeholder="Next of Kin with address & Telephone"
                                                                            id="NextofKinWithAddressTelephone" value={NextofKinWithAddressTelephone} onChange={(e) => { setNextofKinWithAddressTelephone(e.target.value); setError(''); }} />
                                                                        {error && NextofKinWithAddressTelephone === '' && <div className="invalid-feedback">Please enter Next of Kin With Address Telephone</div>}
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-3 col-xl-3">
                                                                    <div className="single-field">
                                                                        <label htmlFor="Insurance"> Insurance<span class="required">*</span></label>
                                                                        <input type="text" className={`form-control ${error && InsurancePolicyNo === '' ? 'is-invalid' : ''}`} placeholder="Insurance Policy No"
                                                                            id="InsurancePolicyNo" value={InsurancePolicyNo} onChange={(e) => { setInsurancePolicyNo(e.target.value); setError(''); }} />
                                                                        {error && InsurancePolicyNo === '' && <div className="invalid-feedback">Please enter insurance policy no</div>}
                                                                    </div>
                                                                </div>



                                                                <div className="col-md-4 col-xl-4">
                                                                    <div className="single-field checkboxesfield">
                                                                        <div><label for="textnoofseats" class="form-label">Dietary Details :<span class="required">*</span></label>
                                                                            {/* <p>Dietary Details : </p> */}
                                                                        </div>
                                                                        <div> <input type="radio" id="veg" value="Veg" checked={DietaryDetails === "Veg"}
                                                                            onChange={(e) => { setDietaryDetails(e.target.value); setError(''); }}
                                                                            name="diet" className={`${error && DietaryDetails === '' ? 'is-invalid' : ''}`} />
                                                                            <label htmlFor="veg">Veg</label>
                                                                        </div>

                                                                        <div>
                                                                            <input type="radio" id="nonveg" value="Non-veg" checked={DietaryDetails === "Non-veg"}
                                                                                onChange={(e) => { setDietaryDetails(e.target.value); setError(''); }}
                                                                                name="diet" />

                                                                            <label htmlFor="nonveg"> Non-veg</label>
                                                                        </div>
                                                                        <div>
                                                                            <input type="radio" id="both" value="Veg and Non-veg" checked={DietaryDetails === "Veg and Non-veg"}
                                                                                onChange={(e) => { setDietaryDetails(e.target.value); setError(''); }}
                                                                                name="diet" className={`${error && DietaryDetails === '' ? 'is-invalid' : ''}`} />
                                                                            <label htmlFor="both"> Both</label>
                                                                            {error && DietaryDetails === '' && <div className="invalid-feedback">Please select dietary details</div>}
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>


                                                    </div>

                                                    <div id="sectionC" className="section" ref={ref3}>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <p><b>Refund Policy :</b></p>
                                                            </div>
                                                        </div>
                                                        <div className="form-left">

                                                            <div className="row">



                                                                <div className="col-md-12 col-xl-12">
                                                                    <div className="single-field">

                                                                        <div className="table-responsive mb-30 booking-table">
                                                                            <table className="table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Sr.</th>
                                                                                        <th>No of days</th>
                                                                                        <th>% of course fee to be refunded</th>
                                                                                        <th>Remarks</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>(a)</td>
                                                                                        <td>*90 days earlier </td>
                                                                                        <td>100%</td>
                                                                                        <td rowspan="4">
                                                                                            *(i) Days prior to the commencement of the course.<br />
                                                                                            (ii) Cancellation to be intimated through mail.<br />
                                                                                            (iii) Seats are non transferable however original applicant can be given vacancy in the next course if vacancies are available.
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>(b)</td>
                                                                                        <td>*89-60 days</td>
                                                                                        <td>50%</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>(c)</td>
                                                                                        <td>*59-30 days</td>
                                                                                        <td>25%</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>(d)</td>
                                                                                        <td>Refund to students who are once
                                                                                            transferred their seat, RTH (Return to
                                                                                            Home) on medical, disciplinary,
                                                                                            personnel grounds, those physically
                                                                                            unfit to undergo the course
                                                                                            request for cancellation less than 30
                                                                                            days of period</td>
                                                                                        <td>Nil</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-xl-12">
                                                                    <div className="single-field single-fields">
                                                                        <input type="checkbox" id="injury" name="injury" value="injury"
                                                                            checked={isinjury}
                                                                            onChange={injuryCheckboxChange} className={`${error && isinjury === false ? 'is-invalid' : ''}`} />
                                                                        <label htmlFor="injury"> In case of any injury sustained during the course, I will not hold NIMAS responsible for the same.
                                                                        </label>
                                                                        {error && isinjury === false && <div className="invalid-feedback">Kindly select Refund Policy !!</div>}
                                                                    </div>

                                                                </div>
                                                                <div className="col-md-12 col-xl-12">
                                                                    <div className="single-field single-fields">
                                                                        <input type="checkbox" id="entries" name="entries" value="entries"
                                                                            checked={isentries}
                                                                            onChange={entriesCheckboxChange} className={`${error && isentries === false ? 'is-invalid' : ''}`} />
                                                                        <label htmlFor="entries"> The above entries have been made by me and are true and correct.</label>
                                                                        {error && isentries === false && <div className="invalid-feedback">Kindly select Refund Policy !!</div>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-xl-12">
                                                                    <div className="single-field single-fields">
                                                                        <input type="checkbox" id="policy" name="policy" value="policy"
                                                                            checked={ispolicy}
                                                                            onChange={policyCheckboxChange} className={`${error && ispolicy === false ? 'is-invalid' : ''}`} />
                                                                        <label htmlFor="policy"> I Accept Privacy Policy and Terms & Conditions.</label>
                                                                        {error && ispolicy === false && <div className="invalid-feedback">Kindly select Refund Policy !!</div>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-xl-12">
                                                                    <div className="single-field single-fields">
                                                                        <input type="checkbox" id="refund" name="refund" value="refund"
                                                                            checked={isrefund}
                                                                            onChange={refundCheckboxChange} className={`${error && isrefund === false ? 'is-invalid' : ''}`} />
                                                                        <label htmlFor="refund">I've read refund policies and agree.</label>
                                                                        {error && isrefund === false && <div className="invalid-feedback">Kindly select Refund Policy !!</div>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-xl-12">
                                                                    <div className="single-field">
                                                                        <button onClick={fun_bookingValidation} className="btn btn-primary">Book Course</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>

    );
}
export default NimasApplication;
