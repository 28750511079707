import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import '../Common/custom-style.css';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner'


function Register() {
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [imgurl, setimgurl] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [getmaxstudentid, setgetmaxstudentid] = useState('');
  const [gender, setgender] = useState("");
  const [dob, setDOB] = useState("");
  const [mobile, setmobile] = useState("");
  const [address, setaddress] = useState("");
  const [City, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [country, setcountry] = useState("");
  const [error, setError] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [selectedFile, setselectedfile] = useState(null);
  const [isLoader, setIsLoader] = useState(true);

  // setTimeout(() => {
  //   setIsLoader(false);
  // }, 10000);


  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const productdata2 = { "NumberOfFieldsView": 50, "filters": {}, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 4 }

  function handleId() {
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(productdata2),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.response?.records.length > 0) {
          setgetmaxstudentid(resp.response?.records[0][3].value + 1);
          //setimgurl(resp.response?.records[0][4].value);
        }
        else {
          setgetmaxstudentid(1)
        }
        setIsLoader(false);
      });
    });
  }
  useEffect(() => {
    handleId();
  }, [])
  // const signUPpara_ = {
  //   "templateID": "4",
  //   "_1": getmaxstudentid,
  //   "_2": ""
  //   , "_3": name
  //   , "_4": "", "_5": ""
  //   , "_6": email
  //   , "_7": password
  //   , "_8": "", "_9": "", "_10": "", "_11": "", "_12": "", "_13": "", "_14": "", "_15": "", "_16": "", "_17": "", "_18": ""
  // }
  const [Age, SetAge] = useState("");
  const signUPpara = {
    "templateID": "4"
    , "_1": getmaxstudentid
    , "_2": imgurl
    , "_3": name
    , "_4": mobile
    , "_5": ""
    , "_6": email
    , "_7": password
    , "_8": gender
    , "_9": dob
    , "_10": address
    , "_11": City
    , "_12": state
    , "_13": pincode
    , "_14": country
    , "_15": ""
    , "_16": ""
    , "_17": ""
    , "_18": ""
    , "_19": "isStudent"
    , "_20": Age
    , "_21": ""
    , "_22": ""
    , "_23": ""
    , "_24": ""
    , "_25": ""
    , "_26": ""
    , "_27": ""
    , "_28": ""
  }

  function onFileUpload() {

    const formData = new FormData();
    if (selectedFile != null) {
      setIsLoader(true);
      formData.append("myFile", selectedFile);
      formData.append("name", selectedFile.name);
      setselectedfile(selectedFile.name);

      axios.post('https://api.dialerp.com/api/api/UploadCommonFilesToAzure', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization-Token': authToken,
        },
      }).then((response) => {
        if (response.data.hasErrors === true) {
          alert(response.data.errors);
          setIsLoader(false);
        }
        else if (response.data.response !== null) {
          setimgurl(response.data.response[0].imageUrl);
          setIsLoader(false);
        }

      })
        .catch((err) => alert(err));
    }


  };

  function fun_signUP() {
    debugger
    SetAge("");
    var age = Math.floor((new Date() - new Date(dob)) / 1000 / 60 / 60 / 24 / 365.25)
    SetAge(age);
    if (name.trim() === "") {
      setError("required");
      return;
    }
    else if (dob.trim() === "") {
      setError('required"');
      return;
    }
    else if (age < 17 || age > 40) {
      setError("Enter age must be between 17 and 40.");
      document.getElementById('dob').focus();
      return;
    }
    else if (email.trim() === "") {
      setError('required');
      return;
    }
    else if (!emailRegex.test(email.trim())) {
      setError('required');
      return;
    }
    else if (password.trim() === "") {
      setError('required"');
      return;
    }
    else if (gender.trim() === "") {
      setError('required"');
      return;
    }
    else if (mobile.trim() === "") {
      setError('required"');
      return;
    }
    else if (address.trim() === "") {
      setError('required"');
      return;
    }
    else if (City.trim() === "") {
      setError('required"');
      return;
    }
    else if (state.trim() === "") {
      setError('required"');
      return;
    }
    else if (country.trim() === "") {
      setError('required"');
      return;
    }
    else if (pincode.trim() === "") {
      setError('required"');
      return;
    }
    else if (!isChecked) {
      setError('required"');
      return;
    }
    else {
      setIsLoader(true);
      fetch(
        "https://api.dialerp.com/api/api/getTemplateDataList",
        {
          method: "POST",
          headers: {
            "Authorization-Token": authToken,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(productdata2),
        }
      ).then((result) => {
        result.json().then((resp) => {
          if (resp.response?.records.length > 0) {
            setgetmaxstudentid(resp.response?.records[0][3].value + 1);

            fetch("https://api.dialerp.com/api/api/addUpdateTemplateDataWeb", {
              method: "POST",
              headers: {
                "Authorization-Token": authToken,
                Accept: "application/json, text/plain",
                "Content-Type": "application/json;charset=UTF-8",
              },
              body: JSON.stringify(signUPpara),
            }).then((result) => {
              result.json().then((resp) => {
                if (resp.hasErrors === false) {
                  alert("Account registered successfully. Please login");
                  setIsLoader(false);
                  window.location.href = '/Student-admin/Login';
                } else {
                  alert(resp.errors);
                }
                setIsLoader(false);
              });
            });
          }
          else {
            setgetmaxstudentid(1)
            fetch("https://api.dialerp.com/api/api/addUpdateTemplateDataWeb", {
              method: "POST",
              headers: {
                "Authorization-Token": authToken,
                Accept: "application/json, text/plain",
                "Content-Type": "application/json;charset=UTF-8",
              },
              body: JSON.stringify(signUPpara),
            }).then((result) => {
              result.json().then((resp) => {
                if (resp.hasErrors === false) {
                  alert("Account registered successfully. Please login");
                  setIsLoader(false);
                  window.location.href = '/Student-admin/Login';
                } else {
                  alert(resp.errors);
                }
                setIsLoader(false);
              });
            });
          }
        });
      });
    }
  }
  function handleKeyPress(e) {
    var key = e.key;
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  }
  return (
    <>
      {isLoader &&
        <div id="loadingg">
          <ThreeDots height="80" width="80" radius="9" color="#fff" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      }
      <div className="login">
        <div className="login-box">
          <img src="../admin/img/nimas_logo.png" alt="..." className="bylogo" />
          <h2 mat-h2>Create an Account</h2>
          <div className="card mb-3">
            <div className="card-body">
              <div className="col-12">
                <div>
                  <label htmlFor="yourUsername" className="form-label">Student Name<span class="required">*</span></label>
                  <div className="input-group">
                    <input type="text" name="name" value={name}
                      className={`form-control ${error && name.trim() === "" ? 'is-invalid' : ""}`}
                      onChange={(e) => setName(e.target.value)} id="Student Name" />
                    {error && name.trim() === "" && <div className="invalid-feedback">Please enter student name</div>}
                  </div>

                </div>
                <div>
                  <label htmlFor="yourUsername" className="form-label">Your Email<span class="required">*</span></label>
                  <div className="input-group">
                    <input type="text" value={email} name="email" onChange={(e) => setEmail(e.target.value)} id="Your Email"
                      className={`form-control ${error && (email.trim() === '' || !emailRegex.test(email.trim())) ? 'is-invalid' : ''}`}
                    />
                    {error && email.trim() === '' && <div className="invalid-feedback">Please enter email</div>}
                    {error && email.trim() !== '' && !emailRegex.test(email.trim()) && <div className="invalid-feedback">Please enter a valid email address</div>}
                  </div>

                </div>
                <div>
                  <label htmlFor="yourPassword" className="form-label">Password<span class="required">*</span></label>
                  <div className="input-group">
                    <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)}
                      className={`form-control ${error && password.trim() === '' ? 'is-invalid' : ''}`} id="yourPassword" />
                    {error && password.trim() === '' && <div className="invalid-feedback">Please enter password</div>}
                  </div>

                </div>

                <div className="single-field half-field-last">
                  <label htmlFor="Gender" className="form-label">Gender<span class="required">*</span></label>
                  <div className="input-group">
                    <select id="gender"
                      className={`form-control ${error && gender.trim() === '' ? 'is-invalid' : ''}`}
                      value={gender} onChange={(e) => setgender(e.target.value)}>
                      <option value="">Select gender</option>
                      <option value="Male" selected>Male</option>
                      <option value="Female" selected>Female</option>
                    </select>
                    {error && gender.trim() === '' && <div className="invalid-feedback">Please select gender</div>}
                  </div>
                </div>
                <div className="single-field half-field-last">
                  <label htmlFor="DOB" className="form-label">DOB<span class="required">*</span></label>
                  <div className="input-group">
                    <input name="dob" type="date"
                      className={`form-control ${error && dob.trim() === '' || error === 'Enter age must be between 17 and 40.' ? 'is-invalid' : ''}`}
                      id="dob" value={dob} onChange={(e) => setDOB(e.target.value)} />
                    {error && dob.trim() === ''
                      && <div className="invalid-feedback">Please select DOB</div>
                      || error === 'Enter age must be between 17 and 40.'
                      && <div className="invalid-feedback">Enter age must be between 17 and 40.</div>}
                  </div>
                </div>
                <div>
                  <label htmlFor="Mobile" className="form-label">Mobile<span class="required">*</span></label>
                  <div className="input-group">
                    <input name="fullName" type="text"
                      className={`form-control ${error && mobile.trim() === '' ? 'is-invalid' : ''}`}
                      id="Mobile" value={mobile} maxLength={10} onChange={(e) => setmobile(e.target.value)} onKeyPress={(e) => handleKeyPress(e)} />
                    {error && dob.trim() === '' && <div className="invalid-feedback">Please enter mobile no</div>}
                  </div>
                </div>
                <div>
                  <label htmlFor="Address" className="form-label">Address<span class="required">*</span></label>
                  <div className="input-group">
                    <input name="Address" type="text" id="Address" value={address} onChange={(e) => setaddress(e.target.value)}
                      className={`form-control ${error && address.trim() === '' ? 'is-invalid' : ''}`} />
                    {error && address.trim() === '' && <div className="invalid-feedback">Please enter address</div>}
                  </div>
                </div>
                <div className="single-field half-field-last">
                  <label htmlFor="City" className="form-label">City<span class="required">*</span></label>
                  <div className="input-group">
                    <input name="City" type="text" id="City" value={City} onChange={(e) => setCity(e.target.value)}
                      className={`form-control ${error && City.trim() === '' ? 'is-invalid' : ''}`} />
                    {error && City.trim() === '' && <div className="invalid-feedback">Please enter city</div>}
                  </div>
                </div>
                <div className="single-field half-field-last">
                  <label htmlFor="State" className="form-label">State<span class="required">*</span></label>
                  <div className="input-group">
                    <input name="State" type="text" id="State" value={state} onChange={(e) => setState(e.target.value)}
                      className={`form-control ${error && state.trim() === '' ? 'is-invalid' : ''}`} />
                    {error && state.trim() === '' && <div className="invalid-feedback">Please enter state</div>}
                  </div>
                </div>
                <div className="single-field half-field-last">
                  <label htmlFor="country" className="form-label">country<span class="required">*</span></label>
                  <div className="input-group">
                    <select id="country" value={country} onChange={(e) => setcountry(e.target.value)}
                      className={`form-control ${error && country.trim() === '' ? 'is-invalid' : ''}`} >
                      <option value="">Select Country</option>
                      <option value="India" selected>India</option>
                    </select>
                    {error && country.trim() === '' && <div className="invalid-feedback">Please select country</div>}
                  </div>
                </div>
                <div className="single-field half-field-last">
                  <label htmlFor="Pincode" className="form-label">Pin Code<span class="required">*</span></label>
                  <div className="input-group">
                    <input name="Pincode" type="number" id="Pincode " value={pincode} onChange={(e) => setPincode(e.target.value)}
                      className={`form-control ${error && pincode.trim() === '' ? 'is-invalid' : ''}`} />
                    {error && pincode.trim() === '' && <div className="invalid-feedback">Please enter pincode</div>}
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">Profile Pic</label>
                  <div>
                    <input type="file" accept=".png,.jpeg,.jpg,.JPEG" value={selectedFile} onChange={(e) => setselectedfile(e.target.files[0])} />
                    <button style={{ display: "none" }} onClick={onFileUpload()}>
                      Upload!
                    </button>
                  </div>
                </div>
              </div>



              <div className="col-12">
                <div className="form-check">
                  <input className="form-check-input" checked={isChecked}
                    onChange={handleCheckboxChange} type="checkbox" name="remember" value="true" id="rememberMe"
                  />
                  <label className="form-check-label terms" htmlFor="rememberMe">By proceeding, you agree to NIMAS User Agreement, Terms and Privacy Policy</label>
                  {error && isChecked === false && <p style={{ color: 'red' }}>Please accept the terms and conditions</p>}
                </div>
              </div>
              <div className="col-12">
                <button className="buttons" onClick={fun_signUP}>Create Account</button>
              </div>
              <div className="col-12">
                <p className="small mb-0 text-center terms">Already have an account? <Link to="/student-admin/login">Login</Link></p>
              </div>
            </div>
          </div>
          <small className="glogin">or Register with</small>
          <div className="google"><img src="../admin/img/google.png" alt="" className="bylogo" /></div>
        </div>

      </div>
    </>
  );
}
export default Register;