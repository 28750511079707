import { colors } from "@mui/material";
import React, {useEffect, useState } from "react";
import {Link} from 'react-router-dom';


function Header() {
    const userStudentinfo = JSON.parse(localStorage.getItem("user-Studentinfo"));
 
    return ( 
       
            <header id="header" className="header-area absulate-header">
            
                <div className="navigation-area">
                    <div className="container">
                        <div className="row navigation-bg">
                            <div className="col-lg-2 col-sm-4 col-4">
                               
                                <div className="site-logo">
                                    <Link to ="/"><img src="img/logo.png" alt="Nimas" /></Link>
                                </div>
                               
                            </div>
                            <div className="col-lg-10 col-sm-8 col-8 order-two">
                               
                                <div className="main-menu-wrap">
                                    <nav className="gene-nav">
                                    <ul className="menu">
                                        <li><Link to="/">Home</Link>
                                        </li>
                                        <li className="has-dropdown"><Link to="">About Us</Link>
                                            <ul>
                                            <li><Link to="/about-us">About Nimas</Link></li>
                                            <li><Link to="/story">Story</Link></li>
                                            <li><Link to="/aim">Aim</Link></li>
                                            <li><Link to="/campus">Campus</Link></li>
                                            <li><Link to="/Organisation">Organisation</Link></li>
                                            </ul>                                           
                                        </li>
                                        <li className="has-dropdown"><Link to="">Courses</Link>
                                            <ul>
                                            <li><Link to="/Mountaineering-courses">Mountaineering</Link></li>
                                            <li><Link to="/mtb-courses">Mountain Terrain Biking</Link></li>
                                            <li><Link to="/scuba-diving-courses">Scuba Diving</Link></li>
                                            <li><Link to="/rafting-courses">White Water Rafting</Link></li>
                                            <li><Link to="/paragliding-courses">Paragliding</Link></li>
                                            <li><Link to="/paramotor-courses">Paramotor</Link></li>
                                            </ul>                                           
                                        </li>

                                        <li><Link to="/news-and-events">News & Media</Link></li>
                                        <li><Link to="/faq">Faq</Link></li>
                                        <li><Link to="/downloads">Downloads</Link></li>
                                        <li><Link to="/contact-us">Contact Us</Link></li>
                                        {localStorage.getItem("user-Studentinfo") ? (
																<li><Link to="/student-admin/dashboard" ><i className="far fa-user"></i>{userStudentinfo?.records[0][5].value}</Link></li>
															) : (
																<li><Link to="/student-admin/login"><i className="far fa-user"></i> STUDENT LOGIN</Link></li>
                                                                
															)}
                                        {/* <li><Link to="/nimas-admin/login"><i className="far fa-user"></i> Admin LOGIN</Link></li> */}
                                        <li><Link to="/student-admin/login">Apply Online</Link></li>
                                    </ul>
                                    </nav> 
                                </div>
                                
                                <div className="mobile-menu-area"></div>
                               
                            </div>
                          
                        </div>
                    </div>
                </div>
            </header>
            


    );
  }
  
  export default Header;
  