import React, { useEffect, useState } from "react";
// import { Link } from 'react-router-dom';
import '../Common/custom-style.css';
import Headersidebar from '../Common/Headersidebar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import ApiIcon from '@mui/icons-material/Api';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import moment from 'moment'
import { ThreeDots } from 'react-loader-spinner'


const drawerWidth = 240;

function NimasDashboard() {
  localStorage.removeItem("StudentDetailinfo");
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  const userStudentinfo = JSON.parse(localStorage.getItem("user-admininfo"));
  if (userStudentinfo === null) {
    window.location.href = '/nimas-admin/login';
  }
  // const [getmaxstudentid, setgetmaxstudentid] = useState('');
  const [TotalStudents, setTotalStudents] = useState();
  const [ApprovedStudents, setApprovedStudents] = useState(0);
  const [CancelStudents, setCancelStudents] = useState(0);
  const [PendingApproval, setPendingApproval] = useState(0);
  const [DOCPending, setDOCPending] = useState(0);
  const [productdata, setProductData] = useState([]);
  // const [ReceiptListdata, SetReceiptListdata] = useState('');
  const Bookinglistpra = {
    "NumberOfFieldsView": 50, "filters": {},
    "isDownload": false, "index": 1, "pgSize": 10000, "templateID": 5
  };
  const [isLoader, setIsLoader] = useState(true);

  // setTimeout(() => {
  //   setIsLoader(false);
  // }, 3000);
  let PS = 0;
  let DP = 0;
  let AS = 0;
  let CS = 0;
  // let FS = 0;
  function handleId() {
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(Bookinglistpra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        setProductData(resp);
        // setTotalStudents(resp.response.records.filter((dl) => dl[26].value).length);        // const currentDate = new Date(); 
        setTotalStudents(resp.response.records.length);        // const currentDate = new Date(); 

        // resp.response.records?.filter((dl) => dl[26].value).forEach((obj) => {
        resp.response.records?.forEach((obj) => {
          const BookingStatus = obj[19]?.value;
          if (BookingStatus === "Confirmed") {
            AS++;
            setApprovedStudents(AS);
          }
          else if (BookingStatus === "In-Review"&& (obj[27]?.value !== "" && obj[29]?.value !== "" )) {
            PS++;
            setPendingApproval(PS);
          }
          else if ((obj[27]?.value === "" || obj[29]?.value === "")
          && BookingStatus !== "Confirmed"&&BookingStatus !== "Cancel" && BookingStatus !== "Failed") {
            // else if (BookingStatus === "In-Review" || BookingStatus === "Payment Upload") {
            DP++;
            setDOCPending(DP);
          }
          else if (BookingStatus === "Cancel" || BookingStatus === "Failed") {
            CS++;
            setCancelStudents(CS);
          }
        });
      });
      setIsLoader(false);
    });
  }
  useEffect(() => {
    handleId();
    localStorage.removeItem("bookinginfo");
  }, [0])
  function fun_getbookingidonclick(id) {
    localStorage.setItem("bookinginfo", id)
  }
  function fun_getStudentDetailclick(id) {
    localStorage.setItem("StudentDetailinfo", id)
  }
  return (
    <>
      {isLoader && <div id="loadingg">
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#fff"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>}
      <Box sx={{ display: 'flex' }} className="custcontent">
        <div>

          <Headersidebar />
        </div>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <main id="main" className="main dashmain">

            <div className="pagetitle">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/" target="_blank">Home</a></li>

                  <li className="breadcrumb-item active">Dashboard</li>
                </ol>
              </nav>
            </div>

            <section className="section dashboard">
              <div className="row">


                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-xxl-3 col-md-3">
                      <a href="/nimas-admin/bookings">
                        <div className="card info-card sales-card">


                          <div className="card-body">

                            <div className="d-flex align-items-center">
                              <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                <DashboardIcon />
                              </div>
                              <div className="ps-3">
                                <h5 className="card-title">Total Booking</h5>
                                <h6>{TotalStudents}</h6>

                              </div>
                            </div>
                          </div>

                        </div>
                      </a>
                    </div>
                    <div className="col-xxl-3 col-md-3">
                      <a href="/nimas-admin/in-review-bookings">
                        <div className="card info-card customers-card">
                          <div className="card-body">
                            <div className="d-flex align-items-center">
                              <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                <ApiIcon />
                              </div>
                              <div className="ps-3">
                                <h5 className="card-title">In-Review Booking</h5>
                                <h6>{PendingApproval}</h6>

                              </div>
                            </div>

                          </div>
                        </div>
                      </a>
                    </div>

                    <div className="col-xxl-3 col-md-3">
                      <a href="/nimas-admin/document-Pending">
                        <div className="card info-card customers-card">
                          <div className="card-body">
                            <div className="d-flex align-items-center">
                              <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                <ApiIcon />
                              </div>
                              <div className="ps-3">
                                <h5 className="card-title">Document Pending</h5>
                                <h6>{DOCPending}</h6>

                              </div>
                            </div>

                          </div>
                        </div>
                      </a>
                    </div>

                    <div className="col-xxl-3 col-md-3">
                      <a href="/nimas-admin/confirmbookings">
                        <div className="card info-card revenue-card">


                          <div className="card-body">

                            <div className="d-flex align-items-center">
                              <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">

                                <AcUnitIcon />
                              </div>
                              <div className="ps-3">
                                <h5 className="card-title">Confirmed Booking</h5>
                                <h6>{ApprovedStudents}</h6>

                              </div>
                            </div>
                          </div>

                        </div>
                      </a>
                    </div>
                    <div className="col-xxl-3 col-md-3">
                      <a href="/nimas-admin/cancel-bookings">
                        <div className="card info-card customers-card">
                          <div className="card-body">
                            <div className="d-flex align-items-center">

                              <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                <ApiIcon />
                              </div>
                              <div className="ps-3">
                                <h5 className="card-title">Cancel Booking</h5>
                                <h6>{CancelStudents}</h6>

                                {/* <h6><a href="/nimas-admin/pendingbookings" target="_blank">{PendingApproval}</a></h6> */}

                              </div>
                            </div>

                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>


              </div>

              <div className="row">


                <div className="col-md-12">
                  <div className="card recent-sales overflow-auto">



                    <div className="card-body">
                      <h5 className="card-title">Recent Bookings </h5>


                      <div className="tableresp">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Sr.</th>
                              <th scope="col">Date</th>
                              <th scope="col">Booking Id</th>
                              <th scope="col">Student Name</th>
                              <th scope="col">Serial No</th>
                              {/* <th scope="col">No of Seats</th> */}
                              <th scope="col">Adventure Name</th>
                              <th scope="col">Course Dates</th>
                              <th scope="col">Transaction Id</th>
                              <th scope="col">Payment Status</th>
                              <th scope="col">Cancel Reason</th>
                            </tr>
                          </thead>
                          <tbody>
                            {productdata?.response?.records.filter((dl) => dl[26]?.value).slice(0, 10).map((studinfo, i) => {
                              i++;
debugger
                              return (
                                <tr>
                                  <th scope="row">{i}</th>
                                  <td>{moment(studinfo[1]?.value).format("DD-MMM-YYYY hh:mm:ss")}</td>
                                  {/* <td><a href="/student-admin/bookingdetails"  onClick={localStorage.setItem("bookinginfo", studinfo[0].value)}>{studinfo[3].value}</a></td> */}
                                  <td><a href="/student-admin/invoicenew" onClick={() => fun_getbookingidonclick(studinfo[3]?.value)}>{studinfo[3]?.value}</a></td>
                                  <td><a href="/nimas-admin/student-info" onClick={() => fun_getStudentDetailclick(studinfo[25]?.value)}>{studinfo[24]?.value}</a></td>
                                  <td>{studinfo[6]?.value}</td>
                                  {/* <td>{studinfo[8].value}</td> */}
                                  <td>{studinfo[4]?.value}<br />({studinfo[5]?.value})</td>
                                  <td>{studinfo[21]?.value}<br />/{studinfo[22]?.value}</td>
                                  <td>{studinfo[26]?.value}</td>
                                  <td><span
                                    className={`datee 
                                ${studinfo[19]?.value === 'Confirmed' ? 'text-green' :
                                        studinfo[19]?.value === 'In-Review' ? 'text-blue' :
                                          studinfo[19]?.value === 'Payment Upload' ? 'text-blue' :

                                            studinfo[19]?.value === 'Failed' ? 'text-red' :
                                              studinfo[19]?.value === 'Cancel' ? 'text-red' : ''}`
                                    }>{studinfo[19]?.value}</span><br />
                                    <span className={`datee11 
                                ${studinfo[27]?.value === '' ? 'text-blue' : ''}`}>
                                      {studinfo[19]?.value === 'Payment Upload' ? "/ Document Pending" : ""}</span>
                                  </td>
                                  <td>{studinfo[28]?.value}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>
                </div>


              </div>
            </section>

          </main>
        </Box>
      </Box>
    </>
  );
}

NimasDashboard.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
export default NimasDashboard;
