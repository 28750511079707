import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import LogoutIcon from '@mui/icons-material/Logout';

function Headernew() {
  const userStudentinfo = JSON.parse(localStorage.getItem("user-Studentinfo"));
  //console.log(userStudentinfo?.records[0][4]?.value,"12121");
  function fun_LogOut() {
    localStorage.clear();
    window.location.href = '/student-admin/login';
  }
  useEffect(() => {

  }, []);
  return (
    <>
      <header id="header" className="header fixed-top d-flex align-items-center">

        <div className="d-flex align-items-center justify-content-between">
          <i className="bi bi-list toggle-sidebar-btn"></i>
          <Link to="/student-admin/dashboard" className="logo d-flex align-items-center">
            <img src="../admin/img/logo.jpg" alt="" />

          </Link>

        </div>
        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item dropdown pe-3 uderd">

              <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                <img src={userStudentinfo?.records[0][4]?.value !== "" ? userStudentinfo?.records[0][4]?.value : "../admin/img/profileicon.png"} alt="Profile" className="rounded-circle" style={{ height: '35px', width: '35px' }} />
                <span className="d-none d-md-block dropdown-toggle ps-2">
                  {localStorage.getItem("user-Studentinfo") ? (
                    userStudentinfo?.records[0][5]?.value
                  ) : (
                    'Student Name'
                  )}</span>
              </a>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile" style={{ marginRight: '10px 0px !important' }}>
                <li>
                  <Link className="dropdown-item d-flex align-items-center" to="/student-admin/dashboard">
                    <DashboardIcon className="sideico" />
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link className="dropdown-item d-flex align-items-center" to="/student-admin/booking">
                    <BookmarksIcon className="sideico" />
                    <span>My Bookings</span>
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link className="dropdown-item d-flex align-items-center" to="/student-admin/myprofile">
                    <PersonIcon className="sideico" />
                    <span>My Profile</span>
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>




                <li>
                  <Link className="dropdown-item d-flex align-items-center" to="#">
                    <LogoutIcon className="sideico" />
                    <span onClick={fun_LogOut}>Log Out</span>
                  </Link>
                </li>

              </ul>
            </li>

          </ul>
        </nav>

      </header>
    </>
  );
}

export default Headernew;
