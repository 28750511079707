import React, { useEffect, useState } from "react";
import '../Common/custom-style.css';
import { ThreeDots } from 'react-loader-spinner'




function NimasRegister() {
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [getmaxstudentid, setgetmaxstudentid] = useState('');
  const [gender, setgender] = useState("");
  const [dob, setDOB] = useState("");
  const [mobile, setmobile] = useState("");
  const [address, setaddress] = useState("");
  const [City, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [country, setcountry] = useState("");
  const [error, setError] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [isLoader, setIsLoader] = useState(true);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const productdata2 = { "NumberOfFieldsView": 50, "filters": {}, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 4 }

  function handleId() {
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(productdata2),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.response?.records.length > 0) {
          setgetmaxstudentid(resp.response?.records[0][3].value + 1);
        }
      });
      setIsLoader(false);
    });
    
  }
  useEffect(() => {
    handleId();
  }, [0])
  const signUPpara = {
    "templateID": "7"
    , "_1": getmaxstudentid, "_2": ""
    , "_3": name
    , "_4": mobile
    , "_5": ""
    , "_6": email
    , "_7": password
    , "_8": gender
    , "_9": dob, "_10": address, "_11": City, "_12": state, "_13": pincode, "_14": country, "_15": "", "_16": "", "_17": "", "_18": ""
    , "_19": "isAdmin"
  }
  function fun_signUP() {
    if (name.trim() === "") {
      setError("required");
      //document.getElementById('textnoofseats').focus();;
      return;
    }
    if (email.trim() === "") {
      setError('required');
      //document.getElementById('textnoofseats').focus();;
      return;
    }
    else if (!emailRegex.test(email.trim())) {
      setError('required');
      //document.getElementById('textnoofseats').focus();;
      return;
    }
    else if (password.trim() === "") {
      setError('required"');
      //document.getElementById('textnoofseats').focus();;
      return;
    }
    else if (gender.trim() === "") {
      setError('required"');
      //document.getElementById('textnoofseats').focus();;
      return;
    }
    else if (dob.trim() === "") {
      setError('required"');
      //document.getElementById('textnoofseats').focus();;
      return;
    }
    else if (mobile.trim() === "") {
      setError('required"');
      //document.getElementById('textnoofseats').focus();;
      return;
    }
    else if (address.trim() === "") {
      setError('required"');
      //document.getElementById('textnoofseats').focus();;
      return;
    }
    else if (City.trim() === "") {
      setError('required"');
      //document.getElementById('textnoofseats').focus();;
      return;
    }
    else if (state.trim() === "") {
      setError('required"');
      //document.getElementById('textnoofseats').focus();;
      return;
    }
    else if (country.trim() === "") {
      setError('required"');
      //document.getElementById('textnoofseats').focus();;
      return;
    }
    else if (pincode.trim() === "") {
      setError('required"');
      //document.getElementById('textnoofseats').focus();;
      return;
    }else if (!isChecked) {
      setError('required"');
      return;
    }
    fetch("https://api.dialerp.com/api/api/addUpdateTemplateDataWeb", {
      method: "POST",
      headers: {
        "Authorization-Token": authToken,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(signUPpara),
    }).then((result) => {
      result.json().then((resp) => {
        debugger;
        if (resp.hasErrors === false) {
          alert("Account registered successfully");
          window.location.href = '/nimas-admin/register';
        } else {
          alert(resp.errors);
        }
      });
    });
  }
  return (
    <>
    {isLoader &&
      <div id="loadingg">
        <ThreeDots height="80" width="80" radius="9" color="#fff" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
      </div>
    }
    <div className="login">
    <div className="login-box">
      <img src="../admin/img/nimas_logo.png" className="bylogo" />
      <h2 mat-h2>Create an Account</h2>
      <div className="card mb-3">
        <div className="card-body">
          <div className="col-12">
            <div>
              <label htmlFor="yourUsername" className="form-label">User Name<span class="required">*</span></label>
              <div className="input-group">
                <input type="text" name="name" value={name}
                  className={`form-control ${error && name.trim() === "" ? 'is-invalid' : ""}`}
                  onChange={(e) => setName(e.target.value)} id="Student Name" />
                   {error && name.trim() === "" && <div className="invalid-feedback">Please enter user name</div>}
              </div>
           
            </div>
            <div>
              <label htmlFor="yourUsername" className="form-label">Your Email<span class="required">*</span></label>
              <div className="input-group">
                <input type="text" value={email} name="email" onChange={(e) => setEmail(e.target.value)} id="Your Email"
                  className={`form-control ${error && (email.trim() === '' || !emailRegex.test(email.trim())) ? 'is-invalid' : ''}`}
                />
                   {error && email.trim() === '' && <div className="invalid-feedback">Please enter email</div>}
              {error && email.trim() !== '' && !emailRegex.test(email.trim()) && <div className="invalid-feedback">Please enter a valid email address</div>}
              </div>
           
            </div>
            <div>
              <label htmlFor="yourPassword" className="form-label">Password<span class="required">*</span></label>
              <div className="input-group">
                <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)}
                  className={`form-control ${error && password.trim() === '' ? 'is-invalid' : ''}`} id="yourPassword" />
                    {error && password.trim() === '' && <div className="invalid-feedback">Please enter password</div>}
              </div>
            
            </div>

            <div>
              <label htmlFor="Gender" className="form-label">Gender<span class="required">*</span></label>
              <div className="input-group">
                <select id="gender" 
                 className={`form-control ${error && gender.trim() === '' ? 'is-invalid' : ''}`}
                  value={gender} onChange={(e) => setgender(e.target.value)}>
                  <option value="">Select gender</option>
                  <option value="Male" selected>Male</option>
                  <option value="Female" selected>Female</option>
                </select>
                {error && gender.trim() === '' && <div className="invalid-feedback">Please select gender</div>}
              </div>
            </div>
            <div>
              <label htmlFor="DOB" className="form-label">DOB<span class="required">*</span></label>
              <div className="input-group">
                <input name="dob" type="date" 
                   className={`form-control ${error && dob.trim() === '' ? 'is-invalid' : ''}`}
                 id="dob" value={dob} onChange={(e) => setDOB(e.target.value)} />
                {error && dob.trim() === '' && <div className="invalid-feedback">Please select DOB</div>}
              </div>
            </div>
            <div>
              <label htmlFor="Mobile" className="form-label">Mobile<span class="required">*</span></label>
              <div className="input-group">
                <input name="fullName" type="text" 
                 className={`form-control ${error && mobile.trim() === '' ? 'is-invalid' : ''}`}
                  id="Mobile" maxLength={10} value={mobile} onChange={(e) => setmobile(e.target.value)} />
                    {error && dob.trim() === '' && <div className="invalid-feedback">Please enter mobile no</div>}
              </div>
            </div>
            <div>
              <label htmlFor="Address" className="form-label">Address<span class="required">*</span></label>
              <div className="input-group">
                <input name="Address" type="text" id="Address" value={address} onChange={(e) => setaddress(e.target.value)} 
                  className={`form-control ${error && address.trim() === '' ? 'is-invalid' : ''}`} />
                {error && address.trim() === '' && <div className="invalid-feedback">Please enter address</div>}
              </div>
            </div>
            <div>
              <label htmlFor="City" className="form-label">City<span class="required">*</span></label>
              <div className="input-group">
                <input name="City" type="text"  id="City" value={City} onChange={(e) => setCity(e.target.value)} 
                  className={`form-control ${error && City.trim() === '' ? 'is-invalid' : ''}`} />
                  {error && City.trim() === '' && <div className="invalid-feedback">Please enter city</div>}
              </div>
            </div>
            <div>
              <label htmlFor="State" className="form-label">State<span class="required">*</span></label>
              <div className="input-group">
                <input name="State" type="text"  id="State" value={state} onChange={(e) => setState(e.target.value)}
                 className={`form-control ${error && state.trim() === '' ? 'is-invalid' : ''}`} />
                 {error && state.trim() === '' && <div className="invalid-feedback">Please enter state</div>}
              </div>
            </div>
            <div>
              <label htmlFor="country" className="form-label">country<span class="required">*</span></label>
              <div className="input-group">
                <select id="country"  value={country} onChange={(e) => setcountry(e.target.value)} 
                 className={`form-control ${error && country.trim() === '' ? 'is-invalid' : ''}`} >
                  <option value="">Select Country</option>
                  <option value="India" selected>India</option>
                </select>
                {error && country.trim() === '' && <div className="invalid-feedback">Please select country</div>}
              </div>
            </div>
            <div>
              <label htmlFor="Pincode" className="form-label">Pin Code<span class="required">*</span></label>
              <div className="input-group">
                <input name="Pincode" type="number" id="Pincode " value={pincode} onChange={(e) => setPincode(e.target.value)}
                    className={`form-control ${error && pincode.trim() === '' ? 'is-invalid' : ''}`} />
                    {error && pincode.trim() === '' && <div className="invalid-feedback">Please enter pincode</div>}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-check">
              <input className="form-check-input" checked={isChecked}
                onChange={handleCheckboxChange} type="checkbox" name="remember" value="true" id="rememberMe"
                />
              <label className="form-check-label terms" htmlFor="rememberMe">By proceeding, you agree to NIMAS User Agreement, Terms and Privacy Policy</label>
              {error &&isChecked===false && <p style={{ color: 'red' }}>Please accept the terms and conditions</p>}
            </div>
          </div>
          <div className="col-12">
            {/* <button className="buttons" onClick={fun_signUP}>Create Account</button> */}
          </div>
          {/* <div className="col-12">
            <p className="small mb-0 text-center terms">Already have an account? <Link to="/student-admin/login">Login</Link></p>
          </div> */}
        </div>
      </div>
      <small className="glogin">or Login with</small>
      <div className="google"><img src="../admin/img/google.png" alt="" className="bylogo" /></div>
    </div>

  </div>
  </>
  );
  
}

export default NimasRegister;