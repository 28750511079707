import { useEffect, useState } from "react";
import React from "react";
import { Link } from 'react-router-dom';
import '../Common/custom-style.css';
import '/node_modules/bootstrap/dist/css/bootstrap.min.css';
import '/node_modules/bootstrap/dist/js/bootstrap.min.js';
import Headersidebar from '../Common/Headersidebar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReplyIcon from '@mui/icons-material/Reply';
import ArticleIcon from '@mui/icons-material/Article';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import moment from 'moment'
import { ThreeDots } from 'react-loader-spinner'

const drawerWidth = 240;
function Cancelbooking() {

  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  const [productdata, setProductData] = useState([]);
  const [error, setError] = useState("");
  // const [ReceiptListdata, SetReceiptListdata] = useState('');
  const Bookinglistpra = {
    "NumberOfFieldsView": 50, "filters": {}, "isDownload": false,
    "index": 1, "pgSize": 1000, "templateID": 5
  };
  const [isLoader, setIsLoader] = useState(true);
  function handleId() {
    setIsLoader(true);
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(Bookinglistpra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        setProductData(resp);
        setIsLoader(false);
        // fun_ReceiptListdata();
      });
    });

  }
  const CancelAndFailedBookingList = [];
  productdata?.response?.records?.forEach(obj => {
    if (obj[19].value === "Cancel" || obj[19].value === "Failed") {
      CancelAndFailedBookingList.push({ ...obj });
    }
  });

  function fun_booking() {
    if (BookingS.trim() === "") {
      setError("required");
      return;
    }
  }
  useEffect(() => {

    handleId();
  }, [0])
  const [anchorE1, setAnchorE1] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const open = Boolean(anchorE1);
  const opens = Boolean(anchorE2);

  const handleClick = (event) => {
    setAnchorE1(event.currentTarget);
    setAnchorE2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorE1(null);
    setAnchorE2(null);
  };
  const [age, setAge] = React.useState('');
  const [ages, setAges] = React.useState('');
  const [BookingS, setBookingS] = React.useState('');
  const handleChange = (event) => {
    setAge(event.target.value);
    setAges(event.target.value);
    setBookingS(event.target.value);
  };

  function fun_getbookingidonclick(id) {
    localStorage.setItem("bookinginfo", id)
  }
  function fun_getStudentDetailclick(id) {
    localStorage.setItem("StudentDetailinfo", id)
  }

  return (

    <>
      {isLoader && <div id="loadingg">
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#fff"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>}
      <Box sx={{ display: 'flex' }} className="custcontent">

        <div>

          <Headersidebar />
        </div>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >

          <main id="main" className="main">

            <div className="pagetitle">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/nimas-admin/dashboard">Home</Link></li>
                  <li className="breadcrumb-item active">Cancelled Bookings</li>
                </ol>
              </nav>
            </div>

            <div className="bookingss">
              <section className="section profile">
                <div className="row">


                  <div className="col-xl-12">

                    <div className="card">

                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div className="col-md-6">
                            <h5 className="card-title">Cancelled Bookings</h5>
                          </div>
                          <div className="col-md-6 text-end">
                            <label>Total Records: <b>{CancelAndFailedBookingList.length}</b></label>
                          </div>
                        </div>

                        <div className="tableresp">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Sr.</th>
                                <th scope="col">Date</th>
                                <th scope="col">Booking Id</th>
                                <th scope="col">Student Name</th>
                                <th scope="col">Serial No</th>
                                {/* <th scope="col">No of Seats</th> */}
                                <th scope="col">Adventure Name</th>
                                <th scope="col">Course Dates</th>
                                <th scope="col">Transaction Id</th>
                                <th scope="col">Payment Status</th>
                                <th scope="col">Cancel Reason</th>
                              </tr>
                            </thead>
                            <tbody>
                              {CancelAndFailedBookingList?.map((studinfo, i) => {
                                i++;
                                return (
                                  <tr>
                                    <th scope="row">{i}</th>
                                    <td>{moment(studinfo[1].value).format("DD-MMM-YYYY hh:mm:ss")}</td>
                                    {/* <td><a href="/nimas-admin/bookingdetails" target="_blank" onClick={localStorage.setItem("bookinginfo", studinfo[0].value)}>{studinfo[3].value}</a></td> */}

                                    <td><Link to="/student-admin/invoicenew" onClick={() => fun_getbookingidonclick(studinfo[3].value)}>{studinfo[3].value}</Link></td>
                                    <td><Link to="/nimas-admin/student-info" onClick={() => fun_getStudentDetailclick(studinfo[25].value)}>{studinfo[24].value}</Link></td>
                                    <td>{studinfo[6].value}</td>
                                    {/* <td>{studinfo[8].value}</td> */}
                                    <td>{studinfo[4].value}<br />({studinfo[5].value})</td>
                                    <td>{studinfo[21].value}<br />/{studinfo[22].value}</td>
                                    <td>{studinfo[26].value}</td>
                                    <td><span
                                      className={`datee  ${studinfo[19].value === 'Confirmed' ? 'text-green' : studinfo[19].value === 'In-Review' ? 'text-blue' : studinfo[19].value === 'Payment Upload' ? 'text-blue' : studinfo[19].value === 'Failed' ? 'text-red' : studinfo[19].value === 'Cancel' ? 'text-red' : ''}`
                                      }>{studinfo[19].value}</span>
                                    </td>
                                    <td>{studinfo[28].value}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </main>
        </Box>
      </Box>
    </>
  );
}
export default Cancelbooking;
