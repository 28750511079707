import React, {useEffect, useState } from "react";
import Header from '../Common/Header';
import Footer from '../Common/Footer';


function Terms() {


  return (
        <>
          <Header />
          <div className="wrapper">
          <div className="bradcumb-area style-1 overlay-bg-2">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col">
						
							<div className="bradcumb text-center">
								<h3>Terms & Conditions</h3>
							</div>
						
						</div>
					</div>
				</div>
			</div>

          </div>

          <div className="about-adventure-area pt-110 pb-200 privacy">
				<div className="container">
					
					<div className="row">
						<div className="col-lg-12">
                            <p><strong>NATIONAL INSTITUTE OF MOUNTAINEERING AND Adventure SPORTS (NIMAS)<br/>
DIRANG, WEST KAMENG (Distt), Arunachal Pradesh- 790101</strong>
                            </p>
                            <p>These Terms of Use constitute a binding contract between you (hereinafter referred as "User" /"You") and National Institute of Mountaineering and Adventure sports (NIMAS) (hereinafter referred as 'NIMAS) with regard to your use of its website www.nimasdirang.com (hereinafter referred as "Website") and the use of various Services that are provided by NIMAS. For the removal of doubts, it is clarified that by using this Website or by availing the Service(s) you constitute an acknowledgement and acceptance of these Terms of Use. If you do not agree with any part of these Terms of Use please do not use this Website or avail our Services.
</p>

<p><b>User Agreement</b></p>

<h5>1. General Terms and Conditions</h5>

                            <p>(a) National Institute of Mountaineering and Adventure Sports (NIMAS) is an autonomous body under the aegis of Ministry of Defence register under Indian Societies Act with its registered office at Dirang, West Kameng (Dist), Arunachal Pradesh, India - 790101. NIMAS on broad objective established to impart theoretical and practical training in the field of Mountaineering, Aqua Adventure and Aero Adventure Activities. Awakening interest in and love for adventure activities and exploration among youth. Generating a sense of preservation of environment and ecology in the Himalayan region through nature workshops (herein after referred as 'Services').
</p>
                            <p>(b) This User Agreement is applicable to all NIMAS services. In addition to this User Agreement and depending on the services opted by the User, the User shall be required to read and accept the relevant terms and conditions of Service (TOS) for each such Service, which may be updated or modified by NIMAS from time to time. such TOS shall be deemed to be a part of this User Agreement and in the event of a conflict between such TOS and this User Agreement, the terms of TOS shall prevail.</p>

                            <p>(c) The services are offered to the user conditioned on acceptance without modification of all the terms, conditions and notices contained in this User Agreement and the TOS, as may be posted on the Website from time to time. For the removal of doubts, it is clarified that availing of the Services by the User constitutes an acknowledgement and acceptance by the User of this User Agreement and the TOS. If the User does not agree with any part of such terms, conditions and notices or the terms and conditions of the TOS the User must not avail the NIMAS Services.</p>

                            <p>(d) Additionally, the Service Provider itself may provide terms and guidelines that govern particular features, offers or the operating rules and policies applicable to each Service. The User shall be responsible for ensuring compliance with the terms and guidelines or operating rules and policies of the Service Provider with whom the User elects to deal, including without limitation terms and conditions set forth in a Service Providers.</p>

                            <p>(e) NIMAS at its sole discretion reserves the right not to accept any service placed by the User through the Website without assigning any reason thereof. Any contract to provide any Service by NIMAS is not complete until full money towards the Service is received from the User and accepted by NIMAS.</p>

                            <p>(f) User hereby consents, expresses and agrees that he has read and fully understands the terms and conditions of this User Agreement and the Privacy Policy of NIMAS as given in the Website without any change.</p>


                            <h5>2. Modification of Terms</h5>
                            <p>NIMAS reserves the right to change, modify or alter, at any time, the terms and conditions of this User Agreement, Privacy Policy and the terms, conditions and notices under which the Services are offered through the Website, including but not limited to the charges for the Services provided through the Website. The User shall be responsible for regularly reviewing the concerned terms and conditions.
</p>
                            <h5>3. Fees Payment</h5>

                            <p>(a) NIMAS reserves the right to charge listing fees for certain listings, as well as transaction fees based on certain completed transactions using the NIMAS Services. NIMAS further reserves the right to alter any and all fees from time to time, without notice.
</p>
                            
                            <p>(b) The User shall be liable to pay all applicable transaction charges for the payment / Refund done to / by NIMAS payment gateway / Demand Draft / RTGS / NEFT for availing the service / cancellation of service.
</p>
                            <h5>4. Payment Options</h5>

                            <p><b>4.1 . Online Card Payments</b></p>  

                            <p>(a) Visa, Master and American express Card payments are processed through an online payment gateway system. You need not worry about your card information falling into the wrong hands because your bank will authorise the card transaction directly without any information passing through us. In approximately 25-30 seconds (depending on your internet connection) your bank will issue, using the online payment gateway, an authorisation code and confirmation of completion of transaction.
</p>

  
<p>(b) NIMAS, as a VeriSign Certified Site, uses the latest 128 bit encryption technology and other sophisticated methods to protect your credit card information. You can book your Service using SSL encryption (the internet standard for secure transactions). If the payment on the credit card is declined for some reason, alternate payment instructions must be received by NIMAS.
</p>
<p>(c) NIMAS charges a service fee on all booking made by the User. In case of cancellation of booking refund is made as per as our refund policy (visit our refund policy)</p>


<p><b>4.2. Internet Banking</b></p>  

<p>If you have an account with any of the below mentioned banks, then you can pay for your booking through respective bank's net banking options and the amount will be automatically debited from your account. NIMAS processes the payments through an online gateway system which enables safe and secure transaction. On receipt of payments conformation letter will be automatically generated in you registered mail id.
</p>


                            <ul>
                                <li>(a) State Bank Of India</li>
                                <li> (b) Citi Bank</li>
                                <li>  (c) HDFC Bank</li>
                                <li>  (d) IDBI Bank</li>
                                <li>  (e) Indusind Bank</li>
                                <li>   (f) Kotak Bank</li>
                                <li>  (g) Punjab National Bank</li>
                                <li> (h) AXIS Bank</li>
                                <li> (i) Bank of Baroda - Retail Net Banking</li>
                                <li> (j) Bank of Baroda - Corporate Net Banking</li>
                            </ul>   

                            <h5>5. Booking</h5> 
                            <p>(a) NIMAS vest- the power to grant extra vacancy even though the vacancy are filled only under special request and genuine reasons with application to NIMAS.</p> 

                            <p>(b) Special courses are conducted by the institute at higher rates than given in the schedule. NIMAS is a non profitable organisation but special course have no government of India subsidy therefore actual charges have to be paid by the trainees.</p> 

                            <p>(c). For Bulk booking the strength should be more than 10 and below 40 members. Trainees / organizations who wish to avail these services if vacancies are vacant can enroll themselves on application by post or email to NIMAS.</p> 

                            <p>(d) Cancellation (see conformation and cancellation Policy) of vacancy will be possible in special courses. Transfer of vacancy is permitted in special courses only if sponsoring / conducting agencies gives prior intimation to institute and submits relevant documents along with application to institute for transfer of vacancy.</p> 

                            <p>(e) Fee includes: Expenses on food, accommodation, equipment, transportation, medicines and other training expenses during the course.</p> 

                            <p>(f) Admission to Foreigners: As of now foreigners are not allowed for any courses, unless specified in course schedule or instructions.</p> 

                            <p>(g) Indian Armed Forces & Para Military Personnel: Serving Armed forces, Para Military and NCC Personnel, should apply through their respective service Headquarters and Departmental channels. They can also however directly apply as private trainees</p> 

                            <p>(h) Repetition of course : The courses conducted in NIMAS are subsidized and the vacancies are limited. Repeating a course by an individual leads to denial of opportunity to someone else.</p> 

                            <p>(i) This Institute also conducts special adventure courses for schools and establishments on full cost basis for 1+ days. These are open and anyone can apply for their confirmation as per school / establishment vacations.</p> 


                            <p><b>(j) Eligibility</b></p>
                            <p>(a) Advance Mountaineering Course is for trainees graded 'A' and recommended for advance only in Basic Mountaineering courses from NIMAS; Dirang, HAWS; Gulmarg (J&K), NIM: Uttarkashi, HMI: Darjeeling, JIM: Pahalgam (J&K), ITBP Trg Centre Auli, Joshimath, ABBIM & AS; Manali and SGMI; Gangtok.</p>
                            <p>(b) Search & Rescue and Method of Instruction (Mol) courses are only for trainees graded 'A' in advance Mountaineering course'</p>

                            <p><b>Note: </b></p>
<p>Arrival: Trainees must arrive an evening before commencement of the course, also late arrivals even by a day will not be permitted to join the course.</p>



<p>Physical Fitness Level : Trainees should be in good physical and mental condition prior to undertaking any course' (complete details given in our broucher)</p>



<h5>5.1 Booking Confirmation</h5>

<p>You should not take any action based on information on the Website until you have received a confirmation of your booking. In case of confirmations to be received by email, if you do not receive a confirmation of your booking within the stipulated time period, first look into your "spam" or "junk" folder to verify that it has not been misdirected, and if still not found, please contact us.</p>




<h5>6. Confirmation and Cancellation/Rescheduling and Refund policy</h5>

<p>(a) Please refer Confirmation and Cancellation / Rescheduling and Refund Policy while using NIMAS Services.</p>
<p><b>Note:</b> It is mandatory to contact NIMAS for refunds See refund rules.</p>

<p>(b)The above mentioned cancellation process and refund processing time may vary. Refund would be through cheque or demand draft or NEFT. These Refund charges has to be borne by the User itself.</p>


<h5>7 . Disclaimer of Warranties/Limitation of Liability</h5>

<p>(a) NIMAS will endeavor to ensure that all the information provided by it is, to the best of its knowledge, correct. However, NIMAS neither Warrants nor makes any representations regarding the quality, accuracy or completeness of the information or data. NIMAS makes no warranty, express or implied, concerning the website and/or its contents, and disclaims all assurances of fitness for a particular purpose and guarantees of merchantability in respect of services, including any liability, responsibility or any other claim, in respect of any loss, whether direct, indirect or consequential, to any User or any other person, arising out of or from the use of any such information.</p>

<p>(b) To the extent NIMAS acts only as a facilitator of the Services which are being offered and rendered by third party Service Providers, it shall not have any liability whatsoever for any aspect of the arrangements between the Service Provider and the User as regards the standards, quality, fitness, merchantability, consequences of the use of the Services provided by the Service Providers. In no circumstances shall NIMAS be liable for the Services provided by the Service Provider.</p>

<p>(c) Training provided by NIMAS is risk associated. However NIMAS adheres to all the safety procedures. NIMAS does not hold itself responsible to death or injury during training. User identifies himself as volunteer in training then only he is admitted to the training.</p>

<p>(d) NIMAS shall not be liable for damages or losses that may result from User's use of training programmes / other services.</p>

<p>(e) In no event shall NIMAS be liable for any direct, indirect, punitive, incidental, special, consequential damages or any other damages resulting.</p>

<p>(f) NIMAS shall not be responsible for the delay or inability to use the website or Services, the provision of or failure to provide Services, or for any information, services and related graphics. Further, NIMAS shall not be held responsible for non-availability of the Website during periodic maintenance operations or any unplanned suspension of access to the Services that may occur due to technical reasons or for any reason beyond the control of Adventure and Nature. The user understands and agrees that any material and/or data downloaded or otherwise obtained from Website/ NIMAS is done entirely at their own discretion and risk and they will be solely responsible for any damage to his/her/its computer systems or any other loss that results from such material and/or data.</p>

<p>(g) NIMAS shall not be responsible for any loss of personnel equipment during course and the payment of the NIMAS equipment at the actual cost has to be paid by the User in case NIMAS equipment is lost or damaged.</p>

<p>(h) These limitations, disclaimer of warranties and exclusions apply without regard to whether the damages arise from (i) breach of contract, (ii) breach of warranty, (iii) negligence, or (iv) any other cause of action, to the extent such exclusion and limitations are not prohibited by applicable law.</p>

<p>(i) NIMAS holds no responsibility for any of the user Generated content on the website. NIMAS neither warrants nor makes any representations concerning the quality or accuracy of the User Generated Content on the website. Additionally, NIMAS is not liable for any User Generated Content which may be deemed as vulgar, profane or harmful.</p>


<h5>8. Links to Third Party Websites</h5>

<p>(a) The website may contain links to other websites ("Linked sites"). The Linked Sites are not under the control of NIMAS or the Website and NIMAS is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked site. NIMAS is not responsible for any form of transmission, whatsoever, received by the User from any Linked Site. NIMAS is providing these links to the User only as a convenience, and the inclusion of any link does not imply endorsement by NIMAS or the website of the Linked Sites or any association with its operators or owners including the legal heirs or assigns thereof.</p>

<p>(b) NIMAS is not responsible for any errors, omissions or representations on any Linked Site. NIMAS does not endorse any advertiser on any Linked Site in any manner. The Users are requested to verify the accuracy of all information on their own before undertaking any reliance on the information provided by the Linked Site.</p>

<p>(c) The website may contain third party advertisements. The Users are requested to verify the accuracy of all information on their own before undertaking any reliance on such third party advertisements. NIMAS shall not be liable for any direct, indirect, consequential or punitive losses, damages, claims including attorney's fees incurred from the action or inaction of User by reliance in any information on such advertisements.</p>



<h5>9. Prohibition Against Unlawful Use.</h5>
<p>As a condition of the use of the Website, the User warrants that they will not use the website for any purpose that is unlawful or illegal under any law for the time being in force within or outside India or prohibited by this Agreement and/or the TOS including both specific and implied. In addition, the Website shall not be used in any manner, which could damage, disable, overburden or impair it or interfere with any other party's use and/or enjoyment of the Website. The User shall refrain from obtaining or attempting to obtain any materials or information through any means not intentionally made available or provided for or through the Website.</p>




<h5>10. Use of Communication Services</h5>
<p>(a) The website may contain services such as email, chat, bulletin board services, information related to various tourist spots, news groups, forums, communities, personal web pages, calendars, and/or other message (hereinafter collectively referred to as "Communication Services"). The User agrees and undertakes to use the communication Services only to post, send and receive messages and material that are proper and related to the particular communication service. By way of example, and not as a limitation, the User agrees and undertakes that when using a communication service, the User will not:
</p>

<ul>
    <li>(i) Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights of others;
</li>
    <li>(ii) publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information;
</li>
    <li>(iii) upload files that contain software or other material protected by intellectual property laws unless the User owns or controls the rights thereto or have received all necessary consents;
</li>
    <li>(iv) upload or distribute files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of the Website or another's computer;
</li>
    <li>(v) advertise or offer to sell or buy any goods or services for any business purpose, unless such Communication Service specifically allows such messages;
</li>
    <li>(vi) conduct or forward surveys, contests, pyramid schemes or chain letters;
</li>
    <li>(vii) Download any file posted by another user of a Communication Service that the User know, or reasonably should know, cannot be legally distributed in such manner;
</li>
    <li>(viii) falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded;
</li>
    <li>(ix) violate any code of conduct or other guidelines, which to any particular Communication Service;
</li>
    <li>(x) violate any applicable laws or regulations for the time being in force in or outside India; and
</li>
    <li>(xi) violate any of the terms and conditions of this User Agreement or any other terms and conditions for the use of the Website contained elsewhere herein.
</li>
</ul>

                            <p>(b) NIMAS will review materials posted through communication service and shall remove any materials in its sole discretion. NIMAS reserves the right to terminate the User's access to any or all of the communication services at any time without notice for any reason whatsoever.
</p>

                            <p>(c) NIMAS reserves the right at all times to disclose any information as is necessary to satisfy or comply with any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, in Adventure and Nature's sole discretion.
</p>

                            <p>(d) NIMAS does not control or endorse the content, messages or information found in any Communication Service and, therefore, NIMAS specifically disclaims any liability or responsibility whatsoever with regard to the communication Services and any actions resulting from the user's participation in any Communication Service.
</p>
                            <p>(e) Materials uploaded to a Communication Service may be subject to posted limitations on usage, reproduction and/or dissemination. User is responsible for keeping himself updated of and adhering to such limitations if they download the materials.
 </p>

                            <p>(f) When you register with website, we or any of our partners/affiliate/group companies may contact you from time to time to provide the offers/information of such products/services that we believe may benefit you.
</p>

<h5>11. Termination/Access Restriction</h5>

                            <p>(b)NIMAS reserves the right, in its sole discretion, to terminate the access to the Website and the related services or any portion thereof at any time, without notice.
</p>

<h5>12. User's Obligations and User Account</h5>

                            <p>(a) The User represents and confirms that the User is of legal age to enter in to a binding contract and is not a person barred from availing the Services under the laws of India or other applicable law.
</p>

<p>(b) The User also understands that the Services may include certain communications from NIMAS as service announcements and administrative messages. The user understands and agrees that the Services are provided on an "as is" basis and that NIMAS does not assume any responsibility for deletions, mis-delivery or failure to store any user communications or personalized settings.
</p>
<p>(c) Registration of the User on the website is optional. If the User opts to register himself on-the website, upon completion of the registration process, the user shall receive a user id and password. The User agrees and undertakes at all times to be responsible for maintaining the confidentiality of their password and user id, and shall be fully responsible for all activities that occur by use of such password or user id. Further, the User agrees not to use any other party's user id and password for any purpose whatsoever without proper authorization from such party. You are responsible for the security of your password and for all transactions undertaken using your password through our Services. The Password entered by you is transmitted in one- way encrypted form to our database and stored as such. Thus the Password will not be known even to NIMAS. You confirm that you are the authorised holder of the credit card or the original account holder used in the transactions you make using the NIMAS Services. NIMAS will not be responsible for any financial loss, inconvenience or mental agony resulting from misuse of your ID/password/credit card number/account details number for using NIMAS Services.
</p>
<p>(d) The user also agrees and undertakes to immediately notify NIMAS of any unauthorized use of the user's password or user id and to ensure that the user logs off at the end of each session at the Website NIMAS shall not be responsible for any, direct or indirect, loss or damage arising out of the user's failure to comply with this requirement.
</p>
<p>(e) The User also agrees to: (a) provide true, accurate and complete information about himself and his beneficiaries as prompted by the registration form ("Registration Data") on the Website; and (b) maintain and promptly update the Registration Data to keep it true, accurate, current and complete. If the User provide any information that is untrue, inaccurate, not current or incomplete or NIMAS has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, NIMAS has the right to suspend or terminate the User's registration and refuse any and all current or future use of the Website and/or any Service.
</p>
<p>(f) Furthermore, the User grants NIMAS the right to disclose to third parties Registration Data to the extent necessary for the purpose of carrying out the Services.
</p>

<h5>13. Breach</h5>
<p>Without prejudice to the other remedies available to NIMAS under this User Agreement, the TOS or under applicable law, NIMAS may limit the user's activity, or end the user's listing, warn other users of the user's actions, immediately temporarily/indefinitely suspend or terminate the user's registration, and/or refuse to provide the user with access to the Website if:
</p>
<p>(a) the user is in breach of this User Agreement, the TOS and/or the documents it incorporates by reference;
</p>
<p>(b) NIMAS is unable to verify or authenticate any information provided by the user; or
</p>
<p>(c) NIMAS believes that the user's actions may infringe of any third party rights or breach any applicable law or otherwise result in any liability for the user, other users of the Website and/or NIMAS.
</p>
<p>(d) NIMAS may at any time in its sole discretion reinstate suspended users. Once the user have been indefinitely suspended, the user may not register or attempt to register with Website/ NIMAS or use the Website in any manner whatsoever until such time that the user is reinstated by NIMAS. Notwithstanding the foregoing, if the user breaches this User Agreement, the TOS or the documents it incorporates by reference, NIMAS reserves the right to recover any amounts due and owing by the User to NIMAS and/or the Service Provider and to take strict legal action as NIMAS deems necessary.
</p>

<h5>14. Proprietary Rights</h5>
<p>(a) NIMAS may provide the User with content such as sound, photographs, graphics, video or other material contained in sponsor advertisements or information. This material may be protected by copyrights, trademarks or other intellectual property rights and laws. The User may use this material only as expressly authorized by NIMAS and shall not copy, transmit or create derivative works of such material without express authorization from NIMAS.
</p>
<p>(b) The User acknowledges and agrees that they shall not upload, post, reproduce or distribute any content on or-through the Website that is protected by copyright or other proprietary right of a third party, without obtaining the permission of the owner of such right. Any copyrighted or other-proprietary content distributed on or through the Website with the consent of the owner must contain the appropriate copyright or other proprietary rights notice- The unauthorized submission or distribution of copyrighted or other proprietary content is illegal and could subject the User to personal liability or criminal prosecution.
</p>

<h5>15. Right to lnformation (RTl)</h5>
<p>(a) NIMAS being a government origination is bound to give information to the seeker.
</p>
<p>(b) NIMAS reserves the right not to disclose any of the information which will breach the security of the institute or it's personnel's.
</p>
<p>(c) NIMAS reserve the right not give any information on the results, Question Paper, Answer Sheets, Minutes of meeting, Noting etc.
</p>

<h5>16. E - Tendering
</h5>
<p>The complete tendering process from next financial year will be on e tendering. Details will be available in the concerned section.
</p>


<h5>17. Relationship
</h5>
<p>None of the provisions of this User Agreement, TOS, terms and conditions, notices or the, right to use the Website by the User contained herein or any other section or pages of the Website and/or the Linked Sites, shall be deemed to constitute a partnership between the user and NIMAS and no party shall have any authority to bind or shall be deemed to be the agent of the other in any way. It may be noted, however, that if by using the Website, the User authorizes NIMAS and its agents to access third party sites designated by them or on their behalf for retrieving requested information, the user shall be deemed to have appointed NIMAS and its agents as their agent for this purpose.
</p>


<h5>18. Headings
</h5>
<p>The headings and subheadings herein are included for convenience and identification only and are not intended to describe, interpret, define or limit the scope, extent or intent of this User Agreement, the TOS or the right to use the Website by the User contained herein or any other section or pages of the Website or any Linked Sites in any manner whatsoever.
</p>



<h5>19. Interpretation of Number and Genders
</h5>
<p>The terms and conditions contained herein shall apply equally to both the singular and plural form of the terms defined. Whenever the context may require, any pronoun shall include the corresponding masculine and feminine. The words "include", "includes" and "including" shall be deemed to be followed by the phrase "without limitation". Unless the context otherwise requires, the terms "herein", "hereof ", "hereto", "hereunder" and words of similar import refer to this User Agreement as a whole.
</p>


<h5>20. Indemnification
</h5>
<p>The User agrees to indemnify, defend and hold NIMAS harmless from and against any and all losses, liabilities, claims, damages, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by NIMAS that arise out of, result from, or may be payable by virtue of, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed by the User pursuant to this User Agreement and/or the TOS.
</p>



<h5>21. Severability
</h5>
<p>If any provision of this User Agreement is determined to be invalid or unenforceable in whole or in part, such invalidity or unenforceability shall attach only to such provision or part of such provision and the remaining part of such provision and all other provisions of this User Agreement shall continue to be in full force and effect.
</p>


<h5>22. Termination of Agreement and Services
</h5>
<p>(a) Either the User or NIMAS may terminate this User Agreement and a Service with or without cause at any time to be effective immediately.
</p>
<p>(b)The User agrees that NIMAS may under certain circumstances and without prior notice, immediately terminate the User's user id and access to the Website/Services. Causes for termination may include, but shall not be limited to, breach by the User of this User Agreement or the TOS, requests by enforcement or government agencies, requests by the User, non-payment of fees owed by the User in connection with the Services as specified in the applicable TOS.
</p>
<p>(c) This Use Agreement may be terminated by either the user or NIMAS through a written notice to the other. NIMAS shall not be liable to the User or any third party for termination of any Service. Should the User object to any terms and conditions of this User Agreement, any TOS or become dissatisfied with the Service in any way, the User's only recourse is to immediately: (i) discontinue use of the Website/Service; and (ii) notify NIMAS of such discontinuance.
</p>
<p>(d) Upon termination of the Service, User's right to use the Website/Services and software shall immediately cease. The User shall have no right and NIMAS shall have no obligation thereafter to execute any of the User's uncompleted tasks or forward any unread or unsent messages to the User or any third party. Once the User's registration or the Services are terminated, cancelled or suspended, any data that the User has stored on the Website may not be retrieved later.
</p>


<h5>24. Notices
</h5>
<p>All notices and communications (including those related to changes in the TOS, Service, termination of Service etc.,) shall be in writing, in English and shall deemed given if delivered personally or by commercial messenger or courier service, or mailed by registered or certified mail (return receipt requested) or sent via email/facsimile (with acknowledgment of complete transmission) to the following address or by displaying on the Website:
</p>
<p>(a) If to NIMAS, at info@nimasdirang.com and/or at the address posted on the website.
</p>
<p>(b) If to a non registered user, at the communication and/or email address specified in the application form availing of NIMAS Service.
</p>
<p>(c) If to a registered user, at the communication and/or email address specified in the registration form. Notice shall be deemed to have been served 48 hours after it has been sent, dispatched, displayed, as the case may be, unless, where notice has been sent by email, it comes to the knowledge of the sending party, that the email address is invalid.
</p>


<h5>25. Governing Law and Jurisdiction
</h5>
<p>This User Agreement and each TOS shall be governed by and constructed in accordance with the laws of India. All disputes arising in relation hereto shall be subject to the exclusive.
</p>


<p><strong>
                                (Col Ranveer Singh Jamwal SM VSM**)<br/>
                                Col
                                Principal
                                NIMAS
                            </strong>
                        </p>



						</div>
					</div>
					
				</div>
			</div>
          <Footer />
              
        </>
  );
}

export default Terms;
