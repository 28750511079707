  import React, {useEffect, useState } from "react";
  import Header from '../Common/Header';
  import Footer from '../Common/Footer';
  import CircularProgress from '@mui/material/CircularProgress';
  import Box from "@mui/material/Box";
  import { ThreeDots } from 'react-loader-spinner'

  function Home2() {

	{/* const loading = document.querySelector('.loading');
	const wrapper = document.querySelector('.wrapper');
	setTimeout(() => {
	loading.style.opacity = "0";
	wrapper.style.opacity = "1";
	}, 2000)

	 
*/}


    return (
          <>
		{/*<div className="loading">
			<div align="center" className="loadercircle">Loading...
			</div>
	</div>*/}

            <Header />
            <div className="wrapper">

           
			<section className="slider-area">
				<div id="welcome-slide" className="">
				
					<div className="overlay-bg-2">
						<div className="slide-thumb shape-1">
							<img src="img/slider/banner.png" alt="" />
					
							<div className="slide-content">
								<div className="container">
									<div className="row justify-content-center">
										<div className="col-lg-10 col-sm-12">
										
											<div className="slide-content-area">
											
												<div className="single-slide-content text-center">
													<div className="content-text">
														<h1>Adventure Awaits</h1>
													</div>
													
												</div>
												
											</div>
											
										</div>
									</div>
								</div>
							</div>
							
						</div>
					</div>
					
					
				</div>
			</section>
			

		
			<div className="about-area pb-115">
				<div className="about-bg mb-none">
					<img src="img/about/SVP08533.png" alt="" />
				</div>
				<div className="container">
					<div className="row">
						<div className="col col-lg-6"></div>
						<div className="col-lg-6 wow fadeInDown">
						
							<div className="section-title">
								<p className="title">WELCOME TO NIMAS</p>
								<h2>YOUR GATEWAY TO ADVENTURE! </h2>
							</div>
						
							<div className="about-content">
								<p>Nestled within a sprawling 38-acre contemporary campus, NIMAS stands as the
premier adventure institute, boasting expertise in land, air, and aqua verticals. Our
state-of-the-art facilities and world-class trainers offer unparalleled professional
training in mountaineering and adventure sports. </p>

<p>Embark on a journey with us as we delve into the realms of mountaineering, mountain
terrain biking, scuba diving, white water rafting, paragliding, and paramotor training.
With a rich history of shaping aspiring adventurers, NIMAS has honed the skills of
thousands of students from across the country, cultivating a legacy of excellence in
mountaineering and beyond. Join us and become part of this extraordinary tradition
today! </p>

							</div>
						</div>
					</div>
				</div>
				
			</div>
			

			{/*<div className="quick-icon-area pb-90">
				<div className="container">
					<div className="row">
						<div className="col-lg-2 col-sm-4 wow fadeInDown">
					
							<div className="single-quick-icon text-center">
								<div className="quick-icon-content">
									<img src="img/icon/2.png" alt="" />
									<p><a href="/mountaineering">Mountaineering</a></p>
								</div>
							</div>
							
						</div>
						<div className="col-lg-2 col-sm-4 wow fadeInDown">
							
							<div className="single-quick-icon text-center">
								<div className="quick-icon-content">
									<img src="img/icon/6.png" alt="" />
									<p><a href="/mountain-terrain-biking">Mountain Terrain Biking</a></p>
								</div>
							</div>
						
						</div>
						<div className="col-lg-2 col-sm-4 wow fadeInDown">
							
							<div className="single-quick-icon text-center">
								<div className="quick-icon-content">
									<img src="img/icon/22.png" alt="" style={{width: '60px'}} />
									<p><a href="/white-water-rafting">White Water Rafting</a></p>
								</div>
							</div>
							
						</div>
						<div className="col-lg-2 col-sm-4 wow fadeInDown">
						
							<div className="single-quick-icon text-center">
								<div className="quick-icon-content">
									<img src="img/icon/23.png" alt="" style={{width: '60px'}} />
									<p><a href="/scuba-diving">SCUBA Diving</a> </p>
								</div>
							</div>
							
						</div>
						<div className="col-lg-2 col-sm-4 wow fadeInDown">
						
							<div className="single-quick-icon text-center">
								<div className="quick-icon-content">
									<img src="img/icon/24.png" alt="" style={{width: '60px'}} />
									<p><a href="/paragliding">Paragliding</a></p>
								</div>
							</div>
							
						</div>
						<div className="col-lg-2 col-sm-4 wow fadeInDown">
							
							<div className="single-quick-icon text-center">
								<div className="quick-icon-content">
									<img src="img/icon/25.png" alt="" style={{width: '60px'}} />
									<p><a href="/paramotor">Paramotor</a> </p>
								</div>
							</div>
						
						</div>
					</div>
				</div>
			</div>
		
			*/}
			
			<div className="adventure-area pb-90 pt-50">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-xl-6 col-md-8">
						
							<div className="section-title text-center">
								<p className="title">OUR TRAINING WING</p>
								<h2>SCALING HEIGHTS, DEFYING LIMITS</h2>
								
							</div>
							
						</div>
						<p className="text-center" style={{marginBottom:50}}>	Step into the realm of adventure with our specialized training wing at NIMAS. Here, we
offer exclusive courses in adventure sports across land, air, and aqua domains.
Whether you're drawn to the rugged terrain of mountaineering, the exhilarating
freedom of paragliding, or the underwater mysteries of scuba diving, our expert
trainers and cutting-edge facilities ensure an unparalleled learning experience. Dive
into a world where boundaries are challenged, skills are honed, and unforgettable
adventures await. Join us and discover the thrill of pushing your limits in the most
breathtaking environments imaginable. </p>
					</div>
					<div className="row">
						<div className="col-lg-4 col-sm-4">
						
							<div className="single-adventure wow slideInLeft">
								<img src="img/adventure/a4.jpg" alt="adventure" className="ladv" />
								<div className="adventure-content">
									<h5>Land Adventure</h5>
								</div>
							</div>
						
						</div>
						<div className="col-lg-4 col-sm-4">
							
							<div className="single-adventure wow fadeInDown">
								<img src="img/adventure/aero.png" alt="adventure" />
								<div className="adventure-content">
								<h5>Aero Adventure</h5>
								</div>
							</div>
						
						</div>
						<div className="col-lg-4 col-sm-4">
						
							<div className="single-adventure wow slideInRight">
								<img src="img/adventure/aqua.png" alt="adventure" />
								<div className="adventure-content">
									<h5>Aqua Adventure</h5>
								</div>
							</div>
							
						</div>
						
					</div>
					
				</div>
			</div>
			<div className="adventure-area pb-90 pt-50">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-xl-6 col-md-8">
						
							<div className="section-title text-center">
								<p className="title">WHY CHOOSE US? 
</p>
								<h2>WHERE PASSION MEETS PEAK PERFORMANCE... 
</h2>
								
							</div>
							
						</div>
						<p className="text-center" style={{marginBottom:50}}>We go beyond the ordinary to exceed your expectations, ensuring that every course
is a step closer to the summit of adventure.  </p>
					</div>
					<div className="row">
						<div className="col-lg-4 col-sm-4">
						<div className="single-adventure wow fadeInDown text-center tb">
							Expertise and Experience
							</div>
						
							
			</div>
						<div className="col-lg-4 col-sm-4">
							
							<div className="single-adventure wow fadeInDown text-center tb">
							Specialized Courses
							</div>
						
						</div>
						<div className="col-lg-4 col-sm-4">
						<div className="single-adventure wow fadeInDown text-center tb">
						Student-Centric Approach
							</div>
							
						</div>
						<div className="col-lg-4 col-sm-4">
						<div className="single-adventure wow fadeInDown text-center tb">
						Quality and Safety
							</div>
							
						</div>
						<div className="col-lg-4 col-sm-4">
						<div className="single-adventure wow fadeInDown text-center tb">
						Certified Instructors
							</div>
							
						</div>
						<div className="col-lg-4 col-sm-4">
						<div className="single-adventure wow fadeInDown text-center tb">
						Safety Equipment & Training Facilities
							</div>
							
						</div>
						
					</div>
					
				</div>
			</div>
		
			
			
			<div className="selling-tips-area sarea pb-80 pt-120">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="selling-tips-wrap">
								
								<div className="single-selling-tips section-title">
									<p className="title">OUR COURSES OFFERED</p>
									<h2>LET THE MOUNTAINS BE YOUR CLASSROOM</h2>
									<p>Set within a contemporary 38-acre campus, NIMAS is the first adventure Institute in
land, air and aqua verticals. The campus equipped with state-of-the-art equipment
and world-class trainers provides professional training in mountaineering and
adventure sports. At present the institute provide training in Mountaineering,
Mountain Terrain Biking, Scuba Diving, White Water Rafting, Paragliding and
Paramotor training, NIMAS has trained thousands of students from all over the
country churning out great mountaineers and adventurers. NIMAS has recently
introduced outbound Leadership training course for mid-senior level executives. </p>
								
								</div>
							
							</div>
						</div>
					
					</div>
					<div className="row">
					<div className="col-lg-4 col-sm-6">
						
						<div className="single-adventure wow fadeInDown">
							<a href="/Mountaineering-courses"><img src="img/adventure/77.jpg" alt="adventure" /></a>
							<div className="adventure-content">
								<h6><a href="/Mountaineering-courses">Mountaineering</a></h6>
							</div>
						</div>
						
					</div>
					<div className="col-lg-4 col-sm-6">
					
						<div className="single-adventure wow fadeInDown">
							<a href="/mtb-courses"><img src="img/adventure/6.jpg" alt="adventure" /></a>
							<div className="adventure-content">
								<h6><a href="/mtb-courses">Mountain Terrain Biking</a></h6>
							</div>
						</div>
					
					</div>
						<div className="col-lg-4 col-sm-6">
						
							<div className="single-adventure wow fadeInDown">
								<a href="/scuba-diving-courses"><img src="img/adventure/5.jpg" alt="adventure" /></a>
								<div className="adventure-content">
									<h6><a href="/scuba-diving-courses">SCUBA Diving</a></h6>
								</div>
							</div>
							
						</div>
						<div className="col-lg-4 col-sm-6">
						
							<div className="single-adventure wow fadeInDown">
								<a href="/rafting-courses"><img src="img/adventure/3.webp" alt="adventure" /></a>
								<div className="adventure-content">
									<h6><a href="/rafting-courses">White Water Rafting</a></h6>
								</div>
							</div>
							
						</div>
						<div className="col-lg-4 col-sm-6">
						
							<div className="single-adventure wow fadeInDown">
								<a href="/paragliding-courses"><img src="img/adventure/2.jpg" alt="adventure"  /></a>
								<div className="adventure-content">
									<h6><a href="/paragliding-courses">Paragliding</a></h6>
								</div>
							</div>
							
						</div>
						<div className="col-lg-4 col-sm-6">
							
							<div className="single-adventure wow fadeInDown">
								<a href="/paramotor-courses"><img src="img/adventure/88.jpg" alt="adventure" style={{ height:238}}/></a>
								<div className="adventure-content">
									<h6><a href="/paramotor-courses">Paramotor</a></h6>
								</div>
							</div>
						
						</div>
					</div>
				</div>
			</div>
			
			
		
			<div className="partner-area">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10">
						
							<div className="section-title text-center">
							{/* <p className="title">Testimonials</p>*/}
								<h2>ENDORSEMENTS</h2>
							</div>
							
						</div>
					</div>
					<div className="row">
						<div className="col">
							<div className="partner-wrap owl-carousel">
							
								<div className="single-partner">
									{/*<img className="bottom" src="img/avator.webp" alt="" />*/}
									<strong className="stname">MR KBVM KRISHNA PRASAD <small>ADVANCE MOUNTAINEERING COURSE SER NO-15</small> 
</strong>
									<p><i>A mountaineer should be ready for any situation. Course was very exciting and
challenging, instructors are well experienced and helpful. I am very much satisfied and
happy that I have learnt from the best. 
</i></p>
								</div>
							
						
								<div className="single-partner">
									{/*<img className="bottom" src="img/avator.webp" alt="" />*/}
									<strong className="stname">MISS NAVEENA
									<small>BASIC MOUNTAINEERING COURSE SER NO 39</small>
									</strong>
									<p><i>I gained a lot of experience for life and learnt a great deal about team work and discipline
required in life from NIMAS. Now I am confident that I can live and flourish in any
situation life pitch at me. 

</i></p>
								</div>
								
						
								<div className="single-partner">
									{/*<img className="bottom" src="img/avator.webp" alt="" />*/}
									<strong className="stname">MISS SMRITI BHAGAT 

									<small>BASIC MOUNTAINEERING
COURSE SER NO 39 </small>
									</strong>
									<p><i>I had always dreamt of becoming a mountaineer, and not just a mountaineer, but a good
and a strong one.
The Basic Mountaineering Course from NIMAS was just the right way to start off my
dream. In these 28 days, I discovered the importance of values such as patience,
friendship, mindfulness and a deep sense of confidence in myself. The feeling of
fulfillment and achievement after the glacier training at the mighty Gorichen Glacier is
just beyond imagination and no words can describe how humble I felt in front of Nature's
vastness.
The dedication, care and commitment of each of the instructors and the staff in
maintaining a safe and smooth learning experience is worth mentioning.
I will always cherish the memories of those 28 days and keep the learnings with me
forever." 

</i></p>
								</div>
								
						
								<div className="single-partner">
									{/*<img className="bottom" src="img/avator.webp" alt="" />*/}
									<strong className="stname">MR SUSHANT KAUL
									<small>BASIC MOUNTAINEERING COURSE SER NO 39</small>
									</strong>
									<p><i>I am so glad that I got a chance to get trained from the most amazing team of
professionals at NIMAS. I have had the most transformative journey while growing and
learning with the champions who teach you the best with the right techniques. Learning
here was always fun. I enjoyed everything from training, food, accommodation, teachers.
Everything has left a mark and also have added a beautiful memory in my life book. This
is the only place I feel where you pick up the right Expertise to save lives while having
fun. As I always say, 'mazza aa gaya' but with NIMAS and team, I am sure everyone will
share the same views. 

</i></p>
								</div>

							

								<div className="single-partner">
									{/*<img className="bottom" src="img/avator.webp" alt="" />*/}
									<strong className="stname">MR RAVI AHLAWAT
									<small>BASIC PARAMOTOR COURSE SER NO 13 </small>
									</strong>
									<p><i>It was a pleasure being at NIMAS where I completed my Basic and Intermediate
Paramotor Course. The instructors are amongst the best professionals in India and the
entire training was conducted with extreme perfection and safety. The courses are
designed to not only give a strong foundation to trainees but also makes them mentally
robust and true professionals. I would strongly recommend adventure enthusiasts and
Paramotor aspirants to undergo courses from NIMAS which is the only Government
National Institute offering certified course recognized by Government of India. 

</i></p>
								</div>

								<div className="single-partner">
									{/*<img className="bottom" src="img/avator.webp" alt="" />*/}
									<strong className="stname">MR KARTHIK RAMASWAMY
									<small>COMBINED PARAGLIDING COURSE SER NO 05 &
PARAGLIDING REFRESHER COURSE SER NO-01 
</small>
									</strong>
									<p><i>Thank you NIMAS for a life changing transformation in my person by way of training of
imparting the necessary skills and discipline to be able to take flight on my own. My
heartfelt gratitude to my instructors Mr Ranjit Singh, Shashi Pal, Ajit Sangma and
Narendra Yadav and the Army culture without which such a feat is impossible. Who
better than our nation’s brave hearts to be at the helm of shaping our country’s
adventurous youth. 


</i></p>
								</div>

							
								
							</div>
						</div>
					</div>
				</div>
			</div>
			

            </div>
            <Footer />
                
          </>
    );
  }

  export default Home2;
