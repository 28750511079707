import React, { useEffect } from "react";
//import {Link} from 'react-router-dom';
import '../Common/custom-style.css';
//import PersonIcon from '@mui/icons-material/Person';
import Headernew from '../Common/Headernew';
import $ from 'jquery';
//import Button from '@mui/material/Button';
//import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

function Thankyou() {
  localStorage.removeItem("save-temp-Bookinginfo");
  localStorage.removeItem("Booking-Info");
  localStorage.removeItem("Booking-Type");
  const paymentInfo = JSON.parse(localStorage.getItem("payment-Info"));
  if(paymentInfo===null){
    window.location.href = '/'; 
  }
  var $fileInput = $('.file-input');
  var $droparea = $('.file-drop-area');
  
  // highlight drag area
  $fileInput.on('dragenter focus click', function() {
    $droparea.addClass('is-active');
  });
  
  // back to normal state
  $fileInput.on('dragleave blur drop', function() {
    $droparea.removeClass('is-active'); 
  });
  
  // change inner text
  $fileInput.on('change', function() {
    var filesCount = $(this)[0].files.length;
    var $textContainer = $(this).prev();
  
    if (filesCount === 1) {
      // if single file is selected, show file name
      var fileName = $(this).val().split('\\').pop();
      $textContainer.text(fileName);
    } else {
      // otherwise show number of files
      $textContainer.text(filesCount + ' files selected');
    }
  });

  
	useEffect(() => {
	}, []);
  
  return (
    <>
      <div><Headernew /></div>

      <main id="main" className="main mainss mainssp">
        <section className="section dashboard">
          <div className="container-fluid pb-200">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
             {/*} <div class="doc-upload">
                <p>Upload your Medical Documents to complete booking process.</p>
                <div class="file-drop-area">
                  <span class="fake-btn">Choose files</span>
                  <span class="file-msg">or drag and drop files here</span>
                  <input class="file-input" type="file" multiple />
                </div>
                <Button variant="contained">Upload</Button>

  </div>*/}
                <div className="thankyou">
                  <div className="card cards">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <img src="../admin/img/success.png" style={{ margin: '20px auto', display: 'block' }} alt="img"/>
                        </div>
                        <div className="col-md-6">
                          <div className="content">
                            <div className="wrapper-1">
                              <div className="wrapper-2">
                                <h3><i>Booking Successful</i></h3>
                                <p>Thanks for booking. We have reserve your space..  </p>
                                <p>Your Payment Id : <span style={{ color: '#198754' }}><b>{paymentInfo._1}</b></span></p>
                                <p>Your Booking Id : <span style={{ color: '#198754' }}><b>{paymentInfo._2}</b></span></p>
                                
                            
                               
                              </div>
                              <div className="steps">
                                <p style={{fontWeight:'500'}}><i>Steps to Complete you Booking (Upload Documents)</i></p>
                                <ul>
                                  <li><b>Step 1:</b> Download Below docs using download links <br/>
                                  <a href='../doc/MEDICAL-FORM.doc' style={{fontWeight:'600', textTransform:'uppercase'}} download> 
                                  Medical Form</a></li>

                                  <li><b>Step 2:</b> Fill the doc with relevant information.</li>
                                  <li><b>Step 3:</b> Get it signed and Stamped by your doctor.</li>
                                  <li><b>Step 4:</b> Go to your bookings.  <a href='/student-admin/booking'> 
                                  <b>Click Here</b></a></li>
                                  <li><b>Step 5:</b> Search your booking using your booking id.</li>
                                  <li><b>Step 6:</b> Open the booking detail page and upload the scanned documents to complete your booking.</li>
                               
                                </ul>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Thankyou;
