import React, { useEffect, useState, useRef } from "react";
import Footer from "../../Common/Footer";
import Header from "../../Common/Header";
import { colors } from "@mui/material";
import { ThreeDots } from 'react-loader-spinner';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


function Mountaineeringdetails() {
	let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
	const [productdata, setProductData] = useState([]);
	const newArrayList = [];
	const productdata2 = { "NumberOfFieldsView": 50, "filters": { "Category Name": "Mountaineering" }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 3 }
	const [isLoader, setIsLoader] = useState(true);

	function ProductData1() {
		fetch(
			"https://api.dialerp.com/api/api/getTemplateDataList",
			{

				method: "POST",
				headers: {
					"Authorization-Token": authToken,
					Accept: "application/json, text/plain",
					"Content-Type": "application/json;charset=UTF-8",
				},
				body: JSON.stringify(productdata2),

			}
		).then((result) => {
			result.json().then((resp) => {
				setProductData(resp);
			});
			setIsLoader(false);
		});
	}
	useEffect(() => {
		ProductData1();
	}, [0]);

	productdata?.response?.records?.forEach(obj => {
		if (!newArrayList.some(o => o[5].value === obj[5].value)) {
			newArrayList.push({ ...obj });
		}
	});
	function CourseBooking(obj) {
		localStorage.removeItem("Booking-Info");
		localStorage.removeItem("Booking-Type");
		localStorage.setItem("Booking-Info", JSON.stringify(obj));
		localStorage.setItem("Booking-Type", "Land Adventure");
		const userdelete1 = JSON.parse(localStorage.getItem("user-Studentinfo"));
		if (userdelete1 != null) {
			window.location.href = '/student-admin/book-course';
		}
		else {
			window.location.href = '/student-admin/login';
		}
	}


	const titleRef = useRef()
	function handleBackClick(id) {
		//debugger
		const element = document.getElementById(`${id}`);
		if (element) {
			// 👇 Will scroll smoothly to the top of the next section
			element.scrollIntoView({ behavior: 'smooth' });
		}
	}


	return (
		<>
			{isLoader && <div id="loadingg">
				<ThreeDots
					height="80"
					width="80"
					radius="9"
					color="#fff"
					ariaLabel="three-dots-loading"
					wrapperStyle={{}}
					wrapperClassName=""
					visible={true}
				/>
			</div>}
			<Header />
			<div className="wrapper coursedetails">
				<div className="bradcumb-area adventure-3 overlay-bg-2">
					<div className="container">

					</div>
				</div>
			</div>
			<div className="container pb-200">
				<div className="row">
					<div className="col-sm-12">
						<div>
							{/*<p style={{ width: '50%', margin: '0px 0px 0px 30px', fontWeight:'600' }}>Select Course</p>
							<div className="select-dropdown">	<select name="boxes" className="boxselect form-control" style={{ width: '316px' }}>
								<option value="all" selected>All Adventure</option>
								{newArrayList?.map((dd, i) => {
									return (
										<option value={'ad' + i}>{dd[5].value}</option>
									);
								})}
							
							</select></div>*/}



							<div className="container">
								<div className="row">

									<div className="col-lg-12">
										<div className="">
											<div name="boxes" className="boxselect crspan">

												{newArrayList?.map((dd, i) => {
													return (
														<span value={'ad' + i}
															onClick={() => {
																handleBackClick('ad' + i);
															}}
														>{dd[5].value}</span>
													);
												})}

											</div></div>

									</div>

									<div className="col-lg-6 col-sm-12">
										<div className="single-camp-hi">
											<img src="images/adventure/570x250/mountaineering.png" alt="" />
										</div>
									</div>
									<div className="col-lg-6 col-sm-6 disf">

										<div>
											<h4>Mountaineering Courses</h4>
											<p className="para">Ascend to new heights with our specialized mountaineering courses, from mastering
												essential climbing techniques to developing wilderness survival skills, our courses at
												NIMAS will shape you into a resilient and knowledgeable mountaineer. Secure your
												spot now and be prepared to ascend to new heights. The courses include theoretical
												classes and practical training at altitudes raging 15,000-17,000 feet and also include
												adequate exposure to learning and implementing knowledge of meteorology,
												geography, map reading and first aid in mountaineering practices. The courses have
												been designed in four stages each enhancing the skills and experience of
												predecessor course. </p>

										</div></div>



								</div>
							</div>
						</div>
						{newArrayList?.map((productitem122, i) => {
							return (
								<div style={{ marginTop: 50 }}>
									<div className={'box all wow fadeInDown ad' + i} id={'ad' + i} ref={titleRef}>
										<div className="camping-hi-area">
											<div className="container">
												<div className="row">
													<div className="col-lg-7">
														<div className="section-title sec-t">
															<h5>{productitem122[5].value}</h5>
														</div>
													</div>
												</div>
												<div className="row justify-content-center">
													<div className="col-lg-12">
														<div className="dis-alpine section-title">
															<div className="sin-sis-alpine-wrap">
																<div className="row">
																	<div className="col-sm-2">
																		<div className="single-alpine">
																			<h4>Course Fee</h4>
																			<h3>₹{productitem122[12].value}/-</h3>
																		</div>
																	</div>
																	<div className="col-sm-2">
																		<div className="single-alpine">
																			<h4>Duration</h4>
																			<h3>{productitem122[6].value}</h3>
																		</div>
																	</div>
																	<div className="col-sm-1">
																		<div className="single-alpine">
																			<h4>Age</h4>
																			<h3>{productitem122[14].value}</h3>
																		</div>
																	</div>
																	<div className="col-sm-1">
																		<div className="single-alpine">
																			<h4>Seats</h4>
																			<h3>{productitem122[7].value}</h3>
																		</div>
																	</div>
																	<div className="col-sm-4">
																		<div className="single-alpine">
																			<h4>Season </h4>
																			<h3>{productitem122[8].value}</h3>
																		</div>
																	</div>
																	<div className="col-sm-2">
																		<div className="single-alpine">
																			<h4>Reviews</h4>
																			<ul>
																				<li><i className="fas fa-star active"></i></li>
																				<li><i className="fas fa-star active"></i></li>
																				<li><i className="fas fa-star active"></i></li>
																				<li><i className="fas fa-star active"></i></li>
																				<li><i className="fas fa-star "></i></li>
																			</ul>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="row justify-content-center pb-50">
													<div className="col-lg-12">
														<div className="table-responsive mb-30">
															<table className="table">
																<thead>
																	<tr>
																		<th scope="col"><p>Serial.No.</p></th>
																		<th scope="col"><p>Course Dates	</p></th>
																		<th scope="col"><p>Course Fees	</p></th>
																		<th scope="col"><p>Capacity</p></th>
																		<th scope="col"><p>Age</p></th>
																		<th scope="col"><p>Type</p></th>
																		<th scope="col"><p>Available Seats</p></th>
																		<th scope="col">Action</th>
																	</tr>
																</thead>
																<tbody>
																	{productdata?.response?.records?.filter((productitem11, i) => {
																		return productitem11[5].value === productitem122[5].value;
																	}).sort((a, b) => { return a[9].value.localeCompare(b[9].value); })
																		.map((filteredItem, i) => (
																			<tr key={i}>
																				<td>{filteredItem[9].value}</td>
																				<td>{filteredItem[10].value} - {filteredItem[11].value}</td>
																				<td>₹{filteredItem[12].value}/-</td>
																				<td>{filteredItem[13].value}</td>
																				<td>{filteredItem[14].value}</td>
																				<td>{filteredItem[15].value}</td>
																				<td>{filteredItem[16].value}</td>
																				<td>
																					{filteredItem[16].value > '0' ?
																						(
																							<a className="btn btn-theme mb-autoo" onClick={() => CourseBooking(filteredItem)}>Book Now</a>
																						) : <span className="btn btn-theme mb-autoo" style={{ color: 'red', padding: '0px' }}>Sold</span>
																					}
																				</td>

																			</tr>
																		))}
																</tbody>
															</table>
															<div >
																{
																	productitem122[5].value === "Method of Instruction Course (MOI)" ?
																		"EXPEDITION ABOVE 5500 MTR WILL BE COVERED DURING THE SAID COURSE IF WEATHER CONDITION IS ALLOWED" : ""
																}
															</div>
														</div>
													</div>
												</div>

											</div>
										</div>
									</div>

								</div>
							);
						})}
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}
export default Mountaineeringdetails;
