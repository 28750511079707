
import { Link } from 'react-router-dom';
import '../Common/custom-style.css';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import ApiIcon from '@mui/icons-material/Api';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import PersonIcon from '@mui/icons-material/Person';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import LogoutIcon from '@mui/icons-material/Logout';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { useEffect, useState } from "react";
import BlockIcon from '@mui/icons-material/Block';




const drawerWidth = 240;
//const margintop = 61;
function Headersidebar(props) {


  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [loginAdminname, setloginAdminname] = useState('');
  const [loginimg, setloginimg] = useState('');
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  // else{
  //   setloginAdminname(userStudentinfo?.records[0][5].value);
  // }

  useEffect(() => {
    const userStudentinfo = JSON.parse(localStorage.getItem("user-admininfo"));
    if (userStudentinfo !== null) {
      setloginAdminname(userStudentinfo?.records[0][5].value);
      setloginimg(userStudentinfo?.records[0][4].value);
    }
  }, [0]);
  const drawer = (
    <div>

      <ul className="sidebar-nav" id="sidebar-nav">

        <li className="nav-item">
          <Link className="nav-link " to="/nimas-admin/dashboard">
            <DashboardIcon />
            <span>Dashboard</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" to="#">
            <BookmarksIcon /><span>Bookings</span><ChevronRightIcon className="dropnav" />
          </Link>
          <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
          <li>
              <Link to="/nimas-admin/document-Pending">
                <ApiIcon /><span>Document Pending</span>
              </Link>
            </li>
            <li>
              <Link to="/nimas-admin/in-review-bookings">
                <ApiIcon /><span>In-Review Bookings</span>
              </Link>
            </li>
            <li>
              <Link to="/nimas-admin/confirmbookings">
                <AcUnitIcon /><span>Confirm Bookings</span>
              </Link>
            </li>
            <li>
              <Link to="/nimas-admin/cancel-bookings">
                <BlockIcon /><span>Cancelled Bookings</span>
              </Link>
            </li>
            <li>
              <Link to="/nimas-admin/bookings">
                <CollectionsBookmarkIcon /><span>All Bookings</span>
              </Link>
            </li>
          </ul>
        </li>

      </ul>


    </div>
  );
  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <>

      <div className="header-sidebar">

        <header id="header" className="header fixed-top d-flex align-items-center" >

          <div className="d-flex align-items-center justify-content-between">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'block' } }}
              className="icobtnns">
              <MenuIcon />
            </IconButton>
            <a href="/" target='_blank' className="logo d-flex align-items-center">
              <img src="../admin/img/logo.jpg" alt="" />
            </a>
          </div>
          <nav className="header-nav ms-auto">
            <ul className="d-flex align-items-center">
              <li className="nav-item dropdown pe-3 uderd">

                <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                  <img  src={loginimg !==""? loginimg : "../admin/img/profileicon.png"} style={{ height: '35px', width: '35px' }} alt="Profile" className="rounded-circle" />
                  <span className="d-none d-md-block dropdown-toggle ps-2">{loginAdminname}</span>
                </a>
               
                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile" style={{ marginRight: '10px 0px !important' }}>
                  <li>
                    <Link className="dropdown-item d-flex align-items-center" to="/nimas-admin/dashboard">
                      <DashboardIcon className="sideico" />
                      <span>Dashboard</span>
                    </Link>
                  </li>
                  {/* <li>
                    <hr className="dropdown-divider" />
                  </li> */}
                  {/* <li>
                    <Link className="dropdown-item d-flex align-items-center" to="/nimas-admin/bookings">
                      <BookmarksIcon className="sideico" />
                      <span>My Bookings</span>
                    </Link>
                  </li> */}
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link className="dropdown-item d-flex align-items-center" to="/nimas-admin/myprofile">
                      <PersonIcon className="sideico" />
                      <span>My Profile</span>
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>




                  <li>
                    <Link className="dropdown-item d-flex align-items-center" id="logoutButton_" to="/nimas-admin/login">
                      <LogoutIcon className="sideico" />
                      <span>Log Out</span>
                    </Link>

                  </li>

                </ul>
              </li>

            </ul>
          </nav>

        </header>

      </div>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle} v
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, marginTop: '61px' },
          }}
          open
          className="deskdraw"
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >

      </Box>
    </>
  );
}

Headersidebar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
export default Headersidebar;
