import React, {useEffect, useState } from "react";
import Header from '../Common/Header';
import Footer from '../Common/Footer';


function News() {
	let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
	const [productdata, setProductData] = useState([]);
	const [error, setError] = useState("");
	// const [ReceiptListdata, SetReceiptListdata] = useState('');
	const NewsMedialistpra = { "NumberOfFieldsView": 50, "filters": {}, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 9 };
	const [isLoader, setIsLoader] = useState(true);
	function NewsMedia() {
	 
	  fetch(
		"https://api.dialerp.com/api/api/getTemplateDataList",
		{
		  method: "POST",
		  headers: {
			"Authorization-Token": authToken,
			Accept: "application/json, text/plain",
			"Content-Type": "application/json;charset=UTF-8",
		  },
		  body: JSON.stringify(NewsMedialistpra),
		}
	  ).then((result) => {
		result.json().then((resp) => {
		  setProductData(resp);
		  // fun_ReceiptListdata();
		  console.log(resp,"PPPPP");
		});
		setIsLoader(false);
	  });
	 
	}
	useEffect(() => {

		NewsMedia();
	  }, [0])
  return (
        <>
          <Header />
          <div className="wrapper">
          <div className="bradcumb-area style-1 overlay-bg-2">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col">
						
							<div className="bradcumb text-center">
								<h3>News</h3>
							</div>
						
						</div>
					</div>
				</div>
			</div>

            <div className="adventure-area pb-200 pt-50 newss">
				<div className="container">
				
					<div className="row">

					{productdata?.response?.records?.map((studinfo, i) => {
                        
						return (
						<div className="col-lg-6 col-sm-6 wow fadeInDown">
						
							<div className="single-adventure">

							<div className="row">
								<div className="col-lg-3 col-sm-6">
									<img src= {studinfo[6].value} alt="NIMAS" />
								</div>
								<div className="col-lg-9 col-sm-6">
								<div className="adventure-content">
									{/* <a href={studinfo[5].value} target="_blank"><h5>{studinfo[4].value}</h5></a> */}
									<a href={studinfo[5].value !==""? studinfo[5].value: studinfo[9].value} target="_blank"><h5>{studinfo[4].value}</h5></a>
								</div></div>
							</div>
							</div>
						
						</div>
							);
						})}




						</div>
					
						
						</div>
					</div>

          </div>

       
			
          <Footer />
              
        </>
  );
}

export default News;
