import React, { useEffect, useState, useRef } from "react";
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import { ThreeDots } from 'react-loader-spinner'

function Mtbdetails() {

	let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
	const [productdata, setProductData] = useState([]);
	const newArrayList = [];
	const productdata2 = { "NumberOfFieldsView": 50, "filters": { "Category Name": "Mountain Terrain Biking" }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 3 }
	const [isLoader, setIsLoader] = useState(true);

	function ProductData1() {
		fetch(
			"https://api.dialerp.com/api/api/getTemplateDataList",
			{

				method: "POST",
				headers: {
					"Authorization-Token": authToken,
					Accept: "application/json, text/plain",
					"Content-Type": "application/json;charset=UTF-8",
				},
				body: JSON.stringify(productdata2),

			}
		).then((result) => {
			result.json().then((resp) => {
				setProductData(resp);
			});
			setIsLoader(false);
		});

	}

	useEffect(() => {
		ProductData1();
	}, [0]);

	productdata?.response?.records?.forEach(obj => {

		if (!newArrayList.some(o => o[5].value === obj[5].value)) {
			newArrayList.push({ ...obj });
		}
	});
	function CourseBooking(obj) {
		localStorage.removeItem("Booking-Info");
		localStorage.removeItem("Booking-Type");
		localStorage.setItem("Booking-Info", JSON.stringify(obj));
		localStorage.setItem("Booking-Type", "Land Adventure");
		const userdelete1 = JSON.parse(localStorage.getItem("user-Studentinfo"));
		if (userdelete1 != null) {
			window.location.href = '/student-admin/book-course';
		}
		else {
			window.location.href = '/student-admin/login';
		}
	}



	const titleRef = useRef()
	function handleBackClick(id) {
		//debugger
		const element = document.getElementById(`${id}`);
		if (element) {
			// 👇 Will scroll smoothly to the top of the next section
			element.scrollIntoView({ behavior: 'smooth' });
		}
	}



	return (
		<>
			{isLoader && <div id="loadingg">
				<ThreeDots
					height="80"
					width="80"
					radius="9"
					color="#fff"
					ariaLabel="three-dots-loading"
					wrapperStyle={{}}
					wrapperClassName=""
					visible={true}
				/>
			</div>}
			<Header />
			<div className="wrapper coursedetails">
				<div className="bradcumb-area adventure-5 overlay-bg-2">
					<div className="container">

					</div>
				</div>

			</div>

			<div className="container pb-200">
				<div className="row">
					<div className="col-sm-12">

						{/*	<div>
							<p style={{ width: '50%', margin: '0px 0px 0px 30px', fontWeight:'600' }}>Select Course</p>
							<div className="select-dropdown">	<select name="boxes" className="boxselect form-control" style={{ width: '316px' }}>
								<option value="all" selected>All Adventure</option>
								{newArrayList?.map((dd, i) => {
									return (
										<option value={'ad' + i}>{dd[5].value}</option>
									);
								})}
							
							</select></div>
						</div>
					*/}

						<div className="gallery">
							<div className="container">
								<div className="row">
									<div className="col-lg-12">
										<div className="">
											<div name="boxes" className="boxselect crspan">

												{newArrayList?.map((dd, i) => {
													return (
														<span value={'ad' + i}
															onClick={() => {
																handleBackClick('ad' + i);
															}}
														>{dd[5].value}</span>
													);
												})}

											</div></div>

									</div>

									<div className="col-lg-6 col-sm-12">

										<div className="single-camp-hi">
											<img src="images/adventure/570x250/cycling.png" alt="" />
										</div>

									</div>
									<div className="col-lg-6 col-sm-6 disf">

										<div>
											<h4>Mountain Terrain Biking Courses</h4>
											<p className="para">BMTB & AMTB courses at NIMAS are the only certified biking programs in India,
												offering formalized training lasting two to three weeks. These courses focus on
												climbing, high altitude biking techniques, and bike repair & maintenance, set in
												mountainous regions with diverse terrains. From rocky trails to steep descents, the
												courses cater to riders of all levels, fostering a lifestyle connecting individuals with
												nature. Whether you're a beginner or experienced, these courses provide a platform to
												grow, explore, and enjoy the thrill of mountain biking. Saddle up, gear up, and conquer
												the mountains on two wheels!
											</p>
										</div></div>
								</div>
							</div>

						</div>

						{newArrayList?.map((productitem122, i) => {
							return (
								<div>
									<div class={'box all wow fadeInDown ad' + i} id={'ad' + i} ref={titleRef}>

										<div className="camping-hi-area">
											<div className="container">
												<div className="row">
													<div className="col-lg-7">
														<div className="section-title sec-t">
															<h5>{productitem122[5].value}</h5>
														</div>
													</div>
												</div>
												<div className="row justify-content-center">
													<div className="col-lg-12">
														<div className="dis-alpine section-title">
															<div className="sin-sis-alpine-wrap">
																<div className="row">
																	<div className="col-sm-2">
																		<div className="single-alpine">
																			<h4>Course Fee</h4>
																			<h3>₹{productitem122[12].value}/-</h3>
																		</div>
																	</div>
																	<div className="col-sm-2">
																		<div className="single-alpine">
																			<h4>Duration</h4>
																			<h3>{productitem122[6].value}</h3>
																		</div>
																	</div>
																	<div className="col-sm-1">
																		<div className="single-alpine">
																			<h4>Age</h4>
																			<h3>{productitem122[14].value}</h3>
																		</div>
																	</div>
																	<div className="col-sm-1">
																		<div className="single-alpine">
																			<h4>Seats</h4>
																			<h3>{productitem122[7].value}</h3>
																		</div>
																	</div>
																	<div className="col-sm-4">
																		<div className="single-alpine">
																			<h4>Season </h4>
																			<h3>{productitem122[8].value}</h3>
																		</div>
																	</div>
																	<div className="col-sm-2">
																		<div className="single-alpine">
																			<h4>Reviews</h4>
																			<ul>
																				<li><i className="fas fa-star active"></i></li>
																				<li><i className="fas fa-star active"></i></li>
																				<li><i className="fas fa-star active"></i></li>
																				<li><i className="fas fa-star active"></i></li>
																				<li><i className="fas fa-star "></i></li>
																			</ul>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="row justify-content-center pb-50">
													<div className="col-lg-12">
														<div className="table-responsive mb-30">
															<table className="table">
																<thead>
																	<tr>
																		<th scope="col"><p>Serial.No.</p></th>
																		<th scope="col"><p>Course Dates	</p></th>
																		<th scope="col"><p>Course Fees	</p></th>
																		<th scope="col"><p>Capacity</p></th>
																		<th scope="col"><p>Age</p></th>
																		<th scope="col"><p>Type</p></th>
																		<th scope="col"><p>Available Seats</p></th>
																		<th>Action</th>
																	</tr>
																</thead>
																<tbody>
																	{productdata?.response?.records?.filter((productitem11, i) => {return productitem11[5].value === productitem122[5].value; })
																	.sort((a, b) => { return a[9].value.localeCompare(b[9].value); })
																	.map((filteredItem, i) => (
																		<tr key={i}>
																			<td>{filteredItem[9].value}</td>
																			<td>{filteredItem[10].value} - {filteredItem[11].value}</td>
																			<td>₹{filteredItem[12].value}/-</td>
																			<td>{filteredItem[13].value}</td>
																			<td>{filteredItem[14].value}</td>
																			<td>{filteredItem[15].value}</td>
																			<td>{filteredItem[16].value}</td>
																			<td>
																				{filteredItem[16].value > '0' ?
																					(
																						<a className="btn btn-theme mb-autoo" onClick={() => CourseBooking(filteredItem)}>Book Now</a>
																					) : <span className="btn btn-theme mb-autoo" style={{ color: 'red', padding: '0px' }}>Sold</span>
																				}

																			</td>

																		</tr>
																	))}
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							);
						})}

					</div>
				</div>

			</div>
			<Footer />

		</>
	);
}

export default Mtbdetails;
