import { useEffect, useState } from "react";
import React from "react";
import { Link } from 'react-router-dom';
import '../Common/custom-style.css';
import '/node_modules/bootstrap/dist/css/bootstrap.min.css';
import '/node_modules/bootstrap/dist/js/bootstrap.min.js';
import Headersidebar from '../Common/Headersidebar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import moment from 'moment'
import { ThreeDots } from 'react-loader-spinner'

const drawerWidth = 240;
function NimasBooking() {
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  const [productdata, setProductData] = useState([]);
  const [ReceiptListdata, SetReceiptListdata] = useState('');
  const [error, setError] = useState("");
  const Bookinglistpra = { "NumberOfFieldsView": 50, "filters": {}, "isDownload": false, "index": 1, "pgSize": 1000, "templateID": 5 };
  const [isLoader, setIsLoader] = useState(true);

  // setTimeout(() => {
  //   setIsLoader(false);
  // }, 3000);
  function handleId() {
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(Bookinglistpra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.response?.records.length > 0) {
          setProductData(resp);
          //fun_ReceiptListdata();
          //console.log(resp.response?.records[0][3].value,"llll");
        }
      });
      setIsLoader(false);
    });
  }

  function fun_ReceiptListdata(id) {
    const ReceiptIdPra = { "NumberOfFieldsView": 50, "filters": {}, "isDownload": false, "index": 1, "pgSize": 1000, "templateID": 6 }
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(ReceiptIdPra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.response?.records.length > 0) {
          SetReceiptListdata(resp);
          console.log(resp.response?.records, "maxReceiptId");
        }
      });
    });
  }
  function fun_booking() {
    if (BookingS.trim() === "") {
      setError("required");
      return;
    }
  }
  useEffect(() => {

    handleId();
  }, [0])
  const [anchorE1, setAnchorE1] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const open = Boolean(anchorE1);
  const opens = Boolean(anchorE2);

  const handleClick = (event) => {
    setAnchorE1(event.currentTarget);
    setAnchorE2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorE1(null);
    setAnchorE2(null);
  };
  const [BookingS, setBookingS] = React.useState('');
  const [ages, setAges] = React.useState('');

  const handleChange = (event) => {
    setBookingS(event.target.value);
    setAges(event.target.value);
  };


  function fun_getbookingidonclick(id) {
    localStorage.setItem("bookinginfo", id)
  }
  function fun_getStudentDetailclick(id) {
    localStorage.setItem("StudentDetailinfo", id)
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100); // Added state for items per page
  const totalItems = productdata?.response?.records?.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = productdata?.response?.records?.slice(indexOfFirstItem, indexOfLastItem) || [];

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  // Function to handle changes in items per page dropdown
  function handleItemsPerPageChange(event) {
    setItemsPerPage(event.target.value);
    setCurrentPage(1); // Reset to the first page when changing items per page
  }
  return (

    <>
      {isLoader && <div id="loadingg">
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#fff"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>}

      <Box sx={{ display: 'flex' }} className="custcontent">

        <div>

          <Headersidebar />
        </div>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >

          <main id="main" className="main">

            <div className="pagetitle">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/nimas-admin/dashboard">Home</Link></li>
                  <li className="breadcrumb-item active">All Bookings</li>
                </ol>
              </nav>
            </div>

            <div className="bookingss">
              <section className="section profile">
                <div className="row">


                  <div className="col-xl-12">

                    <div className="card">

                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div className="col-md-6">
                            <h5 className="card-title">All Bookings </h5>
                          </div>
                          <div className="col-md-6 text-end"style={{display: 'contents'}} >
                            <div className="items-per-page" style={{marginLeft:"255px"}}>
                              <label htmlFor="itemsPerPage">Show per Page:</label>
                              <select
                                id="itemsPerPage"
                                value={itemsPerPage}
                                onChange={handleItemsPerPageChange}>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="300">300</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                              </select>
                            </div>
                            <label>Total Records: <b>{productdata?.response?.records?.length}</b></label>
                          </div>
                        </div>



                        <div className="tableresp" style={{ overflowY: 'auto' }}>
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Sr.</th>
                                <th scope="col">Date</th>
                                <th scope="col">Booking Id</th>
                                <th scope="col">Student Name</th>
                                <th scope="col">Serial No</th>
                                <th scope="col">No of Seats</th>
                                <th scope="col">Adventure Name</th>
                                <th scope="col">Course Dates</th>
                                <th scope="col">Transaction Id</th>
                                <th scope="col">Payment Status</th>
                                <th scope="col">Cancel Reason</th>
                                {/* <th scope="col">Action</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {/* {productdata?.response?.records?.filter((dl) => dl[26]?.value).map((studinfo, i) => { */}
                              {currentItems?.map((studinfo, i) => {
                                //i++;
                                const srNo = i + 1 + indexOfFirstItem;
                                return (
                                  <tr>

                                    <th scope="row">{srNo}</th>
                                    <td>{moment(studinfo[1].value).format("DD-MMM-YYYY hh:mm:ss")}</td>
                                    {/* <td><a href="/nimas-admin/bookingdetails" target="_blank" onClick={localStorage.setItem("bookinginfo", studinfo[0].value)}>{studinfo[3].value}</a></td>
                                  <td><Link to="/nimas-admin/student-info">{studinfo[24].value}</Link></td> */}

                                    <td><a href="/student-admin/invoicenew" onClick={() => fun_getbookingidonclick(studinfo[3].value)}>{studinfo[3].value}</a></td>
                                    <td><a href="/nimas-admin/student-info" onClick={() => fun_getStudentDetailclick(studinfo[25].value)}>{studinfo[24].value}</a></td>
                                    <td>{studinfo[6]?.value}</td>
                                    <td>{studinfo[8]?.value}</td>
                                    <td>{studinfo[4]?.value}<br />({studinfo[5]?.value})</td>
                                    <td>{studinfo[21]?.value}/{studinfo[22]?.value}</td>
                                    <td>{studinfo[26]?.value}</td>

                                    <td><span
                                      className={`datee 
                                ${studinfo[19]?.value === 'Confirmed' ? 'text-green' :
                                          studinfo[19]?.value === 'In-Review' ? 'text-blue' :
                                            studinfo[19]?.value === 'Payment Upload' ? 'text-blue' :

                                              studinfo[19]?.value === 'Failed' ? 'text-red' :
                                                studinfo[19]?.value === 'Cancel' ? 'text-red' : ''}`
                                      }>{studinfo[19]?.value}</span><br />

                                      <span className={`datee11 
                                ${studinfo[27]?.value === '' ? 'text-blue' : ''}`}>
                                        {studinfo[27]?.value !== "" ? "" : "/ Document Pending"}</span>
                                    </td>
                                    <td>{studinfo[28]?.value}</td>
                                    {/* {ReceiptListdata?.response?.records?.map((receiptinfo, i) => {
                                    if(receiptinfo[4].value===studinfo[3].value){
                                    return (
                                      <td>{receiptinfo[7].value}</td>
                                    );
                                    }
                                  })} */}
                                    {/* <td><span className="badge bg-danger">{studinfo[19].value}</span></td> */}
                                    {/* <td>
                                    <input className="dark-light" type="checkbox" id="dark-light" name="dark-light" />
                                    <div className="sec-center">
                                      <input className="dropdown" type="checkbox" id="dropdown" name="dropdown" />
                                      <label className="for-dropdown" for="dropdown"> <MoreVertIcon /></label>
                                      <div className="section-dropdown">
                                        <ul className="dd-menu">
                                          <li><a href="#popup1"><DoneIcon /> Accept</a></li>
                                        
                                          <li><a href="https://accounts.google.com/v3/signin/identifier?dsh=S-1626618653%3A1688997590964983&continue=https%3A%2F%2Fmail.google.com%2Fmail%2Fu%2F0%2F&emr=1&followup=https%3A%2F%2Fmail.google.com%2Fmail%2Fu%2F0%2F&ifkv=AeDOFXgvy-LCAS7gAOrn8WzO5ay1Y2VZGqMn12l4ENW4ghVdSacuZp4KqF3WNEav-5Db52SwWkD_oQ&osid=1&passive=1209600&service=mail&flowName=GlifWebSignIn&flowEntry=ServiceLogin"><ReplyIcon /> Custom Reply</a></li>
                                          <li><ArticleIcon /> Generate Report</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </td> */}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div id="popup1" className="overlay">
                          <div className="popup">
                            <h6>Update Booking Status</h6>
                            <a className="close" href="#">&times;</a>
                            <div className="content">
                              <FormControl fullWidth variant="standard">
                                <InputLabel id="demo-simple-select-label">Booking Status</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={BookingS}
                                  label="Booking Status"
                                  onChange={handleChange}
                                  className={`${error && BookingS.trim() === '' ? 'is-invalid' : ''}`}
                                >
                                  <MenuItem value="Confirmed">Confirmed</MenuItem>
                                  <MenuItem value="In-Review">In-Review</MenuItem>
                                  <MenuItem value="Cancel">Cancel</MenuItem>
                                  <MenuItem value="Failed">Failed</MenuItem>
                                </Select>
                                {error && BookingS.trim() === '' && <div className="invalid-feedback">Please select booking status</div>}
                              </FormControl>

                              <Stack spacing={2} direction="row">
                                <Button variant="outlined">Cancel</Button>
                                <Button variant="contained" onClick={fun_booking}>Confirm</Button>
                              </Stack>
                            </div>
                          </div>
                        </div>
                        <div className="pagination_" style={{ textAlign: "center" }}>
                          {Array.from({ length: totalPages }, (_, i) => (
                            <button style={{ margin: '0 5px' }}
                              key={i}
                              onClick={() => handlePageChange(i + 1)}
                              disabled={currentPage === i + 1}
                            >
                              {i + 1}
                            </button>
                          ))}
                        </div>

                      </div>
                    </div>

                  </div>
                </div>

              </section>
            </div>

          </main>
        </Box>
      </Box>
    </>


  );
}

export default NimasBooking;
