import React, { useEffect, useState } from "react";
import Headernew from '../Common/Headernew';
import '/node_modules/bootstrap/dist/css/bootstrap.min.css';
import '/node_modules/bootstrap/dist/js/bootstrap.min.js';
import { Link } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner'
function Payment() {
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;

  const savetempBookinginfo = JSON.parse(localStorage.getItem("save-temp-Bookinginfo"));
  const userStudentinfo = JSON.parse(localStorage.getItem("user-Studentinfo"));

  // if (userStudentinfo === null) {
  //   window.location.href = '/Student-admin/Login';
  // }
  // if (savetempBookinginfo === null) {
  //   window.location.href = '/student-admin/book-course';
  // }

  const [fullName, setfullName] = useState("");
  const [mobile, setmobile] = useState("");
  const [Email, setEmail] = useState("");
  const [error, setError] = useState('');
  const [aadharimg, setaadharimg] = useState("");
  const [selectedFile, setselectedfile] = useState(null);

  const loginpra = { "NumberOfFieldsView": 50, "filters": { "Email": userStudentinfo?.records[0][8].value, "Password": userStudentinfo?.records[0][9].value }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 4 }
  function fun_Login() {
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(loginpra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          if (resp.response?.records.length > 0) {
            setfullName(resp.response?.records[0][5].value);
            setmobile(resp.response?.records[0][6].value);
            setEmail(resp.response?.records[0][8].value);

            setSerialNo(savetempBookinginfo._4);
            setcourse(savetempBookinginfo._3);
            fun_courseinfolist(savetempBookinginfo._4);
          }
          else {
            window.location.href = '/Student-admin/Login';
          }
        }
        else {
          alert(resp.errors);
        }
      });
    });
  }

  //const [_id, setid] = useState("");
  const [ReceiptId, setReceiptId] = useState("");
  const [BookingId, setBookingId] = useState("");
  // const [BookingStatus, setBookingStatus] = useState("");
  const [PaymentMethod, setPaymentMethod] = useState("");
  const [TransactionId, setTransactionId] = useState("");
  const [Amount, setAmount] = useState(0);
  const [SerialNo, setSerialNo] = useState("");

  const MaxReceiptIdPra = { "NumberOfFieldsView": 50, "filters": {}, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 6 }
  function fun_MaxReceiptId() {
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(MaxReceiptIdPra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          let value = 2023001;
          if (resp.response?.records.length > 0) {
            setReceiptId(resp.response?.records[0][3].value + 1);
          }
          else {
            setReceiptId(value);
          }
        }
        else {
          alert(resp.errors);
        }
      });
    });
  }
  const [isLoader, setIsLoader] = useState(false);
  function onFileUpload() {
    //selectedFile.files[0].name

    const formData = new FormData();
    if (selectedFile != null) {
      setIsLoader(true);
      formData.append("myFile", selectedFile);
      formData.append("name", selectedFile.name);
      setselectedfile(selectedFile.name);
      setaadharimg(selectedFile.name);
      axios({
        method: 'post',
        url: "https://dialerpwebapis-dev-as.azurewebsites.net/api/UploadData/UploadCommonFilesToAzure?userID=" + 40330 + "&parentID=" + 40330 + "'",
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data', 'Authorization-Token': authToken },
      })
        .then((response) => {
          if (response.data.hasErrors === true) {
            alert(response.data.errors);
            setIsLoader(false);
          }
          else if (response.data.response !== null) {
            // alert("Image Upload successfully ");
            setaadharimg(response.data.response[0].imageUrl);
            setIsLoader(false);
          }
        }
        )
        .catch((err) =>
          alert(err)
        );
    }

  };
  useEffect(() => {
    fun_Login();
    fun_bookingMaxbookingId();
    fun_MaxReceiptId();
    getTemplateIDList();
    ProductData1();
  }, [0]);

  // function fun_getBookinglist() {
  //   const BookinglistPra = { "NumberOfFieldsView": 50, "filters": { "BookingID": savetempBookinginfo._1 }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 5 }
  //   fetch(
  //     "https://api.dialerp.com/api/api/getTemplateDataList",
  //     {
  //       method: "POST",
  //       headers: {
  //         "Authorization-Token": authToken,
  //         Accept: "application/json, text/plain",
  //         "Content-Type": "application/json;charset=UTF-8",
  //       },
  //       body: JSON.stringify(BookinglistPra),
  //     }
  //   ).then((result) => {
  //     result.json().then((resp) => {
  //       if (resp.response?.records.length > 0) {
  //         // setBookingDetails(resp.response?.records[0]);
  //         setid(resp.response?.records[0][0].value);
  //         setBookingId(resp.response?.records[0][3].value);
  //         //setBookingStatus(resp.response?.records[0][19].value);
  //         setBookingStatus("Payment Upload")
  //         setSerialNo(resp.response?.records[0][6].value);
  //        // setCategory(resp.response?.records[0][4].value);
  //         setcourse(resp.response?.records[0][5].value);
  //         // setprice(resp.response?.records[0][5].value);
  //         fun_courseinfolist(resp.response?.records[0][6].value);
  //       }
  //     });
  //   });
  // }
  function fun_courseinfolist(sno) {
    const BookinglistPra_ = { "NumberOfFieldsView": 50, "filters": { "Serial No": sno }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 3 }
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(BookinglistPra_),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          if (resp.response?.records.length > 0) {

            setL_id(resp.response?.records[0][0].value)
            setlSr(resp.response?.records[0][3].value)
            setlCategoryName(resp.response?.records[0][4].value)
            setlCourseName(resp.response?.records[0][5].value)
            setlSeats(resp.response?.records[0][7].value)
            setlSeason(resp.response?.records[0][8].value)
            setlSerialNo(resp.response?.records[0][9].value)
            setlCourseDatesFrom(resp.response?.records[0][10].value)
            setlCourseDatesTo(resp.response?.records[0][11].value)
            setlCourseFees(resp.response?.records[0][12].value);
            setAmount(resp.response?.records[0][12].value);
            setlCapacity(resp.response?.records[0][13].value);
            setlAge(resp.response?.records[0][14].value);
            setlType(resp.response?.records[0][15].value);
            setAvailableSeats(resp.response?.records[0][16].value - 1)
          }
        }
        else {
          window.location.href = '/';
        }
      });
    });
  }

  const maxBookingPra = { "NumberOfFieldsView": 50, "filters": {}, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 5 }
  function fun_bookingMaxbookingId() {
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(maxBookingPra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        let value = 2023000001
        if (resp.response?.records.length > 0) {
          setBookingId(resp.response?.records[0][3].value + 1);
          //console.log(BookingID);
        }
        else {
          setBookingId(value);
        }
      });
    });
  }
  const [L_id, setL_id] = useState('');
  const [lSr, setlSr] = useState('');
  const [lCategoryName, setlCategoryName] = useState('');
  const [lCourseName, setlCourseName] = useState('');
  const [lSeats, setlSeats] = useState('');
  const [lSeason, setlSeason] = useState('');
  const [lSerialNo, setlSerialNo] = useState('');
  const [lCourseDatesFrom, setlCourseDatesFrom] = useState('');
  const [lCourseDatesTo, setlCourseDatesTo] = useState('');
  const [lCourseFees, setlCourseFees] = useState('');
  const [lCapacity, setlCapacity] = useState('');
  const [lAge, setlAge] = useState();
  const [lType, setlType] = useState('');
  const [AvailableSeats, setAvailableSeats] = useState('');
  const [courseName, setcourse] = useState();

  const [disabled, setDisabled] = useState(false);

  const fun_PaymentValidation = () => {
    setDisabled(true);
    if (ispolicy === false) {
      const policy = document.getElementById('entries');
      policy.focus();
      // alert("Kindly select Refund Policy !!");
      setDisabled(false);
      setError("Kindly select Refund Policy !!");
      return;
    }
    else if (fullName.trim() === "") {
      setError("Please enter name");
      setDisabled(false);
      return;
    }
    else if (TransactionId.trim() === "") {
      setError("Please enter transaction id");
      setDisabled(false);
      return;
    }
    else if (PaymentMethod.trim() === "") {
      setError("Please select payment method");
      setDisabled(false);
      return;
    }
    else if (Amount === 0) {
      setDisabled(false);
      setError("Please enter payment amount");
      return;
    }
    else if (selectedFile === null) {
      setDisabled(false);
      setError("Please upload your aadhar documents");
      return;
    }

    else {
      fun_addNewBooking();
    }

  }
  const addnewBookingpra = {
    "templateID": "5"
    , "_1": BookingId
    , "_2": savetempBookinginfo._2
    , "_3": savetempBookinginfo._3
    , "_4": savetempBookinginfo._4
    , "_5": savetempBookinginfo._5
    , "_6": savetempBookinginfo._6
    , "_7": savetempBookinginfo._7
    , "_8": savetempBookinginfo._8
    , "_9": savetempBookinginfo._9
    , "_10": savetempBookinginfo._10
    , "_11": savetempBookinginfo._11
    , "_12": savetempBookinginfo._12
    , "_13": savetempBookinginfo._13
    , "_14": savetempBookinginfo._14
    , "_15": savetempBookinginfo._15
    , "_16": savetempBookinginfo._16
    , "_17": "Payment Upload"
    , "_18": savetempBookinginfo._18
    , "_19": savetempBookinginfo._19
    , "_20": savetempBookinginfo._20
    , "_21": savetempBookinginfo._21
    , "_22": savetempBookinginfo._22
    , "_23": savetempBookinginfo._23
    , "_24": TransactionId
    , "_25": ""
    , "_26": ""
    , "_27": aadharimg
  }
  const fun_addNewBooking = () => {
    fetch("https://api.dialerp.com/api/api/addUpdateTemplateDataWeb",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(addnewBookingpra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          fun_saveReceiptdetails()
        }
        else {
          alert(resp.errors);
        }
      });
    });
  }
  const saveReceiptdetailspara = {
    "templateID": "6"
    , "_1": ReceiptId
    , "_2": BookingId
    , "_3": "Payment Upload"
    , "_4": PaymentMethod
    , "_5": TransactionId
    , "_6": Amount
    , "_7": 0
    , "_8": Amount
    , "_9": 0
    , "_10": Amount
    , "_11": SerialNo
  }
  const fun_saveReceiptdetails = () => {
    fetch(
      "https://api.dialerp.com/api/api/addUpdateTemplateDataWeb",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(saveReceiptdetailspara),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          //fun_addBooking();
          fun_CourseupdateAvailableSeats();

          //  window.location.href = "/student-admin/thankyou";
        }
        else {
          alert(resp.errors);
        }
      });
    });
  };
  // const addBookingpra = {
  //   "templateID": "5"
  //   , "_id": _id
  //   , "_1": BookingId
  //   , "_2": CategoryName
  //   , "_3": courseName
  //   , "_4": SerialNo
  //   // , "_5": NextofKin
  //   , "_6": 1
  //   , "_17": BookingStatus
  //   , "_24": TransactionId
  // };

  // const fun_addBooking = () => {
  //   debugger
  //   fetch("https://api.dialerp.com/api/api/addUpdateTemplateDataWeb",
  //     {
  //       method: "POST",
  //       headers: {
  //         "Authorization-Token": authToken,
  //         Accept: "application/json, text/plain",
  //         "Content-Type": "application/json;charset=UTF-8",
  //       },
  //       body: JSON.stringify(addBookingpra),
  //     }
  //   ).then((result) => {
  //     result.json().then((resp) => {
  //       if (resp.hasErrors === false) {
  //         fun_CourseupdateAvailableSeats();
  //       }
  //       else {
  //         alert(resp.errors);
  //       }
  //     });
  //   });
  // }

  const fun_CourseupdateAvailableSeats = () => {
    const BookinglistPra_ = {
      "templateID": "3", "_id": L_id, "_1": lSr, "_2": lCategoryName, "_3": lCourseName, "_5": lSeats, "_6": lSeason, "_7": lSerialNo, "_8": lCourseDatesFrom, "_9": lCourseDatesTo, "_10": lCourseFees, "_11": lCapacity, "_12": lAge, "_13": lType, "_14": AvailableSeats
    }
    fetch(
      "https://api.dialerp.com/api/api/addUpdateTemplateDataWeb",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(BookinglistPra_),
      }
    ).then((result) => {
      result.json().then((resp) => {
        setDisabled(false);
        localStorage.setItem("payment-Info", JSON.stringify(saveReceiptdetailspara));
        sendsms();
        window.location.href = "/student-admin/thankyou";
      });
    });
  }
  const [ispolicy, setpolicy] = useState(false);
  const policyCheckboxChange = (event) => { setpolicy(event.target.checked); };

  const [smsContent, setsmsContent] = useState("");
  const [templateID, settemplateID] = useState("");
  const [senderID, setsenderID] = useState("");
  const [dltEntityID, setdltEntityID] = useState("")
  const TemplateIDpra = {
    "autofillTxt": "", "itemGroupName": "", "fileLst": [], "pgSize": 100, "userID": 40330,
    "index": 1, "isDownload": false, "toEmailID": "info@nimasdirang.com", "TemplateID": "1107169259796234487"
  }

  function getTemplateIDList() {
    fetch("https://api.dialerp.com/api/api/getTemplateIDList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(TemplateIDpra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp?.hasErrors === false) {
          if (resp?.response?.objTemplateIDList.length > 0) {
            setsmsContent(resp.response?.objTemplateIDList[0].smsContent);
            settemplateID(resp.response?.objTemplateIDList[0].templateID);
            setsenderID(resp.response?.objTemplateIDList[0].senderID);
            setdltEntityID(resp.response?.objTemplateIDList[0].dltEntityID);
          }
        }
        else {
          alert(resp.errors);
        }
      });
    });
  }
  const smspra =
  {
    "userID": 40330,
    "parentID": 40330,
    "dltEntityID": dltEntityID,
    "senderID": senderID,
    "templateID": templateID,
    "smsContent": smsContent,
    "mobile": mobile,
    "toEmailID": "info@nimasdirang.com"
  }
  function sendsms() {
    fetch("https://api.dialerp.com/api/api/SendSMSOTP",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(smspra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          console.log(resp?.response)
          // if (resp.response?.records.length > 0) {
          //   // setsmsContent(resp.response?.objTemplateIDList[0]["smsContent"]);
          //   // settemplateID(resp.response?.objTemplateIDList[0]["templateID"]);
          //   // setsenderID(resp.response?.objTemplateIDList[0]["senderID"]);
          //   // setdltEntityID(resp.response?.objTemplateIDList[0]["dltEntityID"]);
          // }
        }
        // else {
        //   alert(resp.errors);
        // }
      });
    });
  }

  const productdata2 = {
    "NumberOfFieldsView": 50,
    "filters": {
      //     "Category Name": savetempBookinginfo._23,
      // "Course Name": savetempBookinginfo._23,
      "Serial No": savetempBookinginfo._4
    }
    , "isDownload": false, "index": 1, "pgSize": 100, "templateID": 3
  }
  function ProductData1() {
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {

        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(productdata2),

      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          if (resp?.response?.records[0][16].value > 0) {
          }
          else {
            localStorage.removeItem("save-temp-Bookinginfo");
            alert("Seat not available in this course !!!");
            window.location.href = `student-admin/book-course`;
          }
        }
        else {
          alert(resp.errors);
        }
      });
    });
  }
  return (
    <>
      {isLoader && <div id="loadingg">
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#fff"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>}
      <div><Headernew /></div>
      <main id="main" className="main mainss mainssp tmain">
        <section className="section dashboard">
          <div className="container-fluid pb-200">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="pagetitle">
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to="/student-admin/dashboard">Home</Link></li>
                      <li className="breadcrumb-item active">Payment</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-md-8 col-sm-12">
                <div className="card cards">
                  <div className="card-body">
                    <div className="bankdetails">

                      <h5 className="payhead">Requirements</h5>
                      <div className="single-fields" style={{ margin: '10px 0px 10px 20px', display: 'block' }}>
                        <input type="checkbox" id="entries" name="entries"
                          checked={ispolicy}
                          onChange={policyCheckboxChange} className={`${error && ispolicy === false ? 'is-invalid' : ''}`} />
                        <label htmlFor="entries"> Medical Form</label>
                        <br />
                        {error && ispolicy === false && <div className="invalid-feedback infeedback">Kindly select requirements checkbox !!</div>}
                      </div>
                      <p>
                        <span style={{ color: '#ff0000' }}>
                          <b>Note:</b>
                          <ul>
                        <li>The trainee should not have undergone any major surgery or suffering from Seizures, High Blood Pressure, Heart Related Deseases, Fracture or Ligaments tear or Knee, Ankle and Shoulder.
                        </li>
                        <li>Students should upload their medical forms within three days, duly signed by an MBBS Doctor with a rubber seal.
                        </li>
                        </ul>
                        </span>
                      </p>

                      <a href='../doc/MEDICAL-FORM.doc' download style={{ margin: '10px', display: 'block' }}><Button variant="text" size="small"><DownloadIcon /> Download Medical Form</Button></a>
                      <div className="borderrs"></div>

                      <h5 className="payhead">Bank Details</h5>
                      <p style={{ width: "98%" }}><span style={{ color: '#ff0000' }}><b>Note:</b>Transfer your course fees to this account details and please enter the Transaction ID below to confirm your booking.</span></p>
                      <ul>
                        <li>Account Name	:	NIMAS Fee Receipt Account</li>
                        <li>Bank Name	:	State Bank of India, Dirang 790101, Arunachal Pradesh</li>
                        <li>Account No.	:	33825821120</li>
                        <li>IFSC Code	:	SBIN0006010</li>
                        <li>MICR Code	:	784002519</li>
                        <li>Branch Code	:	6010</li>

                      </ul>
                    </div>

                    <div className="paytab paytabs">
                      <h5 className="payhead">Enter your Transaction Details</h5>
                      <div className="form-left">
                        <div className="row">
                          <div className="col-md-6 col-xl-6">
                            {/* <label>Name</label> */}
                            <label htmlFor="fullName" className="form-label">Name<span className="required">*</span></label>
                            <div className="single-field">
                              <input type="text" className={`form-control ${error && fullName.trim() === '' ? 'is-invalid' : ''}`}
                                placeholder="Your Name" id="fullName" value={fullName} onChange={(e) => { setfullName(e.target.value); setError(''); }} disabled />
                              {error && fullName.trim() === '' && <div className="invalid-feedback">Please enter name</div>}
                            </div>
                          </div>
                          <div className="col-md-6 col-xl-6">
                            {/* <label>Transaction Id</label> */}
                            <label htmlFor="TransactionId" className="form-label">Transaction Id<span className="required">*</span></label>
                            <div className="single-field">
                              <input type="text" className={`form-control ${error && TransactionId.trim() === '' ? 'is-invalid' : ''}`} placeholder="Transaction Id" id="TransactionId"
                                value={TransactionId} onChange={(e) => setTransactionId(e.target.value)} />
                              {error && TransactionId.trim() === '' && <div className="invalid-feedback">Please enter Transaction Id</div>}
                            </div>
                          </div>


                          <div className="col-md-6 col-xl-6">
                            {/* <label>Payment Method</label> */}
                            <label htmlFor="cars" className="form-label">Payment Method<span className="required">*</span></label>
                            <div className="single-field">
                              <select name="cars" id="cars" className={`form-control ${error && PaymentMethod.trim() === '' ? 'is-invalid' : ''}`} value={PaymentMethod} onChange={(e) => setPaymentMethod(e.target.value)} >
                                <option value="">Select Payment Method</option>
                                <option value="Bank Transfer">Bank Transfer</option>
                                <option value="GPay">GPay To Bank Account</option>
                                <option value="Phone Pay">Phone Pay To Bank Account</option>
                                <option value="PayTM">PayTM To Bank Account</option>
                              </select>
                              {error && PaymentMethod.trim() === '' && <div className="invalid-feedback">Please select payment method</div>}
                            </div>
                          </div>
                          <div className="col-md-6 col-xl-6">
                            <div className="single-field">
                              <label className="form-label">Amount<span className="required">*</span></label>
                              <input type="test" className={`form-control ${error && Amount === 0 ? 'is-invalid' : ''}`} placeholder="Amount" value={Amount} disabled />
                              {/* <input type="test" className={`form-control ${error && Amount === 0 ? 'is-invalid' : ''}`} placeholder="Amount" value={Amount} onChange={(e) => setAmount(e.target.value)} disabled/> */}
                              {error && Amount === 0 && <div className="invalid-feedback">Please enter payment amount</div>}
                            </div>

                          </div>
                          <div className="col-md-6 col-xl-6">

                            <div className="single-field">
                              <label className="form-label">Aadhar Documents<span className="required">*</span></label>

                              <input type="file" accept=".pdf" value={selectedFile} onChange={(e) => { setselectedfile(e.target.files[0]); setError(''); }} className={`${error && selectedFile === null ? 'is-invalid' : ''}`} />
                              <button style={{ display: "none" }} onClick={onFileUpload("aadDoc")}></button>
                              {error && selectedFile === null && <div className="invalid-feedback">Please upload your aadhar documents</div>}
                            </div>


                          </div>
                          <div className="col-md-12 col-xl-12 text-center">
                            <span className="bookNowBtn "><button disabled={disabled} className="primaryBtn" onClick={fun_PaymentValidation}>Submit</button></span>
                          </div>
                          <div className="col-md-12 col-xl-12">
                            <p className="font12 line-height16 termcon">By continuing to pay, I understand and agree with the<a href="#" target="_blank" rel="noopener noreferrer"> privacy policy</a>, the <a href="#" target="_blank" rel="noopener noreferrer"> user agreement</a> and<a href="#" target="_blank" rel="noopener noreferrer"> terms of service</a> of NIMAS.</p>
                          </div>
                        </div>

                      </div>

                    </div>

                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col col-sm-12">
                <div className="prmRoomDtlCard">
                  <div className="prmRoomDtlCard__inner">
                    <img src="../admin/img/mountaineering.png" alt="..." className="wd-100" />
                    <span className="inlineBlock font16 latoBlack blackText truncate appendBottom7" data-testid="baseSrRoomName heading">{courseName}</span>
                    <div className="makeFlex">
                      <div className="prmRoomDtlCard__left">
                        <p className="makeFlex hrtlCenter appendBottom12 pr"> <PersonIcon className="icoo" />  <span className="font12">For 1 Person</span></p>
                        <div className="adtitle">
                          <span className="">Adventurer</span>
                          <p>{fullName} </p>
                          <p>{Email} | {mobile}</p>
                        </div>
                      </div>
                      <div className="prmRoomDtlCard__right">
                        <div className="prmRoomDtlCard__right maxWidth170">
                          <p className="grayText font16 lineThrough">₹0</p>
                          <p className="font20 blackText latoBlack">₹{Amount}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>
      </main>
    </>
  );
}
export default Payment;
