import React, { useEffect, useState, useRef } from "react";
import '../Common/custom-style.css';
import '/node_modules/bootstrap/dist/css/bootstrap.min.css';
import '/node_modules/bootstrap/dist/js/bootstrap.min.js';
import moment from 'moment';
import Button from '@mui/material/Button';
import PrintIcon from '@mui/icons-material/Print';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import { ThreeDots } from 'react-loader-spinner'
import { Mail, Token } from "@mui/icons-material";
//import { sendmail } from "../Common/sendmail";
import axios from "axios";
function Invoicenew() {

  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  var bookingid = localStorage.getItem('id');

  // const userStudentinfo = JSON.parse(localStorage.getItem("user-Studentinfo"));
  const admininfo = JSON.parse(localStorage.getItem("user-admininfo"));
  const [toEmailId, settoEmailId] = useState('');
  // const [fromEmailId, setfromEmailId] = useState('');
  const [fromEmail, setfromEmail] = useState('');
  const [contactName, setcontactName] = useState('');
  //const [name, setname] = useState("");
  const [error, seterror] = useState('');
  const [id, SetId] = useState();

  const userStudentinfo = JSON.parse(localStorage.getItem("user-Studentinfo"));
  var usertype = null;
  //const adminlogininfo = JSON.parse(localStorage.getItem("user-admininfo"));
  if (userStudentinfo === null && admininfo === null) {
    window.location.href = '/';
  }
  else {
    if (userStudentinfo !== null) {
      usertype = userStudentinfo?.records[0][21].value
    }
    if (admininfo !== null) {
      usertype = userStudentinfo?.records[0][21].value
    }
  }

  const booking = localStorage.getItem('bookinginfo');
  if (booking === null) {
    window.location.href = '/';
  }
  if (bookingid === null) {
    bookingid = booking;
  }
  const [selectedFile, setselectedfile] = useState(null);
  const [B_id, SetBId] = useState();
  //const [id, SetId] = useState();
  const [todate, SetTodate] = useState();
  const [fromdate, Setfromdate] = useState();
  const [bookingdate, Setbookingdate] = useState();
  const [idbookingstatus, Setstatus] = useState();
  const [age, Setage] = useState();
  const [seat, Setseat] = useState();
  const [coursename, Setcourse] = useState();
  const [category, setcategory] = useState();
  const [imgurl, setimgurl] = useState("");
  const [aadharimg, setaadharimg] = useState("");
  const [transactionreceipt, settransactionreceipt] = useState("");
  const [serialno, setSerialNo] = useState("");
  const [isLoader, setIsLoader] = useState(true);
  const [upcoming, setupcoming] = useState(false);
  const [complete, setcomplete] = useState(false);
  const [Running, setRunning] = useState(false);
  const [BookingListData, setBookingListData] = useState(false);
  const currentDate = new Date(); // Get the current date
  function BooKingData() {
    const Bookinglistpra = { "NumberOfFieldsView": 50, "filters": { "BookingID": bookingid }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 5 };
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(Bookinglistpra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          if (resp.response?.records.length > 0) {
            debugger
            setBookingListData(resp.response?.records[0]);
            SetBId(resp.response?.records[0][0]?.value);
            SetId(resp.response?.records[0][3]?.value);
            SetTodate(resp.response?.records[0][22]?.value);
            Setfromdate(resp.response?.records[0][21]?.value);
            Setbookingdate(resp.response?.records[0][1]?.value);
            Setage(resp.response?.records[0][23]?.value);
            Setstatus(resp.response?.records[0][19]?.value);
            Setseat(resp.response?.records[0][8]?.value);
           // Setbookingdate(resp.response?.records[0][1]?.value);
            Setcourse(resp.response?.records[0][5]?.value);
            setcategory(resp.response?.records[0][4]?.value);
            setSerialNo(resp.response?.records[0][6]?.value);
            setimgurl(resp.response?.records[0][27]?.value);
            settoEmailId(resp.response?.records[0][25].value);
            setaadharimg(resp.response?.records[0][29]?.value);
            settransactionreceipt(resp.response?.records[0][30]?.value);
            const comparisonToDate = new Date(resp.response?.records[0][22]?.value);
            const comparisonFromDate = new Date(resp.response?.records[0][21]?.value);
            if (comparisonFromDate > currentDate) {
              setupcoming(true);
            }
            if (comparisonToDate < currentDate) {
              setcomplete(true);
            }
            if (currentDate >= comparisonFromDate && comparisonToDate >= currentDate) {
              setRunning(true);
            }
            CourseList(resp.response?.records[0][4].value, resp.response?.records[0][6].value);
            fun_Login(resp.response?.records[0][25].value);
            fun_ReceiptListdata(resp.response?.records[0][3].value);

            setIsLoader(false);
          }
          else {
            alert("data not found!!")
          }
        }
      });
    });
  }
  //const [ReceiptListdata, SetReceiptListdata] = useState();
  const [courseDate, setcourseDate] = useState();
  function CourseList(cName, serialno) {
    const Courselistpra = { "NumberOfFieldsView": 50, "filters": { "Category Name": cName, "Serial No": serialno }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 3 };
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(Courselistpra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.response?.records.length > 0) {
          setcfee(resp.response.records[0][12].value);
          setduration(resp.response.records[0][6].value);
          setSerialNo(resp.response.records[0][9].value);
          setcourseDate(resp.response.records[0][10].value + " / " + resp.response.records[0][11].value)
        }
        else {
        }
      });
    });
  }

  function fun_ReceiptListdata(id) {
    const ReceiptIdPra = { "NumberOfFieldsView": 50, "filters": { "BookingId": id }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 6 }
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(ReceiptIdPra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.response?.records.length > 0) {
          setRcdate(resp.response?.records[0][1].value)
          setReceiptId(resp.response?.records[0][3].value)
          setGST(resp.response?.records[0][9].value);
          setSubTotal(resp.response?.records[0][10].value);
          setPayAmount(resp.response?.records[0][12].value);
          setPaymentMethod(resp.response?.records[0][6].value);
          setTransactionId(resp.response?.records[0][7].value);
          setPayStatus(resp.response?.records[0][5].value);
          setamountInWords(convertNumberToWords(resp.response?.records[0][12].value));
        }
      });
    });
  }
  const [cfee, setcfee] = useState();
  const [duration, setduration] = useState();
  const [GST, setGST] = useState(0);
  const [SubTotal, setSubTotal] = useState(0);
  const [PayAmount, setPayAmount] = useState(0);
  const [PaymentMethod, setPaymentMethod] = useState("0");
  const [TransactionId, setTransactionId] = useState("");
  const [ReceiptId, setReceiptId] = useState("");
  const [Rcdate, setRcdate] = useState("");
  const [PayStatus, setPayStatus] = useState("");
  useEffect(() => {
    BooKingData();
  }, [0]);

  const [PermanentAddress, setPermanentAddress] = useState("");
  const [StudentFullName, setFullname] = useState("");
  const [City, setCity] = useState("");
  const [State, SetState] = useState("");
  const [Pincode, setPincode] = useState("");
  const [PdfPath, setPdfPath] = useState("");

  function fun_Login(email) {
    const loginpra = { "NumberOfFieldsView": 50, "filters": { "Email": email }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 4 }
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(loginpra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          if (resp.response?.records.length > 0) {
            setPermanentAddress(resp.response?.records[0][12].value);
            setCity(resp.response?.records[0][13].value);
            SetState(resp.response?.records[0][14].value);
            setPincode(resp.response?.records[0][15].value);
            setFullname(resp.response?.records[0][5].value);
            setcontactName(resp.response?.records[0][5].value);
            setstudentInfo(resp);
            setstudentMob(resp.response?.records[0][6]?.value);
            setstudentName(resp.response?.records[0][5]?.value);
            setstudentEmail(resp.response?.records[0][8]?.value);
            debugger
            setstudentprofile(resp.response?.records[0][4]?.value);
          }
        }

      });
    });
  }
  const [studentInfo, setstudentInfo] = useState([]);
  const [studentMob, setstudentMob] = useState('');
  const [studentName, setstudentName] = useState('');
  const [studentEmail, setstudentEmail] = useState('');
  const [studentprofile, setstudentprofile] = useState('');

  const [amountInWords, setamountInWords] = useState("");
  function convertNumberToWords(number) {
    const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    const scales = ['', 'Thousand', 'Million', 'Billion', 'Trillion'];

    if (number === 0) {
      return 'Zero';
    }
    const chunks = [];
    while (number) {
      chunks.push(number % 1000);
      number = Math.floor(number / 1000);
    }
    const parts = [];
    for (let i = 0; i < chunks.length; i++) {
      const chunk = chunks[i];
      const hundreds = Math.floor(chunk / 100);
      const tensAndUnits = chunk % 100;
      const scale = scales[i];

      if (hundreds !== 0) {
        parts.push(units[hundreds] + ' Hundred');
      }

      if (tensAndUnits !== 0) {
        if (tensAndUnits < 10) {
          parts.push(units[tensAndUnits]);
        } else if (tensAndUnits < 20) {
          parts.push(teens[tensAndUnits - 10]);
        } else {
          const tensDigit = Math.floor(tensAndUnits / 10);
          const unitsDigit = tensAndUnits % 10;
          parts.push(tens[tensDigit] + (unitsDigit !== 0 ? ' ' + units[unitsDigit] : ''));
        }
      }

      if (scale && chunk !== 0) {
        parts.push(scale);
      }
    }
    return parts.reverse().join(' ');
  }


  function loademail() {
    getEmailID();
  }

  //https://dialerpwebapis-dev-as.azurewebsites.net/
  const [emaildata, setemaildata] = useState("");
  function getEmailID() {
    setIsLoader(true)
    const daa = {
      "ParentId": 40330,
      "userID": 40330,
      "searchFromEmail": "info@nimasdirang.com"
    };
    //JSON.stringify(daa);
    //   fetch("https://api.dialerp.com/api/SmsEmail/GetEmailIDema,
    //   {
    //       method: "POST",      
    //       body: JSON.stringify(daa),
    //       headers: { "Accept": "application/json","Content-Type": 'application/json', 'Authorization-Token': authToken },
    //   }
    // )
    fetch(
      "https://api.dialerp.com/api/api/GetEmailID",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(daa),
      }
    ).then((result) => {

      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          setemaildata(resp);
          setfromEmail(resp.response.fromEmail);
          //Setsmtpport(resp.response.smtpHost);
          setIsLoader(false)
          downloadHTMLtoPDF();
        }
        else {
          alert(resp.errors);
          setIsLoader(false)
        }
      });
    });
  }
  function downloadHTMLtoPDF() {
    setIsLoader(true)
    if (htmldivRef.current) {
      let printContents = htmldivRef.current.innerHTML;
      printContents = printContents.split("href").join("title");// .replace('href','title');
      printContents = printContents.split("#007bff").join("#000000");//.replace('#007bff','#000000');
      const postData = {
        "userID": 40330, "fileName": "Invoice(" + ReceiptId + ")", "htmlData": printContents,
      };
      fetch(
        "https://api.dialerp.com/api/api/downloadHTMlToPDFForVouchers",
        {
          method: "POST",
          headers: {
            "Authorization-Token": authToken,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(postData),
        }
      ).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            setPdfPath(resp.response);
            setIsLoader(false)
          }
          setIsLoader(false)
        });
      });
    } else {
    }
  }
  function SendEmail() {
    setIsLoader(true)
    if (PdfPath === null) {
      setIsLoader(false)
      return
    }
    if (fromEmail === null || fromEmail === "") {
      setIsLoader(false)
      alert("Please verify your Email ID!")
      return
    }

    if (toEmailId === null || toEmailId === "") {
      setIsLoader(false)
      seterror('required"');
      return
    }
    const postData =
    {
      "index": 1,
      "userID": 40330,
      "toEmailID": fromEmail,
      "attachment": PdfPath,
      "Subject": "Payment Invoice(" + ReceiptId + ")",
      "Body": "Dear " + contactName + ", <br> <br> Hope you are doing great!<br> <br>We have attached the Payment Invoice (" + ReceiptId + ") for your reference.<br> <br>Thanks & Regards,<br><br><b>Email: " + fromEmail + "</b><br><b>WebSite:https://nimasdirang.com/</b><br><b>Contact no: 91-9774731855</b>",

      "items": [
        {
          "accountName": "InDirect Dddd Lead",
          "contactName": contactName,
          "email": toEmailId,
          "userID": 40330,
          "isChecked": true
        }
      ],
      "smtpHost": emaildata.response.smtpHost,
      "smtpPort": emaildata.response.smtpPort,
      "smtpPassword": emaildata.response.smtpPassword,
      "fromEmail": emaildata.response.fromEmail,
      "fromName": emaildata.response.fromName,
      "smtpAuthDomain": emaildata.response.smtpAuthDomain,
      "toEmailIDs": toEmailId,
      "fileName": "Payment Invoice(" + ReceiptId + ")"
    }

    fetch(
      "https://api.dialerp.com/api/api/SendEmailwithAttachment",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(postData),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          alert("Email sent successfully");
          setIsLoader(false)
          window.location.reload(true)
        }
        else {
          alert(resp.errors);
          setIsLoader(false)
        }
      });
    });

  }
  const handlePrint = () => {
    const elementsToHide = document.querySelectorAll('.element-to-hide');
    elementsToHide.forEach((element) => {
      element.style.display = 'none';
    });

    // Trigger the print dialog
    window.print();

    // Restore the visibility of hidden elements after printing
    elementsToHide.forEach((element) => {
      element.style.display = '';
    });
  };

  const htmldivRef = useRef(null);
  const onPDF = () => {
    setIsLoader(true)
    if (htmldivRef.current) {
      const printContents = htmldivRef.current.innerHTML;
      const postData = {
        "userID": 40330, "fileName": "Invoice(" + ReceiptId + ")", "htmlData": printContents,
      };
      fetch(
        "https://api.dialerp.com/api/api/downloadHTMlToPDF",
        {
          method: "POST",
          headers: {
            "Authorization-Token": authToken,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(postData),
        }
      ).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            downloadFile(resp.response, "pdf", "Invoice(" + ReceiptId + ")");
            setIsLoader(false)
          }
        });
      });
    } else {
    }
  };
  function downloadFile(path, type, fileName) {
    setIsLoader(true)
    var link = document.createElement('a');
    link.href = path;
    link.download = fileName + "." + type;
    link.click();
    setIsLoader(false)
  }

  function onFileUpload(type) {
    //selectedFile.files[0].name

    const formData = new FormData();

    if (selectedFile != null) {
      setIsLoader(true)
      if (type === "medDoc") {
        formData.append("myFile", selectedFile);
        formData.append("name", selectedFile.name);
        setselectedfile(selectedFile.name);
        setimgurl(selectedFile.name);
      }
      if (type === "aadDoc") {
        formData.append("myFile", selectedFile);
        formData.append("name", selectedFile.name);
        setselectedfile(selectedFile.name);
        setaadharimg(selectedFile.name);
      }
      if (type === "TRDoc") {
        formData.append("myFile", selectedFile);
        formData.append("name", selectedFile.name);
        setselectedfile(selectedFile.name);
        settransactionreceipt(selectedFile.name);
      }

      axios.post('https://api.dialerp.com/api/api/UploadCommonFilesToAzure', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization-Token': authToken,
        },
      }
      ).then((response) => {
        if (response.data.hasErrors === true) {
          alert(response.data.errors);
          setIsLoader(false);
        }
        else if (response.data.response !== null) {
          // alert("Image Upload successfully ");
          if (type === "medDoc") {
            setimgurl(response.data.response[0].imageUrl);
            setIsLoader(false);
          }
          if (type === "aadDoc") {
            setaadharimg(response.data.response[0].imageUrl);
            setIsLoader(false);
          }
          if (type === "TRDoc") {
            settransactionreceipt(response.data.response[0].imageUrl);
            setIsLoader(false);
          }
          if (response.data.response[0].imageUrl !== null) {
            setIsLoader(false);
            fun_addBooking(response.data.response[0].imageUrl, type);
          }
        }
      }
      )
        .catch((err) =>
          alert(err)
        );
    }
  };
  function fun_addBooking(img, type) {
    if (type === "medDoc") {
      setIsLoader(true);
      const addBookingpra = {
        "templateID": "5"
        , "_id": B_id
        , "_1": booking
        , "_2": category
        , "_3": coursename
        , "_4": serialno
        // , "_5": NextofKin
        , "_6": seat
        , "_17": 'In-Review'
        , "_25": img
      }
      fetch("https://api.dialerp.com/api/api/addUpdateTemplateDataWeb",
        {
          method: "POST",
          headers: {
            "Authorization-Token": authToken,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(addBookingpra),
        }
      ).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            alert("File upload successfully...")
            setIsLoader(false);
            window.location.reload(true);
            // fun_CourseupdateAvailableSeats();
          }
          else {
            setIsLoader(false);
            alert(resp.errors);
          }
        });
      });
    }
    if (type === "aadDoc") {
      setIsLoader(true);
      const addBookingpra = {
        "templateID": "5"
        , "_id": B_id
        , "_1": booking
        , "_2": category
        , "_3": coursename
        , "_4": serialno
        // , "_5": NextofKin
        , "_6": seat
        , "_17": idbookingstatus
        , "_27": img
      }
      fetch("https://api.dialerp.com/api/api/addUpdateTemplateDataWeb",
        {
          method: "POST",
          headers: {
            "Authorization-Token": authToken,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(addBookingpra),
        }
      ).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            alert("File upload successfully...")
            setIsLoader(false);
            window.location.reload(true);

          }
          else {
            setIsLoader(false);
            alert(resp.errors);
          }
        });
      });
    }
    if (type === "TRDoc") {
      setIsLoader(true);
      const addBookingpra = {
        "templateID": "5"
        , "_id": B_id
        , "_1": booking
        , "_2": category
        , "_3": coursename
        , "_4": serialno
        // , "_5": NextofKin
        , "_6": seat
        , "_17": idbookingstatus
        , "_28": img
      }
      fetch("https://api.dialerp.com/api/api/addUpdateTemplateDataWeb",
        {
          method: "POST",
          headers: {
            "Authorization-Token": authToken,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(addBookingpra),
        }
      ).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            alert("File upload successfully...")
            setIsLoader(false);
            window.location.reload(true);

          }
          else {
            setIsLoader(false);
            alert(resp.errors);
          }
        });
      });
    }
  }
  const handleDeleteClick = () => {
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    if (confirmDelete) {
      setaadharimg("");
      alert("Deleted successfully!"); // You can use alert for feedback if needed
    }
  };
  const handleDeleteClickMD = () => {
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    if (confirmDelete) {
      setimgurl("");
      alert("Deleted successfully!"); // You can use alert for feedback if needed
    }
  };
  const handleDeleteClickTR = () => {
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    if (confirmDelete) {
      settransactionreceipt("");
      alert("Deleted successfully!"); // You can use alert for feedback if needed
    }
  };
  return (
    <>
      {isLoader &&
        <div id="loadingg">
          <ThreeDots height="80" width="80" radius="9" color="#fff" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      }
      <div className="invoice" style={{ padding: '80px 20px', background: '#f2f2f2', fontFamily: 'roboto' }}>
        <div className='button-row element-to-hide'>
          {admininfo !== null ? <a href="#popup1" onClick={loademail} > <Button variant="outlined" size="small">  <SwapHorizIcon />  Send to Email</Button></a> : null}
          <Button variant="outlined" size="small" onClick={handlePrint}><PrintIcon /> Print</Button>
          <Button variant="outlined" size="small" onClick={onPDF}><DownloadIcon /> Download PDF</Button>
        </div>
        <div ref={htmldivRef} id="divprint">
          {studentInfo?.response?.records?.map((studinfo, i) => {
            i++;
            return (
              <table cellPadding="0" cellSpacing="0" style={{ width: '800px', margin: '0 auto', border: '1px solid #d3d3d3', background: '#fff', fontFamily: 'arial' }}>
                <tr style={{ marginBottom: '15px' }}>
                  <td style={{ width: '50%', padding: '0px 20px' }}><div style={{ color: 'rgb(25, 118, 210)', fontSize: '30px', fontWeight: '600' }}>
                    <img src="https://nimasdirang.com/admin/img/nimas_logo.png" alt="" style={{ width: '100px' }} /></div>
                  </td>
                  <td style={{ width: '50%', padding: '0px 20px', color: '#000', fontSize: '19px', fontWeight: '600', textAlign: 'right' }}>Receipt</td>
                </tr>
                <tr>
                  <td style={{ width: '50%', padding: '10px 0px 5px 20px', verticalAlign: 'top' }}><p style={{ color: '#000', fontSize: '16px', fontWeight: '600', marginBottom: '0px' }}>Bill From</p>
                    <p style={{
                      color: '#000', fontSize: '14px', fontWeight: '400', marginBottom: '5px',
                      marginTop: '5px'
                    }}>NIMAS</p>
                    <p style={{ color: '#000', fontSize: '14px', fontWeight: '400', marginBottom: '5px' }}>
                      National Institute of Mountaineering and Adventure Sports Dirang, West Kameng
                      Arunachal Pradesh
                      Pin- 790101<br />
                      IN
                    </p>
                    <p style={{
                      color: '#000', fontSize: '14px', fontWeight: '400', marginBottom: '5px',
                      marginTop: ' 0px'
                    }}><b>Pan Number</b> : AAEFU0850G</p>
                    <p style={{
                      color: '#000', fontSize: '14px', fontWeight: '400', marginBottom: '5px',
                      marginTop: ' 0px'
                    }}><b>GST No</b> : 06AAEFU0850G1ZE</p>
                  </td>
                  <td style={{ width: '50%', padding: '10px 20px 5px 20px', verticalAlign: 'top' }}>
                    <div style={{ marginBottom: '25px' }}><p style={{ color: '#000', fontSize: '16px', fontWeight: '600', marginBottom: '0px', textAlign: 'right' }}>Bill To</p>
                      <p style={{ color: '#000', fontSize: '14px', fontWeight: '400', marginBottom: '0px', textAlign: 'right', marginTop: ' 5px' }}>
                        {StudentFullName}<br />{PermanentAddress}, <br /> {City}, {State}, {Pincode}
                      </p>
                      {/* <p style={{ color: '#000', fontSize: '14px', fontWeight: '400', marginBottom: '0px', textAlign: 'right' }}>
                        {PermanentAddress}
                      </p>
                      <p style={{ color: '#000', fontSize: '14px', fontWeight: '400', marginBottom: '0px', textAlign: 'right' }}>
                       
                      </p> */}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '20px 20px 0px 20px' }} colSpan="8">
                    <strong style={{ padding: '0px', fontSize: '14px', textAlign: 'left', display: 'block', marginBottom: '5px' }}>Booking Details</strong>
                    <table cellPadding="0" cellSpacing="0" style={{ width: '100%', border: '1px solid #d3d3d3', background: '#fff', fontFamily: ' arial' }}>
                      <tr>
                        <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>Booking ID</th>
                        <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>Course Serial.No.</th>
                        <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>Course Duration</th>
                        <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>Course Date</th>
                        <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>Booking Date</th>
                        {/* <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>Booking Status</th> */}
                        {/* <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>Payment Status</th> */}
                      </tr>
                      <tr>
                        <>
                          <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{id}</td>
                          <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{serialno}</td>
                          <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{duration}</td>
                          <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{courseDate}</td>
                          <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{moment(bookingdate).format('DD-MMM-YYYY hh:mm:ss')}</td>
                          {/* {idbookingstatus === 'Cancel' ?
                          <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3',color:'red' ,borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{idbookingstatus}</td>
                          :
                          idbookingstatus === 'Confirmed' ?
                          <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3',color:'green', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{idbookingstatus}</td>
                          :idbookingstatus === 'In-Review' ?
                          <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3',color:'blue', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{idbookingstatus}</td>
                          :<td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{idbookingstatus}</td>
                        } */}

                        </>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '20px' }} colSpan="2">
                    <strong style={{ padding: '0px', fontSize: '14px', textAlign: 'left', display: 'block', marginBottom: '5px' }}>Receipt Details</strong>
                    <table cellPadding="0" cellSpacing="0" style={{ width: '100%', border: '1px solid #d3d3d3', background: '#fff' }}>
                      <tr>
                        <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', background: '#f2f2f2', borderRight: '1px solid #d3d3d3' }}>S. No.</th>
                        <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', background: '#f2f2f2', borderRight: '1px solid #d3d3d3', textAlign: ' left' }}>Course Name</th>
                        <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', background: '#f2f2f2', borderRight: '1px solid #d3d3d3', textAlign: ' left' }}>Price</th>
                        <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', background: '#f2f2f2', borderRight: '1px solid #d3d3d3', textAlign: ' left' }}>Seats</th>
                        <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', background: '#f2f2f2', borderRight: '1px solid #d3d3d3', textAlign: ' left' }}>Net Amt.</th>
                        <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', background: '#f2f2f2', borderRight: '1px solid #d3d3d3', textAlign: ' left' }}>Tax Rate</th>
                        <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', background: '#f2f2f2', borderRight: '1px solid #d3d3d3', textAlign: ' left' }}>Tax Amt.</th>
                        <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', background: '#f2f2f2', textAlign: ' left' }}>Total Amt.</th>
                      </tr>
                      <tr>
                        <td style={{ padding: '2px', fontSize: '13px', borderRight: '1px solid #d3d3d3', borderBottom: '1px solid #d3d3d3' }}>1</td>
                        <td style={{ padding: '2px', fontSize: '13px', borderRight: '1px solid #d3d3d3', borderBottom: '1px solid #d3d3d3' }}>{coursename}-{serialno}</td>
                        <td style={{ padding: '2px', fontSize: '13px', borderRight: '1px solid #d3d3d3', borderBottom: '1px solid #d3d3d3' }}>₹{cfee}</td>
                        <td style={{ padding: '2px', fontSize: '13px', borderRight: '1px solid #d3d3d3', borderBottom: '1px solid #d3d3d3' }}>{seat}</td>
                        <td style={{ padding: '2px', fontSize: '13px', borderRight: '1px solid #d3d3d3', borderBottom: '1px solid #d3d3d3' }}>₹{SubTotal}</td>
                        <td style={{ padding: '2px', fontSize: '13px', borderRight: '1px solid #d3d3d3', borderBottom: '1px solid #d3d3d3' }}>0%</td>
                        <td style={{ padding: '2px', fontSize: '13px', borderRight: '1px solid #d3d3d3', borderBottom: '1px solid #d3d3d3' }}>₹0</td>
                        <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3' }}>₹{PayAmount}</td>
                      </tr>
                      <tr>
                        <td colSpan="6" style={{ padding: '2px', fontSize: '13px', borderRight: '1px solid #d3d3d3', fontWeight: '600', borderBottom: '1px solid #d3d3d3' }}>Total</td>
                        <td style={{ padding: '2px', fontSize: '13px', borderRight: '1px solid #d3d3d3', fontWeight: '600', borderBottom: '1px solid #d3d3d3' }}>₹{GST}</td>
                        <td style={{ padding: '2px', fontSize: '13px', fontWeight: '600', borderBottom: '1px solid #d3d3d3' }}>₹{PayAmount}</td>
                      </tr>
                      <tr>
                        <td colSpan="8" style={{ padding: '2px', fontSize: '13px', borderRight: '1px solid #d3d3d3', fontWeight: '700', borderBottom: '1px solid #d3d3d3' }}>Amount in words :{amountInWords} <br /></td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 20px 20px' }} colSpan="8">
                    <table cellPadding="0" cellSpacing="0" style={{ width: '100%', border: '1px solid #d3d3d3', background: '#fff' }}>
                      <tr>
                        <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>Receipt ID</th>
                        <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>TransactionId</th>
                        <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>Date & Time</th>
                        <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>Mode of Payment</th>
                        {/* <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>Payment Status</th> */}
                        <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>Booking Status</th>
                      </tr>
                      <tr>
                        <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{ReceiptId}</td>
                        <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{TransactionId}</td>
                        <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{moment(Rcdate).format('DD-MMM-YYYY hh:mm:ss')}</td>
                        <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{PaymentMethod}</td>
                        {/* {PayStatus === 'Payment Upload' ?
                          <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3',color:'green' ,borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>Paid</td>
                          :
                          <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3',color:'red', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>Pending</td>
                        } */}
                        {idbookingstatus === 'Cancel' ?
                          <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', color: 'red', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{idbookingstatus}</td>
                          :
                          idbookingstatus === 'Confirmed' ?
                            <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', color: 'green', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{idbookingstatus}</td>
                            : idbookingstatus === 'In-Review' ?
                              <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', color: 'blue', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{idbookingstatus}</td>
                              : <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{idbookingstatus}</td>
                        }

                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 0px 0px', textAlign: 'center' }} valign="top" colSpan={2} >
                    {BookingListData[28]?.value !== null && BookingListData[28]?.value !== undefined && BookingListData[28]?.value !== '' ? <p style={{ fontSize: '12px', color: 'red', marginBottom: '0rem' }}>Cancel Reason:  {BookingListData[28]?.value}</p> : null}
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 20px 20px' }} colSpan="2" valign="top">
                    <strong style={{ padding: '0px', fontSize: '14px', textAlign: 'left', display: 'block', marginBottom: '5px' }}>Personal Details</strong>
                    <table cellPadding="0" cellSpacing="0" style={{ width: '100%', border: '1px solid #d3d3d3', background: '#fff' }}>

                      <tr>
                        <td style={{ fontSize: '13px', width: '111px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', }} valign="top">
                          {/* <td style={{ padding: '2px 5px', fontSize: '13px',width:'111px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', }} valign="top"> */}
                          <a href={studentprofile}> <img src={studentprofile} className="img-fluid" alt="..." style={{ width: '100px', height: '100px', marginBottom: '5px', marginTop: '5px', marginLeft: '5px', marginRight: '5px' }} />
                          </a>
                          {/* <p>
                        <span style={{ display: 'block', marginBottom: '7px' }}> <b>Name </b>: Sheetal Rawale</span>
                        <span style={{ display: 'block', marginBottom: '7px' }}> <b>Mobile</b> : 7977516947</span>
                        <span style={{ display: 'block', marginBottom: '7px' }}> <b>Email</b> : Sheetal.rawle@gmail.com</span>
                        <span style={{ display: 'block', marginBottom: '7px' }}> <b>DOB </b>: 19-Nov-1992	</span>
                        <span style={{ display: 'block', marginBottom: '7px' }}> <b>Aadhar Number</b> : 500922383423</span>
                        <span style={{ display: 'block', marginBottom: '7px' }}><b>Father's Name</b> : Ankush Tatya Rawle</span>
                      </p> */}
                        </td>
                        <td style={{ padding: '2px 5px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', }} valign="top">

                          <span style={{ marginBottom: '7px', width: '30%', display: 'inline-block', wordBreak: 'break-all' }}><b>Name</b> </span>
                          <span style={{ float: 'right', wordBreak: 'break-all', width: '70%' }}>{studinfo[5]?.value}</span>

                          <span style={{ marginBottom: '7px', width: '30%', display: 'inline-block', wordBreak: 'break-all' }}><b>Mobile</b> </span>
                          <span style={{ float: 'right', wordBreak: 'break-all', width: '70%' }}>{studinfo[6]?.value}</span>

                          <span style={{ marginBottom: '7px', width: '30%', display: 'inline-block', wordBreak: 'break-all' }}><b>Email</b> </span>
                          <span style={{ float: 'right', wordBreak: 'break-all', width: '70%' }}>{studinfo[8]?.value}</span>

                          <span style={{ marginBottom: '7px', width: '30%', display: 'inline-block', wordBreak: 'break-all' }}><b>Gender</b> </span>
                          <span style={{ float: 'right', wordBreak: 'break-all', width: '70%' }}>{studinfo[10]?.value}</span>

                          {/* <p>
                            <span style={{ display: 'block', marginBottom: '5px' }}> <b>Name </b>: {studinfo[5]?.value}</span>
                            <span style={{ display: 'block', marginBottom: '5px' }}> <b>Mobile</b> : {studinfo[6]?.value}</span>
                            <span style={{ display: 'block', marginBottom: '5px' }}> <b>Email</b> : {studinfo[8]?.value}</span>
                            <span style={{ display: 'block' }}> <b>Gender </b>: {studinfo[10]?.value}</span>
                          </p> */}
                        </td>
                        <td style={{ padding: '2px 5px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', }} valign="top">
                          <span style={{ marginBottom: '7px', width: '40%', display: 'inline-block', wordBreak: 'break-all' }}><b>Age</b> </span>
                          <span style={{ float: 'right', wordBreak: 'break-all', width: '60%' }}>{BookingListData[23]?.value}</span>

                          <span style={{ marginBottom: '7px', width: '40%', display: 'inline-block', wordBreak: 'break-all' }}><b>DOB</b> </span>
                          <span style={{ float: 'right', wordBreak: 'break-all', width: '60%' }}>{studinfo[11]?.value}</span>

                          <span style={{ marginBottom: '7px', width: '40%', display: 'inline-block', wordBreak: 'break-all' }}><b>Aadhar Number</b> </span>
                          <span style={{ float: 'right', wordBreak: 'break-all', width: '60%' }}>{studinfo[17]?.value}</span>

                          <span style={{ marginBottom: '7px', width: '40%', display: 'inline-block', wordBreak: 'break-all' }}><b>Father's Name</b> </span>
                          <span style={{ float: 'right', wordBreak: 'break-all', width: '60%' }}>{studinfo[20]?.value}</span>

                          {/* <span style={{ display: 'block', marginBottom: '5px' }}> <b>Age</b> :{BookingListData[23]?.value}</span>
                            <span style={{ display: 'block', marginBottom: '5px' }}> <b>DOB </b>: {studinfo[11]?.value}</span>
                            <span style={{ display: 'block', marginBottom: '5px' }}> <b>Aadhar Number</b> : {studinfo[17]?.value}</span>
                            <span style={{ display: 'block' }}><b>Father's Name</b> : {studinfo[20]?.value}</span> */}

                        </td>
                      </tr>

                    </table>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 20px 20px' }} colSpan="2">
                    <strong style={{ padding: '0px', fontSize: '14px', textAlign: 'left', display: 'block', marginBottom: '5px' }}>Other Details</strong>
                    <table cellPadding="0" cellSpacing="0" style={{ width: '100%', border: '1px solid #d3d3d3', background: '#fff' }}>
                      <tr align="top">
                        <td style={{ padding: '2px 5px', width: '50%', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', }} valign="top">

                          {/* <span style={{ display: 'block', marginBottom: '7px' }}> <b>Gender </b>: Female</span> */}

                          <span style={{ marginBottom: '7px', width: '50%', display: 'inline-block', wordBreak: 'break-all' }}><b>Shoe Size </b> </span>
                          <span style={{ float: 'right', wordBreak: 'break-all', width: '50%' }}>{BookingListData[12]?.value}</span><hr style={{ margin: '3px' }}></hr>
                          <span style={{ marginBottom: '7px', width: '50%', display: 'inline-block', wordBreak: 'break-all' }}><b>NRI</b> </span>
                          <span style={{ float: 'right', wordBreak: 'break-all', width: '50%' }}>{studinfo[18]?.value}</span><hr style={{ margin: '3px' }}></hr>

                          <span style={{ marginBottom: '7px', width: '50%', display: 'inline-block', wordBreak: 'break-all' }}><b>Nationality</b> </span>
                          <span style={{ float: 'right', wordBreak: 'break-all', width: '50%' }}>{studinfo[19]?.value}</span> <hr style={{ margin: '3px' }}></hr>
                          <span style={{ marginBottom: '27px', width: '50%', display: 'inline-block', wordBreak: 'break-all' }}><b>Academic Qualification </b></span>
                          <span style={{ float: 'right', wordBreak: 'break-all', width: '50%' }}>{BookingListData[13]?.value}</span> <hr style={{ margin: '3px' }}></hr>
                          <span style={{ marginBottom: '7px', width: '50%', display: 'inline-block', wordBreak: 'break-all' }}><b>Alternate Mobile Number </b> </span>
                          <span style={{ float: 'right', wordBreak: 'break-all', width: '50%' }}>{studinfo[7]?.value}</span> <hr style={{ margin: '3px' }}></hr>
                          <span style={{ marginBottom: '7px', width: '50%', display: 'inline-block', wordBreak: 'break-all' }}><b>Dietary Details </b> </span>
                          <span style={{ float: 'right', wordBreak: 'break-all', width: '50%' }}>{BookingListData[17]?.value}</span><hr style={{ margin: '3px' }}></hr>
                          <span style={{ marginBottom: '7px', width: '50%', display: 'inline-block', wordBreak: 'break-all' }}><b>Permanent Address</b> </span>
                          <span style={{ float: 'right', wordBreak: 'break-all', width: '50%' }}>{studinfo[12]?.value},<br /> {studinfo[13]?.value}, {studinfo[14]?.value}-{studinfo[15]?.value} </span>

                        </td>
                        <td style={{ padding: '2px 5px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', }} valign="top">

                          <span style={{ marginBottom: '7px', width: '50%', display: 'inline-block', wordBreak: 'break-all' }}><b>Height in CM</b></span>
                          <span style={{ float: 'right', wordBreak: 'break-all', width: '50%' }}>{BookingListData[9]?.value}</span> <hr style={{ margin: '3px' }}></hr>
                          <span style={{ marginBottom: '7px', width: '50%', display: 'inline-block', wordBreak: 'break-all' }}><b>Weightin KG</b></span>
                          <span style={{ float: 'right', wordBreak: 'break-all', width: '50%' }}>{BookingListData[10]?.value}</span> <hr style={{ margin: '3px' }}></hr>
                          <span style={{ marginBottom: '7px', width: '50%', display: 'inline-block', wordBreak: 'break-all' }}> <b>BMI</b> </span>
                          <span style={{ float: 'right', wordBreak: 'break-all', width: '50%' }}>{BookingListData[11]?.value}</span><hr style={{ margin: '3px' }}></hr>
                          <span style={{ marginBottom: '7px', width: '50%', display: 'inline-block', wordBreak: 'break-all' }}><b>Special Qualification <br />Or Hobbies </b> </span>
                          <span style={{ float: 'right', wordBreak: 'break-all', width: '50%' }}>{BookingListData[14]?.value}</span><hr style={{ margin: '3px' }}></hr>
                          <span style={{ marginBottom: '7px', width: '50%', display: 'inline-block', wordBreak: 'break-all' }}><b>Next of Kin </b></span>
                          <span style={{ float: 'right', wordBreak: 'break-all', width: '50%' }}>{BookingListData[7]?.value}</span><hr style={{ margin: '3px' }}></hr>
                          <span style={{ marginBottom: '7px', width: '50%', display: 'inline-block', wordBreak: 'break-all' }}><b>Insurance Policy No</b> </span>
                          <span style={{ float: 'right', wordBreak: 'break-all', width: '50%' }}>{BookingListData[16]?.value}</span><hr style={{ margin: '3px' }}></hr>
                          <span style={{ marginBottom: '7px', width: '50%', display: 'inline-block', wordBreak: 'break-all' }}> <b>Next of Kin With Address<br />& Telephone</b> </span>
                          <span style={{ float: 'right', wordBreak: 'break-all', width: '50%' }}>{BookingListData[15]?.value}</span>

                        </td>
                      </tr>

                    </table>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '0px 20px 20px', borderBottom: '1px solid #d3d3d3', }} valign="top" colSpan={2} >

                    <div className="card mb-3">
                      {imgurl === "" ? <div className="doc-upload">
                        <p className="text-primary">
                          <UploadIcon />
                          Upload your <b> medical </b> documents to complete booking process.
                        </p>
                        <input type="file" accept=".pdf" value={selectedFile} onChange={(e) => setselectedfile(e.target.files[0])} />
                        <button style={{ display: "none" }} onClick={onFileUpload("medDoc")}></button>
                      </div> :
                        <div className="doc-upload" style={{ padding: '6px 0px 0px 0px', marginBottom: "-6px" }}>
                          <p className="text-scuss"><a href={imgurl}>
                            <Button variant="outlined">
                              <DownloadIcon />
                              Click here to download uploaded medical document.
                            </Button></a>
                            {usertype === 'isStudent' && idbookingstatus !== 'Confirmed' ?
                              <Button style={{ color: 'red' }} onClick={handleDeleteClickMD}><DeleteIcon /></Button> :
                              ''}
                          </p>
                        </div>
                      }
                      {/* {aadharimg === ""||aadharimg===undefined ?
                                    <div className="doc-upload">
                                      <p className="text-primary"><UploadIcon /> Upload your Aadhar Documents to complete booking process.</p>
                                      <input type="file" accept=".pdf" value={selectedFile} onChange={(e) => setselectedfile(e.target.files[0])} />
                                      <button style={{ display: "none" }} onClick={onFileUpload("aadDoc")}></button>
                                    </div> :
                                    <div className="doc-upload">
                                      <p className="text-scuss"><a href={aadharimg}><Button variant="outlined"><ArrowRightAltIcon /> Click here to download your uploaded  Aadhar Documents.</Button></a></p>
                                    </div>
                                  } */}

                      {aadharimg === "" || aadharimg === undefined ?
                        <div className="doc-upload">
                          <p className="text-primary">
                            <UploadIcon />
                            Upload your <b>aadhar</b> documents to complete booking process.
                          </p>
                          <input type="file" accept=".pdf" value={selectedFile} onChange={(e) => setselectedfile(e.target.files[0])} />
                          <button style={{ display: "none" }} onClick={onFileUpload("aadDoc")}></button>
                        </div>
                        :
                        <div className="doc-upload" style={{ padding: '2px 0px 0px 0px', marginBottom: "-6px" }}>
                          <p className="text-success">
                            <a href={aadharimg}>
                              <Button variant="outlined"><DownloadIcon />
                                Click here to download uploaded aadhar document.
                              </Button>
                            </a>
                            {usertype === 'isStudent' && idbookingstatus !== 'Confirmed' ?
                              <Button style={{ color: 'red' }} onClick={handleDeleteClick}><DeleteIcon /></Button> :
                              ''}
                          </p>
                        </div>
                      }
                      {transactionreceipt === "" || transactionreceipt === undefined ?
                        <div className="doc-upload">
                          <p className="text-primary">
                            <UploadIcon />
                            Upload your <b>transaction receipt </b>to complete booking process.
                          </p>
                          <input type="file" accept=".pdf" value={selectedFile} onChange={(e) => setselectedfile(e.target.files[0])} />
                          <button style={{ display: "none" }} onClick={onFileUpload("TRDoc")}></button>
                        </div>
                        :
                        <div className="doc-upload" style={{ padding: '2px 0px 0px 0px', marginBottom: "-6px" }}>
                          <p className="text-success">
                            <a href={transactionreceipt}>
                              <Button variant="outlined">
                                <DownloadIcon />
                                Click here to download uploaded transaction receipt.
                              </Button>
                            </a>
                            {usertype === 'isStudent' && idbookingstatus !== 'Confirmed' ?
                              <Button style={{ color: 'red' }} onClick={handleDeleteClickTR}><DeleteIcon /></Button> :
                              ''}
                          </p>
                        </div>
                      }

                      <div className="row g-0">
                        <div className="col-md-12">
                          <div className="card-body booking contactt">
                            <div style={{ textAlign: 'center' }}>
                              {upcoming === true ? <h4 style={{ color: '#1976d2', textAlign: 'center' }}>This adventure is upcoming.</h4> : null}
                              {Running === true ? <h4 style={{ color: 'rgb(25 135 84)' }}>This adventure is running.</h4> : null}
                              {complete === true ? <h4 style={{ color: '#1976d2' }}>This adventure has been completed.</h4> : null}
                              <div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" style={{ padding: '20px 9px 10px 9px', fontSize: '15px', borderRight: '1px solid #d3d3d3', fontWeight: '700', textAlign: 'right' }}>NIMAS <br /> Authorized Signatory</td>
                </tr>
              </table>
            );
          })}
        </div>
        <div id="popup1" className="overlay" >
          <div className="popup close-popup" style={{ width: '40%' }}>
            <h6>Send Email</h6>
            <a className="close" href="#">&times;</a>
            <div className="content " >
              <table style={{ width: '100%' }}>
                <tr  >
                  <td>Contact Name</td>
                  <td>Email Id</td>
                </tr>
                <tr>
                  <td>
                    <FormControl fullWidth variant="outlined" style={{ margin: "-20px 1px 0px 1px" }}>
                      <TextField labelId="email-label"
                        placeholder="Contact Name" value={contactName} onChange={(e) => setcontactName(e.target.value)} disabled>
                      </TextField>

                    </FormControl>
                  </td>
                  <td>

                    <FormControl fullWidth variant="outlined" style={{ margin: "-20px 1px 0px 1px" }}>
                      {/* <InputLabel id="email-label">Email Id <span class="required">*</span></InputLabel> */}
                      <TextField labelId="email-label" placeholder="Email Id" value={toEmailId} onChange={(e) => settoEmailId(e.target.value)}
                        className={`${error && toEmailId.trim() === '' ? 'is-invalid' : ''}`} >
                      </TextField>
                      {error && toEmailId.trim() === '' && <div className="invalid-feedback" style={{ margin: "-27px 0px 6px 1px" }}>Please Enter Student Email Id</div>}
                    </FormControl>

                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>

                    <FormControl fullWidth variant="standard" style={{ margin: "-16px 0px 17px 0px" }}>
                      <InputLabel id="email-label">From </InputLabel>
                      <Input disabled={"disable"} labelId="email-label" value={fromEmail} onChange={(e) => setfromEmail(e.target.value)}>
                      </Input>
                    </FormControl>

                  </td>
                </tr>
              </table>
              <Stack spacing={2} direction="row" style={{ margin: "0px 1px 0px 1px" }}>
                <Button variant="outlined" onClick={() => { setcontactName(''); settoEmailId(''); }} id="closepop" href="#">Cancel</Button>
                <Button variant="contained" onClick={SendEmail}>Send</Button>
              </Stack>
            </div>
          </div>
        </div>
      </div>
    </>

  );
}

export default Invoicenew;