import React, { useEffect, useState } from "react";

import { Link } from 'react-router-dom';
import '../Common/custom-style.css';
import { ThreeDots } from 'react-loader-spinner'



function Login() {
  localStorage.removeItem("user-Studentinfo");
  localStorage.removeItem("payment-Info");
  localStorage.removeItem("user-admininfo");
  // localStorage.clear();

  const bookingInfo = JSON.parse(localStorage.getItem("Booking-Info"));
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState('');
  const productdata2 = { "NumberOfFieldsView": 50, "filters": { "Email": username, "Password": password }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 4 }
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [isLoader, setIsLoader] = useState(false);

  // setTimeout(() => {
  //   setIsLoader(false);
  // }, 3000);
  
  function fun_Login() {
    if (username.trim() === "") {
      setError('Please enter username');
      return;
    }
    else if (!emailRegex.test(username.trim())) {
      setError('Please enter a valid email address');
      return;
    }
    else if (password.trim() === "''") {
      setError('Please enter password');
      return;
    }
    else if (!isChecked) {
      setError('Please accept the terms and conditions');
      return;
    }
    // Email format validation

    else {

      setIsLoader(true);
      fetch(
        "https://api.dialerp.com/api/api/getTemplateDataList",
        {
          method: "POST",
          headers: {
            "Authorization-Token": authToken,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(productdata2),
        }
      ).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            if (resp.response?.records.length > 0) {
              localStorage.setItem("user-Studentinfo", JSON.stringify(resp.response));
              if (bookingInfo != null) {
                window.location.href = '/student-admin/book-course';
              }
              else {
                window.location.href = '/student-admin/dashboard';
              }

            }
            else {
              alert('Invalid username or password');
            }
          }
          else {
            alert(resp.errors);
          }
        });
       
      });
      setIsLoader(false);
    }
  }

  return (<>

    {isLoader && <div id="loadingg">
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#fff"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>}




    <div className="login logins">
      <div className="login-box">
        <a href="/"> <img src="../admin/img/nimas_logo.png" alt="logo" className="bylogo" /></a>
        <h2 mat-h2>Continue to Login</h2>
        <div className="card mb-3">

          <div className="card-body">

            <div className="col-12">
              <div>
                <label htmlFor="yourUsername" className="form-label">login Id (Email)<span className="required">*</span></label>
                <div className="input-group">
                  {/* Username input */}
                  <input type="text" name="username"
                    className={`form-control ${error && (username.trim() === '' || !emailRegex.test(username.trim())) ? 'is-invalid' : ''}`}
                    id="yourUsername" value={username} onChange={(e) => { setUsername(e.target.value); setError(''); }} />
                  {error && username.trim() === '' && <div className="invalid-feedback">Please enter username</div>}
                  {error && username.trim() !== '' && !emailRegex.test(username.trim()) && <div className="invalid-feedback">Please enter a valid email address</div>}
                </div>
              </div>

              <div>
                <label htmlFor="yourPassword" className="form-label">Password<span className="required">*</span></label>
                <div className="input-group">
                  {/* Password input */}
                  <input
                    type="password" name="password"
                    className={`form-control ${error && password.trim() === '' ? 'is-invalid' : ''}`}
                    id="yourPassword" value={password} onChange={(e) => { setPassword(e.target.value); setError(''); }} />
                  {error && password.trim() === '' && <div className="invalid-feedback">Please enter password</div>}
                </div>

              </div>
            </div>
            <div className="col-12">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" name="remember"
                  id="rememberMe" checked={isChecked} onChange={() => { setIsChecked(!isChecked); setError('') }} />
                <label className="form-check-label terms" htmlFor="rememberMe"> By proceeding, you agree to <Link to="/terms-and-conditions" target="_blank">Terms & Conditions</Link> and <Link to="/privacy-policy" target="_blank">Privacy Policy </Link></label>
              </div>
              {error && isChecked === false && <p style={{ color: 'red' }}>Please accept the terms and conditions</p>}
              {/* Checkbox error message */}

              {/* <div className="form-check">
                <input className="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe" />
                <label className="form-check-label terms" for="rememberMe">By proceeding, you agree to NIMAS User Agreement, Terms and Privacy Policy</label>
              </div> */}
            </div>
            <div className="col-12">
              {/* <Link to="/student-admin/book-course"><a className="buttons" >LOGIN</a></Link>  */}
              <button className="buttons" onClick={fun_Login}>Login</button>
            </div>
            <div className="col-12">
              <p className="small mb-0 text-center terms">Don't have account?  <Link to="/student-admin/register">Create an account</Link></p>
            </div>

          </div>
        </div>
        {/* <small className="glogin">or Login with</small>
        <div className="google"><img src="../admin/img/google.png" alt="" className="bylogo" /></div> */}
      </div>

    </div>

  </>
  );
}

export default Login;
