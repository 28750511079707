import React, { useEffect, useState, useRef } from "react";
import '../Common/custom-style.css';
import '/node_modules/bootstrap/dist/css/bootstrap.min.css';
import '/node_modules/bootstrap/dist/js/bootstrap.min.js';
import moment from 'moment';
import Button from '@mui/material/Button';
import PrintIcon from '@mui/icons-material/Print';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { Mail, Token } from "@mui/icons-material";
//import { sendmail } from "../Common/sendmail";
import axios from "axios";
import DownloadIcon from '@mui/icons-material/Download';
import { ThreeDots } from 'react-loader-spinner'


function Invoice() {

  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  var bookingid = localStorage.getItem('id');
  // const userStudentinfo = JSON.parse(localStorage.getItem("user-Studentinfo"));
  const admininfo = JSON.parse(localStorage.getItem("user-admininfo"));
  const [toEmailId, settoEmailId] = useState('');
  // const [fromEmailId, setfromEmailId] = useState('');
  const [fromEmail, setfromEmail] = useState('');
  const [contactName, setcontactName] = useState('');
  //const [name, setname] = useState("");
  const [error, seterror] = useState('');
  const [id, SetId] = useState();
  // const [smtpport, Setsmtpport] = useState();
  const [bookingdate, Setbookingdate] = useState();
  const [idbookingstatus, Setstatus] = useState();
  const [seat, Setseat] = useState();
  const [coursename, Setcourse] = useState();
  const [serialno, setserialno] = useState();
  const [isLoader, setIsLoader] = useState(true);
  function BooKingData() {
    const Bookinglistpra = { "NumberOfFieldsView": 50, "filters": { "BookingID": bookingid }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 5 };
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(Bookinglistpra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          if (resp.response?.records.length > 0) {
            SetId(resp.response?.records[0][3].value);
            //  SetTodate(resp.response?.records[0][22].value);
            // Setfromdate(resp.response?.records[0][21].value);
            Setbookingdate(resp.response?.records[0][1].value);
            // Setage(resp.response?.records[0][23].value);
            Setstatus(resp.response?.records[0][19].value);
            Setseat(resp.response?.records[0][8].value);
            Setbookingdate(resp.response?.records[0][1].value);
            Setcourse(resp.response?.records[0][5].value);
            // setcategory(resp.response?.records[0][4].value);
            settoEmailId(resp.response?.records[0][25].value);
            setIsLoader(false)
            CourseList(resp.response?.records[0][4].value, resp.response?.records[0][6].value);
            fun_Login(resp.response?.records[0][25].value);
            fun_ReceiptListdata(resp.response?.records[0][3].value);
          }
          else {
            alert("data not found!!")
            setIsLoader(false)
          }
        }

      });
    });
  }
  //const [ReceiptListdata, SetReceiptListdata] = useState();
  const [courseDate, setcourseDate] = useState();

  function CourseList(cName, serialno) {
    setIsLoader(true)
    const Courselistpra = { "NumberOfFieldsView": 50, "filters": { "Category Name": cName, "Serial No": serialno }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 3 };
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(Courselistpra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        setIsLoader(false)
        //setCourseListData(resp.response.records);
        if (resp.response?.records.length > 0) {
          //setCourseListData(resp.response.records);
          setcfee(resp.response.records[0][12].value);
          setduration(resp.response.records[0][6].value);
          setserialno(resp.response.records[0][9].value);
          setcourseDate(resp.response.records[0][10].value + " / " + resp.response.records[0][11].value)
        }
        else {
          console.log("Not found");
        }
      });
    });
  }

  function fun_ReceiptListdata(id) {
    setIsLoader(true)
    const ReceiptIdPra = { "NumberOfFieldsView": 50, "filters": { "BookingId": id }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 6 }
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(ReceiptIdPra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.response?.records.length > 0) {
          setRcdate(resp.response?.records[0][1].value)
          setReceiptId(resp.response?.records[0][3].value)
          setGST(resp.response?.records[0][9].value);
          setSubTotal(resp.response?.records[0][10].value);
          setPayAmount(resp.response?.records[0][12].value);
          setPaymentMethod(resp.response?.records[0][6].value);
          setTransactionId(resp.response?.records[0][7].value);
          setamountInWords(convertNumberToWords(resp.response?.records[0][12].value));
        }
        setIsLoader(false)
      });
    });
  }
  const [cfee, setcfee] = useState();
  const [duration, setduration] = useState();
  const [GST, setGST] = useState(0);
  const [SubTotal, setSubTotal] = useState(0);
  const [PayAmount, setPayAmount] = useState(0);
  const [PaymentMethod, setPaymentMethod] = useState("0");
  const [TransactionId, setTransactionId] = useState("");
  const [ReceiptId, setReceiptId] = useState("");
  const [Rcdate, setRcdate] = useState("");

  useEffect(() => {
    BooKingData();

  }, [0]);

  const [PermanentAddress, setPermanentAddress] = useState("");
  const [StudentFullName, setFullname] = useState("");
  const [City, setCity] = useState("");
  const [State, SetState] = useState("");
  const [Pincode, setPincode] = useState("");
  const [PdfPath, setPdfPath] = useState("");

  function fun_Login(email) {
    setIsLoader(true)
    const loginpra = { "NumberOfFieldsView": 50, "filters": { "Email": email }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 4 }
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(loginpra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          if (resp.response?.records.length > 0) {
            setPermanentAddress(resp.response?.records[0][12].value);
            setCity(resp.response?.records[0][13].value);
            SetState(resp.response?.records[0][14].value);
            setPincode(resp.response?.records[0][15].value);
            setFullname(resp.response?.records[0][5].value);
            setcontactName(resp.response?.records[0][5].value);
          }
        }
        setIsLoader(false)
      });
    });
  }
  const [amountInWords, setamountInWords] = useState("");
  function convertNumberToWords(number) {
    const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    const scales = ['', 'Thousand', 'Million', 'Billion', 'Trillion'];

    if (number === 0) {
      return 'Zero';
    }

    const chunks = [];
    while (number) {
      chunks.push(number % 1000);
      number = Math.floor(number / 1000);
    }

    const parts = [];
    for (let i = 0; i < chunks.length; i++) {
      const chunk = chunks[i];
      const hundreds = Math.floor(chunk / 100);
      const tensAndUnits = chunk % 100;
      const scale = scales[i];

      if (hundreds !== 0) {
        parts.push(units[hundreds] + ' Hundred');
      }

      if (tensAndUnits !== 0) {
        if (tensAndUnits < 10) {
          parts.push(units[tensAndUnits]);
        } else if (tensAndUnits < 20) {
          parts.push(teens[tensAndUnits - 10]);
        } else {
          const tensDigit = Math.floor(tensAndUnits / 10);
          const unitsDigit = tensAndUnits % 10;
          parts.push(tens[tensDigit] + (unitsDigit !== 0 ? ' ' + units[unitsDigit] : ''));
        }
      }

      if (scale && chunk !== 0) {
        parts.push(scale);
      }
    }

    return parts.reverse().join(' ');
  }


  function loademail() {
    getEmailID();
  }

  //https://dialerpwebapis-dev-as.azurewebsites.net/
  const [emaildata, setemaildata] = useState("");
  function getEmailID() {
    setIsLoader(true)
    const daa = {
      "ParentId": 40330,
      "userID": 40330,
      "searchFromEmail": ""
    };
    //JSON.stringify(daa);
    //   fetch("https://api.dialerp.com/api/SmsEmail/GetEmailIDema,
    //   {
    //       method: "POST",      
    //       body: JSON.stringify(daa),
    //       headers: { "Accept": "application/json","Content-Type": 'application/json', 'Authorization-Token': authToken },
    //   }
    // )
    fetch(
      "https://api.dialerp.com/api/api/GetEmailID",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(daa),
      }
    ).then((result) => {

      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          setemaildata(resp);
          setfromEmail(resp.response.fromEmail);
          //Setsmtpport(resp.response.smtpHost);
          setIsLoader(false)
          downloadHTMLtoPDF();
        }
        else {
          alert(resp.errors);
          setIsLoader(false)
        }
      });
    });
  }
  function downloadHTMLtoPDF() {
    setIsLoader(true)
    if (htmldivRef.current) {
      let printContents = htmldivRef.current.innerHTML;
      printContents = printContents.split("href").join("title");// .replace('href','title');
      printContents = printContents.split("#007bff").join("#000000");//.replace('#007bff','#000000');
      const postData = {
        "userID": 40330, "fileName": "Invoice(" + ReceiptId + ")", "htmlData": printContents,
      };
      fetch(
        "https://api.dialerp.com/api/api/downloadHTMlToPDFForVouchers",
        {
          method: "POST",
          headers: {
            "Authorization-Token": authToken,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(postData),
        }
      ).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            setPdfPath(resp.response);
            setIsLoader(false)
          }
          setIsLoader(false)
        });
      });
    } else {
      console.log("Element not found.");
    }
  }
  function SendEmail() {
    setIsLoader(true)
    if (PdfPath === null) {
      setIsLoader(false)
      return
    }
    if (fromEmail === null || fromEmail === "") {
      setIsLoader(false)
      alert("Please verify your Email ID!")
      return
    }

    if (toEmailId === null || toEmailId === "") {
      setIsLoader(false)
      seterror('required"');
      return
    }
    const postData =
    {
      "index": 1,
      "userID": 40330,
      "toEmailID": fromEmail,
      "attachment": PdfPath,
      "Subject": "Payment Invoice(" + ReceiptId + ")",
      "Body": "Dear " + contactName + ", <br> <br> Hope you are doing great!<br> <br>We have attached the Payment Invoice (" + ReceiptId + ") for your reference.<br> <br>Thanks & Regards,<br><br><b>Email: " + fromEmail + "</b><br><b>WebSite:https://nimasdirang.com/</b><br><b>Contact no: 91-9774731855</b>",

      "items": [
        {
          "accountName": "InDirect Dddd Lead",
          "contactName": contactName,
          "email": toEmailId,
          "userID": 40330,
          "isChecked": true
        }
      ],
      "smtpHost": emaildata.response.smtpHost,
      "smtpPort": emaildata.response.smtpPort,
      "smtpPassword": emaildata.response.smtpPassword,
      "fromEmail": emaildata.response.fromEmail,
      "fromName": emaildata.response.fromName,
      "smtpAuthDomain": emaildata.response.smtpAuthDomain,
      "toEmailIDs": toEmailId,
      "fileName": "Payment Invoice(" + ReceiptId + ")"
    }

    fetch(
      "https://api.dialerp.com/api/api/SendEmailwithAttachment",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(postData),
      }
    ).then((result) => {
      debugger;
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          alert("Email sent successfully");
          setIsLoader(false)
          window.location.reload(true)
        }
        else {
          alert(resp.errors);
          setIsLoader(false)
        }
      });
    });

  }
  const handlePrint = () => {
    const elementsToHide = document.querySelectorAll('.element-to-hide');
    elementsToHide.forEach((element) => {
      element.style.display = 'none';
    });

    // Trigger the print dialog
    window.print();

    // Restore the visibility of hidden elements after printing
    elementsToHide.forEach((element) => {
      element.style.display = '';
    });
  };

  const htmldivRef = useRef(null);
  const onFileUpload = () => {
    setIsLoader(true)
    if (htmldivRef.current) {
      const printContents = htmldivRef.current.innerHTML;
      const postData = {
        "userID": 40330, "fileName": "Invoice(" + ReceiptId + ")", "htmlData": printContents,
      };
      fetch(
        "https://api.dialerp.com/api/api/downloadHTMlToPDF",
        {
          method: "POST",
          headers: {
            "Authorization-Token": authToken,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(postData),
        }
      ).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            downloadFile(resp.response, "pdf", "Invoice(" + ReceiptId + ")");
            setIsLoader(false)
          }
        });
      });
    } else {
      console.log("Element not found.");
    }
  };
  function downloadFile(path, type, fileName) {
    setIsLoader(true)
    var link = document.createElement('a');
    link.href = path;
    link.download = fileName + "." + type;
    link.click();
    setIsLoader(false)
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return (
    <>
      {isLoader &&
        <div id="loadingg">
          <ThreeDots height="80" width="80" radius="9" color="#fff" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      }
      <div className="invoice" style={{ padding: '80px 20px', background: '#f2f2f2', fontFamily: 'roboto' }}>
        <div className='button-row element-to-hide'>
          {admininfo !== null ? <a href="#popup1" onClick={loademail} > <Button variant="outlined" size="small">  <SwapHorizIcon />  Send to Email</Button></a> : null}
          <Button variant="outlined" size="small" onClick={handlePrint}><PrintIcon /> Print</Button>
          <Button variant="outlined" size="small" onClick={onFileUpload}><DownloadIcon /> Download PDF</Button>
        </div>
        <div ref={htmldivRef} id="divprint">
          <table cellPadding="0" cellSpacing="0" style={{ width: '800px', margin: '0 auto', border: '1px solid #d3d3d3', background: '#fff', fontFamily: 'arial' }}>
            <tr style={{ marginBottom: '15px' }}>
              <td style={{ width: '50%', padding: '0px 20px' }}><div style={{ color: 'rgb(25, 118, 210)', fontSize: '30px', fontWeight: '600' }}>  <img src="../admin/img/logo.jpg" alt="" style={{ width: '100px' }} /></div></td>
              <td style={{ width: '50%', padding: '0px 20px', color: '#000', fontSize: '19px', fontWeight: '600', textAlign: 'right' }}>Receipt</td>
            </tr>

            <tr>
              <td style={{ width: '50%', padding: '10px 0px 5px 20px', verticalAlign: 'top' }}><p style={{ color: '#000', fontSize: '16px', fontWeight: '600', marginBottom: '0px' }}>Sold By</p>
                <p style={{
                  color: '#000', fontSize: '14px', fontWeight: '400', marginBottom: '5px',
                  marginTop: '5px'
                }}>NIMAS</p>
                <p style={{ color: '#000', fontSize: '14px', fontWeight: '400', marginBottom: '5px' }}>
                  National Institute of Mountaineering and Adventure Sports
                  Dirang, West Kameng
                  Arunachal Pradesh
                  Pin- 790101<br />
                  IN
                </p>
                <p style={{
                  color: '#000', fontSize: '14px', fontWeight: '400', marginBottom: '5px',
                  marginTop: ' 0px'
                }}><b>Pan Number</b> : AAEFU0850G</p>
                <p style={{
                  color: '#000', fontSize: '14px', fontWeight: '400', marginBottom: '5px',
                  marginTop: ' 0px'
                }}><b>GST No</b> : 06AAEFU0850G1ZE</p>
              </td>

              <td style={{ width: '50%', padding: '10px 20px 5px 20px', verticalAlign: 'top' }}>
                <div style={{ marginBottom: '25px' }}><p style={{ color: '#000', fontSize: '16px', fontWeight: '600', marginBottom: '0px', textAlign: 'right' }}>Billing Address</p>
                  <p style={{ color: '#000', fontSize: '14px', fontWeight: '400', marginBottom: '5px', textAlign: 'right', marginTop: ' 5px' }}>
                    {StudentFullName}
                  </p>
                  <p style={{ color: '#000', fontSize: '14px', fontWeight: '400', marginBottom: '5px', textAlign: 'right' }}>
                    {PermanentAddress}
                  </p>
                  <p style={{ color: '#000', fontSize: '14px', fontWeight: '400', marginBottom: '5px', textAlign: 'right' }}>
                    {City}, {State}, {Pincode}
                  </p>
                </div>

              </td>
            </tr>
            <tr>
              <td style={{ padding: '20px 20px 0px 20px' }} colSpan="8">
                <table cellPadding="0" cellSpacing="0" style={{ width: '100%', border: '1px solid #d3d3d3', background: '#fff', fontFamily: ' arial' }}>
                  <tr>
                    <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>Booking ID</th>
                    <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>Course Duration</th>
                    <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>Course Date</th>
                    <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>Booking Date</th>
                    <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>Booking Date</th>
                  </tr>
                  <tr>
                    <><td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{id}</td>
                      <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{duration}</td>
                      <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{courseDate}</td>
                      <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{moment(bookingdate).format('DD-MMM-YYYY')}</td>
                      <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', textAlign: 'center', borderRight: '1px solid #d3d3d3' }}>{idbookingstatus}</td></>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '20px' }} colSpan="2">
                <table cellPadding="0" cellSpacing="0" style={{ width: '100%', border: '1px solid #d3d3d3', background: '#fff' }}>
                  <tr>
                    <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', background: '#f2f2f2', borderRight: '1px solid #d3d3d3' }}>S. No.</th>
                    <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', background: '#f2f2f2', borderRight: '1px solid #d3d3d3', textAlign: ' left' }}>Course Name</th>
                    <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', background: '#f2f2f2', borderRight: '1px solid #d3d3d3', textAlign: ' left' }}>Price</th>
                    <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', background: '#f2f2f2', borderRight: '1px solid #d3d3d3', textAlign: ' left' }}>Seats</th>
                    <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', background: '#f2f2f2', borderRight: '1px solid #d3d3d3', textAlign: ' left' }}>Net Amt.</th>
                    <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', background: '#f2f2f2', borderRight: '1px solid #d3d3d3', textAlign: ' left' }}>Tax Rate</th>
                    <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', background: '#f2f2f2', borderRight: '1px solid #d3d3d3', textAlign: ' left' }}>Tax Amt.</th>
                    <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', background: '#f2f2f2', textAlign: ' left' }}>Total Amt.</th>
                  </tr>
                  <tr>
                    <td style={{ padding: '2px', fontSize: '13px', borderRight: '1px solid #d3d3d3', borderBottom: '1px solid #d3d3d3' }}>1</td>
                    <td style={{ padding: '2px', fontSize: '13px', borderRight: '1px solid #d3d3d3', borderBottom: '1px solid #d3d3d3' }}>{coursename}-{serialno}</td>
                    <td style={{ padding: '2px', fontSize: '13px', borderRight: '1px solid #d3d3d3', borderBottom: '1px solid #d3d3d3' }}>₹{cfee}</td>
                    <td style={{ padding: '2px', fontSize: '13px', borderRight: '1px solid #d3d3d3', borderBottom: '1px solid #d3d3d3' }}>{seat}</td>
                    <td style={{ padding: '2px', fontSize: '13px', borderRight: '1px solid #d3d3d3', borderBottom: '1px solid #d3d3d3' }}>₹{SubTotal}</td>
                    <td style={{ padding: '2px', fontSize: '13px', borderRight: '1px solid #d3d3d3', borderBottom: '1px solid #d3d3d3' }}>0%</td>
                    <td style={{ padding: '2px', fontSize: '13px', borderRight: '1px solid #d3d3d3', borderBottom: '1px solid #d3d3d3' }}>₹0</td>
                    <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3' }}>₹{PayAmount}</td>
                  </tr>
                  <tr>
                    <td colSpan="6" style={{ padding: '2px', fontSize: '13px', borderRight: '1px solid #d3d3d3', fontWeight: '600', borderBottom: '1px solid #d3d3d3' }}>Total</td>
                    <td style={{ padding: '2px', fontSize: '13px', borderRight: '1px solid #d3d3d3', fontWeight: '600', borderBottom: '1px solid #d3d3d3' }}>₹{GST}</td>
                    <td style={{ padding: '2px', fontSize: '13px', fontWeight: '600', borderBottom: '1px solid #d3d3d3' }}>₹{PayAmount}</td>
                  </tr>
                  <tr>
                    <td colSpan="8" style={{ padding: '2px', fontSize: '13px', borderRight: '1px solid #d3d3d3', fontWeight: '700', borderBottom: '1px solid #d3d3d3' }}>Amount in words :{amountInWords} <br /></td>

                  </tr>
                  <tr>
                    <td colSpan="8" style={{ padding: '20px 9px 10px 9px', fontSize: '15px', borderRight: '1px solid #d3d3d3', fontWeight: '700', textAlign: 'right' }}>NIMAS <br /> Authorized Signatory</td>

                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '0px 20px 20px' }} colSpan="8">
                <table cellPadding="0" cellSpacing="0" style={{ width: '100%', border: '1px solid #d3d3d3', background: '#fff' }}>
                  <tr>
                    <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>Receipt ID</th>
                    <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>TransactionId</th>
                    <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>Date & Time</th>
                    <th style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>Mode of Payment</th>
                  </tr>
                  <tr>
                    <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{ReceiptId}</td>
                    <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{TransactionId}</td>
                    <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{moment(Rcdate).format('DD-MMM-YYYY hh:mm:ss')}</td>
                    <td style={{ padding: '2px', fontSize: '13px', borderBottom: '1px solid #d3d3d3', borderRight: '1px solid #d3d3d3', textAlign: 'center' }}>{PaymentMethod}</td>
                  </tr>
                </table>
              </td>
            </tr>



          </table>
        </div>
        <div id="popup1" className="overlay" >
          <div className="popup close-popup" style={{ width: '40%' }}>
            <h6>Send Email</h6>
            <a className="close" href="#">&times;</a>
            <div className="content " >
              <table style={{ width: '100%' }}>
                <tr  >
                  <td>Contact Name</td>
                  <td>Email Id</td>
                </tr>
                <tr>
                  <td>
                    <FormControl fullWidth variant="outlined" style={{ margin: "-20px 1px 0px 1px" }}>
                      <TextField labelId="email-label"
                        placeholder="Contact Name" value={contactName} onChange={(e) => setcontactName(e.target.value)} disabled>
                      </TextField>

                    </FormControl>
                  </td>
                  <td>

                    <FormControl fullWidth variant="outlined" style={{ margin: "-20px 1px 0px 1px" }}>
                      {/* <InputLabel id="email-label">Email Id <span class="required">*</span></InputLabel> */}
                      <TextField labelId="email-label" placeholder="Email Id" value={toEmailId} onChange={(e) => settoEmailId(e.target.value)}
                        className={`${error && toEmailId.trim() === '' ? 'is-invalid' : ''}`} >
                      </TextField>
                      {error && toEmailId.trim() === '' && <div className="invalid-feedback" style={{ margin: "-27px 0px 6px 1px" }}>Please Enter Student Email Id</div>}
                    </FormControl>

                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>

                    <FormControl fullWidth variant="standard" style={{ margin: "-16px 0px 17px 0px" }}>
                      <InputLabel id="email-label">From </InputLabel>
                      <Input disabled={"disable"} labelId="email-label" value={fromEmail} onChange={(e) => setfromEmail(e.target.value)}>
                      </Input>
                    </FormControl>

                  </td>
                </tr>
              </table>
              <Stack spacing={2} direction="row" style={{ margin: "0px 1px 0px 1px" }}>
                <Button variant="outlined" onClick={() => { setcontactName(''); settoEmailId(''); }} id="closepop" href="#">Cancel</Button>
                <Button variant="contained" onClick={SendEmail}>Send</Button>
              </Stack>
            </div>
          </div>
        </div>
      </div>
    </>

  );
}

export default Invoice;