import * as ReactDOM from 'react-dom';
import React, { useEffect, useState, Component } from "react";
import axios from 'axios';
import '/node_modules/bootstrap/dist/css/bootstrap.min.css';
import '/node_modules/bootstrap/dist/js/bootstrap.min.js';
import Headernew from '../Common/Headernew';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { ThreeDots } from 'react-loader-spinner'


function Myprofile() {


  var userStudentinfo = JSON.parse(localStorage.getItem("user-Studentinfo"));
  if (userStudentinfo === null) {
    window.location.href = '/Student-admin/Login';
  }
  const authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  const loginpra = { "NumberOfFieldsView": 50, "filters": { "Email": userStudentinfo?.records[0][8].value, "Password": userStudentinfo?.records[0][9].value }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 4 }

  const [fullName, setfullName] = useState("");
  const [error, setError] = useState('');
  const [gender, setgender] = useState("");
  const [dob, setDOB] = useState("");
  const [mobile, setmobile] = useState("");
  const [address, setaddress] = useState("");
  const [City, setCity] = useState("");
  const [state, setStates] = useState("");
  const [pincode, setPincode] = useState("");
  const [altmobile, setAltmobile] = useState("");
  const [country, setcountry] = useState("");
  const [aadharNumber, setaadharNumber] = useState("");
  const [nir, setnir] = useState("");
  const [nationality, setnationality] = useState("");
  const [fathersName, setfathersName] = useState("");
  const [selectedFile, setselectedfile] = useState(null);
  const [imgurl, setimgurl] = useState("");
  const [imgurl_, setimgurl_] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [NextofKinWithAddressTelephone, SetNextofKinWithAddressTelephone] = useState("");
  const [AnySpecialQualificationOrHobbies, SetAnySpecialQualificationOrHobbies] = useState("");
  const [AcademicQualification, SetAcademicQualification] = useState("");
  const [ShoeSize, SetShoeSize] = useState("");
  const [BMI, SetBMI] = useState("");
  const [WeightinKG, SetWeightinKG] = useState("");
  const [HeightinCM, SetHeightinCM] = useState("");
  const [NextofKin, SetNextofKin] = useState("");
  const [Age, SetAge] = useState("");


  function fun_Login() {
    setIsLoader(true);
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(loginpra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.response?.records.length > 0) {
          setfullName(resp.response?.records[0][5].value);
          setimgurl(resp.response?.records[0][4].value);
          setgender(resp.response?.records[0][10].value);
          //setDOB(userStudentinfo?.records[0][11].value);
          setmobile(resp.response?.records[0][6].value);
          setaddress(resp.response?.records[0][12].value);
          setCity(resp.response?.records[0][13].value);
          setStates(resp.response?.records[0][14].value);
          setPincode(resp.response?.records[0][15]?.value);
          setAltmobile(resp.response?.records[0][7]?.value);
          setcountry(resp.response?.records[0][16]?.value);
          setaadharNumber(resp.response?.records[0][17]?.value);
          setnir(resp.response?.records[0][18]?.value);
          setnationality(resp.response?.records[0][19]?.value);
          setfathersName(resp.response?.records[0][20]?.value);
          SetAge(resp.response?.records[0][22]?.value);
          SetNextofKin(resp.response?.records[0][23]?.value);
          SetHeightinCM(resp.response?.records[0][24]?.value);
          SetWeightinKG(resp.response?.records[0][25]?.value);
          SetBMI(resp.response?.records[0][26]?.value);
          SetShoeSize(resp.response?.records[0][27]?.value);
          SetAcademicQualification(resp.response?.records[0][28]?.value);
          SetAnySpecialQualificationOrHobbies(resp.response?.records[0][29]?.value);
          SetNextofKinWithAddressTelephone(resp.response?.records[0][30]?.value);

          const timeStamp = Date.parse(resp.response.records[0][11].value);
          const date = new Date(timeStamp);
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const dateVal = date.getDate();
          const formattedDate_ = `${dateVal < 10 ? "0" + dateVal : dateVal}-${month < 10 ? "0" + month : month}-${year}`;
          const formattedDate = formattedDate_.split("-").reverse().join("-");
          setDOB(formattedDate);
          SetAge("");
          var age = Math.floor((new Date() - new Date(formattedDate)) / 1000 / 60 / 60 / 24 / 365.25)
          SetAge(age);
        }
        else {
          window.location.href = 'student-admin/login';
        }
      });
      setIsLoader(false);
    });
  }
  useEffect(() => {
    fun_Login();
  }, [0]);
  //const state = {



  //};
  //  function   onFileChange(e) {

  //         // Update the state
  //         setselectedfile(e.target.files[0]);
  //         alert(e.target.files[0]);

  //     };
  // const [formData, setFormData] = useState({

  //   image: undefined
  // });

  // const onChangeImage = event => {
  //   console.log(event.target.files[0]);
  //   setFormData( prev => ({
  //     ...prev,
  //     image: event.target.files[0]
  //   }));
  // }

  function onFileUpload() {
    //selectedFile.files[0].name
    const formData = new FormData();
    if (selectedFile != null) {
      setIsLoader(true);
      formData.append("myFile", selectedFile);
      formData.append("name", selectedFile.name);
      setselectedfile(selectedFile.name);
    
      axios({
        method: 'post',
        url: "https://dialerpwebapis-dev-as.azurewebsites.net/api/UploadData/UploadCommonFilesToAzure?userID=" + 40330 + "&parentID=" + 40330 + "'",
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data', 'Authorization-Token': authToken },
      })
        .then((response) => {
          if (response.data.hasErrors === true) {
            alert(response.data.errors);
            setIsLoader(false);
          }
          else if (response.data.response != null) {
            setimgurl_(response.data.response[0].imageUrl);
            setIsLoader(false);
          }
        }
        )
        .catch((err) =>
          alert(err)
        );  
    }

  };
  //0-_id', value: '64992a4b5373e8d75d450b31'}
  //1-createDt', value: '2023-06-26T11:33:55.548Z'}
  //2-templateID', value: 4}
  //3-StudentID', value: 2}
  //4-Profile Pic', value: ''}

  //5-Full Name', value: 'Chandra kant Sharma'}
  //6-Mobile', value: 8952894790}
  //7-Alternate Mobile Number', value: 8952894790}
  //8-Email', value: 'chandrakant@dialerp.com'}
  //9-Password', value: '123'}
  //10-Gender', value: 'Male'}
  //11-DOB', value: '10-Jan-1997'}
  //12-Permanent Address', value: 'mansarovar jaipur'}
  //13-City', value: 'Jaipur'}
  //14-State', value: 'Rajasthan'}
  //15-Pincode', value: 303305}
  //16-Country', value: 'India'}
  //17-Aadhar Number', value: 123456789123}
  //18-NRI', value: ''}
  //19-Nationality', value: ''}
  //20-Father's Name", value: 'Dinesh Kumar Sharma'}

  const signUPpara = {
    "templateID": "4"
    , "_id": userStudentinfo?.records[0][0].value
    , "_1": userStudentinfo?.records[0][3].value
    , "_2": imgurl_
    , "_3": fullName
    , "_4": mobile
    , "_5": altmobile
    , "_6": userStudentinfo?.records[0][8].value
    , "_7": userStudentinfo?.records[0][9].value
    , "_8": gender
    , "_9": dob
    , "_10": address
    , "_11": City
    , "_12": state
    , "_13": pincode
    , "_14": country
    , "_15": aadharNumber
    , "_16": nir
    , "_17": nationality
    , "_18": fathersName
    , "_19": userStudentinfo?.records[0][21]?.value
    //, "_19": fathersName
    , "_20": Age
    , "_21": NextofKin
    , "_22": HeightinCM
    , "_23": WeightinKG
    , "_24": BMI
    , "_25": ShoeSize
    , "_26": AcademicQualification
    , "_27": AnySpecialQualificationOrHobbies
    , "_28": NextofKinWithAddressTelephone
    // , "_29": 
    // , "_30": 
  }
  function fun_Dobceck(valDob) {
    setDOB(valDob);
    SetAge("");
    var age = Math.floor((new Date() - new Date(valDob)) / 1000 / 60 / 60 / 24 / 365.25)
    SetAge(age);
  }
  function fun_signUP() {

    debugger
    SetAge("");
    var age = Math.floor((new Date() - new Date(dob)) / 1000 / 60 / 60 / 24 / 365.25)
    SetAge(age);
    if (age < 17 || age > 40) {
      setError("Enter age must be between 17 and 40.");
      document.getElementById('dob').focus();
      return;
    }
    // if(email==="")
    // {
    //   alert("Please Enter Email");
    //   return
    // }
    // if(password==="")
    // {
    //   alert("Please Enter Password");
    //   return
    // }
    // if(name==="")
    // {
    //   alert("Please Enter Name");
    //   return
    // }
    // if(isChecked===true)
    // {
    //   console.log(isChecked);
    //   alert("Please check Term & Condition");
    //   return
    // }
    else {
      fetch("https://api.dialerp.com/api/api/addUpdateTemplateDataWeb",
        {
          method: "POST",
          headers: {
            "Authorization-Token": authToken,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(signUPpara),
          // body: JSON.stringify({
          //   "templateID":"4",
          //   "_1":getmaxstudentid,
          //   "_2":""
          //   ,"_3":name
          //   ,"_4":"","_5":""
          //   ,"_6":email
          //   ,"_7":password
          //   ,"_8":"","_9":"","_10":"","_11":"","_12":"","_13":"","_14":"","_15":"","_16":"","_17":"","_18":""         
          // }),
        }
      ).then((result) => {
        result.json().then((resp) => {

          if (resp.hasErrors === false) {
            alert("Profile update successfully");
            fun_Login();
            //window.location.href = '/Student-admin/Login';
          }
          else {
            alert(resp.errors);
          }
        });
      });
    }
  }
  return (

    <>

      {isLoader && <div id="loadingg">
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#fff"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>}
      <div>

        <Headernew />

      </div>

      <main id="main" className="main">

        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/student-admin/dashboard">Dashboard</a></li>
              <li className="breadcrumb-item active">Profile</li>
            </ol>
          </nav>
        </div>

        <section className="section profile">
          <div className="row">
            <div className="col-xl-4">

              <div className="card">
                <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">

                  <img src={imgurl !== "" ? imgurl : "../admin/img/profile-img.jpg"} alt="Profile" style={{ height: '125px', width: '125px' }} className="rounded-circle img" />
                  <h2> {localStorage.getItem("user-Studentinfo") ? (
                    userStudentinfo?.records[0][5].value
                  ) : (
                    ''
                  )}</h2>
                  {/* <div className="social-links mt-2">
                    <a href="#" className="twitter"><FacebookIcon /></a>
                    <a href="#" className="facebook"><TwitterIcon /></a>
                    <a href="#" className="instagram"><InstagramIcon /></a>
                    <a href="#" className="linkedin"><LinkedInIcon /></a>
                  </div> */}
                </div>
              </div>

            </div>

            <div className="col-xl-8">

              <div className="card">
                <div className="card-body pt-3">

                  <h5><b>Profile Details</b></h5>
                  <div className="row mb-3">
                    <div className="col-md-4 col-lg-4">
                      <label htmlFor="fullName" className="col-form-label">Full Name</label>
                      <input name="fullName" type="text" className="form-control" placeholder="Enter Full Name" id="fullName" value={fullName} onChange={(e) => setfullName(e.target.value)} />
                    </div>

                    <div className="col-md-4 col-lg-4">
                      <label htmlFor="fullName" className="col-form-label">Profile Pic</label>
                      <input type="file" accept=".png,.PNG,.jpeg,.jpg,.JPG,.JPEG" value={selectedFile} onChange={(e) => setselectedfile(e.target.files[0])} />
                      <button style={{ display: "none" }} onClick={onFileUpload()}></button>
                    </div>

                    <div className="col-md-4 col-lg-4">
                      <label htmlFor="Email" className="col-form-label">Email</label>
                      <input name="Email" type="text" className="form-control" id="Email" value={userStudentinfo?.records[0][8].value} disabled />
                    </div>
                  </div>
                  {/* <div className="row mb-3">
                    
                    <div className="col-md-8 col-lg-9">
                      <input type="file" accept=".png,.PNG,.jpeg,.jpg,.JPG,.JPEG" value={selectedFile} onChange={(e) => setselectedfile(e.target.files[0])} />
                      <button style={{ display: "none" }} onClick={onFileUpload()}></button>
                    </div>
                  </div>

                  <div className="row mb-3">
                    
                  </div> */}
                  <div className="row mb-3">

                    <div className="col-md-4 col-lg-4">
                      <label htmlFor="Gender" className=" col-form-label">Gender</label>
                      <select id="gender" className="form-control" value={gender} onChange={(e) => setgender(e.target.value)}>
                        <option value="">Select gender</option>
                        <option value="Male" selected>Male</option>
                        <option value="Female" selected>Female</option>
                      </select>
                      {/* <input name="Gender" type="text" className="form-control" id="Gender" value={gender} onChange={(e) => setgender(e.target.value)}/> */}
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <label htmlFor="dob" className="col-form-label">DOB </label>
                      <input name="dob" type="date" 
                       className={`form-control ${error && dob.trim() === '' || error === 'Enter age must be between 17 and 40.' ? 'is-invalid' : ''}`}
                      id="dob" placeholder="Enter Date Of Birth"
                        value={dob} onChange={(e) => fun_Dobceck(e.target.value)} />
                      {/* <input name="dob" type="date" className="form-control" id="dob" placeholder="Enter Date Of Birth" value={dob} 
                      disabled/> */}
                      {error && dob.trim() === ''
                      && <div className="invalid-feedback">Please select DOB</div>
                      || error === 'Enter age must be between 17 and 40.'
                      && <div className="invalid-feedback">Enter age must be between 17 and 40.</div>}
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <label htmlFor="Mobile" className="col-form-label">Mobile</label>
                      <input name="Mobile" type="number" className="form-control" id="Mobile" placeholder="Enter Mobile" value={mobile} onChange={(e) => setmobile(e.target.value)} />
                    </div>
                  </div>

                  <div className="row mb-3">

                    <div className="col-md-4 col-lg-4">
                      <label htmlFor="AlternateMobile" className="col-form-label">Alternate Mobile Number	</label>
                      <input name="AlternateMobile" type="number" className="form-control" placeholder="Enter Alternate Mobile Number" id="AlternateMobile" value={altmobile} onChange={(e) => setAltmobile(e.target.value)} />
                    </div>
                    <div className="col-md-8 col-lg-8">
                      <label htmlFor="Address" className=" col-form-label">Address</label>
                      <input name="Address" type="text" className="form-control" placeholder="Enter Address" id="Address" value={address} onChange={(e) => setaddress(e.target.value)} />
                    </div>

                  </div>

                  <div className="row mb-3">
                    <div className="col-md-4 col-lg-4">
                      <label htmlFor="City" className=" col-form-label">City</label>
                      <input name="City" type="text" className="form-control" placeholder="Enter City" id="City" value={City} onChange={(e) => setCity(e.target.value)} />
                    </div>

                    <div className="col-md-4 col-lg-4">
                      <label htmlFor="State" className="col-form-label">State</label>
                      <input name="State" type="text" className="form-control" placeholder="Enter State" id="State" value={state} onChange={(e) => setStates(e.target.value)} />
                    </div>

                    <div className="col-md-4 col-lg-4">
                      <label htmlFor="Pincode" className="col-form-label">Pincode</label>
                      <input name="Pincode" type="number" placeholder="Enter Pincode" className="form-control" id="Pincode" value={pincode} onChange={(e) => setPincode(e.target.value)} />
                    </div>
                  </div>
                  <div className="row mb-3">

                  </div>
                  <div className="row mb-3">
                    <div className="col-md-4 col-lg-4">
                      <label htmlFor="Country" className="col-form-label">Country</label>
                      <select id="Country" className="form-control" value={country} onChange={(e) => setcountry(e.target.value)}>
                        <option value="">Select Country</option>
                        <option value="India" selected>India</option>
                      </select>
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <label htmlFor="AadharNumber" className="col-form-label">Height in CM</label>
                      <input name="AadharNumber" placeholder="Enter Height in CM" type="number" className="form-control" id="HeightinCM	" value={HeightinCM} onChange={(e) => SetHeightinCM(e.target.value)} />
                    </div>

                    <div className="col-md-4 col-lg-4">
                      <label htmlFor="NRI" className="col-form-label">Age</label>
                      {/* <input name="NRI" type="number" placeholder="Enter Age" className="form-control" id="Age " value={Age}
                       onChange={(e) => SetAge(e.target.value)} disabled/> */}
                      <input name="NRI" type="number" placeholder="Enter Age" className="form-control" id="Age " value={Age}
                        disabled />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-4 col-lg-4">
                      <label htmlFor="AadharNumber" className="col-form-label">Aadhar Number</label>
                      <input name="AadharNumber" placeholder="Enter Aadhar Number" type="number" className="form-control" id="aadharNumber	" value={aadharNumber} onChange={(e) => setaadharNumber(e.target.value)} />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <label htmlFor="NRI" className="col-form-label">NRI</label>
                      <div className="single-field checkboxesfield">
                        <label htmlFor="nirYes">
                          <input type="radio" id="nirYes" value="Yes" checked={nir === "Yes"} onChange={(e) => setnir(e.target.value)} />
                          Yes
                        </label>
                        <label htmlFor="nirNo">
                          <input type="radio" id="nirNo" value="No" checked={nir === "No"} onChange={(e) => setnir(e.target.value)} />
                          No
                        </label>
                      </div>
                      {/* <input name="NRI" type="number" className="form-control" id="NRI " value={pincode} onChange={(e) => setPincode(e.target.value)} /> */}
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <label htmlFor="Nationality" className="col-form-label">ShoeSize</label>
                      <input name="Nationality" type="text" placeholder="Enter ShoeSize" className="form-control" id="ShoeSize" value={ShoeSize} onChange={(e) => SetShoeSize(e.target.value)} />
                    </div>
                  </div>
                  <div className="row mb-3">

                    <div className="col-md-4 col-lg-4">
                      <label htmlFor="Nationality" className="col-form-label">BMI</label>
                      <input name="Nationality" type="text" placeholder="Enter BMI" className="form-control" id="BMI" value={BMI} onChange={(e) => SetBMI(e.target.value)} />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <label htmlFor="Nationality" className="col-form-label">Weight in KG</label>
                      <input name="Nationality" type="text" placeholder="Enter WeightinKG" className="form-control" id="WeightinKG" value={WeightinKG} onChange={(e) => SetWeightinKG(e.target.value)} />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <label htmlFor="Nationality" className="col-form-label">Nationality	</label>
                      <input name="Nationality" type="text" placeholder="Enter Nationality" className="form-control" id="Nationality" value={nationality} onChange={(e) => setnationality(e.target.value)} />
                    </div>
                  </div>
                  <div className="row mb-3">

                    <div className="col-md-4 col-lg-4">
                      <label htmlFor="FatherName" className="col-form-label">Father's Name</label>
                      <input name="FatherName" placeholder="Enter Father's Name" type="text" className="form-control" id="FatherName" value={fathersName} onChange={(e) => setfathersName(e.target.value)} />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <label for="NextofKin" className="col-form-label">Next of Kin</label>
                      <div >
                        <select name="cars" id="cars" value={NextofKin} onChange={(e) => { SetNextofKin(e.target?.value); }} className={`form-control`}>
                          <option value="">Next of Kin</option>
                          <option value="Mother">Mother</option>
                          <option value="Father">Father</option>
                          <option value="Sister">Sister</option>
                          <option value="Brother">Brother</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <label htmlFor="Nationality" className="col-form-label">Special Qualification/Hobbies</label>
                      <input name="Nationality" type="text" placeholder="Enter Special Qualification / Hobbies" className="form-control" id="Nationality" value={AnySpecialQualificationOrHobbies} onChange={(e) => SetAnySpecialQualificationOrHobbies(e.target.value)} />
                    </div>
                  </div>
                  <div className="row mb-3">

                    <div className="col-md-4 col-lg-4">
                      <label htmlFor="FatherName" className="col-form-label">Qualification</label>
                      <input name="FatherName" placeholder="Enter  Academic Qualification" type="text" className="form-control" id="Qualification" value={AcademicQualification} onChange={(e) => SetAcademicQualification(e.target.value)} />
                    </div>
                    <div className="col-md-8 col-lg-8">
                      <label htmlFor="FatherName" className="col-form-label">Next of Kin With Address & Telephone</label>
                      <input name="FatherName" placeholder="Enter NextofKinWithAddressTelephone" type="text" className="form-control" id="NextofKinWithAddressTelephone" value={NextofKinWithAddressTelephone} onChange={(e) => SetNextofKinWithAddressTelephone(e.target.value)} />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label htmlFor="btn" className="col-form-label"></label>
                    <div className="col-md-8 col-lg-9">
                      <button className="buttons btnupdate" onClick={fun_signUP}>Update</button>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </section>

      </main>
    </>


  );
}

export default Myprofile;
