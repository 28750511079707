import React, { useEffect, useState } from "react";
// import { Link } from 'react-router-dom';
import '../Common/custom-style.css';
import '/node_modules/bootstrap/dist/css/bootstrap.min.css';
import '/node_modules/bootstrap/dist/js/bootstrap.min.js';
import Button from '@mui/material/Button';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Box from '@mui/material/Box';
import Headersidebar from '../Common/Headersidebar';
import moment from 'moment'
import { ThreeDots } from 'react-loader-spinner'
import { Link } from 'react-router-dom';
const drawerWidth = 240;

function StudentDetails() {
  localStorage.removeItem("bookinginfo")
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  const booking = localStorage.getItem('StudentDetailinfo');
  if (booking === null) {
    window.location.href = '/';
  }
  const [sprofile, setsprofile] = useState("");
  const [fullName, setfullName] = useState("");
  const [gender, setgender] = useState("");
  const [dob, setDOB] = useState("");
  const [mobile, setmobile] = useState("");
  const [address, setaddress] = useState("");
  const [City, setCity] = useState("");
  const [state, setStates] = useState("");
  const [pincode, setPincode] = useState("");
  const [isLoader, setIsLoader] = useState(true);
  function fun_Login() {
    const loginpra = { "NumberOfFieldsView": 50, "filters": { "Email": booking }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 4 }
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(loginpra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          if (resp.response?.records.length > 0) {
            setsprofile(resp.response?.records[0][4].value)
            setfullName(resp.response?.records[0][5].value);
            setgender(resp.response?.records[0][10].value);
            setmobile(resp.response?.records[0][6].value);
            setaddress(resp.response?.records[0][12].value);
            setCity(resp.response?.records[0][13].value);
            setStates(resp.response?.records[0][14].value);
            setPincode(resp.response?.records[0][15].value);
            setDOB(resp.response.records[0][11].value);
            setIsLoader(false);
          }
        }
        else {
          setIsLoader(false);
          window.location.href = 'student-admin/login';
        }
      });
    });
    setIsLoader(false);
  }
  const [productdata, setProductData] = useState([]);
  const Bookinglistpra = { "NumberOfFieldsView": 50, "filters": { "Email": booking }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 5 };
  function handleId() {
    setIsLoader(true);
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(Bookinglistpra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          setProductData(resp);
        }
        else {
          alert(resp.errors);
        }
        setIsLoader(false);
      });
    });
  }
  const upcomingisDateGreaterThanTodaynewArrayList = [];
  const currentDate = new Date(); // Get the current date
  //upcoming
  productdata?.response?.records?.forEach(obj => {
    const comparisonFromDate = new Date(obj[21].value);
    if (comparisonFromDate > currentDate) {
      upcomingisDateGreaterThanTodaynewArrayList.push({ ...obj });
    }
  });
  //complete
  const completeisDateLessThanTodaynewArrayList = [];
  productdata?.response?.records?.forEach(obj => {
    const comparisonToDate = new Date(obj[22].value);
    if (comparisonToDate < currentDate) {
      completeisDateLessThanTodaynewArrayList.push({ ...obj });
    }
  });
  //Running
  const RunningisDateLessThanTodaynewArrayList = [];
  productdata?.response?.records?.forEach(obj => {
    const comparisonFromDate = new Date(obj[21].value);
    const comparisonToDate = new Date(obj[22].value);
    if (currentDate >= comparisonFromDate && comparisonToDate >= currentDate) {
      RunningisDateLessThanTodaynewArrayList.push({ ...obj });
    }
  });

  function fun_getbookingidonclick(id) {
    localStorage.setItem("bookinginfo", id)
  }
  useEffect(() => {
    fun_Login();
    handleId();
  }, [booking]);
  return (
    <>
      {isLoader &&
        <div id="loadingg">
          <ThreeDots height="80" width="80" radius="9" color="#fff" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      }
      <Box sx={{ display: 'flex' }} className="custcontent">
        <div>
          <Headersidebar />
        </div>
        <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }} >
          <main id="main" className="main">

            <div className="pagetitle">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/nimas-admin/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item active">Student Profile</li>
                </ol>
              </nav>
            </div>

            <section className="section profile">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-body pt-3">
                      <h5 className="card-title">Student Profile Details</h5>
                      <div className="tabs">
                        <input type="radio" name="tabs" id="tabone" checked="checked" />
                        <label  htmlFor="tabone" className="tablabel">Personal Details</label>
                        <div className="tab">

                          <div className="card" style={{ marginBottom: '20px' }}>
                            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                              <img src={sprofile !== "" ? sprofile : "../admin/img/profileicon.png"} alt="Profile" style={{ height: '125px', width: '125px' }} className="rounded-circle img" />
                              <h2>{fullName}</h2>
                            </div>
                          </div>
                          <form>
                            {/* <div className="row mb-3">
                              <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">Full Name</label>
                              <div className="col-md-8 col-lg-9">
                                <input name="fullName" type="text" className="form-control" id="fullName" value={fullName} disabled />
                              </div>
                            </div> */}
                            <div className="row mb-3">
                              <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">Gender</label>
                              <div className="col-md-8 col-lg-9">
                                <input name="fullName" type="text" className="form-control" id="fullName" value={gender} disabled />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">DOB</label>
                              <div className="col-md-8 col-lg-9">
                                <input name="fullName" type="text" className="form-control" id="fullName" value={dob} disabled />
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">Mobile</label>
                              <div className="col-md-8 col-lg-9">
                                <input name="fullName" type="text" className="form-control" id="fullName" value={mobile} disabled />
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">Address</label>
                              <div className="col-md-8 col-lg-9">
                                <input name="fullName" type="text" className="form-control" id="fullName" value={address} />
                              </div>
                            </div>


                            <div className="row mb-3">
                              <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">City</label>
                              <div className="col-md-8 col-lg-9">
                                <input name="fullName" type="text" className="form-control" id="fullName" value={City} disabled />
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">State</label>
                              <div className="col-md-8 col-lg-9">
                                <input name="fullName" type="text" className="form-control" id="fullName" value={state} />
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">Pincode</label>
                              <div className="col-md-8 col-lg-9">
                                <input name="fullName" type="text" className="form-control" id="fullName" value={pincode} disabled />
                              </div>
                            </div>
                          </form>
                        </div>

                        <input type="radio" name="tabs" id="tabtwo" />
                        <label htmlFor="tabtwo" className="tablabel">Booking Details</label>
                        <div className="tab">
                          <div className="card-body pt-3">

                            <ul className="nav nav-tabs nav-tabs-bordered">

                              <li className="nav-item">
                                <Button className="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview">Upcoming</Button>
                              </li>

                              <li className="nav-item">
                                <Button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit"> Completed</Button>
                              </li>

                              <li className="nav-item">
                                <Button className="nav-link"  data-bs-toggle="tab" data-bs-target="#running"> Running</Button>
                              </li>

                            </ul>
                            <div className="tab-content pt-2 booking">
                              <div className="tab-pane fade show active profile-overview pt-3" id="profile-overview">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="tableresp">
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th scope="col">Sr.</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Booking Id</th>
                                            <th scope="col">Student Name</th>
                                            <th scope="col">Serial No</th>
                                            {/* <th scope="col">No of Seats</th> */}
                                            <th scope="col">Adventure Name</th>
                                            <th scope="col">Course Dates</th>
                                            <th scope="col">Transaction Id</th>
                                            <th scope="col">Payment Status</th>
                                            <th scope="col">Cancel Reason</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {upcomingisDateGreaterThanTodaynewArrayList?.filter((dl) => dl[26].value).map((upcoming, i) => {
                                            i++;
                                            return (
                                              <tr>
                                                <th scope="row">{i}</th>
                                                <td>{moment(upcoming[1].value).format("DD-MMM-YYYY hh:mm:ss")}</td>
                                                <td><Link to="/student-admin/invoicenew" onClick={() => fun_getbookingidonclick(upcoming[3].value)}>{upcoming[3].value}</Link></td>
                                                <td>{upcoming[24].value}</td>
                                                <td>{upcoming[6].value}</td>
                                                {/* <td>{upcoming[8].value}</td> */}
                                                <td>{upcoming[4].value} <br />{upcoming[5].value}</td>
                                                <td>{upcoming[21].value} <br/>/ {upcoming[22].value}</td>
                                                <td> {upcoming[26].value}</td>
                                                <td><span className={`datee  ${upcoming[19].value === 'Confirmed' ? 'text-green' :
                                                  upcoming[19].value === 'In-Review' ? 'text-blue' :
                                                    upcoming[19].value === 'Payment Upload' ? 'text-blue' :
                                                      upcoming[19].value === 'Failed' ? 'text-red' :
                                                        upcoming[19].value === 'Cancel' ? 'text-red' : ''
                                                  }`
                                                }>{upcoming[19].value}
                                                </span><br />
                                                  <span className={`datee11 ${upcoming[27].value === '' || upcoming[19].value === "Payment Upload" ? 'text-blue' : ''}`}>
                                                    {upcoming[19].value === "Payment Upload" ? "/ Document Pending" : null}
                                                  </span>
                                                </td>
                                                <td>{upcoming[28].value}</td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>

                                </div>

                              </div>

                              <div className="tab-pane fade profile-edit pt-3 booking" id="profile-edit">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="tableresp">
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th scope="col">Sr.</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Booking Id</th>
                                            <th scope="col">Student Name</th>
                                            <th scope="col">Serial No</th>
                                            {/* <th scope="col">No of Seats</th> */}
                                            <th scope="col">Adventure Name</th>
                                            <th scope="col">Course Dates</th>
                                            <th scope="col">Transaction Id</th>
                                            <th scope="col">Payment Status</th>
                                            <th scope="col">Cancel Reason</th>
                                          </tr>
                                        </thead>
                                        <tbody>

                                          {completeisDateLessThanTodaynewArrayList?.map((complete, i) => {
                                            i++;
                                            return (
                                              <tr>
                                                <th scope="row">{i}</th>
                                                <td>{moment(complete[1].value).format("DD-MMM-YYYY hh:mm:ss")}</td>
                                                <td><Link to="/student-admin/invoicenew" onClick={() => fun_getbookingidonclick(complete[3].value)}>{complete[3].value}</Link></td>
                                                <td>{complete[24].value}</td>
                                                <td>{complete[6].value}</td>
                                                {/* <td>{complete[8].value}</td> */}
                                                <td>{complete[4].value} <br />{complete[5].value}</td>
                                                <td>{complete[21].value} <br/>/ {complete[22].value}</td>
                                                <td> {complete[26].value}</td>
                                                <td><span className={`datee ${complete[19].value === 'Confirmed' ? 'text-green' :
                                                  complete[19].value === 'In-Review' ? 'text-blue' :
                                                    complete[19].value === 'Payment Upload' ? 'text-blue' :
                                                      complete[19].value === 'Failed' ? 'text-red' :
                                                        complete[19].value === 'Cancel' ? 'text-red' : ''
                                                  }`
                                                }>{complete[19].value}</span><br />
                                                  <span className={`datee11 ${complete[27].value === '' || complete[19].value === "Payment Upload" ? 'text-blue' : ''}`}>
                                                    {complete[19].value === "Payment Upload" ? "/ Document Pending" : null}</span>
                                                </td>
                                                <td>{complete[28].value}</td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="tab-pane fade profile-edit pt-3 booking" id="running">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="tableresp">
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th scope="col">Sr.</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Booking Id</th>
                                            <th scope="col">Student Name</th>
                                            <th scope="col">Serial No</th>
                                            {/* <th scope="col">No of Seats</th> */}
                                            <th scope="col">Adventure Name</th>
                                            <th scope="col">Course Dates</th>
                                            <th scope="col">Transaction Id</th>
                                            <th scope="col">Payment Status</th>
                                            <th scope="col">Cancel Reason</th>
                                          </tr>
                                        </thead>
                                        <tbody> 
                                          {RunningisDateLessThanTodaynewArrayList?.map((Running, i) => {
                                            i++;
                                            return (
                                              <tr>
                                                <th scope="row">{i}</th>
                                                <td>{moment(Running[1].value).format("DD-MMM-YYYY hh:mm:ss")}</td>
                                                <td><Link to="/student-admin/invoicenew" onClick={() => fun_getbookingidonclick(Running[3].value)}>{Running[3].value}</Link></td>
                                                <td>{Running[24].value}</td>
                                                <td>{Running[6].value}</td>
                                                {/* <td>{Running[8].value}</td> */}
                                                <td>{Running[4].value} <br />{Running[5].value}</td>
                                                <td>{Running[21].value} <br/>/ {Running[22].value}</td>
                                                <td> {Running[26].value}</td>
                                                <td><span className={`datee ${Running[19].value === 'Confirmed' ? 'text-green' :
                                                  Running[19].value === 'In-Review' ? 'text-blue' :
                                                    Running[19].value === 'Payment Upload' ? 'text-blue' :
                                                      Running[19].value === 'Failed' ? 'text-red' :
                                                        Running[19].value === 'Cancel' ? 'text-red' : ''
                                                  }`
                                                }>{Running[19].value}</span><br />
                                                  <span className={`datee11 ${Running[27].value === '' || Running[19].value === "Payment Upload" ? 'text-blue' : ''}`}>
                                                    {Running[19].value === "Payment Upload" ? "/ Document Pending" : null}</span>
                                                </td>
                                                <td>{Running[28].value}</td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </Box>
      </Box>
    </>
  );
}

export default StudentDetails;
