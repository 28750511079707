import { useEffect, useState } from "react";
import React from "react";
import { Link } from 'react-router-dom';
import '../Common/custom-style.css';
import '/node_modules/bootstrap/dist/css/bootstrap.min.css';
import '/node_modules/bootstrap/dist/js/bootstrap.min.js';
import Headersidebar from '../Common/Headersidebar';
import Box from '@mui/material/Box';
import moment from 'moment'
import { ThreeDots } from 'react-loader-spinner'

const drawerWidth = 240;
function Confirmbooking() {
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  const [productdata, setProductData] = useState([]);
  const [ReceiptListdata, SetReceiptListdata] = useState('');
  const Bookinglistpra = { "NumberOfFieldsView": 50, "filters": { "Booking Status": 'Confirmed' }, "isDownload": false, "index": 1, "pgSize": 1000, "templateID": 5 };
  const [isLoader, setIsLoader] = useState(true);

  function handleId() {
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(Bookinglistpra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          setProductData(resp);
          //fun_ReceiptListdata();
          setIsLoader(false);
        }
        else {
          alert(resp.errors);
          setIsLoader(false);
        }
      });
      setIsLoader(false);
    });
  }
  function fun_ReceiptListdata(id) {
    setIsLoader(true);
    const ReceiptIdPra = { "NumberOfFieldsView": 50, "filters": {}, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 6 }
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(ReceiptIdPra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          if (resp.response?.records.length > 0) {
            SetReceiptListdata(resp);
            setIsLoader(false);
          }
        }
        else {
          alert(resp.errors);
          setIsLoader(false);
        }
        setIsLoader(false);
      });
    });
  }
  useEffect(() => {
    handleId();
  }, [0])
  function fun_getbookingidonclick(id) {
    localStorage.setItem("bookinginfo", id)
  }
  function fun_getStudentDetailclick(id) {
    localStorage.setItem("StudentDetailinfo", id)
  }
  return (
    <>
      {isLoader && <div id="loadingg">
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#fff"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>}
      <Box sx={{ display: 'flex' }} className="custcontent">
        <div>
          <Headersidebar />
        </div>
        <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }} >
          <main id="main" className="main">
            <div className="pagetitle">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/nimas-admin/dashboard">Home</Link></li>
                  <li className="breadcrumb-item active">Confirm Bookings</li>
                </ol>
              </nav>
            </div>
            <div className="bookingss">
              <section className="section profile">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div className="col-md-6">
                            <h5 className="card-title">Confirm Bookings </h5>
                          </div>
                          <div className="col-md-6 text-end">
                            <label>Total Records: <b>{productdata?.response?.totalRecords}</b></label>
                          </div>
                        </div>

                        <div className="tableresp">
                          <table>
                            <thead>
                              <tr>
                                <th scope="col">Sr.</th>
                                <th scope="col">Date</th>
                                <th scope="col">Booking Id</th>
                                <th scope="col">Student Name</th>
                                <th scope="col">Serial No</th>
                                {/* <th scope="col">No of Seats</th> */}
                                <th scope="col">Adventure Name</th>
                                <th scope="col">Course Dates</th>
                                <th scope="col">Transaction Id</th>
                                <th scope="col">Payment Status</th>
                                {/* <th scope="col">Action</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {productdata?.response?.records?.map((studinfo, i) => {
                                i++;
                                return (
                                  <tr>
                                    <th scope="row">{i}</th>
                                    <td>{moment(studinfo[1].value).format("DD-MMM-YYYY hh:mm:ss")}</td>
                                    <td><Link to="/student-admin/invoicenew" onClick={() => fun_getbookingidonclick(studinfo[3].value)}>{studinfo[3].value}</Link></td>
                                    <td><Link to="/nimas-admin/student-info" onClick={() => fun_getStudentDetailclick(studinfo[25].value)}>{studinfo[24].value}</Link></td>
                                    <td>{studinfo[6].value}</td>
                                    {/* <td>{studinfo[8].value}</td> */}
                                    <td>{studinfo[4].value}<br />({studinfo[5].value})</td>
                                    <td>{studinfo[21].value}<br />/{studinfo[22].value}</td>
                                    <td>{studinfo[26].value}</td>
                                    <td><span
                                      className={`datee 
                             ${studinfo[19].value === 'Confirmed' ? 'text-green' :
                                          studinfo[19].value === 'In-Review' ? 'text-blue' :
                                            studinfo[19].value === 'Payment Upload' ? 'text-blue' :
                                              studinfo[19].value === 'Failed' ? 'text-red' :
                                                studinfo[19].value === 'Cancel' ? 'text-red' : ''
                                        }`
                                      }>{studinfo[19].value}</span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

          </main>
        </Box>
      </Box>
    </>


  );
}

export default Confirmbooking;
