import React, { useEffect, useState } from "react";
import '../Common/custom-style.css';
import { Link } from 'react-router-dom';
function NimasLogin() {
  localStorage.clear();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  // const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState('');
  const productdata2 = { "NumberOfFieldsView": 50, "filters": { "Email": username, "Password": password }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 7 }
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  function fun_Login() {
    if (username.trim() === "") {
      setError('Please enter username');
      return;
    }
    else if (!emailRegex.test(username.trim())) {
      setError('Please enter a valid email address');
      return;
    }
    else if (password.trim() === "") {
      setError('Please enter password');
      return;
    }
    else {
      fetch(
        "https://api.dialerp.com/api/api/getTemplateDataList",
        {
          method: "POST",
          headers: {
            "Authorization-Token": authToken,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(productdata2),
        }
      ).then((result) => {
        result.json().then((resp) => {
          debugger
          if (resp.response?.records.length > 0) {
            localStorage.setItem("user-admininfo", JSON.stringify(resp.response));
            window.location.href = '/nimas-admin/dashboard';
          }
          else {
            alert('Invalid username or password');
          }
        });
      });
    }
  }
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
  }, []);


  return (
    <div className="login logins">

      <div className="login-box">
      <a href="/">  <img src="../admin/img/nimas_logo.png" className="bylogo" /></a>
        <h2 mat-h2>Continue to Login</h2>
        <div class="card mb-3">

          <div class="card-body">

            <div class="col-12">
              <div>
                <label for="yourUsername" class="form-label">login Id (Email)<span class="required">*</span></label>
                <div class="input-group">
                  {/* Username input */}
                  <input type="text" name="username" placeholder="Enter login"
                    className={`form-control ${error && (username.trim() === '' || !emailRegex.test(username.trim())) ? 'is-invalid' : ''}`}
                    id="yourUsername" value={username} onChange={(e) => { setUsername(e.target.value); setError(''); }} />
                  {error && username.trim() === '' && <div className="invalid-feedback">Please enter username</div>}
                  {error && username.trim() !== '' && !emailRegex.test(username.trim()) && <div className="invalid-feedback">Please enter a valid email address</div>}
                </div>
              </div>

              <div>
                <label for="yourPassword" class="form-label">Password<span class="required">*</span></label>
                <div class="input-group">
                  {/* Password input */}
                  <input
                    type="password" name="password" placeholder="Enter password"
                    className={`form-control ${error && password.trim() === '' ? 'is-invalid' : ''}`}
                    id="yourPassword" value={password} onChange={(e) => { setPassword(e.target.value); setError(''); }} />
                  {error && password.trim() === '' && <div className="invalid-feedback">Please enter password</div>}
                </div>

              </div>
            </div>
            <div class="col-12">
              <button class="buttons" onClick={fun_Login}>Login</button>
            </div>
            {/* <div class="col-12">
              <p class="small mb-0 text-center terms">Don't have account?  <Link to="/nimas-admin/register">Create an account</Link></p>
            </div> */}

          </div>
        </div>
        {/* <small className="glogin">or Login with</small>
        <div className="google"><img src="../admin/img/google.png" alt="" className="bylogo" /></div> */}
      </div>

    </div>
  );
}

export default NimasLogin;
