import React, {useEffect, useState } from "react";
import Header from '../Common/Header';
import Footer from '../Common/Footer';


function PrivacyPolicy() {


  return (
        <>
          <Header />
          <div className="wrapper">
          <div className="bradcumb-area style-1 overlay-bg-2">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col">
						
							<div className="bradcumb text-center">
								<h3>Privacy Policy</h3>
							</div>
						
						</div>
					</div>
				</div>
			</div>

          </div>

          <div className="about-adventure-area pt-110 pb-200 privacy">
				<div className="container">
					
					<div className="row">
						<div className="col-lg-12">
                        <p>
                            By using our website www.nimasdirang.com (hereinafter referred as "website")
                            you consent to abide by our Privacy Policy set out herein. Your privacy is important to us. We value your trust in us. We will work hard to earn your confidence so that you can enthusiastically use our services and recommend us to friends and relatives. To better protect your privacy, we promise that we will take steps to use your personal information only in ways that are compatible with this Privacy Policy. This Privacy Policy explains how we use and safeguard your information.
                            <br/>
                            <br/>

                            When you use our Website, we may collect and store your personal information from you. Our goal is providing an ultimate knowledge in the field of mountaineering, Aqua Adventure activities, Aero Adventure activities by which your entry and exit will be safe, efficient, and smooth. We only collect personal information about you that we consider necessary for achieving this purpose.
                        </p>
                        <p>
                            <strong>
                                1. Your Information
                            </strong>
                        </p>
                        <p>
                            To get our course admission / use our website you will need to register using our online
                            registration form, where you may be required to provide us with your personal information including but not limited to your name, date of birth, contact details, e-mail lD, user lD, password, residence, bank details and other personal information as indicated on the form throughout the Website. Where possible, we indicate which fields are mandatory and which fields-are optional. We may also collect your information through the use of cookies or from our affiliates or from other parties or from other means.
                        </p>
                        <p><strong> 2. Use of Your Information</strong></p>
                        <p>
                            (a) Website does not have setup to collect automatic personal information of the visitors
                            to the Website. In some areas of the Website, we may ask you to provide information that will enables us to verify whether you are entitled to access and use certain Services or to follow up with you after your visit to Website or to otherwise support your relationship with us. It is completely optional for you to join. However, failure to join us may mean that you cannot access and use information and Services.
                            <br/>
                            <br/>
                            (b) The personal information you provided to us while using website, such as your
                            name, date of birth, contact details, e-mail lD, user lD, password, residence, bank details and other personal information shall be kept confidential and shall be used to provide you Services from Website, to notify you updated information, new courses and offers of expeditions from third parties that we think may be of interest to you. Your information may be provided to our employees, agents, contractors, affiliates and service providers in need to know basis to support the operation of the Website and Services. Employees, agents, contractors, affiliates and service providers so provided with your personal information will be required to keep your information confidential and not use it for any purpose other than to carry out the Services they are performing for Website.
                            <br/>
                            <br/>
                            (c). By use of our Website you agree to provide us, right to use your personal
                            information for courses and services. To fulfill such purposes we may share your certain
                            information with other mountaineering institute in India of which we are a part.
                            <br/>
                            <br/>
                            (d) We will send your Information about our various expedition, news letter and courses
                            which may be of interest to you if only you subscribe for this. Later this can be discontinued ,if you feel in not suitable to you or you not interested in the field of mountaineering.
                        </p>


                        <p>
                            <strong>3. Disclosure of Information</strong>
                        </p>
                        <p>
                            (a) You allow to disclose your personal information, if disclosure is required by law or
                            it is related to judicial or governmental investigation or proceedings.
                            <br/>
                            <br/>

                            (b) If We have reason to believe that a User of Website is in breach of the Terms of
                            Use, TOS or any other agreement with us, then we reserve the right to make public or
                            otherwise disclose such User's information in order to pursue our claim or prevent further injury to us.
                        </p>
                        <p>
                            <strong>4. Security </strong>

                            (a) With respect to security: We transfer and receive all information, including sensitive financial information, over a secure, encrypted connection. We have appropriate Security measures in place in our facilities to protect against the loss, misuse, or alteration of information that we collect via our site.
                            <br/><br/>

                            (b) No information will be verbally nor written be handed to unauthorized person which can be breached to him.

                            <strong>5. Data Security</strong>
                            To prevent unauthorized access, maintain data accuracy and ensure the correct use
                            of information, we have put in place appropriate physical, electronic. and managerial
                            procedures to safeguard and secure the information we collect online However, we shall
                            not be liable for the use of your personal information. No data transmitted over the Internet or any wireless network can be guaranteed to be perfectly secured. As a result, while we try to protect your information no website or company including ourselves can absolutely ensure or guarantee the security of any information you transmit to us and you do so at your own risk

                            <strong>6. Use of Third Party Websites</strong>
                            We have established relationship with other parties to offer you benefit of other courses and services which we ourselves do not offer. We offer you access to these other parties through their websites links from our website. You may be required to submit
                            information for purposes of registering or applying for courses or services provided by such third parties. The privacy policy of such third parties may differ from ours, and we may not have any control over the information that you submit to such third party website. We disclaim all the liabilities of confidentiality for the information you provided to the third party through our website. We therefore encourage you to read their policy before responding to any offers, products or services provided by such third parties.

                            <strong>Cookies</strong>
                            (a) Cookies are small amounts of data that is sent to your browser and stored on your
                            computer's hard drive. A cookie can be sent to your computer's hard drive only if you
                            access our Website. You can choose to accept or refuse the cookies by changing your
                            browser setting. We use cookies to store specific information about you and track your
                            visits to our Website. It is common for websites to use cookies to enhance identification of their users. lf you do not de-activate or erase cookies, each time you use the same
                            computer to access our Website our web server will be notified of your visits to our Website and in turn we may have knowledge of your visit and the pattern of your usage of our Website.
                            <br/><br/>
                            (b) Use of cookies makes web surfing easier by performing certain functions such as
                            saving your passwords, your personal preferences regarding your use of the Website and to make sure you do not see the same repeatedly.
                            <br/><br/>
                            (c) lf you set refuse cookies in your browser, it is possible that some areas of our
                            Website will not function properly. During the use of our Website you may be required to
                            accept cookies. Advertisers and partners may also use cookies. We do not control use of these cookies and disclaims responsibilities for information collect through them.
                            <br/>
                            <br/>

                            <strong>8. Minor</strong>

                            We do not intend to provide courses to children under 14 years of age Use of our Website is available only to the persons who can enter into legally binding contracts under the laws of India. lf you are a minor i.e. under age of eighteen (18) years, you are allowed to visit our Website, seek all information but registration has to be done through a parent for the courses. Separate indemnity bond needs to be in signed and posted to Institute. lf a minor has provided information without parental or guardian consent, the parent or guardian should contact us to remove the information.

                            <strong>9. Website Registration</strong>
                            To provide a better Site experience, we may ask for limited Personal Information for such uses as correspondence, Site registration, booking requests, program
                            descriptions, surveys, and/or contests.

                            <strong>10. Program Registration</strong>

                            For information about registering see book now process and confirmation and cancellation policies.
                           

                            <strong>11. IP Address Collection</strong>

                            NIMAS collects IP address information used to access our site only if we find visitor trying to hack or stealing information of other visitors. This information may later transferred to cyber cell for further process. No personal identify information is linked to IP addresses.

                            <strong>12. Changes to Privacy Policy</strong>
                            We reserve all rights to change, amend or modify this Privacy Policy at any time and in any manner without pre-information to the users. The changed, amended or modified Privacy Policy will be displayed in the Website. You agree that all collected information (whether or not collected prior to or after the new Policy become effective) will be governed by the newest Privacy Policy then in effect. Accessing, Correcting and Changing Your Information.

                            <strong>13. Blog / News letter</strong>

                            We respect your view. We think this will help us to improve more in the field of adventure which we are bound to. You can access, view and edit your information through the edit information option in your Account with website. This information provided should not be in personnel to some nor should defame country, Institute or any individual. The words used to be in most respectful manner. Posting of unlawful pictures and word will make the informer legible to punishments abided by the law of Indian Government.

                            <strong>14. Contact Us</strong>

                            Feel free to give your Inputs regarding our Privacy Policy or our Services provided to you. lf you would like to contact us for any reasons please e-mail us at info@nimasdirang.com.<br/>
                            You can also send your comments and responses by post using the below mentioned<br/>

                            Address:<br/>

                            National Institute of Mountaineering and Adventure Sports (NIMAS)<br/>

                            Dirang, West Kameng (Distt)<br/>

                            Arunachal Pradesh - 790101<br/>


                            or<br/>



                            NIMAS<br/>

                            c/o 99 APO<br/>

                            900194
                            <strong>WHAT INFORMATION DO WE COLLECT?</strong>

                            We collect information from you when you register on our site subscribe to our newsletter, respond to a survey or fill out a form.
                            <br/>
                            <br/>

                            When registering on our site, as appropriate, you may be asked to enter your: name or email-address. You may, however, visit our site anonymously.
                            <br/>
                            <br/>

                            Google, as a third party vendor, uses cookies to serve ads on this site. Google's use of the DART cookie enables it to serve ads to your users based on their visit to your sites and other sites on the Internet. Users may opt out of the use of the DART cookie by visiting the Google ad and content network privacy policy.
                        </p>
                        <p><strong>
                                (Col Ranveer Singh Jamwal SM VSM**)<br/>
                                Col
                                Principal
                                NIMAS
                            </strong>
                        </p>

						</div>
					</div>
					
				</div>
			</div>
          <Footer />
              
        </>
  );
}

export default PrivacyPolicy;
