import { useEffect, useState } from "react";
import React from "react";
import '../Common/custom-style.css';
import '/node_modules/bootstrap/dist/css/bootstrap.min.css';
import '/node_modules/bootstrap/dist/js/bootstrap.min.js';
import moment from 'moment'
import Headernew from '../Common/Headernew';
import Headersidebar from '../Common/Headersidebar';
import axios from 'axios';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import PrintIcon from '@mui/icons-material/Print';
import { ThreeDots } from 'react-loader-spinner'


function Bookingdetails() {
  const currentDate = new Date(); // Get the current date
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  //const bookingInfo = JSON.parse(localStorage.getItem("Booking-Info"));
  const userStudentinfo = JSON.parse(localStorage.getItem("user-Studentinfo"));
  const adminlogininfo = JSON.parse(localStorage.getItem("user-admininfo"));
  if (userStudentinfo === null && adminlogininfo === null) {
    window.location.href = '/';
  }
  const booking = localStorage.getItem('bookinginfo');
  if (booking === null) {
    window.location.href = '/';
  }
  const [selectedFile, setselectedfile] = useState(null);
  const [B_id, SetBId] = useState();
  const [id, SetId] = useState();
  const [todate, SetTodate] = useState();
  const [fromdate, Setfromdate] = useState();
  const [bookingdate, Setbookingdate] = useState();
  const [idbookingstatus, Setstatus] = useState();
  const [age, Setage] = useState();
  const [seat, Setseat] = useState();
  const [coursename, Setcourse] = useState();
  const [category, setcategory] = useState();
  const [imgurl, setimgurl] = useState("");
  const [aadharimg, setaadharimg] = useState("");
  const [serialno, setSerialNo] = useState("");
  const [isLoader, setIsLoader] = useState(true);
  const [upcoming, setupcoming] = useState(false);
  const [complete, setcomplete] = useState(false);
  const [Running, setRunning] = useState(false);
  const [BookingListData, setBookingListData] = useState(false);
  function BooKingData() {
    setIsLoader(true);
    if (booking === null) {
      window.location.href = "/student-admin/thankyou";
    }
    const Bookinglistpra = { "NumberOfFieldsView": 50, "filters": { "BookingID": booking }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 5 };
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(Bookinglistpra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        //setBookingData(resp.response);
        if (resp.hasErrors === false) {
          if (resp.response?.records.length > 0) {
            setBookingListData(resp.response?.records[0]);
            SetBId(resp.response?.records[0][0]?.value);
            SetId(resp.response?.records[0][3]?.value);
            SetTodate(resp.response?.records[0][22]?.value);
            Setfromdate(resp.response?.records[0][21]?.value);
            Setbookingdate(resp.response?.records[0][2]?.value);
            Setage(resp.response?.records[0][23]?.value);
            Setstatus(resp.response?.records[0][19]?.value);
            Setseat(resp.response?.records[0][8]?.value);
           // Setbookingdate(resp.response?.records[0][1]?.value);
            Setcourse(resp.response?.records[0][5]?.value);
            setcategory(resp.response?.records[0][4]?.value);
            setSerialNo(resp.response?.records[0][6]?.value);
            setimgurl(resp.response?.records[0][27]?.value);
            debugger
            setaadharimg(resp.response?.records[0][29]?.value);
            const comparisonToDate = new Date(resp.response?.records[0][22]?.value);
            const comparisonFromDate = new Date(resp.response?.records[0][21]?.value);
            if (comparisonFromDate > currentDate) {
              setupcoming(true);
            }
            if (comparisonToDate < currentDate) {
              setcomplete(true);
            }
            if (currentDate >= comparisonFromDate && comparisonToDate >= currentDate) {
              setRunning(true);
            }
            fun_ReceiptListdata(resp.response?.records[0][3]?.value);
            CourseList(resp.response?.records[0][4]?.value, resp.response?.records[0][5]?.value, resp.response?.records[0][6]?.value);
            fun_getstudentinfo(resp.response?.records[0][25]?.value);

          }
          else {
            alert("data not found!!")
            window.location.href = '/';
          }
        }
        else {
          window.location.href = '/';
        }
      });
    });
    setIsLoader(false);
  }
  function CourseList(cName, cType, serialno) {
    const Courselistpra = { "NumberOfFieldsView": 50, "filters": { "Category Name": cName, "Serial No": serialno }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 3 };
    setIsLoader(true);
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(Courselistpra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          if (resp.response?.records.length > 0) {
            //setCourseListData(resp.response.records);
            setcfee(resp.response.records[0][12]?.value);
            setduration(resp.response.records[0][6]?.value);

          }
          else {
            alert("Data not found!!")
          }
        }
        else {
          window.location.href = '/';
        }
      });
    });
    setIsLoader(false);
  }
  const [ReceiptListdata, SetReceiptListdata] = useState();
  function fun_ReceiptListdata(id) {
    const ReceiptIdPra = { "NumberOfFieldsView": 50, "filters": { "BookingId": id }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 6 }
    setIsLoader(true);
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(ReceiptIdPra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          if (resp.response?.records.length > 0) {
            SetReceiptListdata(resp);
            //console.log(resp.response?.records, "maxReceiptId");
          }
        }
        else {
          window.location.href = '/';
        }
      });
    });
    setIsLoader(false);
  }
  function onFileUpload(type) {
    //selectedFile.files[0].name

    const formData = new FormData();
    if (selectedFile != null && type === "medDoc") {
      formData.append("myFile", selectedFile);
      formData.append("name", selectedFile.name);
      setselectedfile(selectedFile.name);
      setimgurl(selectedFile.name);
    }
    if (selectedFile != null && type === "aadDoc") {
      formData.append("myFile", selectedFile);
      formData.append("name", selectedFile.name);
      setselectedfile(selectedFile.name);
      setaadharimg(selectedFile.name);
    }
    // axios({
    //   method: 'post',
    //   url: "https://dialerpwebapis-dev-as.azurewebsites.net/api/UploadData/UploadCommonFilesToAzure?userID=" + 40330 + "&parentID=" + 40330 + "'",
    //   data: formData,
    //   headers: { 'Content-Type': 'multipart/form-data', 'Authorization-Token': authToken },
    // })
    axios.post('https://api.dialerp.com/api/api/UploadCommonFilesToAzure', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization-Token': authToken,
      },
    }
    ).then((response) => {
      if (response.data.hasErrors === true) {
        alert(response.data.errors);
      }
      else if (response.data.response !== null) {
        // alert("Image Upload successfully ");
        if (type === "medDoc") {
          setimgurl(response.data.response[0].imageUrl);
        }
        if (type === "aadDoc") {
          setaadharimg(response.data.response[0].imageUrl);
        }
        if (response.data.response[0].imageUrl !== null) {
          fun_addBooking(response.data.response[0].imageUrl, type);
        }
      }
    }
    )
      .catch((err) =>
        alert(err)
      );
  };
  function fun_addBooking(img, type) {
    if (type === "medDoc") {
      const addBookingpra = {
        "templateID": "5"
        , "_id": B_id
        , "_1": booking
        , "_2": category
        , "_3": coursename
        , "_4": serialno
        // , "_5": NextofKin
        , "_6": seat
        , "_17": 'In-Review'
        , "_25": img
      }
      fetch("https://api.dialerp.com/api/api/addUpdateTemplateDataWeb",
        {
          method: "POST",
          headers: {
            "Authorization-Token": authToken,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(addBookingpra),
        }
      ).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {

            alert("File upload successfully...")
            window.location.reload(true);
            // fun_CourseupdateAvailableSeats();
          }
          else {
            alert(resp.errors);
          }
        });
      });
    }
    if (type === "aadDoc") {
      const addBookingpra = {
        "templateID": "5"
        , "_id": B_id
        , "_1": booking
        , "_2": category
        , "_3": coursename
        , "_4": serialno
        // , "_5": NextofKin
        , "_6": seat
        , "_17": 'In-Review'
        , "_27": img
      }
      fetch("https://api.dialerp.com/api/api/addUpdateTemplateDataWeb",
        {
          method: "POST",
          headers: {
            "Authorization-Token": authToken,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(addBookingpra),
        }
      ).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            alert("File upload successfully...")
            window.location.reload(true);
          }
          else {
            alert(resp.errors);
          }
        });
      });
    }
  }
  const [cfee, setcfee] = useState();
  const [duration, setduration] = useState();
  //const [ites, setItes] = useState([]);
  useEffect(() => {
    BooKingData();
  }, [0]);
  //console.log(authToken);
  function fun_getstudentinfo(username) {
    const productdata2 = { "NumberOfFieldsView": 50, "filters": { "Email": username }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 4 }

    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(productdata2),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          if (resp.response?.records.length > 0) {
            setstudentInfo(resp);
            setstudentMob(resp.response?.records[0][6]?.value);
            setstudentName(resp.response?.records[0][5]?.value);
            setstudentEmail(resp.response?.records[0][8]?.value);
            setstudentprofile(resp.response?.records[0][4]?.value);
          }
        }
        else {
          window.location.href = '/';
        }
      });
    });
  }
  const [studentInfo, setstudentInfo] = useState([]);
  const [studentMob, setstudentMob] = useState('');
  const [studentName, setstudentName] = useState('');
  const [studentEmail, setstudentEmail] = useState('');
  const [studentprofile, setstudentprofile] = useState('');

  const handlePrint = () => {
    const elementsToHide = document.querySelectorAll('.element-to-hide');

    elementsToHide.forEach((element) => {
      element.style.display = 'none';
    });
    // Trigger the print dialog
    window.print();

    // Restore the visibility of hidden elements after printing
    elementsToHide.forEach((element) => {
      element.style.display = '';
    });
  };
  return (

    <>
      {isLoader &&
        <div id="loadingg">
          <ThreeDots height="80" width="80" radius="9" color="#fff" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      }
      <div>
        {localStorage.getItem("user-Studentinfo") ? (
          <Headernew />
        ) : (
          <Headersidebar />
        )}
      </div>

      <main id="main" className="main">


        <section className="section profile">
          <div className="row">


            <div className="col-xl-12">

              <div className="">
                <div className="card-body pt-3">


                  <div className="booking">
                    <div className="bookingdetails">
                      <div className="">


                        <div className="actiontop element-to-hide">
                          <a href="tel:'+91-9774731855'" style={{ margin: '0px 10px 10px 0px', display: 'inline-block' }}><Button variant="outlined" size="small"><CallIcon /> Call us for any query</Button></a>

                          <a href='/student-admin/invoice' onClick={localStorage.setItem('id', id)}><Button variant="outlined" size="small"><DownloadIcon /> Payment  Receipt</Button></a>
                          <a href="#" style={{ margin: '0px 10px 10px 10px', display: 'inline-block' }}><Button variant="outlined" size="small" onClick={handlePrint}><PrintIcon /> Print</Button></a>
                        </div>
                        <div className="modal-body">
                          {ReceiptListdata?.response?.records?.map((rinfo, i) => {
                            return (
                              <div>
                                <div className="card mb-3">

                                  <div className="row">
                                    <div className="col-md-8">
                                      <img src="../admin/img/logo.jpg" alt="" className="imglogo" />
                                    </div>
                                    <div className="col-md-4">
                                      <h4 className="card-title">Receipt Id :{rinfo[3]?.value} </h4>
                                      <h4 className="card-title">Booking Id :{id}</h4>
                                      <h4 className="card-title">TransactionId :{rinfo[7]?.value} </h4>
                                      <h4 className="card-title"> Booking Status :
                                        <span className={`datee 
                                          ${idbookingstatus === 'Confirmed' ? 'text-green' :
                                            idbookingstatus === 'In-Review' ? 'text-blue' :
                                              idbookingstatus === 'Payment Upload' ? 'text-blue' :
                                                idbookingstatus === 'Failed' ? 'text-red' :
                                                  idbookingstatus === 'Cancel' ? 'text-red' : ''
                                          }`
                                        }>
                                          <b>{idbookingstatus}</b>
                                        </span>
                                      </h4>
                                    </div>
                                  </div>

                                  <hr />
                                  <div className="row g-0">
                                    <div className="col-md-4 col-xl-4">
                                      {category === "Mountaineering" ? <img src="../admin/img/adventure/570x250/mountaineering.png" className="img-fluid rounded-start" alt="..." /> : null}
                                      {category === "Mountain Terrain Biking" ? <img src="../admin/img/adventure/570x250/cyclinggg.jpg" className="img-fluid rounded-start" alt="..." /> : null}
                                      {category === "SCUBA Diving" ? <img src="../admin/img/adventure/570x250/scuba1.png" className="img-fluid rounded-start" alt="..." /> : null}
                                      {category === "White Water Rafting" ? <img src="../admin/img/adventure/570x250/wwr.jpg" className="img-fluid rounded-start" alt="..." /> : null}
                                      {category === "Paragliding" ? <img src="../admin/img/adventure/570x250/paragliding.jpg" className="img-fluid rounded-start" alt="..." /> : null}
                                      {category === "Paramotor" ? <img src="../admin/img/adventure/570x250/paramotor.jpg" className="img-fluid rounded-start" alt="..." /> : null}
                                    </div>
                                    <div className="col-md-5 col-xl-5">
                                      <div className="card-body">
                                        <div>
                                          <h5 className="card-title">{coursename}</h5>
                                          <p className="card-text label label2">{category}</p>

                                          <div className="flexxcont flexxs">
                                            <div>
                                              <small className="label">Booking Date</small>
                                              <p className="datee">{moment(bookingdate).format('DD-MMM-YYYY')}</p>
                                            </div>

                                            <div>
                                              <small className="label">Adventure Date</small>
                                              <p className="datee">{fromdate} - {todate}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3 col-xl-3">
                                      <img src={studentprofile} className="img-fluid rounded-start" alt="..." style={{width:'120px', height:'120px', float:'right'}} />
                                    </div>
                                  </div>
                                </div>
                                <div className="card mb-3">
                                  <div className="row g-0">
                                    <div className="col-12">
                                      <strong className="minihead">Personal Details</strong>
                                      <table className="table">
                                        <thead style={{ fontSize: '12px', verticalAlign: 'middle' }}>
                                          <tr>
                                            <th scope="col">Full_Name</th>
                                            <th scope="col">Mobile</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Date_of_Birth</th>
                                            <th scope="col">Permanent Address</th>
                                            <th scope="col">Aadhar Number</th>
                                            <th scope="col">Father's_Name</th>
                                          </tr>
                                        </thead>
                                        <tbody style={{ fontSize: '13px' }}>
                                          {studentInfo?.response?.records?.map((studinfo, i) => {
                                            i++;
                                            return (
                                              <tr className={i % 2 === 0 ? 'even-row' : 'odd-row'}>
                                                <td>{studinfo[5]?.value}</td>
                                                <td>{studinfo[6]?.value} </td>
                                                <td>{studinfo[8]?.value}</td>
                                                <td>{studinfo[11]?.value}</td>
                                                <td>{studinfo[12]?.value},<br></br> {studinfo[13]?.value}, {studinfo[14]?.value}-{studinfo[15]?.value}</td>
                                                <td>{studinfo[17]?.value}</td>
                                                <td>{studinfo[20]?.value}</td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div className="card mb-3">
                                  {studentInfo?.response?.records?.map((studinfo, i) => {
                                    i++;
                                    return (
                                      <div className="row">
                                        <strong className="minihead">More Details</strong>
                                        <div className="col-md-6">
                                          <div className="card-body booking contactt">
                                            <div>
                                              <div className="">
                                                <div className="flexxcont">
                                                  <span className="label">Gender</span>
                                                  <span className="label">{studinfo[10]?.value}</span>
                                                </div>
                                                <hr></hr>
                                                <div className="flexxcont">
                                                  <span className="label">Age</span>
                                                  <span className="label">{BookingListData[23]?.value}</span>
                                                </div>
                                                <hr></hr>
                                                <div className="flexxcont">
                                                  <span className="label">Shoe Size</span>
                                                  <span className="label">{BookingListData[12]?.value}</span>
                                                </div>

                                                <hr></hr>
                                                <div className="flexxcont">
                                                  <span className="label">Academic Qualification</span>
                                                  <span className="label">{BookingListData[13]?.value}</span>
                                                </div>
                                                <hr></hr>
                                                <div className="flexxcont">
                                                  <span className="label">NRI</span>
                                                  <span className="label">{studinfo[18]?.value}</span>
                                                </div>
                                                <hr></hr>
                                                <div className="flexxcont">
                                                  <span className="label">Nationality</span>
                                                  <span className="label">{studinfo[19]?.value}</span>
                                                </div>
                                                <hr></hr>
                                                <div className="flexxcont">
                                                  <span className="label">Alternate Mobile Number</span>
                                                  <span className="label">{studinfo[7]?.value}</span>
                                                </div>
                                                <hr></hr>
                                                <div className="flexxcont">
                                                  <span className="label"> Dietary Details</span>
                                                  <span className="label">{BookingListData[17]?.value}</span>
                                                </div>
                                                <hr></hr>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="card-body booking payment">
                                            <div>
                                              <div className="">
                                                <div className="flexxcont">
                                                  <span className="label">Heightin CM</span>
                                                  <span className="label">{BookingListData[9]?.value}</span>
                                                </div>
                                                <hr></hr>
                                                <div className="flexxcont">
                                                  <span className="label">Weightin KG</span>
                                                  <span className="label">{BookingListData[10]?.value}</span>
                                                </div>
                                                <hr></hr>
                                                <div className="flexxcont">
                                                  <span className="label">BMI</span>
                                                  <span className="label">{BookingListData[11]?.value}</span>
                                                </div>
                                                <hr></hr>
                                                <div className="flexxcont">
                                                  <span className="label">Special Qualification<br></br> Or Hobbies</span>
                                                  <span className="label">{BookingListData[14]?.value}</span>
                                                </div>
                                                <hr></hr>
                                                <div className="flexxcont">
                                                  <span className="label">Next of Kin</span>
                                                  <span className="label">{BookingListData[7]?.value}</span>
                                                </div>
                                                <hr></hr>
                                                <div className="flexxcont">
                                                  <span className="label">Next of Kin With <br></br>Address & Telephone</span>
                                                  <span className="label">{BookingListData[15]?.value}</span>
                                                </div><hr></hr>
                                                <div className="flexxcont">
                                                  <span className="label">Insurance Policy No</span>
                                                  <span className="label">{BookingListData[16]?.value}</span>
                                                </div><hr></hr>
                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="card mb-3">
                                  <div className="row g-0">
                                    <div className="col-md-12">
                                      <div className="booking">
                                        <div>
                                          <strong className="minihead">Other Details</strong>
                                          <div className="flexxcont pdetails">
                                            <div className="dblock">
                                              <small className="label">Course Fee</small>
                                              <p className="datee">₹{cfee}/-</p>
                                            </div>
                                            <div className="dblock">
                                              <small className="label">Duration</small>
                                              <p className="datee">{duration}
                                              </p>
                                            </div>
                                            <div className="dblock">
                                              <small className="label">Age</small>
                                              <p className="datee">{age}</p>
                                            </div>

                                            <div className="dblock">
                                              <small className="label">Seats</small>
                                              <p className="datee">{seat}</p>
                                            </div>

                                            <div className="dblock">
                                              <small className="label">Payment Method</small>
                                              <p className="datee">{rinfo[6]?.value}</p>
                                            </div>
                                            <div className="dblock">
                                              <small className="label">Payment Status</small>
                                              <p className=
                                                {`datee 
                                          ${rinfo[5]?.value === 'Confirmed' ? 'text-green' :
                                                    rinfo[5]?.value === 'In-Review' ? 'text-blue' :
                                                      rinfo[5]?.value === 'Payment Uploaded' ? 'text-blue' :
                                                        rinfo[5]?.value === 'Failed' ? 'text-red' :
                                                          rinfo[5]?.value === 'Cancel' ? 'text-red' : ''
                                                  }`
                                                }>{rinfo[5]?.value}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card mb-3">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="card-body booking contactt">

                                        <div>
                                          <strong className="minihead">Primary Student on your booking</strong>

                                          <div className="">
                                            <div className="">

                                              <p className="datee name"><strong><PersonIcon /> {studentName}</strong></p>
                                            </div>
                                            <div className="flexxcont">
                                              <p className="labelee">All communication for your booking will be sent to : </p>
                                            </div>

                                            <div className="flexxcont">
                                              <p className="datee"><EmailIcon /> <strong>{studentEmail}</strong>
                                              </p>
                                            </div>

                                            <div className="flexxcont">
                                              <p className="datee"><CallIcon />  <strong>{studentMob}</strong></p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="card-body booking payment">

                                        <div>
                                          <strong className="minihead">Payment Details</strong>

                                          <div className="">
                                            <div className="flexxcont">
                                              <small className="label labels">Total Price</small>
                                              <p className="datee">₹{cfee}/-</p>
                                            </div>
                                            <div className="flexxcont">
                                              <small className="label labels">GST</small>
                                              <p className="datee">₹{rinfo[9]?.value}/-
                                              </p>
                                            </div>

                                            <div className="flexxcont borderr">
                                              <small className="label labels">Sub Total </small>
                                              <p className="datee">₹{cfee}/-</p>
                                            </div>

                                            <div className="flexxcont">
                                              <small className="label labels">Discount</small>
                                              <p className="datee">₹{rinfo[11]?.value}/-</p>
                                            </div>

                                            <div className="flexxcont borderr">
                                              <small className="label labels">Pay Amount</small>
                                              <p className="datee">₹{rinfo[12]?.value}/-</p>
                                            </div>

                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card mb-3">
                                  {imgurl === "" ? <div className="doc-upload">
                                    <p className="text-primary"><UploadIcon /> Upload your Medical Documents to complete booking process.</p>
                                    <input type="file" accept=".pdf" value={selectedFile} onChange={(e) => setselectedfile(e.target.files[0])} />
                                    <button style={{ display: "none" }} onClick={onFileUpload("medDoc")}></button>
                                  </div> :
                                    <div className="doc-upload">
                                      <p className="text-scuss"><a href={imgurl}><Button variant="outlined"><DownloadIcon /> Click here to download uploaded Medical Document.</Button></a></p>
                                    </div>
                                  }
                                  {/* {aadharimg === ""||aadharimg===undefined ?
                                    <div className="doc-upload">
                                      <p className="text-primary"><UploadIcon /> Upload your Aadhar Documents to complete booking process.</p>
                                      <input type="file" accept=".pdf" value={selectedFile} onChange={(e) => setselectedfile(e.target.files[0])} />
                                      <button style={{ display: "none" }} onClick={onFileUpload("aadDoc")}></button>
                                    </div> :
                                    <div className="doc-upload">
                                      <p className="text-scuss"><a href={aadharimg}><Button variant="outlined"><ArrowRightAltIcon /> Click here to download your uploaded  Aadhar Documents.</Button></a></p>
                                    </div>
                                  } */}

                                  {aadharimg === "" || aadharimg === undefined ?
                                    '' :
                                    <div className="doc-upload">
                                      <p className="text-success"><a href={aadharimg}><Button variant="outlined"><DownloadIcon /> Click here to download uploaded Aadhar Document.</Button></a></p>
                                    </div>
                                  }
                                  
                                  <div className="row g-0">
                                    <div className="col-md-12">
                                      <div className="card-body booking contactt">
                                        <div className="bottomarea">
                                          {upcoming === true ? <h4 className="text-primary">This adventure is upcoming.</h4> : null}
                                          {Running === true ? <h4 className="text-success">This adventure is running.</h4> : null}
                                          {complete === true ? <h4 className="text-primary">This adventure has been completed.</h4> : null}
                                          <div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Bookingdetails;
