import { useEffect, useState } from "react";
import React from "react";
import { Link } from 'react-router-dom';
import '../Common/custom-style.css';
import '/node_modules/bootstrap/dist/css/bootstrap.min.css';
import '/node_modules/bootstrap/dist/js/bootstrap.min.js';
import Headersidebar from '../Common/Headersidebar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DoneIcon from '@mui/icons-material/Done';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import moment from 'moment'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { ThreeDots } from 'react-loader-spinner'


const drawerWidth = 240;
function InreviewBookings() {
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  const [productdata, setProductData] = useState([]);
  const [error, setError] = useState("");
  const [ReceiptListdata, SetReceiptListdata] = useState('');
  const [BookingS, setBookingS] = React.useState('');
  const [CancelReason, setCancelReason] = React.useState('');
  const [showOption, setShowOption] = useState(false);
  const Bookinglistpra = { "NumberOfFieldsView": 50, "filters": {}, "isDownload": false, "index": 1, "pgSize": 1000, "templateID": 5 };
  const [isLoader, setIsLoader] = useState(true);
  function handleId() {
    setIsLoader(true);
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(Bookinglistpra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        setProductData(resp);
        // fun_ReceiptListdata();
      });
      setIsLoader(false);
    });
  }

  const InreviewBookingsList = [];
  productdata?.response?.records?.forEach(obj => {
    // if (obj[19].value === "In-Review"|| obj[19].value === "Payment Upload") {
      if (obj[19].value === "In-Review"&& obj[27]?.value !== "" && obj[29]?.value !== "") {
      InreviewBookingsList.push({ ...obj });
    }
  });

  function fun_ReceiptListdata(id) {
    const ReceiptIdPra = { "NumberOfFieldsView": 50, "filters": {}, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 6 }
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(ReceiptIdPra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.response?.records.length > 0) {
          SetReceiptListdata(resp);
        }
      });
    });
  }


  const [IDBooking, setIDBooking] = useState("");
  const [UpdateBookingID, setUpdateBookingID] = useState("");
  const [Category, setCategory] = useState("");
  const [Courses, setCourses] = useState("");
  const [SerialNo, setSerialNo] = useState("");
  const [Noofseats, setNoofseats] = useState(0);
  function GetBookingData(bno) {
    const GetBookingDataNew = { "NumberOfFieldsView": 50, "filters": { "BookingID": bno }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 5 };
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(GetBookingDataNew),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.response?.records.length > 0) {
          setIDBooking(resp.response.records[0][0].value);
          setUpdateBookingID(resp.response.records[0][3].value);
          setCategory(resp.response.records[0][4].value);
          setCourses(resp.response.records[0][5].value);
          setSerialNo(resp.response.records[0][6].value);
          setNoofseats(resp.response.records[0][8].value);
          fun_courseinfolist(resp.response.records[0][6].value);
        }
      });
    });
  }
  const [IDReceipt, setIDReceipt] = useState("");
  const [ReceiptId, setReceiptId] = useState("");
  const [ReceiptBookingId, setReceiptBookingId] = useState("");
  const [PaymentMethod, setPaymentMethod] = useState("");
  const [TransactionId, setTransactionId] = useState("");
  const [Amount, setAmount] = useState(0);
  function GetReceiptData(ID) {
    const UpdateReceiptData = { "NumberOfFieldsView": 50, "filters": { "BookingId": ID }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 6 };
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(UpdateReceiptData),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.response?.records.length > 0) {
          setIDReceipt(resp.response.records[0][0].value);
          setReceiptId(resp.response.records[0][3].value);
          setReceiptBookingId(resp.response.records[0][4].value);
          setPaymentMethod(resp.response.records[0][6].value);
          setTransactionId(resp.response.records[0][7].value);
          setAmount(resp.response.records[0][8].value);
        }
      });
    });
  }

  function fun_showpoup(rowIndex) {
    //SetID(rowIndex)
    fun_GetDatashowpoup(rowIndex);
    //GetReceiptData(ID);
  }
  function fun_GetDatashowpoup(bno) {
    GetBookingData(bno);
    //GetReceiptData(ID);
  }
  function handleupdate() {
    if (BookingS.trim() === "") {
      setError("Please select booking status");
      return;
    }
    else if (showOption === true) {
      if (CancelReason.trim() === "") {
        setError("Please enter cancel reason");
        return;
      }
      else {
        fun_updateBooking();
      }
    }
    else {
      //GetBookingData(ID);
      // GetReceiptData(ID)
      fun_updateBooking();
    }

  }
  useEffect(() => {
    handleId();
  }, [])

  function fun_getbookingidonclick(id) {
    localStorage.setItem("bookinginfo", id)
  }
  function fun_getStudentDetailclick(id) {
    localStorage.setItem("StudentDetailinfo", id)
  }


  const updateBookingpra = {
    "templateID": "5"
    , "_id": IDBooking
    , "_1": UpdateBookingID
    , "_2": Category
    , "_3": Courses
    , "_4": SerialNo
    , "_6": Noofseats
    , "_17": BookingS
    , "_26": CancelReason
  }
  function fun_updateBooking() {
    debugger
    fetch("https://api.dialerp.com/api/api/addUpdateTemplateDataWeb",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(updateBookingpra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          ;
          if (showOption === true) {
            fun_CourseupdateAvailableSeats();

          }
          else {
            alert("Update booking status successfully");
            window.location.reload(true)
          }
          
        }
        else {
          alert(resp.errors);
        }
      });
    });
  }
  const updateReceiptpra = {
    "templateID": "6"
    , "_id": IDReceipt
    , "_1": ReceiptId
    , "_2": ReceiptBookingId
    , "_3": BookingS
    , "_4": PaymentMethod
    , "_5": TransactionId
    , "_6": Amount
  }
  function fun_updateReceipt() {
    fetch("https://api.dialerp.com/api/api/addUpdateTemplateDataWeb",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(updateReceiptpra),

      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          alert("Update booking status successfully");
          window.location.reload(true)
          //document.getElementById("closepop").click();
        }
        else {
          alert(resp.errors);
        }
      });
    });
  }
  const [L_id, setL_id] = useState('');
  const [lSr, setlSr] = useState('');
  const [lCategoryName, setlCategoryName] = useState('');
  const [lCourseName, setlCourseName] = useState('');
  const [lSeats, setlSeats] = useState('');
  const [lSeason, setlSeason] = useState('');
  const [lSerialNo, setlSerialNo] = useState('');
  const [lCourseDatesFrom, setlCourseDatesFrom] = useState('');
  const [lCourseDatesTo, setlCourseDatesTo] = useState('');
  const [lCourseFees, setlCourseFees] = useState('');
  const [lCapacity, setlCapacity] = useState('');
  const [lAge, setlAge] = useState();
  const [lType, setlType] = useState('');
  const [AvailableSeats, setAvailableSeats] = useState('');


  function fun_courseinfolist(sno) {
    const BookinglistPra_ = { "NumberOfFieldsView": 50, "filters": { "Serial No": sno }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 3 }
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(BookinglistPra_),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          if (resp.response?.records.length > 0) {
            setL_id(resp.response?.records[0][0].value)
            setlSr(resp.response?.records[0][3].value)
            setlCategoryName(resp.response?.records[0][4].value)
            setlCourseName(resp.response?.records[0][5].value)
            setlSeats(resp.response?.records[0][7].value)
            setlSeason(resp.response?.records[0][8].value)
            setlSerialNo(resp.response?.records[0][9].value)
            setlCourseDatesFrom(resp.response?.records[0][10].value)
            setlCourseDatesTo(resp.response?.records[0][11].value)
            setlCourseFees(resp.response?.records[0][12].value);
            setAmount(resp.response?.records[0][12].value);
            setlCapacity(resp.response?.records[0][13].value);
            setlAge(resp.response?.records[0][14].value);
            setlType(resp.response?.records[0][15].value);
            setAvailableSeats(resp.response?.records[0][16].value + 1)
          }
        }
        else {
          window.location.href = '/';
        }
      });
    });
  }
  function fun_CourseupdateAvailableSeats() {
    const BookinglistPra_ = {
      "templateID": "3", "_id": L_id, "_1": lSr, "_2": lCategoryName, "_3": lCourseName, "_5": lSeats, "_6": lSeason, "_7": lSerialNo, "_8": lCourseDatesFrom, "_9": lCourseDatesTo, "_10": lCourseFees, "_11": lCapacity, "_12": lAge, "_13": lType, "_14": AvailableSeats
    }
    fetch(
      "https://api.dialerp.com/api/api/addUpdateTemplateDataWeb",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(BookinglistPra_),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          alert("Update booking status successfully");
          window.location.reload(true)
          //handleId();
        }
        else {
          alert(resp.errors);
        }

      });
    });
  }
  
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100); // Added state for items per page
  const totalItems = InreviewBookingsList?.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = InreviewBookingsList?.slice(indexOfFirstItem, indexOfLastItem) || [];

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  // Function to handle changes in items per page dropdown
  function handleItemsPerPageChange(event) {
    setItemsPerPage(event.target.value);
    setCurrentPage(1); // Reset to the first page when changing items per page
  }
  return (
    <>
      {isLoader && <div id="loadingg">
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#fff"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>}
      <Box sx={{ display: 'flex' }} className="custcontent">
        <div>
          <Headersidebar />
        </div>
        <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
          <main id="main" className="main">
            <div className="pagetitle">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/nimas-admin/dashboard">Home</Link></li>
                  <li className="breadcrumb-item active">In-Review Bookings</li>
                </ol>
              </nav>
            </div>
            <div className="bookingss">
              <section className="section profile">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-body">
                     
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div className="col-md-6">
                          <h5 className="card-title">In-Review Bookings </h5>
                          </div>
                          <div className="col-md-6 text-end"style={{display: 'contents'}} >
                            <div className="items-per-page" style={{marginLeft:"255px"}}>
                              <label htmlFor="itemsPerPage">Show per Page:</label>
                              <select
                                id="itemsPerPage"
                                value={itemsPerPage}
                                onChange={handleItemsPerPageChange}>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="300">300</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                              </select>
                            </div>
                            <label>Total Records: <b>{InreviewBookingsList.length}</b></label>
                          </div>
                        </div>
                        <div className="tableresp">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Sr.</th>
                                <th scope="col">Date</th>
                                <th scope="col">Booking Id</th>
                                <th scope="col">Student Name</th>
                                <th scope="col">Serial No</th>
                                {/* <th scope="col">No of Seats</th> */}
                                <th scope="col">Adventure Name</th>
                                <th scope="col">Course Dates</th>
                                <th scope="col">Transaction Id</th>
                                <th scope="col">Payment Status</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* {InreviewBookingsList?.filter((dl) => dl[26].value).map((studinfo, i) => { */}
                                {currentItems?.map((studinfo, i) => {
                                  const srNo = i + 1 + indexOfFirstItem;
                                  return (
                                    <tr>
  
                                      <th scope="row">{srNo}</th>
                                    <td>{moment(studinfo[1].value).format("DD-MMM-YYYY hh:mm:ss")}</td>
                                    {/* <td><a href="/nimas-admin/bookingdetails" target="_blank" onClick={localStorage.setItem("bookinginfo", studinfo[0].value)}>{studinfo[3].value}</a></td>
                                    <td><Link to="/nimas-admin/student-info">{studinfo[24].value}</Link></td> */}
                                    <td><a href="/student-admin/invoicenew"onClick={() => fun_getbookingidonclick(studinfo[3].value)}>{studinfo[3].value}</a></td>
                                    <td><a href="/nimas-admin/student-info"onClick={() => fun_getStudentDetailclick(studinfo[25].value)}>{studinfo[24].value}</a></td>
                                    <td>{studinfo[6].value}</td>
                                    {/* <td>{studinfo[8].value}</td> */}
                                    <td>{studinfo[4].value}<br />({studinfo[5].value})</td>
                                    <td>{studinfo[21].value}<br />/{studinfo[22].value}</td>
                                    <td>{studinfo[26].value}</td>

                                    <td><span
                                      className={`datee 
                                ${studinfo[19].value === 'Confirmed' ? 'text-green' :
                                          studinfo[19].value === 'In-Review' ? 'text-blue' :
                                            studinfo[19].value === 'Payment Upload' ? 'text-blue' :
                                              // studinfo[19].value === 'Failed' ? 'text-red' :
                                              studinfo[19].value === 'Cancel' ? 'text-red' : ''
                                        }`
                                      }>{studinfo[19].value}</span>
                                    </td>
                                    <input className="dark-light" type="checkbox" id="dark-light" name="dark-light" />


                                    <div className="sec-center">
                                      <input className="dropdown" type="checkbox" id={`dropdown-${i}`} name={`dropdown-${i}`} />
                                      <label className="for-dropdown" htmlFor={`dropdown-${i}`}>
                                        <MoreVertIcon />
                                      </label>
                                      <div className="section-dropdown">
                                        <ul className="dd-menu">
                                          <li>
                                            <a href="#popup1" onClick={() => fun_showpoup(studinfo[3].value)}>
                                              <DoneIcon /> Accept
                                            </a>
                                          </li>
                                          <li>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="pagination_" style={{ textAlign: "center" }}>
                          {Array.from({ length: totalPages }, (_, i) => (
                            <button style={{ margin: '0 5px' }}
                              key={i}
                              onClick={() => handlePageChange(i + 1)}
                              disabled={currentPage === i + 1}
                            >
                              {i + 1}
                            </button>
                          ))}
                        </div>
                        <div id="popup1" className="overlay">
                          <div className="popup close-popup">
                            <h6>Update Booking Status</h6>
                            <a className="close" href="#">&times;</a>
                            <div className="content">
                              <FormControl fullWidth variant="standard">
                                <InputLabel id="demo-simple-select-label">Booking Status<span class="required">*</span></InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={BookingS}
                                  label="Booking Status"
                                  onChange={(e) => {
                                    setBookingS(e.target.value); setError(''); if (e.target.value === "Cancel" || e.target.value === "Failed") setShowOption(true);
                                    else setShowOption(false);
                                  }}
                                  className={`${error && BookingS.trim() === '' ? 'is-invalid' : ''}`}
                                >
                                  <MenuItem value="Confirmed">Confirmed</MenuItem>
                                  <MenuItem value="In-Review">In-Review</MenuItem>
                                  <MenuItem value="Cancel">Cancel</MenuItem>
                                  {/* <MenuItem value="Failed">Failed</MenuItem> */}
                                </Select>
                                {error && BookingS.trim() === '' && <div className="invalid-feedback">Please select booking status</div>}
                              </FormControl>
                              {
                                showOption && <div className="single-field">
                                  <input type="text" placeholder="Please enter cancel reason" id="CancelReason" value={CancelReason} onChange={(e) => { setCancelReason(e.target.value); setError(''); }}
                                    className={`form-control ${error && CancelReason === '' ? 'is-invalid' : ''}`} />
                                  {error && CancelReason === '' && <div className="invalid-feedback">Please enter cancel reason</div>}
                                </div>
                              }
                              <Stack spacing={2} direction="row">
                                <Button variant="outlined" id="closepop" href="#">Cancel</Button>
                                <Button variant="contained" onClick={handleupdate}>Confirm</Button>
                              </Stack>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

          </main>
        </Box>
      </Box>
    </>


  );
}

export default InreviewBookings;
