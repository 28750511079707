import React, {useEffect, useState } from "react";
import Header from '../Common/Header';
import Footer from '../Common/Footer';


function Campus() {


  return (
        <>
          <Header />
          <div className="wrapper">
          <div className="bradcumb-area style-1 overlay-bg-2">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col">
						
							<div className="bradcumb text-center">
								<h3>Campus</h3>
							</div>
						
						</div>
					</div>
				</div>
			</div>

          </div>

          <div className="about-adventure-area pt-110 pb-200">
				<div className="container">
					
					<div className="row justify-content-center wow fadeInDown">
						<div className="col-lg-10">
							<p className="">Spread over an area of 38 acres, NIMAS is located in the West Kameng district of Arunachal Pradesh. Situated on a hill which is at a height of 6000-7000 feet, the institute overlooks the town of Dirang and the Sela Pass and boasts of wonderful scenery all around. The upcoming state-of- the-art campus with traditional style houses is being built to provide accommodation to more than two hundreds of students at any given time. Separate hostels for men and women are being constructed and the entire campus is expected to be fully constructed by the next four years.

							</p>
                            <p>One of the main roles of the NIMAS campus is to act as the base camp for all the different programmes that the institute conducts. Dirang is accessible from both Tawang, where most of the mountaineering activities are conducted as well as East Kameng, where students are taken for their training in rafting and aero sports.

</p>
                            <p>Dirang is highly regarded for its amazing landscape and beautiful weather, winters being sunny and monsoons being lush and long. The rich bio-diversity of the region makes it the ideal place for a well- equipped adventure sports establishment like NIMAS. Strategic thought and design is currently being applied to recreate Dirang's bio-diversity within the institute campus.

</p>
						</div>
					</div>
					
				</div>
			</div>
          <Footer />
              
        </>
  );
}

export default Campus;
