import React, { useEffect, useState } from "react";
import '../Common/custom-style.css';
import '/node_modules/bootstrap/dist/css/bootstrap.min.css';
import '/node_modules/bootstrap/dist/js/bootstrap.min.js';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Box from '@mui/material/Box';
import Headersidebar from '../Common/Headersidebar';

const drawerWidth = 240;

function NimasadminProfile() {
  const admininfo = JSON.parse(localStorage.getItem("user-admininfo"));
  if (admininfo === null) {
    window.location.href = '/nimas-admin/login';
  }
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  const loginpra = { "NumberOfFieldsView": 50, "filters": { "Email": admininfo?.records[0][8].value, "Password": admininfo?.records[0][9].value }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 7 }
  const [productdata, setProductData] = useState([]);
  const [fullName, setfullName] = useState("");
  const [gender, setgender] = useState("");
  const [dob, setDOB] = useState("");
  const [mobile, setmobile] = useState("");
  const [address, setaddress] = useState("");
  const [City, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [country, setcountry] = useState("");
  function fun_Login() {
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(loginpra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.response?.records.length > 0) {
          setProductData(resp);
          setfullName(resp.response?.records[0][5].value);
          setgender(resp.response?.records[0][10].value);
          setmobile(resp.response?.records[0][6].value);
          setaddress(resp.response?.records[0][12].value);
          setCity(resp.response?.records[0][13].value);
          setState(resp.response?.records[0][14].value);
          setPincode(resp.response?.records[0][15].value);
          setcountry(resp.response?.records[0][16].value);

          const timeStamp = Date.parse(resp.response.records[0][11].value);
          const date = new Date(timeStamp);
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const dateVal = date.getDate();
          const formattedDate_ = `${dateVal < 10 ? "0" + dateVal : dateVal}-${month < 10 ? "0" + month : month}-${year}`;
          const formattedDate = formattedDate_.split("-").reverse().join("-");
          setDOB(formattedDate);
        }
        else {
          window.location.href = '/nimas-admin/login';
        }
      });
    });
  }

  useEffect(() => {
    fun_Login();
  }, [0]);
  return (

    <>
      <Box sx={{ display: 'flex' }} className="custcontent">

        <div>

          <Headersidebar />
        </div>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >

          <main id="main" className="main">

            <div className="pagetitle">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/nimas-admin/dashboard">Dashboard</a></li>
                  <li className="breadcrumb-item active">Profile</li>
                </ol>
              </nav>
            </div>

            <section className="section profile">
              <div className="row">
                <div className="col-xl-4">
                {productdata?.response?.records?.map((studinfo, i) => {
                              i++;
                              return (
                  <div className="card">
                    <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                    
                    <img src={studinfo[4].value !==""? studinfo[4].value : "https://nimasdirang.com/admin/img/profile-img.jpg"}  alt="Profile" className="rounded-circle img" />
                     
                      <h2>{studinfo[5].value}</h2>
                      <div className="social-links mt-2">
                        <a href="#" className="twitter"><FacebookIcon /></a>
                        <a href="#" className="facebook"><TwitterIcon /></a>
                        <a href="#" className="instagram"><InstagramIcon /></a>
                        <a href="#" className="linkedin"><LinkedInIcon /></a>
                      </div>
                    </div>
                  </div>
                     );
                    })}
                </div>

                <div className="col-xl-8">

                  <div className="card">
                    <div className="card-body pt-3">

                      <h5><b>Profile Details</b></h5>
                      <div className="row mb-3">
                        <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">Full Name</label>
                        <div className="col-md-8 col-lg-9">
                          <input name="fullName" type="text" className="form-control" placeholder="Enter Full Name" id="fullName" value={fullName} onChange={(e) => setfullName(e.target.value)} />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label htmlFor="Email" className="col-md-4 col-lg-3 col-form-label">Email</label>
                        <div className="col-md-8 col-lg-9">
                          <input name="Email" type="text" className="form-control" id="Email" value={admininfo?.records[0][8].value} disabled />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label htmlFor="Gender" className="col-md-4 col-lg-3 col-form-label">Gender</label>
                        <div className="col-md-8 col-lg-9">
                          <select id="gender" className="form-control" value={gender} onChange={(e) => setgender(e.target.value)}>
                            <option value="">Select gender</option>
                            <option value="Male" selected>Male</option>
                            <option value="Female" selected>Female</option>
                          </select>
                         
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label htmlFor="dob" className="col-md-4 col-lg-3 col-form-label">DOB </label>
                        <div className="col-md-8 col-lg-9">
                          <input name="dob" type="date" className="form-control" id="dob" placeholder="Enter Date Of Birth" value={dob} onChange={(e) => setDOB(e.target.value)} />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label htmlFor="Mobile" className="col-md-4 col-lg-3 col-form-label">Mobile</label>
                        <div className="col-md-8 col-lg-9">
                          <input name="Mobile" type="number" className="form-control" id="Mobile" placeholder="Enter Mobile" value={mobile} onChange={(e) => setmobile(e.target.value)} />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label htmlFor="Address" className="col-md-4 col-lg-3 col-form-label">Address</label>
                        <div className="col-md-8 col-lg-9">
                          <input name="Address" type="text" className="form-control" placeholder="Enter Address" id="Address" value={address} onChange={(e) => setaddress(e.target.value)} />
                        </div>
                      </div>


                      <div className="row mb-3">
                        <label htmlFor="City" className="col-md-4 col-lg-3 col-form-label">City</label>
                        <div className="col-md-8 col-lg-9">
                          <input name="City" type="text" className="form-control" placeholder="Enter City" id="City" value={City} onChange={(e) => setCity(e.target.value)} />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label htmlFor="State" className="col-md-4 col-lg-3 col-form-label">State</label>
                        <div className="col-md-8 col-lg-9">
                          <input name="State" type="text" className="form-control" placeholder="Enter State" id="State" value={state} onChange={(e) => setState(e.target.value)} />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label htmlFor="Pincode" className="col-md-4 col-lg-3 col-form-label">Pincode</label>
                        <div className="col-md-8 col-lg-9">
                          <input name="Pincode" type="number" placeholder="Enter Pincode" className="form-control" id="Pincode" value={pincode} onChange={(e) => setPincode(e.target.value)} />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label htmlFor="Country" className="col-md-4 col-lg-3 col-form-label">Country</label>
                        <div className="col-md-8 col-lg-9">
                          <select id="Country" className="form-control" value={country} onChange={(e) => setcountry(e.target.value)}>
                            <option value="">Select Country</option>
                            <option value="India" selected>India</option>
                          </select>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </section>

          </main>
        </Box>
      </Box>
    </>


  );
}

export default NimasadminProfile;
