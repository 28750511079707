import React from "react";
import {Link} from 'react-router-dom';
import '../Common/custom-style.css';
import '/node_modules/bootstrap/dist/css/bootstrap.min.css';
import '/node_modules/bootstrap/dist/js/bootstrap.min.js';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';

import Headersidebar from '../Common/Headersidebar';
import $ from 'jquery';


const drawerWidth = 240;

function Bookingdetails() {

    return (
       
        <>
 
<Box sx={{ display: 'flex' }} className="custcontent">
   
   <div>

     <Headersidebar />
   </div>
   <Box
       component="main"
       sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
     >

<main id="main" className="main">
{/*}
<div className="pagetitle">
  <nav>
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><Link to="/student-admin/dashboard">Home</Link></li>
      <li className="breadcrumb-item active">Booking Details</li>
    </ol>
  </nav>
    </div>*/}

<section className="section profile">
  <div className="row">
   

    <div className="col-xl-12">

      <div className="">
        <div className="card-body pt-3">
       
        
          <div className="booking">
            <div className="bookingdetails">
              <div className="">
              
                <div className="modal-body">
                 
<div className="card mb-3">

<div className="row">
<div className="col-md-8">
   <img src="../admin/img/logo.jpg" alt="" className="imglogo" />
</div>
<div className="col-md-4">
  <h4 className="card-title">Receipt Id :<a  href="#"> 36563569606 </a></h4>
   <h4 className="card-title">Booking Id :<a  href="#"> 78988745894</a></h4>
   <h4 className="card-title"> Booking Status : <span className="text-success"><b>Confirm</b></span></h4>
</div>
</div>

<hr/>
                   <div className="row g-0">
                        <div className="col-md-2 col-xl-2">
                          <img src="../admin/img/mountaineering_2.png" className="img-fluid rounded-start" alt="..." />
                        </div>
                        <div className="col-md-10 col-xl-10">
                          <div className="card-body"> 

                              <div>
                            <h5 className="card-title">Advance Mountaineering course (AMC)</h5>
                            <p className="card-text label label2">Land Adventure</p>

                            <div className="flexxcont">
                              <div>
                                <small className="label">Booking Date</small>
                                 <p className="datee">22-Jan-2023</p>
                              </div>

                              <div>
                                <small className="label">Adventure Date</small>
                                <p className="datee">22-Aug-2023 - 30-Aug-2023</p>
                              </div>
                            </div>
                          </div>
                          </div>
                        </div>
                      </div>
                      </div>


                      <div className="card mb-3">
                   <div className="row g-0">
                      
                        <div className="col-md-12">
                          <div className="booking"> 

                              <div>
                            <strong className="minihead">Other Details</strong>

                            <div className="flexxcont pdetails">
                              <div className="dblock">
                                <small className="label">Course Fee</small>
                                 <p className="datee">₹22,390/-</p>
                              </div>
                              <div className="dblock">
                                <small className="label">Duration</small>
                                 <p className="datee">28 Days
</p>
                              </div>

                              <div className="dblock">
                                <small className="label">Age</small>
                                <p className="datee">17-40</p>
                              </div>

                              <div className="dblock">
                                <small className="label">Seats</small>
                                 <p className="datee">3</p>
                              </div>

                              <div className="dblock">
                                <small className="label">Payment Method</small>
                                 <p className="datee">Net Banking</p>
                              </div>


                              <div className="dblock">
                                <small className="label">Payment Status</small>
                                 <p className="datee text-success">Done</p>
                              </div>

                            </div>

                           


                          </div>
                          </div>
                        </div>
                      </div>
                      </div>


                        <div className="card mb-3">
                   <div className="row">
                      <div className="col-md-6">
                          <div className="card-body booking contactt"> 

                              <div>
                            <strong className="minihead">Primary Student on your booking</strong>

                            <div className="">
                              <div className="">
                              
                                 <p className="datee name">Manoj Kumar</p>
                              </div>
                              <div className="flexxcont">
                                <p className="labelee">All communication for your booking will be sent to : </p>
                              </div>

                               <div className="flexxcont">
                                 <p className="datee"><i className="bx bx-mail-send"></i> manojkumar@gmail.com
</p>
                              </div>

                              <div className="flexxcont">
                                <p className="datee"><i className="bx bx-phone"></i>  +91-9999999999</p>
                              </div>

                              

                            </div>

                           


                          </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card-body booking payment"> 

                              <div>
                            <strong className="minihead">Payment Details</strong>

                            <div className="">
                              <div className="flexxcont">
                                <small className="label labels">Total Price</small>
                                 <p className="datee">₹22,390/-</p>
                              </div>
                              <div className="flexxcont">
                                <small className="label labels">GST</small>
                                 <p className="datee">₹2000
</p>
                              </div>

                              <div className="flexxcont border">
                                <small className="label labels">Sub Total </small>
                                <p className="datee">₹24,390</p>
                              </div>

                               <div className="flexxcont">
                                <small className="label labels">Discount</small>
                                 <p className="datee">₹1000</p>
                              </div>

                               <div className="flexxcont border">
                                <small className="label labels">Pay Amount</small>
                                 <p className="datee">₹23,390</p>
                              </div>

                            </div>

                           


                          </div>
                          </div>
                        </div>
                      </div>
                      </div>


                        



                         <div className="card mb-3">
                   <div className="row g-0">
                      
                        <div className="col-md-12">
                          <div className="card-body booking contactt"> 

                             
                              <div className="bottomarea">
                              <h4 className="text-danger">This adventure has been passed.</h4>
                                

                                   <a href="tel:'+91-9774731855'" className="btn btn-primary"><i className="bx bx-phone"></i> Call us for any query</a>
                              </div>
                            </div>

                        </div>
                      </div>
                      </div>





              </div>
            </div>
          </div>
          </div>

          <p className="text-center" style={{marginTop:'10px'}}>
            <Link to="/nimas-admin/invoice">Download  Receipt</Link>
          </p>


        </div>
      </div>

    </div>
  </div>

     



</section>

</main>
</Box>
</Box>
        </>


    );
  }
  
  export default Bookingdetails;
  