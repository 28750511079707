import React, {useEffect, useState } from "react";
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import CircularProgress from '@mui/material/CircularProgress';
import Box from "@mui/material/Box";
import { ThreeDots } from 'react-loader-spinner'

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import { FreeMode, Pagination, Autoplay } from 'swiper/modules';

function Home() {

  {/* const loading = document.querySelector('.loading');
  const wrapper = document.querySelector('.wrapper');
  setTimeout(() => {
  loading.style.opacity = "0";
  wrapper.style.opacity = "1";
  }, 2000)

   
*/}


  return (
        <>
      {/*<div className="loading">
          <div align="center" className="loadercircle">Loading...
          </div>
  </div>*/}

          <Header />
          <div id="qodef-page-wrapper">
        <div id="qodef-page-outer">
          <div id="qodef-page-inner" className="qodef-content-full-width">
            <main id="qodef-page-content" className="qodef-grid qodef-layout--template qodef-grid-template--12 " role="main">
              <div className="qodef-grid-inner">
                <div className="qodef-grid-item qodef-page-content-section qodef-col--content">
                  <div data-elementor-type="wp-page" data-elementor-id={3186} className="elementor elementor-3186">
                    <section className="elementor-section elementor-top-section elementor-element elementor-element-f971566 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="f971566" data-element_type="section">
                      <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8c27de8" data-id="8c27de8" data-element_type="column">
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div className="elementor-element elementor-element-4a8df83 elementor-widget elementor-widget-trekon_core_rotating_image_tiles" data-id="4a8df83" data-element_type="widget" data-widget_type="trekon_core_rotating_image_tiles.default">
                              <div className="elementor-widget-container">
                                <div className="qodef-shortcode qodef-m  qodef--rotating-image-tiles qodef--9-tiles" style={{width:600, height: 600}}>
                                  <div className="qodef-tile qodef-tile-0">
                                    <div className="qodef-tile-wheel">

                                      <div className="qodef-tile-field qodef-tile-top-left" style={{ backgroundImage: `url("images/07/landing-img-tiles-4.jpg")`}}>
                                      </div>


                                      <div className="qodef-tile-field qodef-tile-mirror-reverse" style={{ backgroundImage: `url("images/07/landing-img-tiles-3.jpg")`}}></div>


                                      <div className="qodef-tile-field qodef-tile-top-right" style={{ backgroundImage: `url("images/07/landing-img-tiles-2.jpg")`}}></div>


                                      <div className="qodef-tile-field qodef-tile-top-left" style={{ backgroundImage: `url("images/07/landing-img-tiles-1.jpg")`}}></div>

                                    </div>
                                  </div>
                                  <div className="qodef-tile qodef-tile-1 ">
                                    <div className="qodef-tile-wheel">
                                      <div className="qodef-tile-field qodef-tile-bottom-right" style={{backgroundImage: `url("images/07/landing-img-tiles-4.jpg")`}} />


                                      <div className="qodef-tile-field qodef-tile-top-right" style={{backgroundImage: `url("images/07/landing-img-tiles-3.jpg")`}} />

                                      <div className="qodef-tile-field " style={{backgroundImage: `url("images/07/landing-img-tiles-2.jpg")`}} />

                                      <div className="qodef-tile-field qodef-tile-circle" style={{backgroundImage: `url("images/07/landing-img-tiles-1.jpg")`}} />
                                    </div>
                                  </div>
                                  <div className="qodef-tile qodef-tile-2 ">
                                    <div className="qodef-tile-wheel">
                                      <div className="qodef-tile-field qodef-tile-bottom-left" style={{backgroundImage: `url("images/07/landing-img-tiles-4.jpg")`}} />

                                      <div className="qodef-tile-field " style={{backgroundImage: `url("images/07/landing-img-tiles-3.jpg")`}} />

                                      <div className="qodef-tile-field qodef-tile-top-right" style={{backgroundImage: `url("images/07/landing-img-tiles-2.jpg")`}} />

                                      <div className="qodef-tile-field qodef-tile-top-left"style={{backgroundImage: `url("images/07/landing-img-tiles-1.jpg")`}} />

                                    </div>
                                  </div>
                                  <div className="qodef-tile qodef-tile-3 ">
                                    <div className="qodef-tile-wheel">
                                      <div className="qodef-tile-field qodef-tile-top-left" style={{backgroundImage: `url("images/07/landing-img-tiles-4.jpg")`}} />


                                      <div className="qodef-tile-field qodef-tile-bottom-right" style={{backgroundImage: `url("images/07/landing-img-tiles-3.jpg")`}} />


                                      <div className="qodef-tile-field qodef-tile-bottom-left" style={{backgroundImage: `url("images/07/landing-img-tiles-2.jpg")`}} />

                                      <div className="qodef-tile-field qodef-tile-top-left" style={{backgroundImage: `url("images/07/landing-img-tiles-1.jpg")`}} />

                                    </div>
                                  </div>
                                  <div className="qodef-tile qodef-tile-4 ">
                                    <div className="qodef-tile-wheel">
                                      <div className="qodef-tile-field qodef-tile-circle" style={{backgroundImage: `url("images/07/landing-img-tiles-4.jpg")`}} />

                                      <div className="qodef-tile-field qodef-tile-bottom-left" style={{backgroundImage: `url("images/07/landing-img-tiles-3.jpg")`}} />

                                      <div className="qodef-tile-field " style={{backgroundImage: `url("images/07/landing-img-tiles-2.jpg")`}} />


                                      <div className="qodef-tile-field qodef-tile-bottom-right" style={{backgroundImage: `url("images/07/landing-img-tiles-1.jpg")`}} />
                                    </div>
                                  </div>
                                  <div className="qodef-tile qodef-tile-5 ">
                                    <div className="qodef-tile-wheel">
                                      <div className="qodef-tile-field qodef-tile-top-left" style={{backgroundImage: `url("images/07/landing-img-tiles-4.jpg")`}} />

                                      <div className="qodef-tile-field qodef-tile-top-right" style={{backgroundImage: `url("images/07/landing-img-tiles-3.jpg")`}} />

                                      <div className="qodef-tile-field qodef-tile-circle" style={{backgroundImage: `url("images/07/landing-img-tiles-2.jpg")`}} />

                                      <div className="qodef-tile-field qodef-tile-bottom-left" style={{backgroundImage: `url("images/07/landing-img-tiles-1.jpg")`}} />

                                    </div>
                                  </div>
                                  <div className="qodef-tile qodef-tile-6 ">
                                    <div className="qodef-tile-wheel">
                                      <div className="qodef-tile-field " style={{backgroundImage: `url("images/07/landing-img-tiles-4.jpg")`}} />

                                      <div className="qodef-tile-field qodef-tile-top-right" style={{backgroundImage: `url("images/07/landing-img-tiles-3.jpg")`}} />

                                      <div className="qodef-tile-field qodef-tile-top-left" style={{backgroundImage: `url("images/07/landing-img-tiles-2.jpg")`}} />

                                      <div className="qodef-tile-field qodef-tile-bottom-right" style={{backgroundImage: `url("images/07/landing-img-tiles-1.jpg")`}} />

                                    </div>
                                  </div>
                                  <div className="qodef-tile qodef-tile-7 ">
                                    <div className="qodef-tile-wheel">
                                      <div className="qodef-tile-field " style={{backgroundImage: `url("images/07/landing-img-tiles-4.jpg")`}} />

                                      <div className="qodef-tile-field qodef-tile-circle" style={{backgroundImage: `url("images/07/landing-img-tiles-3.jpg")`}} />

                                      <div className="qodef-tile-field qodef-tile-mirror-reverse" style={{backgroundImage: `url("images/07/landing-img-tiles-2.jpg")`}} />

                                      <div className="qodef-tile-field " style={{backgroundImage: `url("images/07/landing-img-tiles-1.jpg")`}} />

                                    </div>
                                  </div>
                                  <div className="qodef-tile qodef-tile-8 ">
                                    <div className="qodef-tile-wheel">
                                      <div className="qodef-tile-field qodef-tile-mirror" style={{backgroundImage: `url("images/07/landing-img-tiles-4.jpg")`}} />

                                      <div className="qodef-tile-field qodef-tile-top-right"style={{backgroundImage: `url("images/07/landing-img-tiles-3.jpg")`}} />

                                      <div className="qodef-tile-field qodef-tile-top-right" style={{backgroundImage: `url("images/07/landing-img-tiles-2.jpg")`}} />

                                      <div className="qodef-tile-field qodef-tile-mirror"style={{backgroundImage: `url("images/07/landing-img-tiles-1.jpg")`}} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-2e187c0" data-id="2e187c0" data-element_type="column">
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div className="elementor-element elementor-element-bc56087 elementor-widget elementor-widget-trekon_core_single_image" data-id="bc56087" data-element_type="widget" data-widget_type="trekon_core_single_image.default">
                              <div className="elementor-widget-container">
                                <div className="qodef-shortcode qodef-m  qodef-single-image qodef-layout--default  qodef--retina ">
                                  <div className="qodef-m-image">
                                    <a itemProp="url" href="https://nimasdirang.com/" target="_blank">
                                      <img src="images/logo.png" width={150} height={48} alt="" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="elementor-element elementor-element-cb001c8 elementor-widget elementor-widget-trekon_core_custom_font" data-id="cb001c8" data-element_type="widget" data-widget_type="trekon_core_custom_font.default">
                              <div className="elementor-widget-container">
                                <p className="qodef-shortcode qodef-m  qodef-custom-font qodef-custom-font-550 qodef-layout--simple" style={{ fontSize: '60px', lineHeight: '72px', letterSpacing: '0px', fontWeight: 700, textTransform: 'uppercase', fontFamily:'Roboto', color:'#000'}}>YOUR GATEWAY TO ADVENTURE!
</p>
                              </div>
                            </div>
                            <div className="elementor-element elementor-element-665241d elementor-widget elementor-widget-text-editor" data-id="665241d" data-element_type="widget" data-widget_type="text-editor.default">
                              <div className="elementor-widget-container">
                                <p>Nestled within a sprawling 38-acre contemporary campus, NIMAS stands as the premier adventure institute, boasting expertise in land, air, and aqua verticals.
</p>
                              </div>
                            </div>
                            <div className="elementor-element elementor-element-d6a377a elementor-widget elementor-widget-trekon_core_button" data-id="d6a377a" data-element_type="widget" data-widget_type="trekon_core_button.default">
                              <div className="elementor-widget-container">
                                <a className="qodef-shortcode qodef-m  qodef-button qodef-layout--filled  qodef-html--link" href="/about-us" target="_blank">
                                  <span className="qodef-m-text">Read More</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <section className="elementor-section elementor-top-section elementor-element elementor-element-94aaad1 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="94aaad1" data-element_type="section">
                    <div className="elementor-container elementor-column-gap-default">
                      <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0ba8198" data-id="0ba8198" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div className="elementor-element elementor-element-5f3312c elementor-widget elementor-widget-trekon_core_custom_font" data-id="5f3312c" data-element_type="widget" data-widget_type="trekon_core_custom_font.default">
                            <div className="elementor-widget-container">
                              <p>OUR TRAINING WING</p>
                              <p className="qodef-shortcode qodef-m  qodef-custom-font qodef-custom-font-47 qodef-layout--simple" style={{ fontSize: '52px', lineHeight: '62px', letterSpacing: '0px', fontWeight: 700, fontStyle: 'normal', textTransform: 'uppercase', marginBottom:'20px', fontFamily:'Roboto', color:'#000'}}>SCALING HEIGHTS DEFYING LIMITS
</p>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-af8d35a elementor-widget elementor-widget-text-editor" data-id="af8d35a" data-element_type="widget" data-widget_type="text-editor.default">
                            <div className="elementor-widget-container">
                              <p style={{fontSize:17, lineHeight:'27px'}}>Step into the realm of adventure with our specialized training wing at NIMAS. 
</p>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-257b406 elementor-widget elementor-widget-trekon_core_button" data-id="257b406" data-element_type="widget" data-widget_type="trekon_core_button.default">
                            <div className="elementor-widget-container">
                              <a className="qodef-shortcode qodef-m  qodef-button qodef-layout--filled  qodef-html--link" href="#" target="_blank">
                                <span className="qodef-m-text">View More</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-7c54a7f" data-id="7c54a7f" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div className="elementor-element elementor-element-2827372 elementor-widget elementor-widget-trekon_core_image_gallery" data-id={2827372} data-element_type="widget">
                            <div className="elementor-widget-container">
                              <div className="qodef-shortcode qodef-m  qodef-image-gallery  qodef-grid qodef-swiper-container    qodef-col-num--1  qodef-responsive--predefined qodef-swiper--show-pagination">
                                <div className="swiper-wrapper">
                                  <div className="qodef-e qodef-image-wrapper swiper-slide ">
                                    
                                      <img src="images/features/aero.png" className="attachment-full size-full" alt="h"  />
                                  </div>
                                  <div className="qodef-e qodef-image-wrapper swiper-slide ">
                                   
                                      <img loading="lazy" decoding="async" width={600} height={538} src="images/features/aqua.png" className="attachment-full size-full" alt="h"  /> 
                                  </div>
                                  <div className="qodef-e qodef-image-wrapper swiper-slide ">
                                   
                                      <img loading="lazy" decoding="async" width={600} height={538} src="images/features/land.png" className="attachment-full size-full" alt="h" /> 
                                  </div>
                              
                                </div>
                                <div className="swiper-pagination" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

   
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-4abaee0 elementor-section-full_width elementor-reverse-mobile_extra elementor-reverse-mobile elementor-section-height-default elementor-section-height-default" data-id="4abaee0" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <div className="elementor-container elementor-column-gap-default">
                      <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-7485c15" data-id="7485c15" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div className="elementor-element elementor-element-2c5253c elementor-widget elementor-widget-trekon_core_image_gallery" data-id="2c5253c" data-element_type="widget" data-widget_type="trekon_core_image_gallery.default">
                            <div className="elementor-widget-container">
                              <div className="qodef-shortcode qodef-m  qodef-image-gallery  qodef-grid qodef-swiper-container    qodef-col-num--1  qodef-responsive--predefined qodef-swiper--show-pagination">
                                <div className="swiper-wrapper">

                                  <div className="qodef-e qodef-image-wrapper swiper-slide ">
                                   
                                      <img loading="lazy" decoding="async" width={600} height={538} src="images/features/1.png" className="attachment-full size-full" alt="f" /> 

                                  </div>


                                  <div className="qodef-e qodef-image-wrapper swiper-slide ">
                                   
                                      <img loading="lazy" decoding="async" width={600} height={538} src="images/features/2.png" className="attachment-full size-full" alt="f" /> 
                                  </div>

                                  <div className="qodef-e qodef-image-wrapper swiper-slide ">
                                   
                                      <img loading="lazy" decoding="async" width={600} height={538} src="images/features/3.png" className="attachment-full size-full" alt="f" /> 
                                  </div>


                                  <div className="qodef-e qodef-image-wrapper swiper-slide ">
                                   
                                      <img loading="lazy" decoding="async" width={600} height={538} src="images/features/4.png" className="attachment-full size-full" alt="f" /> 
                                  </div>

                                  <div className="qodef-e qodef-image-wrapper swiper-slide ">
                                   
                                      <img loading="lazy" decoding="async" width={600} height={538} src="images/features/5.png" className="attachment-full size-full" alt="f" /> 
                                  </div>

                                  <div className="qodef-e qodef-image-wrapper swiper-slide ">
                                   
                                      <img loading="lazy" decoding="async" width={600} height={538} src="images/features/6.png" className="attachment-full size-full" alt="f" /> 
                                  </div>
                                </div>
                                <div className="swiper-pagination" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-1a6040d" data-id="1a6040d" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div className="elementor-element elementor-element-0a0b0eb elementor-widget elementor-widget-trekon_core_custom_font" data-id="0a0b0eb" data-element_type="widget" data-widget_type="trekon_core_custom_font.default">
                            <div className="elementor-widget-container">

                              <p style={{color:'#fff', fontSize:17}}>WHY CHOOSE US?</p>
                              <p className="qodef-shortcode qodef-m  qodef-custom-font qodef-custom-font-935 qodef-layout--simple" style={{color: '#FFFFFF',  fontSize: '48px', lineHeight: '62px', letterSpacing: '0px', fontWeight: 700, fontStyle: 'normal', textTransform: 'uppercase', fontFamily:'Roboto', marginBottom:20}}>WHERE PASSION MEETS PEAK PERFORMANCE...</p>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-658352e elementor-widget elementor-widget-text-editor" data-id="658352e" data-element_type="widget" data-widget_type="text-editor.default">
                            <div className="elementor-widget-container">
                              <p><span style={{color: '#ffffff'}}>We go beyond the ordinary to exceed your expectations, ensuring that every course is a step closer to the summit of adventure.

</span></p>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-ba793da elementor-widget elementor-widget-trekon_core_button" data-id="ba793da" data-element_type="widget" data-widget_type="trekon_core_button.default">
                            <div className="elementor-widget-container">
                              <a className="qodef-shortcode qodef-m  qodef-button qodef-layout--filled  qodef-html--link" href="#" target="_blank" data-hover-color="#FFFFFF" data-hover-background-color="#202020" style={{color: '#000000', backgroundImage: 'linear-gradient(to right, #202020 0, #202020 50%, #FFFFFF 50%, #FFFFFF 100%)'}}>
                                <span className="qodef-m-text">View More</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                   <section className="elementor-section elementor-top-section elementor-element elementor-element-a417c9f elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section">
                    <div className="elementor-container elementor-column-gap-default">
                      <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9670772">
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div className="elementor-element elementor-element-2f2f08d elementor-widget elementor-widget-text-editor">
                            <div className="elementor-widget-container">
                              <p style={{textAlign: 'center'}}>OUR COURSES OFFERED</p>
                              <h2 style={{textAlign: 'center', fontWeight:'600'}}>LET THE MOUNTAINS BE YOUR CLASSROOM
</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-81249f8 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="81249f8" data-element_type="section">
                    <div className="elementor-container elementor-column-gap-default">
                      <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-ba95666" data-id="ba95666" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div className="elementor-element elementor-element-8b25a47 elementor-widget elementor-widget-trekon_core_image_with_text" data-id="8b25a47" data-element_type="widget" data-widget_type="trekon_core_image_with_text.default">
                            <div className="elementor-widget-container">
                              <div className="qodef-shortcode qodef-m  qodef-image-with-text qodef-layout--text-below qodef--view-cursor-trigger qodef--enable-border">
                                <div className="qodef-m-video">
                                  <a itemProp="url" className="qodef-m-play" href="/Mountaineering-courses" target="_blank">
                                    <span className="qodef-m-play-inner">
                                      <span className="qodef-play-btn">
                                        <span>VIEW</span></span>
                                    </span>
                                  </a>
                                  <a itemProp="url" href="/Mountaineering-courses" target="_blank"> 
                                  <img src="images/courses/mountaineering.png" />
                                  </a>
                                </div>
                                <div className="qodef-m-content">
                                  <h5 className="qodef-m-title" style={{marginTop: '23px'}}>
                                    <a itemProp="url" href="/Mountaineering-courses" target="_blank">
                                    Mountaineering </a>
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-15b7223" data-id="15b7223" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div className="elementor-element elementor-element-c52e0cb elementor-widget elementor-widget-trekon_core_image_with_text" data-id="c52e0cb" data-element_type="widget" data-widget_type="trekon_core_image_with_text.default">
                            <div className="elementor-widget-container">
                              <div className="qodef-shortcode qodef-m  qodef-image-with-text qodef-layout--text-below qodef--view-cursor-trigger qodef--enable-border">
                                <div className="qodef-m-video">
                                  <a itemProp="url" className="qodef-m-play" href="/mtb-courses" target="_blank">
                                    <span className="qodef-m-play-inner">
                                      <span className="qodef-play-btn">
                                        <span>VIEW</span></span>
                                    </span>
                                  </a>
                                  <a itemProp="url" href="/mtb-courses" target="_blank"> 
                                    <img src="images/courses/mtb.jpg" />
                                  </a>
                                </div>
                                <div className="qodef-m-content">
                                  <h5 className="qodef-m-title" style={{marginTop: '23px'}}>
                                    <a itemProp="url" href="/mtb-courses" target="_blank">
                                    Mountain Terrain Biking </a>
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-15aef7a" data-id="15aef7a" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div className="elementor-element elementor-element-3bf06e5 elementor-widget elementor-widget-trekon_core_image_with_text" data-id="3bf06e5" data-element_type="widget" data-widget_type="trekon_core_image_with_text.default">
                            <div className="elementor-widget-container">
                              <div className="qodef-shortcode qodef-m  qodef-image-with-text qodef-layout--text-below qodef--view-cursor-trigger qodef--enable-border">
                                <div className="qodef-m-image">
                                  <a itemProp="url" className="qodef-m-play" href="/scuba-diving-courses" target="_blank">
                                    <span className="qodef-m-play-inner">
                                      <span className="qodef-play-btn">
                                        <span>VIEW</span></span>
                                    </span>
                                  </a>
                                  <a itemProp="url" href="/scuba-diving-courses" target="_blank">      
                                       <img src="images/courses/scuba.png" />
                                  </a>
                                </div>
                                <div className="qodef-m-content">
                                  <h5 className="qodef-m-title" style={{marginTop: '23px'}}>
                                    <a itemProp="url" href="/scuba-diving-courses" target="_blank">
                                    Scuba Diving </a>
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-d50d108 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="d50d108" data-element_type="section">
                    <div className="elementor-container elementor-column-gap-default">
                      <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-897465f" data-id="897465f" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div className="elementor-element elementor-element-566b921 elementor-widget elementor-widget-trekon_core_image_with_text" data-id="566b921" data-element_type="widget" data-widget_type="trekon_core_image_with_text.default">
                            <div className="elementor-widget-container">
                              <div className="qodef-shortcode qodef-m  qodef-image-with-text qodef-layout--text-below qodef--view-cursor-trigger qodef--enable-border">
                                <div className="qodef-m-image">
                                  <a itemProp="url" className="qodef-m-play" href="/rafting-courses" target="_blank">
                                    <span className="qodef-m-play-inner">
                                      <span className="qodef-play-btn">
                                        <span>VIEW</span></span>
                                    </span>
                                  </a>
                                  <a itemProp="url" href="/rafting-courses" target="_blank">
                                  <img src="images/courses/rafting.jpg" /> </a>
                                </div>
                                <div className="qodef-m-content">
                                  <h5 className="qodef-m-title" style={{marginTop: '23px'}}>
                                    <a itemProp="url" href="/rafting-courses" target="_blank">
                                    White Water Rafting </a>
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-a3773ef" data-id="a3773ef" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div className="elementor-element elementor-element-8fbcccf elementor-widget elementor-widget-trekon_core_image_with_text" data-id="8fbcccf" data-element_type="widget" data-widget_type="trekon_core_image_with_text.default">
                            <div className="elementor-widget-container">
                              <div className="qodef-shortcode qodef-m  qodef-image-with-text qodef-layout--text-below qodef--view-cursor-trigger qodef--enable-border">
                                <div className="qodef-m-video">
                                  <a itemProp="url" className="qodef-m-play" href="/paragliding-courses" target="_blank">
                                    <span className="qodef-m-play-inner">
                                      <span className="qodef-play-btn">
                                        <span>VIEW</span></span>
                                    </span>
                                  </a>
                                  <a itemProp="url" href="/paragliding-courses" target="_blank">
                                    <img src="images/courses/paragliding.png" />
                                  </a>
                                </div>
                                <div className="qodef-m-content">
                                  <h5 className="qodef-m-title" style={{marginTop: '23px'}}>
                                    <a itemProp="url" href="/paragliding-courses" target="_blank">
                                    Paragliding </a>
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-afc9530" data-id="afc9530" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div className="elementor-element elementor-element-5677f0e elementor-widget elementor-widget-trekon_core_image_with_text" data-id="5677f0e" data-element_type="widget" data-widget_type="trekon_core_image_with_text.default">
                            <div className="elementor-widget-container">
                              <div className="qodef-shortcode qodef-m  qodef-image-with-text qodef-layout--text-below qodef--view-cursor-trigger qodef--enable-border">
                                <div className="qodef-m-video">
                                  <a itemProp="url" className="qodef-m-play" href="/paramotor-courses" target="_blank">
                                    <span className="qodef-m-play-inner">
                                      <span className="qodef-play-btn">
                                        <span>VIEW</span></span>
                                    </span>
                                  </a>
                                  <a itemProp="url" href="/paramotor-courses" target="_blank">
                                    <img src="images/courses/paramotor.jpg" />
                                  </a>
                                </div>
                                <div className="qodef-m-content">
                                  <h5 className="qodef-m-title" style={{marginTop: '23px'}}>
                                    <a itemProp="url" href="/paramotor-courses" target="_blank">
                                    Paramotor </a>
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
             
                  <section  style={{marginTop:100}}>
                  <div>

<video id="myVideo" preload  muted autoplay="autoplay" loop controls>
  <source src="images/nimas.mp4" type="video/mp4" />
    
</video>
</div>
                   
                  </section>

                  <section className="elementor-section elementor-top-section elementor-element elementor-element-190301c elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="190301c" data-element_type="section">
                    <div className="elementor-container elementor-column-gap-default">
                      <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-febf58e" data-id="febf58e" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div className="elementor-element elementor-element-26ccea9 elementor-widget elementor-widget-trekon_core_section_title" data-id="26ccea9" data-element_type="widget" data-widget_type="trekon_core_section_title.default">
                            <div className="elementor-widget-container">
                              <div className="qodef-shortcode qodef-m  qodef-section-title qodef-alignment--center ">
                                <h2 className="qodef-m-title" style={{fontWeight:'600'}}>
                                Endorsements </h2>
                                <p className="qodef-m-text" style={{marginTop: '8px'}}>What Our Students Have To Say About Us!</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="elementor-section elementor-top-section elementor-element elementor-element-fb915a5 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="fb915a5" data-element_type="section">
                    <div className="elementor-container elementor-column-gap-default">
                      <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5c171d7" data-id="5c171d7" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div className="elementor-element elementor-element-796abd6 elementor-widget elementor-widget-trekon_core_image_marquee" data-id="796abd6" data-element_type="widget" data-widget_type="trekon_core_image_marquee.default">
                            <div className="elementor-widget-container">
                              <div className="qodef-shortcode qodef-m  qodef-image-marquee qodef-layout--default">

                               
                              <Swiper
        slidesPerView={3}
        spaceBetween={30}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[FreeMode, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide><img src="images/testimonials/1.png" /></SwiperSlide>
        <SwiperSlide><img src="images/testimonials/2.png" /></SwiperSlide>
        <SwiperSlide><img src="images/testimonials/3.png" /></SwiperSlide>
        <SwiperSlide><img src="images/testimonials/4.png" /></SwiperSlide>
        <SwiperSlide><img src="images/testimonials/5.png" /></SwiperSlide>
        <SwiperSlide><img src="images/testimonials/6.png" /></SwiperSlide>
        <SwiperSlide><img src="images/testimonials/7.png" /></SwiperSlide>
      </Swiper>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-bdd790c elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="bdd790c" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <div className="elementor-container elementor-column-gap-default">
                      <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b695bd1 shikhar">
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div className="elementor-element elementor-element-c6cfe1a elementor-widget elementor-widget-trekon_core_custom_font" data-id="c6cfe1a" data-element_type="widget" data-widget_type="trekon_core_custom_font.default">
                            <div className="elementor-widget-container">
                              <p className="qodef-shortcode qodef-m  qodef-custom-font qodef-custom-font-89 qodef-layout--simple" style={{color: '#FFFFFF',  fontSize: '52px', lineHeight: '82px', letterSpacing: '0px', fontWeight: 700, fontStyle: 'normal', textTransform: 'uppercase', fontFamily:'Roboto'}}>ADVENTURE COURSE</p>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-ea74f27 elementor-widget elementor-widget-text-editor" data-id="ea74f27" data-element_type="widget" data-widget_type="text-editor.default">
                            <div className="elementor-widget-container">
                             
<p><span style={{color: '#ffffff', fontSize:15, lineHeight:'15px'}}>NIMAS had the privilege of hosting 18 senior-level executives for an exhilarating adventure course. From thrilling treks and essential equipment use classes to vital medical training and mountaineering knowledge sessions, it was a week packed with excitement and learning.

</span></p>                             
<p><span style={{color: '#ffffff', fontSize:15, lineHeight:'15px'}}>Our rock training sessions on natural rocks and our state-of-the-art artificial wall pushed everyone to new heights, both literally and figuratively, unleashing the leader within.


</span></p>
                            </div>
                          </div>
                        
                        </div>
                      </div>
                      <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-fff4161 shikhar" style={{display:'block'}}>
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div className="elementor-element elementor-element-d510ae5 elementor-widget elementor-widget-trekon_core_image_gallery">
                            <div className="elementor-widget-container">
                            <div>

<video id="myVideo" preload  muted autoplay="autoplay" loop controls>
  <source src="images/harshikhar.mp4" type="video/mp4" />
    
</video>
                            </div>

                            </div>




                          </div>
                        </div>


                        <div className="elementor-widget-wrap elementor-element-populated" style={{backgroundColor:'#E97949', padding:'0px 120px'}}>

                        <div className="elementor-widget-container">

                        <p><span style={{color: '#ffffff', fontSize:15, lineHeight:'15px'}}>Our adventure course for CEOs and top executives over 40 is in full swing, as high-level leaders break free from the boardroom and challenge themselves in the great outdoors.
</span></p>

  </div>

                          </div>
                      </div>
                    </div>
                  </section>
                  <section >
                    <div className="elementor-container elementor-column-gap-default">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-3">
                              <a className="soaiclasss" href="https://www.facebook.com/nimas2018" target="_blank">
                                <img src="images/social/facebook.png" />
                                <span>Facebook</span>
                              </a>
                            </div>
                            <div className="col-md-3">
                            <a className="soaiclasss" href="https://twitter.com/dirangnimas?lang=en" target="_blank">
                                <img src="images/social/twitter.png" />
                                <span>Twitter</span>
                              </a>
                            </div>
                            <div className="col-md-3">
                            <a className="soaiclasss" href="https://www.instagram.com/explore/locations/1702147523365664/nimas-dirang/?hl=en" target="_blank">
                                <img src="images/social/instagram.png" />
                                <span>Instagram</span>
                              </a>
                            </div>
                            <div className="col-md-3">
                            <a className="soaiclasss" href="https://www.youtube.com/results?search_query=NIMAS+Dirang+Arunachal+Pradesh" target="_blank">
                                <img src="images/social/youtube.png" />
                                <span>Youtube</span>
                              </a>
                            </div>
                          </div>
                        </div>
                    </div>
                  </section>
                 
                  {/* <section className="elementor-section elementor-top-section elementor-element elementor-element-783fbcb elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="783fbcb" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <div className="elementor-container elementor-column-gap-default">
                      <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9cfc3df" data-id="9cfc3df" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div className="elementor-element elementor-element-2176ddb elementor-widget elementor-widget-trekon_core_custom_font" data-id="2176ddb" data-element_type="widget" data-widget_type="trekon_core_custom_font.default">
                            <div className="elementor-widget-container">
                              <p className="qodef-shortcode qodef-m  qodef-custom-font qodef-custom-font-526 qodef-layout--simple" style={{color: '#FFFFFF',  fontSize: '80px', lineHeight: '82px', letterSpacing: '0px', fontWeight: 700, textTransform: 'uppercase'}}>TRAVERSE ANY OBSTACLE. GET TREKON</p>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-ff505d7 elementor-widget elementor-widget-trekon_core_button" data-id="ff505d7" data-element_type="widget" data-widget_type="trekon_core_button.default">
                            <div className="elementor-widget-container">
                              <a className="qodef-shortcode qodef-m  qodef-button qodef-layout--filled  qodef-html--link" href="https://qodeinteractive.com/purchase/trekon" target="_blank">
                                <span className="qodef-m-text">purchase</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>  */}
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
          <Footer />
              
        </>
  );
}

export default Home;
