import { useEffect, useState } from "react";
import React from "react";
import { Link } from 'react-router-dom';
import '../Common/custom-style.css';
import '/node_modules/bootstrap/dist/css/bootstrap.min.css';
import '/node_modules/bootstrap/dist/js/bootstrap.min.js';
import moment from 'moment'
import Headernew from '../Common/Headernew';
import { ThreeDots } from 'react-loader-spinner'

function Booking() {
  localStorage.removeItem("bookinginfo");
  localStorage.removeItem("payment-Info");
  localStorage.removeItem("Booking-Type");
  const userStudentinfo = JSON.parse(localStorage.getItem("user-Studentinfo"));
  if (userStudentinfo === null) {
    window.location.href = '/Student-admin/Login';
  }
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  const [productdata, setProductData] = useState([]);
  const Bookinglistpra = { "NumberOfFieldsView": 50, "filters": { "Email": userStudentinfo?.records[0][8]?.value }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 5 };
  const [isLoader, setIsLoader] = useState(true);
  function handleId() {
    debugger
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(Bookinglistpra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        setProductData(resp);
      });
      setIsLoader(false);
    });

  }

  useEffect(() => {
    handleId();
  }, [0])
  const upcomingisDateGreaterThanTodaynewArrayList = [];

  const currentDate = new Date(); // Get the current date
  //upcoming
  productdata?.response?.records?.forEach(obj => {
    const comparisonFromDate = new Date(obj[21]?.value);
    if (comparisonFromDate >= currentDate) {
      upcomingisDateGreaterThanTodaynewArrayList.push({ ...obj });
    }
  });
  //complete
  const completeisDateLessThanTodaynewArrayList = [];
  productdata?.response?.records?.forEach(obj => {
    const comparisonToDate = new Date(obj[22]?.value);
    if (comparisonToDate < currentDate) {
      completeisDateLessThanTodaynewArrayList.push({ ...obj });
    }
  });
  //Running
  const RunningisDateLessThanTodaynewArrayList = [];
  productdata?.response?.records?.forEach(obj => {
    const comparisonFromDate = new Date(obj[21]?.value);
    const comparisonToDate = new Date(obj[22]?.value);
    if (currentDate >= comparisonFromDate && comparisonToDate >= currentDate) {
      RunningisDateLessThanTodaynewArrayList.push({ ...obj });
    }
  });
  //const [Data,setData]=useState();
  function fun_getbookingidonclick(id) {
    localStorage.setItem("bookinginfo", id)
  }

  return (
    <>
      {isLoader && <div id="loadingg">
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#fff"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>}
      <div>
        <Headernew />
      </div>
      <main id="main" className="main">
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/student-admin/dashboard">Dashboard</a></li>
              <li className="breadcrumb-item active">My Bookings</li>
            </ol>
          </nav>
        </div>
        <div className="bookingss">
          <section className="section profile">
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body pt-3">
                    <ul className="nav nav-tabs nav-tabs-bordered">

                      <li className="nav-item">
                        <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview">Upcoming</button>
                      </li>

                      <li className="nav-item">
                        <button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit"> Completed</button>
                      </li>

                      <li className="nav-item">
                        <button className="nav-link" data-bs-toggle="tab" data-bs-target="#running"> Running</button>
                      </li>



                    </ul>
                    <div className="tab-content pt-2 booking">

                      <div className="tab-pane fade show active profile-overview pt-3" id="profile-overview">
                        <div className="row">

                          <div className="col-md-12">

                            <div className="tableresp">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Sr.</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Booking Id</th>
                                    <th scope="col">Student Name</th>
                                    <th scope="col">Serial No</th>
                                    {/* <th scope="col">No of Seats</th> */}
                                    <th scope="col">Adventure Name</th>
                                    <th scope="col">Course Dates</th>
                                    <th scope="col">Transaction Id</th>
                                    <th scope="col">Payment Status</th>
                                    <th scope="col">Cancel Reason</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {upcomingisDateGreaterThanTodaynewArrayList?.map((upcoming, i) => {
                                    i++;
                                    return (
                                      <tr>
                                        <th scope="row">{i}</th>
                                        <td>{moment(upcoming[1]?.value).format("DD-MMM-YYYY hh:mm:ss")}</td>
                                        <td><a href="/student-admin/invoicenew" onClick={() => fun_getbookingidonclick(upcoming[3]?.value)}>{upcoming[3]?.value}</a></td>
                                        <td>{upcoming[24]?.value}</td>
                                        <td>{upcoming[6]?.value}</td>
                                        {/* <td>{upcoming[8]?.value}</td> */}
                                        <td>{upcoming[4]?.value} <br />{upcoming[5]?.value}</td>
                                        <td>{upcoming[21]?.value}<br /> / {upcoming[22]?.value}</td>
                                        <td> {upcoming[26]?.value}</td>
                                        <td><span className={`datee 
                                       ${upcoming[19]?.value === 'Confirmed' ? 'text-green' :
                                            upcoming[19]?.value === 'In-Review' ? 'text-blue' :
                                              upcoming[19]?.value === 'Payment Upload' ? 'text-blue' :
                                                upcoming[19]?.value === 'Failed' ? 'text-red' :
                                                  upcoming[19]?.value === 'Cancel' ? 'text-red' : ''
                                          }`
                                        }>{upcoming[19]?.value}</span><br />
                                          <span className={`datee11 
                                       ${upcoming[27]?.value === '' ? 'text-blue' : ''}`}>
                                            {upcoming[19]?.value === 'Payment Upload' ? "/ Document Pending" : ''}</span>
                                        </td>
                                        <td>{upcoming[28]?.value}</td>
                                      </tr>
                                    );
                                  })}

                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="tab-pane fade profile-edit pt-3 booking" id="profile-edit">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="tableresp">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Sr.</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Booking Id</th>
                                    <th scope="col">Student Name</th>
                                    <th scope="col">Serial No</th>
                                    {/* <th scope="col">No of Seats</th> */}
                                    <th scope="col">Adventure Name</th>
                                    <th scope="col">Course Dates</th>
                                    <th scope="col">Transaction Id</th>
                                    <th scope="col">Payment Status</th>
                                    <th scope="col">Cancel Reason</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {completeisDateLessThanTodaynewArrayList?.map((complete, i) => {
                                    i++;
                                    return (
                                      <tr>
                                        <th scope="row">{i}</th>
                                        <td>{moment(complete[1]?.value).format("DD-MMM-YYYY hh:mm:ss")}</td>
                                        <td><a href="/student-admin/invoicenew" onClick={() => fun_getbookingidonclick(complete[3]?.value)}>{complete[3]?.value}</a></td>
                                        <td>{complete[24]?.value}</td>
                                        <td>{complete[6]?.value}</td>
                                        {/* <td>{complete[8]?.value}</td> */}
                                        <td>{complete[4]?.value} <br />{complete[5]?.value}</td>
                                        <td>{complete[21]?.value} <br />/ {complete[22]?.value}</td>
                                        <td> {complete[26]?.value}</td>
                                        <td><span className={`datee 
                                       ${complete[19]?.value === 'Confirmed' ? 'text-green' :
                                            complete[19]?.value === 'In-Review' ? 'text-blue' :
                                              complete[19]?.value === 'Payment Upload' ? 'text-blue' :
                                                complete[19]?.value === 'Failed' ? 'text-red' :
                                                  complete[19]?.value === 'Cancel' ? 'text-red' : ''
                                          }`
                                        }>{complete[19]?.value}</span><br />
                                          <span className={`datee11 
                                       ${complete[27]?.value === '' ? 'text-blue' : ''}`}>
                                            {complete[19]?.value === 'Payment Upload' ? "/ Document Pending" : ''}</span>

                                        </td>
                                        <td>{complete[28]?.value}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade profile-edit pt-3 booking" id="running">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="tableresp">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Sr.</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Booking Id</th>
                                    <th scope="col">Student Name</th>
                                    <th scope="col">Serial No</th>
                                    {/* <th scope="col">No of Seats</th> */}
                                    <th scope="col">Adventure Name</th>
                                    <th scope="col">Course Dates</th>
                                    <th scope="col">Transaction Id</th>
                                    <th scope="col">Payment Status</th>
                                    <th scope="col">Cancel Reason</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {RunningisDateLessThanTodaynewArrayList?.map((Running, i) => {
                                    i++;
                                    return (
                                      <tr>
                                        <th scope="row">{i}</th>
                                        <td>{moment(Running[1]?.value).format("DD-MMM-YYYY hh:mm:ss")}</td>
                                        <td><a href="/student-admin/invoicenew" onClick={() => fun_getbookingidonclick(Running[3]?.value)}>{Running[3]?.value}</a></td>
                                        <td>{Running[24]?.value}</td>
                                        <td>{Running[6]?.value}</td>
                                        {/* <td>{Running[8]?.value}</td> */}
                                        <td>{Running[4]?.value} <br />{Running[5]?.value}</td>
                                        <td>{Running[21]?.value}<br /> / {Running[22]?.value}</td>
                                        <td> {Running[26]?.value}</td>
                                        <td><span className={`datee 
                                       ${Running[19]?.value === 'Confirmed' ? 'text-green' :
                                            Running[19]?.value === 'In-Review' ? 'text-blue' :
                                              Running[19]?.value === 'Payment Upload' ? 'text-blue' :
                                                Running[19]?.value === 'Failed' ? 'text-red' :
                                                  Running[19]?.value === 'Cancel' ? 'text-red' : ''
                                          }`
                                        }>{Running[19]?.value}</span><br />
                                          <span className={`datee11 
                                       ${Running[27]?.value === '' ? 'text-blue' : ''}`}>
                                            {Running[19]?.value === 'Payment Upload' ? "/ Document Pending" : ''}</span>
                                        </td>
                                        <td>{Running[28]?.value}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}

export default Booking;
