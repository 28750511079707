import React, { useEffect, useState } from "react";
import '../Common/custom-style.css';
import Headernew from '../Common/Headernew';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import ApiIcon from '@mui/icons-material/Api';
import moment from 'moment'


function Dashboard() {
  const userStudentinfo = JSON.parse(localStorage.getItem("user-Studentinfo"));
  if (userStudentinfo === null) {
    window.location.href = '/Student-admin/Login';
  }

  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  const [totalbooking, setTolatbooking] = useState();
  const [Complete, setComplete] = useState(0);
  const [upcoming, setupcoming] = useState(0);
  const [productdata, setProductData] = useState([]);
  const Bookinglistpra = { "NumberOfFieldsView": 50, "filters": { "Email": userStudentinfo?.records[0][8]?.value }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 5 };

  function handleId() {
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(Bookinglistpra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        setProductData(resp);
        setTolatbooking(resp.response.records.length);
        const currentDate = new Date(); // Get the current date
        resp.response.records?.forEach(obj => {
          const comparisonGDate = new Date(obj[22].value);
          if (comparisonGDate > currentDate) {
            //isDateGreaterThanTodaynewArrayList.push({ ...obj });
            ub++;
            setupcoming(ub);
          }
        });

        resp.response.records?.forEach(obj => {
          const comparisonLDate = new Date(obj[22].value);
          if (comparisonLDate < currentDate) {
            cb++;
            setComplete(cb);
          }
        });
      });
    });
  }
  function fun_getbookingidonclick(id) {
    localStorage.setItem("bookinginfo", id)
  }

  useEffect(() => {
    handleId();
  }, [])
  let ub = 0;
  let cb = 0;
  return (
    <>
      <div> <Headernew /></div>
      <main id="main" className="main dashmain">
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/" target="_blank">Home</a></li>

              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-xxl-4 col-md-4">
                  <div className="card info-card sales-card"><div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <DashboardIcon />
                      </div>
                      <div className="ps-3">
                        <h5 className="card-title">Total Bookings</h5>
                        <h6>{totalbooking}</h6>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-md-4">
                  <div className="card info-card revenue-card">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <AcUnitIcon />
                        </div>
                        <div className="ps-3">
                          <h5 className="card-title">Upcoming Bookings</h5>
                          <h6>{upcoming}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-md-4">
                  <div className="card info-card customers-card">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <ApiIcon />
                        </div>
                        <div className="ps-3">
                          <h5 className="card-title">Previous Bookings </h5>
                          <h6>{Complete}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xxl-4 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="news">
                        <img src="../admin/img/images.jfif" alt="" className="blink" />
                        <div className="post-item clearfix">
                          <h3 className="text-center"><b>10% OFF</b> on Basic paramotor Course (BPMC)
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-xxl-12 col-md-12">
                  <div className="card info-card customers-card">
                    <div className="card-body text-center">
                      <h5 className="card-title pulse2">Adventure is waiting for you.<br />Go on a new adventure</h5>
                      <div className="d-flex align-items-center text-center justify-content-center">
                        <div className="ps-3">
                          <a href="/" className="btn btn-primary" style={{ margin: '0 auto', display: 'block' }}><i className="bx bx-run"></i> Book a Course</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card recent-sales overflow-auto">
                <div className="card-body">
                  <h5 className="card-title">My Bookings </h5>
                  <div className="tableresp">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Sr.</th>
                          <th scope="col">Date</th>
                          <th scope="col">Booking Id</th>
                          <th scope="col">Student Name</th>
                          <th scope="col">Serial No</th>
                          {/* <th scope="col">No of Seats</th> */}
                          <th scope="col">Adventure Name</th>
                          <th scope="col">Course Dates</th>
                          <th scope="col">Payment Status</th>
                          <th scope="col">Cancel Reason</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productdata?.response?.records?.map((studinfo, i) => {
                          i++;
                          return (
                            <tr className={i % 2 === 0 ? 'even-row' : 'odd-row'}>
                              <th scope="row">{i}</th>
                              <td>{moment(studinfo[1]?.value).format("DD-MMM-YYYY hh:mm:ss")}</td>
                              <td><a href="/student-admin/invoicenew" onClick={() => fun_getbookingidonclick(studinfo[3]?.value)}>{studinfo[3]?.value}</a></td>
                              <td>{studinfo[24]?.value}</td>
                              <td>{studinfo[6]?.value}</td>
                              {/* <td>{studinfo[8].value}</td> */}
                              <td>{studinfo[4]?.value}<br />({studinfo[5]?.value})</td>
                              <td>{studinfo[21]?.value}<br />/{studinfo[22]?.value}</td>
                              <td><span className=
                                {`datee 
                                  ${studinfo[19]?.value === 'Confirmed' ? 'text-green' :
                                    studinfo[19]?.value === 'In-Review' ? 'text-blue' :
                                      studinfo[19]?.value === 'Payment Upload' ? 'text-blue' :
                                        studinfo[19]?.value === 'Failed' ? 'text-red' :
                                          studinfo[19]?.value === 'Cancel' ? 'text-red' : ''
                                  }`
                                }
                              >{studinfo[19]?.value}</span><br />
                                {/* {studinfo[27].value !==""?"/ Document Uploaded" : "/ Document Pending"} */}
                                <span className={`datee11 
                                ${studinfo[27]?.value === '' ? 'text-blue' : ''}`}>
                                  {studinfo[19]?.value === 'Payment Upload' ? "/ Document Pending" : ''}</span>
                              </td>
                              <td>{studinfo[28]?.value}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div></div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
export default Dashboard;