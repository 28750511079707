import React, {useEffect, useState } from "react";
import Header from '../Common/Header';
import Footer from '../Common/Footer';


function Story() {



  return (
        <>
          <Header />
          <div className="wrapper">
          <div className="bradcumb-area style-1 overlay-bg-2">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col">
						
							<div className="bradcumb text-center">
								<h3>Story</h3>
							</div>
						
						</div>
					</div>
				</div>
			</div>

          </div>

          <div className="about-adventure-area pt-110 pb-200">
				<div className="container">
					
					<div className="row justify-content-center">
						<div className="col-lg-10">
							<p className="text-center mb-45 wow fadeInDown">
							The inception of the National Institute of Mountaineering and Adventure Sports
(NIMAS) can be traced back to a symbolic moment: during the commemoration of the
Golden Jubilee of the 'First Ascent' of Mt Everest on May 20, 2003, Prime Minister Shri
Atal Bihari Vajpayee announced the establishment of a National Level institute. This
institution was envisioned to provide adventure sports and activities across the North
East States of India, marking the metaphorical birth of NIMAS. 
 </p>
							
							<p className="text-center mb-45 wow fadeInDown">
							Following the Government of India's mandate in 2012 for the establishment of an
advanced sports training institute in Arunachal Pradesh, the National Institute of
Mountaineering and Adventure Sports (NIMAS) embarked on a gradual yet
determined journey. Transitioning from the confines of official documentation to
offering structured training accessible to all Indian nationals, the institute has emerged
as a leader in the field of adventure. Through successful collaboration between the
Ministry of Defence, Government of India, the Government of Arunachal Pradesh, and
the dedicated "Team NIMAS," the institute has not only become the country's
pioneering venture of its kind but also a beacon of excellence in adventure training.

							</p>
						</div>
					</div>
					
				</div>
			</div>
          <Footer />
              
        </>
  );
}

export default Story;
